import Vue from 'vue';
import App from './App.vue';
import store from './store';
import Toasted from 'vue-toasted';
import VueMask from 'v-mask';
import VueRouter from 'vue-router';
import './intercom';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

extend('emailOrPhone', {
  ...rules.required,
  message: 'E-mail or Phone field is required'
});

extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
  message: 'This value must be a valid URL',
})

import { DropdownPlugin } from 'bootstrap-vue/esm/components/dropdown'; 
import { CollapsePlugin } from 'bootstrap-vue/esm/components/collapse'; 
import { PopoverPlugin } from 'bootstrap-vue/esm/components/popover'; 
import { TooltipPlugin } from 'bootstrap-vue/esm/components/tooltip'; 
import { ButtonPlugin } from 'bootstrap-vue/esm/components/button'; 
import { CarouselPlugin } from 'bootstrap-vue/esm/components/carousel'; 
import { ModalPlugin } from 'bootstrap-vue/esm/components/modal';

import "./components/viewer/viewer";
import { HelpersMixin } from './mixins/helpers-mixin';
import handleError from "./interceptors/error-handler";
import {CloseIcon} from './components/svg-icons';
import InvalidFeedback from './components/invalid-feedback/InvalidFeedback';
import { routes } from '@/routes';

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueRouter);
export const router = new VueRouter({
  mode: 'history',
  routes
})
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(PopoverPlugin);
Vue.use(TooltipPlugin);
Vue.use(ButtonPlugin);
Vue.use(CarouselPlugin);
Vue.use(ModalPlugin);
Vue.use(Toasted, {
  duration: 3000,
  containerClass: 'lavamapToasted',
  iconPack: "custom-class"
});

Vue.component('CloseIcon', CloseIcon);
Vue.component('InvalidFeedback', InvalidFeedback);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.mixin(HelpersMixin);

window.onerror = (
  message,
  url,
  lineNo,
  columnNo,
  error
) => {
  if (error) handleError(error);
}
Vue.config.errorHandler = (error) => handleError(error);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
