<template>
  <div>
    <ul class="menu-list list-group menu-page" :class="{active: menuPage === MenuPages.MAIN}">
      <li v-if="showEditMode() && !editMode" class="list-group-item">
        <div class="menu-icon">
          <EditIcon style="width:15px"/>
        </div>
        <a class="menu-link" @click="toggleEditMode(!editMode)">
          <div class="title">Edit Tour</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>
      <li v-if="editMode" class="list-group-item">
        <div class="menu-icon">
          <EditIcon style="width:15px"/>
        </div>
        <a class="menu-link" @click="set_menu_page(MenuPages.TOUR_META)">
          <div class="title">Edit Tour Info</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>
      <li class="list-group-item" :class="{active: controlsState === CONTROLS_STATE.MEASURE_DISTANCE}">
        <div class="menu-icon">
          <DistanceIcon class="stroke-text-color"/>
        </div>
        <a class="menu-link" @click="toggleControlsState(CONTROLS_STATE.MEASURE_DISTANCE)">
          <div class="title">Measure Distance</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>

      <li v-if="mode === VIEWER_MODES.FLOORPLAN" class="list-group-item"
          :class="{active: controlsState === CONTROLS_STATE.MEASURE_AREA}">
        <div class="menu-icon">
          <Area/>
        </div>
        <a class="menu-link" @click="toggleControlsState(CONTROLS_STATE.MEASURE_AREA)">
          <div class="title">Calculate Area</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>
      <li v-if="mode !== VIEWER_MODES.FLOORPLAN && !editMode" class="list-group-item">
        <div class="menu-icon">
          <Tag/>
        </div>
        <a class="menu-link">
          <div class="title">Feature Labels</div>
          <Switcher @onSwitch="toggle_features(!showFeatures)" :state="showFeatures"
                    class="ml-auto">
            <template v-slot:onValue>On</template>
            <template v-slot:offValue>Off</template>
          </Switcher>
          <button
              v-if="controlsState === CONTROLS_STATE.EDIT_FEATURE"
              @click="newFeature()"
              class="btn btn-add-room ml-3 mr-n2 p-2"
          >
            <i class="las la-plus"></i>
          </button>
        </a>
      </li>
      <li v-if="editMode" class="list-group-item">
        <div class="menu-icon">
          <Tag/>
        </div>
        <a class="menu-link" @click="newFeature()">
          <div class="title">Add Feature Label</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>
      <li v-if="!editMode" class="list-group-item">
        <div class="menu-icon">
          <RoomIcon/>
        </div>
        <a class="menu-link">
          <div class="title">Room Labels</div>
          <Switcher v-if="mode !== VIEWER_MODES.PANO" @onSwitch="toggleNames(!showNames)" :state="showNames"
                    class="ml-auto">
            <template v-slot:onValue>On</template>
            <template v-slot:offValue>Off</template>
          </Switcher>
          <Switcher v-else @onSwitch="toggleNames(!panoShowNames)" :state="panoShowNames" class="ml-auto">
            <template v-slot:onValue>On</template>
            <template v-slot:offValue>Off</template>
          </Switcher>
          <button
              v-if="controlsState === CONTROLS_STATE.EDIT_ROOM_LABEL &&
              selectedRoom.state !== SELECTED_ROOM_STATES.NEW &&
              selectedRoom.state !== SELECTED_ROOM_STATES.MOVE"
              @click="moveRoom(SELECTED_ROOM_STATES.NEW); toggleMobileMenu(false);"
              class="btn btn-add-room ml-3 mr-n2 p-2"
          >
            <i class="las la-plus"></i>
          </button>
        </a>
      </li>

      <li v-if="editMode" class="list-group-item">
        <div class="menu-icon">
          <RoomIcon/>
        </div>
        <a class="menu-link" @click="enterFloorplan(),setControlsState(CONTROLS_STATE.EDIT_ROOM_LABEL), moveRoom(SELECTED_ROOM_STATES.NEW); toggleMobileMenu(false);">
          <div class="title">Add Room Label</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>

      <li v-if="editMode" class="list-group-item">
        <div class="menu-icon">
          <EntryArrowIcon/>
        </div>
        <a class="menu-link" @click="enterFloorplan(),toggleControlsState(CONTROLS_STATE.EDIT_ENTRY_ARROW),toggleArrow()">
          <div class="title">Place Entry Arrow</div>
          <i class="las la-angle-right ml-auto link-arrow "></i>
        </a>
      </li>
      <template v-if="mode === VIEWER_MODES.FLOORPLAN">
        <li class="list-group-item">
          <div class="menu-icon">
            <SquareFeetIcon />
          </div>
          <a class="menu-link" @click="set_menu_page(MenuPages.STATISTICS)">
            <div class="title">{{ sumArea }} sq ft</div>
            <div class="ml-auto text-secondary">View Floor Areas</div>
            <i class="las la-angle-right ml-3 link-arrow"></i>
          </a>
        </li>
      </template>
      <li class="list-group-item" v-if="!inIframe && !editMode">
        <div class="menu-icon">
          <ShareIcon/>
        </div>
        <a class="menu-link" @click="set_menu_page(MenuPages.SHARE)">
          <div class="title">Share</div>
          <i class="las la-angle-right ml-auto link-arrow"></i>
        </a>
      </li>
      <li class="list-group-item" v-if="!inIframe">
        <div class="menu-icon">
          <UserIcon/>
        </div>
        <a class="menu-link" @click="set_menu_page(MenuPages.AUTH)">
          <div v-if="name" class="title">{{ name }}</div>
          <div v-else class="title">My Account</div>
          <i class="las la-angle-right ml-auto link-arrow"></i>
        </a>
      </li>
    </ul>
    <div v-if="mode === VIEWER_MODES.FLOORPLAN" class="menu-page" :class="{active: menuPage === MenuPages.STATISTICS}">
      <BackMenu :title="''" @onBack="set_menu_page(MenuPages.MAIN)" class="position-absolute"/>
      <Statistics/>
    </div>
    <div v-if="!inIframe" :class="{active: menuPage === MenuPages.SHARE, 'menu-page-height': editMode}" class="menu-page">
      <BackMenu title="Share" @onBack="set_menu_page(MenuPages.MAIN)" class="mb-3"/>
      <div class="overflow-auto">
        <Share/>
      </div>
    </div>
    <div v-if="!inIframe" :class="{active: menuPage === MenuPages.AUTH}" class="menu-page auth-page">
      <BackMenu title="My Account" @onBack="set_menu_page(MenuPages.MAIN)" class="mb-3"/>
      <div class="menu-page-padding">
        <button v-if="name" @click="$emit('signOut')" class="btn btn-block btn-light btn-control shadow">
          <LogoutIcon class="mr-3"/>Sign Out
        </button>
        <button v-else @click="toggleMobileMenu(false); $bvModal.show(signInModal)"
                class="btn btn-block btn-light btn-control shadow">
          <user-icon class="mr-3"/> <span>Sign In </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Statistics from '../../../statistics/Statistics';
import BackMenu from '../back-menu/BackMenu';
import Share from '@/components/controls/share/Share';
import { FeatureFormModes, MenuPages, SELECTED_ROOM_STATES, UserRoles } from '@/constants';
import { UserIcon, ShareIcon, Tag, RoomIcon, SquareFeetIcon, EntryArrowIcon} from '@/components/svg-icons';
import DistanceIcon from '@/components/svg-icons/distance';
import Switcher from '@/components/switcher/Switcher';
import Area from '@/components/svg-icons/area';
import EditIcon from '@/components/svg-icons/edit';
import LogoutIcon from '@/components/svg-icons/logout';

export default {
  name: 'MobileMain',
  components: {
    LogoutIcon,
    EditIcon,
    Area,
    Switcher, DistanceIcon, SquareFeetIcon, RoomIcon, Tag, ShareIcon, Share, BackMenu, Statistics, UserIcon, EntryArrowIcon},
  data() {
    return {
      MenuPages: MenuPages,
      SELECTED_ROOM_STATES
    }
  },
  computed: {
    ...mapState('mobile', ['menuPage']),
    ...mapState('viewer', ['viewer', 'tour']),
    ...mapState('floors', ['floors']),
    ...mapState('controls', ['mode', 'showNames', 'panoShowNames', 'showFeatures']),
    ...mapState('users', ['name']),
    ...mapState('rooms', ['selectedRoom'])
  },
  methods: {
    ...mapMutations('mobile', ['set_menu_page']),
    ...mapMutations('controls', ['toggle_features']),
    ...mapMutations('features', ['set_feature_mode']),
    ...mapActions('viewer', ['loadTour']),
    ...mapActions('controls', ['setControlsState', 'toggleNames', 'toggleEditMode', 'toggleArrow']),
    ...mapActions('mobile', ['toggleMobileMenu']),
    ...mapActions('rooms', ['moveRoom']),
    toggleControlsState(target) {
      this.setControlsState(target);
      this.toggleMobileMenu(false);
    },
    enterFloorplan() {
      if (this.mode !== this.VIEWER_MODES.FLOORPLAN) this.viewer.setMovingMode(this.VIEWER_MODES.FLOORPLAN)
    },
    newFeature() {
      if (this.mode === this.VIEWER_MODES.FLOORPLAN) this.viewer.setMovingMode(this.VIEWER_MODES.ORBIT)
      this.viewer.controls.minPanoPitch = Math.PI-0.01;
      this.viewer.controls.maxPanoPitch = 0.01;
      this.set_feature_mode(FeatureFormModes.NEW);
      this.setControlsState(this.CONTROLS_STATE.MOVE_FEATURE);
      this.toggleMobileMenu(false);
    },
    showEditMode() {
        return this.userRole === UserRoles.ADMIN || this.userRole === UserRoles.TOUR_OWNER
    },
  },
}
</script>

<style scoped src="../MobilePage.scss" lang="scss"></style>
<style scoped src="./MobileMain.scss" lang="scss"></style>
