<template>
  <svg
    width="25"
    height="19"
    viewBox="0 0 28 22"
    
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.994597 2.33555C0.325316 2.80242 0.188477 3.17351 0.188477 3.41815C0.188477 3.58376 0.273964 3.8513 0.704735 4.22114C1.13351 4.58926 1.78226 4.94683 2.58891 5.27126C4.19342 5.91658 6.15716 6.32693 7.47467 6.50153C8.01915 6.57369 8.42622 7.04155 8.42622 7.59518V13.4576C11.9773 13.0769 15.9857 13.0782 19.5339 13.4615V7.59518C19.5339 7.04704 19.9332 6.58206 20.4713 6.50351C21.8936 6.29588 23.8481 5.88457 25.4184 5.24852C26.2078 4.92877 26.8322 4.57892 27.2415 4.21988C27.6477 3.86353 27.7355 3.59858 27.7355 3.41815C27.7355 3.1918 27.6064 2.82776 26.9324 2.36102C26.266 1.89947 25.2398 1.4689 23.8996 1.10593C21.2347 0.38418 17.6201 0.00591087 13.9603 6.87148e-05C10.3001 -0.0057739 6.68663 0.361023 4.02331 1.07951C2.68399 1.44082 1.65957 1.87168 0.994597 2.33555ZM27.7355 6.56117C27.2655 6.84275 26.7529 7.08552 26.2342 7.29558C24.7832 7.88331 23.1061 8.28526 21.7222 8.53397V21.8729C22.8266 21.6597 24.1392 21.3158 25.2817 20.8395C26.0901 20.5025 26.7469 20.1262 27.1842 19.7331C27.6188 19.3425 27.7355 19.0328 27.7355 18.7972V6.56117ZM0.188477 6.54413V18.7972C0.188477 18.959 0.27971 19.2445 0.737897 19.647C1.18668 20.0413 1.85786 20.4278 2.67529 20.7786C3.82779 21.2733 5.14319 21.6434 6.2379 21.8672V8.54163C4.89903 8.30711 3.23462 7.90615 1.77803 7.32032C1.22884 7.09944 0.684658 6.8427 0.188477 6.54413Z"
      
    />
  </svg>
</template>
<script>
export default {
  name: "PanoIcon",
};
</script>
