/* eslint-disable no-unused-vars */
import { mapActions, mapMutations, mapState } from "vuex";

import firebase from "firebase/app";
import "firebase/auth";

import config from "@/config";
import { UserRoles } from "@/constants";

export const AuthMixin = {
  async created() {
    const search = new URL(document.location).searchParams;
    const id = search.get("tour_id");

    if (this.inIframe) {
      const token = search.get("token");
      if (token) {
        try {
          await firebase.auth().signInWithCustomToken(token);
        } catch (e) {
          console.log("Error signing in with custom token", e);
        }
      } else {
        console.log("No token to sign in with");
      }
    }

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let name = user.displayName;
        if (!name && user.email) {
          name = user.email.split('@')[0];
        }
        this.set_name(name);
        let token = await user.getIdToken();
        this.getRoles({token, tour_id: id});
      }
    });
  },
  methods: {
    ...mapActions("users", ["getRoles"]),
    ...mapActions("controls", ["toggleEditMode"]),
    ...mapMutations("users", ["set_name", "set_role", "set_permissions"]),
    async signOut() {
      this.set_name("");
      this.set_role(UserRoles.VISITOR);
      this.set_permissions(config.permissions[UserRoles.VISITOR]);
      this.toggleEditMode(false);

      await firebase.auth().signOut();
    },
  },
  computed: {
    ...mapState("users", ["name"]),
    ...mapState("viewer", ["tour"]),
  },
};
