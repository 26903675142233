<template>
  <div class="pano-debugger">
    <div v-if="mode === VIEWER_MODES.PANO && panoId" class="mb-3 p-3 bg-light shadow rounded d-flex flex-column align-items-center">
      <label for="pano_angle">Panorama Rotation: {{angleInDegrees.toFixed(0)}}&deg;</label>
      <input @input="onRotationEvent('onRotationInput', $event.target.value)" id="pano_angle" type="range" min="0" max="360" class="custom-range"
                    :value="angleInDegrees"/>
    </div>
    <div v-if="mode === VIEWER_MODES.PANO && panoId" class="p-3 mb-3 bg-light shadow rounded d-flex flex-column align-items-center">
      <label>Visibility Slider: {{texRatio}}</label>
      <input  v-b-tooltip.hover.right.html.v-dark="!isTouchable && `Space`" @input="togglePano($event.target.value)" type="range" min="0" max="1" step="0.01"
              class="custom-range"
                      :value="texRatio"/>
    </div>
    <b-dropdown v-if="panoId" variant="light" class="mb-3" toggle-class="btn-control shadow"
                v-b-tooltip.hover.right.html.v-dark="!isTouchable && `Prev: Alt + &lt;<br>Next: Alt + &gt;`"
    >
      <template #button-content>
        <span class="current-pano mr-auto">
          {{showPanoIndex(panoId)}} - {{ showPanoId(panoId) }}
        </span>
      </template>
      <b-dropdown-item 
          @click="navigateToPano(pano.id)" 
          v-for="(pano, name, index) in panos" 
          :value="pano.id" 
          :key="pano.id"
          :link-class="{failed: pano.currentStatus !== SUCCESS_PANO_STATUS}"
      >
        <div :class="{selected: panoId === pano.id}">
          {{index + 1}} - {{ showPanoId(pano.id) }}
        </div>
        <div v-if="pano.thumbnailUrl" :style="{backgroundImage: panoThumbnail(pano.thumbnailUrl)}" class="pano-thumbnail"></div>
      </b-dropdown-item>
    </b-dropdown>
    <div class="btn-group-vertical mb-3 shadow" v-if="mode !== VIEWER_MODES.FLOORPLAN">
      <template v-if="mode === VIEWER_MODES.PANO && panoId">
        <button @click="savePano()" class="btn btn-sm p-2 btn-success btn-block"
                v-b-tooltip.hover.right.html.v-dark="!isTouchable && `Alt + 1`"
        >Save Changes
        </button>
        <button @click="applyPano()" class="btn btn-sm p-2 btn-primary btn-block"
                v-b-tooltip.hover.right.html.v-dark="!isTouchable && `Alt + 2`"
        >Apply Changes
        </button>
        <button @click="resetPano()" class="btn btn-sm p-2 btn-warning btn-block"
                v-b-tooltip.hover.right.html.v-dark="!isTouchable && `Alt + 0`"
        >Reset Changes
        </button>
      </template>

      <template v-if="mode === VIEWER_MODES.ORBIT">
        <button @click="changeClipping(1)" class="btn btn-sm p-2 btn-light"
        >Clipping 1 meter</button>
        <button @click="changeClipping(2)" class="btn btn-sm p-2 btn-light"
        >Clipping 2 meters</button>
        <button @click="changeClipping(-1)" class="btn btn-sm p-2 btn-light"
        >Clipping default</button>
        <button @click="changeClipping(33)" class="btn btn-sm p-2 btn-light"
        >Clipping maximum</button>
      </template>
    </div>

    <div v-if="mode !== VIEWER_MODES.FLOORPLAN" class="p-3 mb-3 bg-light rounded shadow">
      <label>fly rotate speed: {{flythroughRotateSpeed}}</label>
      <input class="custom-range" @input="setFlythroughRotateSpeed($event.target.value)" ref="flythroughRotateSpeedInput"
      type="range" min="0.01" max="0.5" step="0.01" v-model="flythroughRotateSpeed"> 
      <label>fly zoom speed: {{flythroughZoomSpeed}}</label>
      <input class="custom-range" @input="setFlythroughZoomSpeed($event.target.value)" ref="flythroughZoomSpeedInput"
      type="range" min="0.01" max="0.8" step="0.01" v-model="flythroughZoomSpeed"> 
    </div>

    <div v-if="mode === VIEWER_MODES.PANO && panoId" class="pano-controls">
      <div class="xy-axis mr-3 d-inline-block">
        <div class="text-center">
          <button @mouseup="stopMove()" @mouseleave="stopMove()" @mousedown="movePano('forward')"
                  @touchstart="movePano('forward')" @touchend="stopMove()" @touchmove="stopMove()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + W`"
                  class="btn-control btn-icon btn btn-light up mx-auto"><i
              class="las la-lg la-arrow-up"></i></button>
        </div>
        <div class="btn-group">
          <button @mouseup="stopMove()" @mouseleave="stopMove()" @mousedown="movePano('left')"
                  @touchstart="movePano('left')" @touchend="stopMove()" @touchmove="stopMove()"
                  v-b-tooltip.hover.topright.html.v-dark="!isTouchable && `Alt + A;`"
                  class="btn-control btn-icon btn btn-light left"><i
              class="las la-lg la-arrow-left"></i></button>
          <button @mouseup="stopMove()" @mouseleave="stopMove()" @mousedown="movePano('backward')"
                  @touchstart="movePano('backward')" @touchend="stopMove()" @touchmove="stopMove()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + S`"
                  class="btn-control btn-icon btn btn-light down"><i
              class="las la-lg la-arrow-down"></i></button>
          <button @mouseup="stopMove()" @mouseleave="stopMove()" @mousedown="movePano('right')"
                  @touchstart="movePano('right')" @touchend="stopMove()" @touchmove="stopMove()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + D`"
                  class="btn-control btn-icon btn btn-light right"><i
              class="las la-lg la-arrow-right"></i></button>
        </div>
      </div>
      <div class="z-axis">
        <div class="btn-group-vertical shadow">
          <button @mouseup="stopMove()" @mouseleave="stopMove()" @mousedown="movePano('top')"
                  @touchstart="movePano('top')" @touchend="stopMove()" @touchmove="stopMove()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + E`"
                  class="btn-control btn-icon btn btn-light"><i
              class="las la-lg la-upload"></i></button>
          <button @mouseup="stopMove()" @mouseleave="stopMove()" @mousedown="movePano('bottom')"
                  @touchstart="movePano('bottom')" @touchend="stopMove()" @touchmove="stopMove()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Q`"
                  class="btn-control btn-icon btn btn-light"><i
              class="las la-lg la-upload rotate-180"></i></button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { SUCCESS_PANO_STATUS } from '@/constants';

  export default {
    name: 'PanoDebugger',
    props: ['rotation', 'panoId', 'panos'],
    data() {
      return {
        angleInDegrees: 0,
        texRatio: 1,
        SUCCESS_PANO_STATUS,
        flythroughRotateSpeed: 0.05,
        flythroughZoomSpeed: 0.1
      }
    },
    computed: {
      ...mapState('controls', ['mode'])
    },
    watch: {
      rotation() {
        this.angleInDegrees = this.rotation * 180 / Math.PI;
      }
    },
    methods: {
      setFlythroughRotateSpeed(value) {
        this.$emit('onSetFlythroughRotateSpeed', value)
        this.$refs.flythroughRotateSpeedInput.blur()
      },
      setFlythroughZoomSpeed(value) {
        this.$emit('onSetFlythroughZoomSpeed', value)
        this.$refs.flythroughZoomSpeedInput.blur()
      },
      togglePano(value) {
        this.texRatio = value
        this.$emit('onPanoToggle', value)
      },
      showPanoIndex(id) {
        return id ? Object.keys(this.panos).indexOf(id) + 1 : '0';
      },
      showPanoId(id) {
        return id ? id.split('/').pop().split('-')[0] : ''
      },
      onRotationEvent(name, value) {
        this.angleInDegrees = +value;
        this.$emit(name, this.angleInDegrees - this.rotation * 180 / Math.PI);
      },
      savePano() {
        this.$emit('onPanoSave');
      },
      applyPano() {
        this.$emit('onPanoApply');
      },
      resetPano() {
        this.$emit('onPanoReset');
      },
      navigateToPano(id) {
        this.$emit('onPanoSelect', id);
      },
      changeClipping(height) {
        this.$emit('onClippingChange', height);
      },
      movePano(direction) {
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.$emit('onPanoMove', direction);
          }, 100);
        }
      },
      stopMove() {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      },
      handleKeyUp() {
        this.stopMove();
      },

      handleKeyDown(e) {
      let target = e.target.nodeName.toLowerCase();
      if (target !== 'input' && target !== 'textarea') {
        if (e.altKey) {
          switch (e.code) {
            case 'KeyE':
              this.movePano('top');
              break;
            case 'KeyQ':
              this.movePano('bottom');
              break;
            case 'KeyW':
              this.movePano('forward');
              break;
            case 'KeyS':
              this.movePano('backward');
              break;
            case 'KeyD':
              this.movePano('right');
              break;
            case 'KeyA':
              this.movePano('left');
              break;
            default:
              break;
          }
        }

        switch (e.code) {
          case 'Digit1':
            this.savePano();
            break;
          case 'Digit2':
            this.applyPano();
            break;
          case 'Digit0':
            this.resetPano();
            break;
          case 'Space':
            this.togglePano(this.texRatio>0? 0:1);
            break;
          case 'KeyV':
            this.togglePano(0.5);
            break;
          case 'Period':
            this.navigateToPano(this.findNextPanoId('next'));
            break;
          case 'Comma':
            this.navigateToPano(this.findNextPanoId('prev'));
            break;
          }
        }
      },
      findNextPanoId(adjacent) {
        let ids = Object.keys(this.panos);
        const index = ids.findIndex(id => id === this.panoId);
        let length = ids.length;
        if (index === 0 && adjacent === 'prev') {
          return ids[length - 1];
        }
        if (index === length - 1 && adjacent === 'next') {
          return ids[0];
        }
        return ids[adjacent === 'next' ? index + 1 : index - 1];
      }
    },
    mounted() {
      this.angleInDegrees = this.rotation * 180 / Math.PI;
      window.addEventListener('keydown', this.handleKeyDown);
      window.addEventListener('keyup', this.handleKeyUp);

    },
    destroyed() {
      window.removeEventListener('keydown', this.handleKeyDown);
      window.removeEventListener('keyup', this.handleKeyUp);
    }
  }
</script>

<style lang="scss" src="./PanoDebugger.scss" scoped></style>
