import * as THREE from 'three'
import { createImagePlaneBoxMaterial} from '../shaders'

class ModelManager {
  constructor(viewer) {
    this.viewer = viewer
  }

  //actually a sphere but this is the fallback to project the pano onto
  //if there are gaps in the mesh
  createImagePlaneBox = (geometry) => {
    let min = geometry.boundingBox.min
    let max = geometry.boundingBox.max
    const center = new THREE.Vector3()
    geometry.boundingBox.getCenter(center)
    const x = Math.abs(min.x) + Math.abs(max.x)
    const y = Math.abs(min.y) + Math.abs(max.y)
    const z = (Math.abs(min.z) + Math.abs(max.z))
    const maxDimension = Math.max(x, y, z)

    const sphereGeo = new THREE.SphereGeometry(maxDimension * 8, 16, 16)

    return new THREE.Mesh(sphereGeo, createImagePlaneBoxMaterial());
  }

  processPanos = (panos) => {
    const cameraIds = []
    let shot_id;
    for (shot_id in panos) {
      if (Object.prototype.hasOwnProperty.call(panos, shot_id)) {
        cameraIds.push(shot_id)
      }
    }
    return cameraIds
  }

  processBoundingBox = (geometry) => {
    geometry.computeBoundingBox()
    geometry.computeBoundingSphere()
    this.viewer.boundingMin = geometry.boundingBox.min
    this.viewer.boundingMax = geometry.boundingBox.max
    this.viewer.minHeight = geometry.boundingBox.min.z
    this.viewer.maxHeight = geometry.boundingBox.max.z
    this.viewer.center = geometry.boundingBox.getCenter(new THREE.Vector3())
    this.viewer.minCenter = geometry.boundingBox.getCenter(new THREE.Vector3())
    this.viewer.minCenter.z = geometry.boundingBox.min.z
  }

  processClippingPlane = (cameraIds, panos) => {
    if (cameraIds.length) {
      //find the highest pano in the case of split levels and mezzanines
      let maxPanoHeight = -Infinity
      for (let i = 0; i < cameraIds.length; i++) {
        const shot = panos[cameraIds[i]]
        const oc = this.viewer.opticalCenter(shot)
        if (oc.z > maxPanoHeight) maxPanoHeight = oc.z
      }
      return maxPanoHeight + 0.75
    } else {
      //otherwise default to 1.5 meters above the floor
      return this.viewer.defaultClippingHeight + this.viewer.minHeight
    }
  }
  
}

export default ModelManager
