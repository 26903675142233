<template>
  <div>
    <div class="menu-page menu-page-height" :class="{active: menuPage === MenuPages.FEATURE_FORM}">
      <BackMenu :title="(featureMode === FeatureFormModes.NEW ?  'Add' : 'Edit') + ' a Feature Label'" :no-back="true" @onBack="set_menu_page(MenuPages.FEATURE_DETAILS)"
                class="mb-3"/>
      <div class="menu-page-padding overflow-auto">
        <FeatureForm class="mb-4" @onCancel="toggleMobileMenu(false)" @onSave="save($event)"></FeatureForm>
      </div>
    </div>
    <div class="menu-page" :class="{active: menuPage === MenuPages.FEATURE_DETAILS}">
      <div class="menu-page-padding">
        <FeatureDetails class="mb-4" @showOwner="set_menu_page(MenuPages.FEATURE_OWNER)"></FeatureDetails>
      </div>
    </div>
    <div class="menu-page" :class="{active: menuPage === MenuPages.FEATURE_OWNER}">
      <BackMenu @onBack="set_menu_page(MenuPages.FEATURE_DETAILS)" class="mb-3"/>
      <div class="menu-page-padding">
        <OwnerDetails :data="selectedOwner"/>
      </div>
    </div>
    <div class="menu-page" :class="{active: menuPage === MenuPages.FEATURE_LIST}">
      <div class="menu-page-padding">
        <h5 class="my-3 px-4 font-weight-semi-bold">Tour Features</h5>
        <FeatureList class="px-4"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import BackMenu from '../back-menu/BackMenu';
import { MenuPages, FeatureFormModes } from '@/constants';
import FeatureForm from '@/components/features/new-feature/FeatureForm';
import FeatureDetails from '@/components/features/FeatureDetails';
import OwnerDetails from '@/components/owner-details/OwnerDetails';
import FeatureList from '@/components/features/FeatureList';

export default {
  name: 'MobileFeatures',
  data() {
    return {
      MenuPages: MenuPages,
      FeatureFormModes
    }
  },
  components: {FeatureList, BackMenu, FeatureForm, FeatureDetails, OwnerDetails},
  computed: {
    ...mapState('mobile', ['menuPage']),
    ...mapState('controls', ['mode']),
    ...mapState('features', ['featureMode', 'selectedOwner'])
  },
  methods: {
    ...mapMutations('mobile', ['set_menu_page']),
    ...mapActions('features', ['saveFeature']),
    ...mapActions('mobile', ['toggleMobileMenu']),
    save(data) {
      this.saveFeature({data});
      this.toggleMobileMenu();
    }
  }
}
</script>

<style scoped src="../MobilePage.scss" lang="scss"></style>
