<template>
  <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path class="fill"
        d="M19.845 1.26H17.43V0.21C17.43 0.0945 17.3355 0 17.22 0H3.78C3.6645 0 3.57 0.0945 3.57 0.21V1.26H1.155C0.848675 1.26 0.554896 1.38169 0.338292 1.59829C0.121687 1.8149 0 2.10868 0 2.415V6.3C0 8.44463 1.575 10.227 3.62775 10.5525C4.03463 13.6028 6.48375 16.0046 9.555 16.3406V19.1021H4.41C3.94537 19.1021 3.57 19.4775 3.57 19.9421V20.79C3.57 20.9055 3.6645 21 3.78 21H17.22C17.3355 21 17.43 20.9055 17.43 20.79V19.9421C17.43 19.4775 17.0546 19.1021 16.59 19.1021H11.445V16.3406C14.5163 16.0046 16.9654 13.6028 17.3722 10.5525C19.425 10.227 21 8.44463 21 6.3V2.415C21 2.10868 20.8783 1.8149 20.6617 1.59829C20.4451 1.38169 20.1513 1.26 19.845 1.26ZM1.89 6.3V3.15H3.57V8.5995C3.0824 8.44375 2.6569 8.13708 2.35494 7.72377C2.05297 7.31045 1.89016 6.81187 1.89 6.3ZM15.54 9.66C15.54 10.9489 15.0386 12.1642 14.1251 13.0751C13.2116 13.9886 11.9989 14.49 10.71 14.49H10.29C9.00113 14.49 7.78575 13.9886 6.87487 13.0751C5.96137 12.1616 5.46 10.9489 5.46 9.66V1.89H15.54V9.66ZM19.11 6.3C19.11 7.37625 18.4039 8.28975 17.43 8.5995V3.15H19.11V6.3Z"
        />
  </svg>
</template>
<script>
export default {
  name: 'GobletIcon',
}
</script>
