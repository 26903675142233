<template>
  <div
      class="wrapper"
      draggable
      @dragstart="dragStart($event)"
      @dragend="dragEnd()"
  >
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="30.48" height="39" viewBox="0 0 190 168">
     <path class="cls-1" d="M178.91103,168.23728l-85.50557-25S9.01076,168.23728,5.43513,168.23728s-5.96176-2.99219-4.49675-5.63889L88.90871,8.12384c1.74258-3.14815,7.25092-3.14815,8.9935,0l85.50557,154.47456c1.46501,2.6467-.92112,5.63889-4.49675,5.63889Z"/>
    </svg>
    <div class="ghost" ref="ghost"></div>
  </div>
</template>

<script>
  export default {
    name: 'ArrowIcon',
  }
</script>
