//Main dependencies
import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import '../../../vendor/OrbitControls';
import '../../../vendor/libpannellum';
import { createImagePlaneMaterial } from './shaders';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';

//Configs
import config from '@/config';
import {viewerOptions, controlsOptions} from './config';

//Components
import MManager from './components/measurementManager';
import ModManager from './components/modelManager';
import AManager from './components/animationController';
import FPManager from './components/floorplanManager'
import SManager from './components/sceneManager'
import labelOccluder from './components/labelOccluder'
import FLManager from './components/floorManager'
import EntryArrow from '@/components/viewer/components/entryArrow';
import Minimap from '@/components/viewer/components/minimap';
import PanoIndicators from './components/panoIndicators';
import FTControls from './components/flyThroughControls';
import TLoader from './components/tileLoader'

//Helpers
import throttle from 'lodash/throttle';
import {normalize, lerp} from './components/utils'
import {grid, targetHelper} from './components/tourHelpers'
//import { GUI } from 'dat.gui'
import Stats from 'stats.js';

//Vue
import store from '../../store';
import { VIEWER_MODES, AppStates, CONTROLS_STATE, RTSLabels } from '../../constants';
import {hasAccess as hA} from '../../constants/functions';
import { postMessagesMixin } from '../../mixins/post-messages';
import { HelpersMixin } from '@/mixins/helpers-mixin';
import gsap from 'gsap/gsap-core';

//Override Three.js prototypes for raycasting
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

window.viewer3d = ((window, document) => {

  'use strict';


  /**
   * Creates a new scene viewer.
   * @constructor
   * @param {object} config - Object with the following properties:
   *   {HTMLElement} container - The container (div) element for the viewer.
   *   {string} panos - The URL for the scene's camera position JSON file.
   *   {string} mesh - The URL for the scene's Draco mesh.
   *   {string} texture - The URL for the scene mesh's texture.
   *   {string} floorplan - The URL for the scene floorplan image.
   *   {Array} floorplan.position - Two number array specifying the center of the model.
   *   {number} floorplan.distance - The camera's vertical coordinate when viewing the floor plan.
   *   {number} floorplan.angle - The angle (degrees) to rotate the camera to when viewing the floor plan.
   *   {number} modelUnitScaleFactor - Factor to scale model units into feet.
   *   {string} imgBasePath - The URL root for the scene's panoramas.
   *   {object} pnlmMultiresConfigs - Pannellum multires configuration for each panorama.
   *   {number} cursorSize - Size of cursor and camera footprint indicator.
   *   {number} minModelZoom - Minimum zoom in model view.
   *   {number} maxModelZoom - Maximum zoom in model view.
   *   {number} minPanoPitch - Minimum panorama viewer pitch in degrees [-90, 90]
   *   {number} maxPanoPitch - Maximum panorama viewer pitch in degrees [-90, 90]
   *   {Array} roomAreas - Room areas; each entry is an object with name and area (in sq ft) properties.
   *   {object} viewParams - Parameters for setting initial view.
   *   {function} viewParamsCallback - Callback function for updating view parameters.
   *   {Boolean} debug - If true, some debug information is printed during use.
   * @param {object} [dracoLoader] - Optional Draco loader
   * @param {Promise} [mesh] - Optional Promise that resolves to THREE.BufferGeometry of scene mesh
   */
  class Viewer {
    camera;
    controls;
    scene;
    renderer;
    pannellumRenderTarget1;
    pannellumRenderTarget2;
    mouse = new THREE.Vector2(); //normalized mouse pos
    mousePos = new THREE.Vector2(); //pixel mouse pos
    raycaster;
    imagePlane;
    imagePlaneCamera;
    camera_ids = [];
    previousShot;
    returnShot;
    validMoves;
    textureLoaded = false;
    scene_geometry;
    mouseHelper;
    panoAdjustHelper;
    panoAdhustHelperZAxisOffset = 1;
    frontScene;
    backScene;
    camera_footprints = [];
    currentFootprint;
    pannellumRenderer;
    renderUpdateNeeded = true;
    mouseIn = false;
    initTarget;
    initPosition;
    gl;
    idealFov = 95;
    defaultFov = 85;
    orthoZoomFactor = 1;
    mouseDown;
    dragging;
    skipAnimation = false;
    meshTextureLoaded = false;
    onPointerDownPointerDist;
    pointerDownTime = 0;
    anyTextureLoadedAfterOverview = false;
    defaultAnimationSpeed = 0.1;
    domEvent = '';
    plane;
    currentMode;
    previousMode;
    repeatedMode;
    renderPano = false;
    panoIndicators;
    selected = false;
    // Larger factors reduce edge effects when transitioning between panos
    // but require rendering more pixels
    pnlmRenderOversizeFactor = 1; //1.25 seems to be a good value
    defaultClippingHeight = 1.5;
    defaultClippingHeightAdjusted = 1.5;
    minHeight = 0;
    maxHeight = 99;
    center = new THREE.Vector3();
    isLoaded = false;
    prevTime = Date.now();
    time = Date.now();
    locationURLToUpdate = false;
    ipMat;
    labelO;
    tempbool = false
    viewAll = false
    isMultiFloor = false
    mouseOnModel = false
  
    constructor(config) {
      this.idealFOVCoeff = this.idealFov * (16 / 9);
      this.defaultFov = this.getFOV();
      this.config = config;
      this.initialFloor = config.viewParams.floor
      if (config.floorCount > 1) this.isMultiFloor = true

      this.panos = []

      this.textureLoader = new THREE.TextureLoader()
      this.dracoLoader = new DRACOLoader();
      this.dracoLoader.setWorkerLimit(1)
      this.dracoLoader.defaultAttributeIDs.subobject = 'sub_obj';
      this.dracoLoader.defaultAttributeTypes.subobject = 'Float32Array';
      this.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.0/');
      this.dracoLoader.preload()

      this.SceneManager = new SManager(this)
      this.isTouchable = HelpersMixin.computed.isTouchable();
      this.FloorManager = new FLManager(this, this.isTouchable)
      this.TileLoader = new TLoader(this)
    }

    detectIdle(mouseHelper,idleTime) {
      var time;
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      
      function logout() {
        gsap.to(mouseHelper.material, {
          duration: 0.25,
          opacity: 0
        })
      }
      function resetTimer() {
        gsap.to(mouseHelper.material, {
          opacity: 0.25
        })
          clearTimeout(time);
          time = setTimeout(logout, idleTime)
          // 1000 milliseconds = 1 second
      }
  };

    //if the second floor downloads before the first is done processing, store the floorData
    //in waitingFloors and wait for the first floor to finish before preparing. See processOptions()
    updateConfig(floorData) {
      if (!this.isLoaded) {
        this.waitingFloors = floorData
        return
      }
      this.FloorManager.prepareAdditionalFloors(floorData)
    }

    changeFloor = floorNumber => {
      let id, floorHeight, floorDatum
      switch(this.currentMode) {
        case 'orbit':
          this.currentFloor = floorNumber
          this.FloorManager.activateFloor(floorNumber)
          this.FloorManager.moveToFloor(floorNumber)
          this.config.viewParamsCallback({mode: VIEWER_MODES.ORBIT, floor: floorNumber})
          if (this.isTouchable) this.panoIndicators.toggleVisibilityByFloor(floorNumber)
          break
        case 'walk':
          id = this.findClosestCameraByFloor(this.mouseHelper.position, floorNumber)
          this.currentFloor = floorNumber
          this.FloorManager.setCurrentFloor(floorNumber)
          this.FloorManager.activateFloor(floorNumber)
          this.setMovingMode(VIEWER_MODES.PANO, {shot: id});
          if (this.isTouchable) this.panoIndicators.toggleVisibilityByFloor(floorNumber)
          break
        case 'floorplan':
          this.currentFloor = floorNumber
          this.FloorManager.isolateFloor(this.currentFloor)
          this.FloorplanManager.isolateFloorplan(this.currentFloor)
          this.config.viewParamsCallback({mode: VIEWER_MODES.FLOORPLAN, floor: floorNumber})

          floorHeight = this.FloorManager.getCurrentMesh().geometry.boundingBox.max.z
          floorDatum = this.FloorManager.getCurrentFloor().datumLine
          this.controls.animationTarget.setZ(floorDatum)
          this.controls.animationPosition.setZ(floorHeight + 4)

          this.AnimationController.animateClippingPlaneHeight(0.1, 0, this.FloorManager.getCurrentFloor().datumLine, this.clippingPlane, this.ipMat)
          this.panoIndicators.toggleVisibilityByFloor(floorNumber)
         
          break
      }
    }

    refreshFeatures = () => {
      this.labelO.refreshFeatures([...store.state.features.features])
    }

    initViewer = (floorData) => {

      store.watch((state) => state.controls.controlsState, target => {
        this.MeasurementManager.measuring = target === CONTROLS_STATE.MEASURE_DISTANCE;
        this.MeasurementManager.measuringArea = target === CONTROLS_STATE.MEASURE_AREA;

        this.MeasurementManager.cleanMeasurements();
        this.MeasurementManager.deleteAreaMeasure();
        if(this.currentMode === VIEWER_MODES.PANO && this.FloorManager.getCurrentMesh().material.uniforms.texRatio.value < 1) this.AnimationController.animateTexRatio(0.5, 0, 1, this.meshes)

        if (this.MeasurementManager.measuring) {
          this.MeasurementManager.measureMode = 1;
        } else if (this.MeasurementManager.measuringArea) {
          this.MeasurementManager.measureMode = 4;
        } else {
          this.MeasurementManager.measureMode = 0;
          this.MeasurementManager.hideMeasures();
        }
      })

      this.renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.meshes = []

      this.imagePlaneCamera = null;
      this.config.panos = floorData.panos;
      this.config.floorplan = floorData.floorplan;
      this.config.model = floorData.model;

      this.options = {
        showImagePlane: false,
        animationSpeed: this.defaultAnimationSpeed,
        imagePlaneOpacity: 1,
        imagePlaneDir: true,
      };

      this.floorplanOffsetX = 0;
      this.floorplanOffsetY = 0;

      this.floorplanDiffX = 0;
      this.floorplanDiffY = 0;
      this.panoAdjustAngle = 0;
      this.panoAdjustPosition = [0,0,0];
      this.panoTemporaryPosition = null;
      this.meshTextureLoaded = false;

      this.pannellum_render_quat = new THREE.Quaternion();
      this.pannellum_render_euler = new THREE.Euler(0, 0, 0, 'YXZ');
      this.camera_dir = new THREE.Vector3();

      this.panos.push(...Object.values(floorData.panos))
      this.panos.forEach(pano => pano.floorNumber = this.initialFloor)

      let {storageBucket, cloudDomain} = config.firebase;
      // Disable cache if model has the same url but has been updated
      let modelURL = `https://${cloudDomain}/${storageBucket}/${this.config.model.model_key}?${this.config.model.model_udpated}`;

      //check this.maxTextureSize for the maximum allowed texture size before downloading
      //most common options will be 4096, 8192, 16384 - eg. TEXTURE_8K.jpg for 8192
      const gl = this.renderer.getContext()
      this.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE)

      let texURL
      // Use maximum texture resolution related on user display
      // Also disable cache if texture has the same url but has been updated
      if (this.maxTextureSize >= 8192
        && this.config.model.tex_8k_key
        && navigator.maxTouchPoints === 0
        && this.config.floorCount < 3
        ) {
        texURL = `https://${cloudDomain}/${storageBucket}/${this.config.model.tex_8k_key}?${this.config.model.texture_udpated}`;

      } else if (this.config.model.tex_4k_key) {
        texURL = `https://${cloudDomain}/${storageBucket}/${this.config.model.tex_4k_key}?${this.config.model.texture_udpated}`;
      }

      const fetchGeometry = new Promise((resolve, reject) => {
        this.dracoLoader.load(
          modelURL,
          geometry => {
            resolve(geometry)
          },
          () => {},
          error => reject(error)
        )
      })

      const fetchTexture = new Promise((resolve, reject) => {
        this.textureLoader.load(
          texURL,
          texture => resolve(texture),
          () => {},
          error => reject(error)
        )
      })

      return Promise.all([fetchGeometry, fetchTexture])
      .then(assets => {
        const [geometry, texture] = assets

        this.TileLoader.addTiles(floorData.panos, this.config.viewParams.pano)

        this.scene_geometry = geometry;

        if (!this.renderer) this.renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});

        this.ModelManager = new ModManager(this)
        this.scene_geometry.computeBoundsTree() //generate BVH
        this.ModelManager.processBoundingBox(this.scene_geometry)
        this.camera_ids = this.ModelManager.processPanos(this.config.panos)
        this.defaultClippingHeightAdjusted = this.ModelManager.processClippingPlane(this.camera_ids, this.config.panos)
        const clippingHeight = this.isMultiFloor ? 99 : this.defaultClippingHeightAdjusted
        this.clippingPlane = new THREE.Plane( new THREE.Vector3( 0, 0, -1 ), clippingHeight)
  
        let params = this.config.viewParams ? this.config.viewParams : {};

        this.tex = texture
        this.meshTextureLoaded = true
        this.init(params, texURL)

        if (!this.isLoaded) {
          this.isLoaded = true
          this.currentFloor = this.initialFloor
          if (this.waitingFloors) this.FloorManager.prepareAdditionalFloors(this.waitingFloors)
          postMessagesMixin.methods.loadedMessage()
          this.animate();
          const ext = gl.getExtension('GMAN_webgl_memory');

          if (ext) {
            const info = ext.getMemoryInfo();
            //console.log(info)
          }
        }
        store.commit('layout/toogle_app_state', AppStates.ready);

      })
      .catch(err => console.error('error loading assets', err))
    }

        /**
     * Initialize viewer.
     * @param {object} params - Initial config parameters
     * @private
     */
    init = (params, url) => {
      this.raycaster = new THREE.Raycaster();
      this.plane = new THREE.Plane();
      this.plane.normal.set(0, 0, 1);

      this.renderer.setSize(this.config.container.clientWidth, this.config.container.clientHeight);
      this.renderer.sortObjects = true;
      this.renderer.autoClear = false;
      this.renderer.localClippingEnabled = true


      this.config.container.appendChild(this.renderer.domElement);
      if (Object.entries(this.config.panos).length !== undefined) {
        if (this.showMinimap) {
          if (!this.minimap) this.minimap = new Minimap(this.renderer.domElement, this.config.minimap);
          this.minimap.create(this.defaultFov);
        }
        if (!this.pannellumRenderTarget1) {
          this.pannellumRenderTarget1 = new THREE.WebGLRenderTarget(this.config.container.clientWidth * this.pnlmRenderOversizeFactor,
          this.config.container.clientHeight * this.pnlmRenderOversizeFactor);
        }
        if (!this.pannellumRenderTarget2) {
          this.pannellumRenderTarget2 = new THREE.WebGLRenderTarget(this.config.container.clientWidth * this.pnlmRenderOversizeFactor,
          this.config.container.clientHeight * this.pnlmRenderOversizeFactor);
        }
      }

      this.camera = new THREE.PerspectiveCamera(
        this.defaultFov,
        this.config.container.clientWidth / this.config.container.clientHeight,
        0.03,
        10000
      );
      this.camera.up.set(0, 0, 1);

      this.scene_geometry.computeBoundingBox();
      this.scene_geometry.computeBoundingSphere();
      const modelCenter = this.scene_geometry.boundingBox.getCenter(new THREE.Vector3())
      modelCenter.setZ(0)
      this.modelCenter = modelCenter

      //orient the camera behind the entry arrow (if there is one) and zoom into the model
      const radius = this.scene_geometry.boundingSphere.radius
      const arrowPos = new THREE.Vector3()
      if (this.config.floorplan.arrow?.position) {
        arrowPos.setX(this.config.floorplan.arrow.position.x)
        arrowPos.setY(this.config.floorplan.arrow.position.y)
      }
      const axis = new THREE.Vector3(0, 0, 1)
      const angle = new THREE.Vector3().subVectors(arrowPos, modelCenter).normalize()
      const radians = Math.atan2(angle.x, angle.y)

      const initPosition = this.config.floorplan.init_view.position;
      const haveInitPosition = initPosition.x || initPosition.y || initPosition.z

      if (haveInitPosition) {
        let {x, y, z} = initPosition;
        this.initPosition = new THREE.Vector3(x, y, z);
        this.initTarget = new THREE.Vector3().copy(modelCenter)
        this.camera.position.set(x, y, z);
      } else {
        this.setInitPositionAndTarget(modelCenter, radius, axis, radians)
      }

      this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement, this.initTarget, controlsOptions);
      this.flyThroughControls = new FTControls(this.controls, this.config.minFov, this.config.maxFov, this)
      this.controls.persp = this.camera.projectionMatrix.clone();
      this.updateOrthoMatrix();

      this.AnimationController = new AManager(this)

      //animate camera forward when opening tour
      if ((!params.mode || params.mode === 'orbit') && !haveInitPosition) {
        this.AnimationController.animInitPositionAndTarget(modelCenter, radius, axis, radians, this.maxHeight, this.minHeight)
      }
      // //animate camera into model when opening tour
      if (params.mode === 'walk' && this.config.panos[params.pano]) {
        this.AnimationController.animInitPanoPosition(this.config.panos[params.pano])
      }

      //sets the bounds for the camera pan according to the scene geometry bounds.
      //used in controls.updateAnimationTargetsMouse() to clamp the values
      // this.controls.minPan = new THREE.Vector3().copy(this.boundingMin)
      // this.controls.maxPan = new THREE.Vector3().copy(this.boundingMax)

      this.controls.addEventListener('change', () => {
        this.renderUpdateNeeded = true;
      });

      this.controls.minDistance = this.config.minModelZoom; // Limit minimap zoom
      this.controls.maxDistance = this.scene_geometry.boundingSphere.radius*2.3//this.config.maxModelZoom;
      this.controls.minPanoPitch = Math.PI / 2 - (this.config.minPanoPitch !== undefined ? this.config.minPanoPitch : -90) *
        Math.PI / 180; // Limit panorama viewer pitch
      this.controls._minPanoPitch = this.controls.minPanoPitch
      this.controls.maxPanoPitch = Math.PI / 2 - (this.config.maxPanoPitch !== undefined ? this.config.maxPanoPitch : 90) *
        Math.PI / 180;
      this.controls._maxPanoPitch = this.controls.maxPanoPitch

      window.addEventListener('resize', this.onWindowResize, false);
      document.addEventListener('fullscreenchange', this.onWindowResize, false);
      this.renderer.domElement.addEventListener('mousemove', this.onDocumentMouseMove, false);
      this.renderer.domElement.addEventListener('mousedown', this.onDocumentMouseDown, false);
      this.renderer.domElement.addEventListener('mouseup', this.onDocumentMouseUp, false);
      document.body.addEventListener('mouseleave', () => {
        this.dragging = false;
        this.mouseDown = false;
      });
      this.renderer.domElement.addEventListener('mouseleave', this.onDocumentMouseUp, false);
      this.renderer.domElement.addEventListener('touchmove', this.onDocumentTouchMove, false);
      this.renderer.domElement.addEventListener('touchstart', this.onDocumentTouchStart, false);
      this.renderer.domElement.addEventListener('touchend', this.onDocumentMouseUp, false);
      this.renderer.domElement.addEventListener('mouseout', () => {
        this.mouseIn = false;
        this.renderUpdateNeeded = true;
      }, false);
      this.wheelThrottled = throttle(this.onDocumentMouseWheel, 30)
      this.renderer.domElement.addEventListener('wheel', this.wheelThrottled, false);
      document.addEventListener('wheel', (e) => {
        if (e.ctrlKey) e.preventDefault();
      }, {passive: false});
      document.addEventListener('keydown', this.onKeyDown, false);
      document.addEventListener('keyup', this.onKeyUp, false);
      
      document.addEventListener('fullscreenchange', exitHandler);
      document.addEventListener('webkitfullscreenchange', exitHandler);
      document.addEventListener('mozfullscreenchange', exitHandler);
      document.addEventListener('MSFullscreenChange', exitHandler);

      function exitHandler() {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            store.commit('controls/toggle_fullscreen', false);
      }
}  

      let eventListeners = [
        'mousemove',
        'mousedown',
        'mouseup',
        'touchmove',
        'touchstart',
        'touchend',
        'mouseout',
        'wheel'
      ];

      eventListeners.forEach(evtLstnr => {
        this.renderer.domElement.addEventListener(evtLstnr, () => {
          this.domEvent = evtLstnr;
        }, false)
      })

      // Scene mesh
      // this.scene_mesh = new THREE.Mesh(this.scene_geometry, this.scene_material);
      // this.scene_mesh.name = 'sceneMesh'
      // Image plane
      this.ipMat = createImagePlaneMaterial(this.clippingPlane, this.tex)
      this.ipMat.clipping = true
      this.imagePlane = new THREE.Mesh(this.scene_geometry, this.ipMat);
      this.imagePlane.name = 'imagePlane'
      this.meshes.push(this.imagePlane)

      // Cursor and camera footprint indicator
      this.mouseHelper = new THREE.Mesh(new THREE.CircleGeometry(this.config.cursorSize, 48),
        new THREE.MeshBasicMaterial({opacity: 0.25, transparent: true, depthWrite: false , blending: THREE.AdditiveBlending}));
      this.mouseHelper.visible = false;
      this.detectIdle(this.mouseHelper,this.config.mouseHelperIdle);

      this.panoAdjustHelper = new THREE.Mesh(new THREE.CircleGeometry(this.config.cursorSize / 2, 16),
        new THREE.MeshBasicMaterial({opacity: 0.8, transparent: true, depthWrite: false}));
      this.panoAdjustHelper.visible = false;

      this.frontScene = new THREE.Scene();
      this.frontScene.add(this.mouseHelper, this.panoAdjustHelper);

      this.backScene = new THREE.Scene();

      this.calcCameraFootprints();

      this.imagePlaneCamera = this.camera_ids[0];
      let shot = this.config.panos[this.imagePlaneCamera];
      this.imagePlane.visible = true//!this.options.showImagePlane;
      this.imagePlaneBox = this.ModelManager.createImagePlaneBox(this.scene_geometry)
      this.imagePlaneBox.visible = this.options.showImagePlane;

      this.mouseHelperOffset = new THREE.Mesh(new THREE.BoxGeometry(0.1, 0.1, 0.1), new THREE.MeshBasicMaterial({color: 0xff0000, transparent: true, visible: false}))


      this.scene = new THREE.Scene();
      this.scene.add(this.imagePlane, this.imagePlaneBox, this.mouseHelperOffset);

      if (this.panoIndicators) {
        this.panoIndicators.addIndicators(this.isTouchable?this.scene:this.frontScene);
        //Removed from front scene so indicators are occluded by the mesh
        // this.panoIndicators.addIndicators(this.frontScene);
      }

      this.MeasurementManager = new MManager(this.scene)
      //this.MeasurementManager.mobile = this.isTouchable
      //this.MeasurementManager.createMagnifier(this.config.measureMagnifier);

      this.MeasurementManager.createTempLine()
      this.MeasurementManager.createMeasurePlane(this.maxHeight)
      
      this.FloorplanManager = new FPManager(this);
      this.FloorManager.addInitialFloor(this.initialFloor, this.imagePlane, url, url, this.defaultClippingHeightAdjusted, this.config.floorplan, this.config.viewParams.mode)

      this.pannellumRenderer = window.libpannellum.renderer(null, this.renderer.getContext());

      this.pannellumRenderer.init({}, shot ? shot.config.type : 'multires', false,
        2 * Math.PI, Math.PI, 0, () => {
        }, {});

      this.renderUpdateNeeded = true;

      // Set initial view
      this.returnShot = this.camera_ids[0];

      this.entryArrow = new EntryArrow({
        scene: this.scene,
        mouse: this.mouse,
        camera: this.camera,
        el: this.renderer.domElement,
        pixelRatio: window.devicePixelRatio,
        minHeight: this.minHeight + 0.1 || 0.1
      });
      this.entryArrow.create(this.config.floorplan.arrow || {});

        if (this.showMinimap && this.minimap) {
          this.minimap.loadArrow(this.frontScene);
          this.minimap.create_bg(this.backScene);
        }

      this.setMovingMode(params.mode || VIEWER_MODES.ORBIT, params.pano && {shot: params.pano}, false)
      this.AnimationController.animateTexRatio(1, 0, 1, this.meshes)
      

      if(!this.isTouchable)this.MeasurementManager.createMagnifier()

      this.sphere = new THREE.Mesh(new THREE.SphereGeometry(0.05, 16), new THREE.MeshNormalMaterial())
      this.sphere.name = 'labelIntersectSphere'
      this.sphere.visible = false
      this.scene.add(this.sphere)
      this.labelO = new labelOccluder([...store.state.features.features]);
      // this.scene.add(grid(20), targetHelper(this.controls))
    }

    getFOV() {
      if (store.state.layout.isMobileScreen) {
        return this.idealFov;
      }
      let fov = this.idealFOVCoeff / (window.innerWidth / window.innerHeight);
      return fov >= this.idealFov ? this.idealFov : fov;
    }

    updatePanoRotation(value) {
      this.panoAdjustAngle = value;
      this.renderUpdateNeeded = true;
    }

    movePano(direction) {
      const frontSegment = new THREE.Vector3();
      const step = 0.127;
      this.camera.getWorldDirection(frontSegment);
      frontSegment.multiplyScalar(step);

      let [x, y, z] = this.panoAdjustPosition;
      const axis = new THREE.Vector3(0, 0, 1);
      let vector = new THREE.Vector3();
      let angle;
      switch (direction) {
        case "forward":
          x += frontSegment.x;
          y += frontSegment.y;
          break;
        case "backward":
          x -= frontSegment.x;
          y -= frontSegment.y;
          break;
        case "left":
          angle = Math.PI / 2;
          vector.copy(frontSegment).applyAxisAngle(axis, angle);
          x += vector.x;
          y += vector.y;
          break;
        case "right":
          angle = -Math.PI / 2;
          vector.copy(frontSegment).applyAxisAngle(axis, angle);
          x += vector.x;
          y += vector.y;
          break;
        case "top":
          z += step;
          break;
        case "bottom":
          z -= step;
          break;
      }
      this.panoAdjustPosition = [x, y, z];
    }

    applyPanoPosition(shot_id = this.imagePlaneCamera) {
      let p = this.panoAdjustHelper.position;
      this.panoTemporaryPosition = [p.x, p.y, p.z + this.panoAdhustHelperZAxisOffset];
      this.navigateToShot(shot_id);
    }

    savePano(shot_id = this.imagePlaneCamera, pano = null) {
      let shot = this.config.panos[shot_id];

      if (this.imagePlaneCamera === shot_id && this.controls.movingMode === VIEWER_MODES.PANO) {
        if (pano) {
          shot = {...pano};
        } else {
          const diff = +this.panoAdjustAngle.toFixed(2);
          shot.rotation[2] += diff;

          let p = this.panoAdjustHelper.position;
          shot.position = [p.x, p.y, p.z + this.panoAdhustHelperZAxisOffset]
          this.cleanPanoAdjustments();
        }

        this.navigateToShot(shot_id);
      }

      let footprintIndex = this.camera_ids.indexOf(shot_id);
      this.calcCameraFootprint(footprintIndex);
      this.currentFootprint = this.camera_footprints[footprintIndex];

      if (this.panoIndicators) {
        this.panoIndicators.updateIndicator(footprintIndex, shot.position, this.meshes);
      }

      return {
        pano: shot,
        panos: this.config.panos
      };
    }

    moveCameraToPanoPositionDebug(shot) {
      shot = shot || this.config.panos[this.imagePlaneCamera];
      this.controls.animationTarget.add(this.opticalCenter(shot)).sub(this.controls.animationPosition);
      this.controls.animationPosition.copy(this.opticalCenter(shot));
    }

    changeClippingDebug(height) {
      if (height === -1) height = this.defaultClippingHeightAdjusted
      this.AnimationController.animateClippingPlaneHeight(0.5, 0, height, this.clippingPlane, this.ipMat)
    }

    cleanPanoAdjustments() {
      this.panoAdjustAngle = 0;
      this.panoAdjustPosition = [0, 0, 0];
      this.panoTemporaryPosition = null;
    }

    togglePano(value) {
      this.meshes.forEach(mesh => mesh.material.uniforms.texRatio.value = value)
    }

    getPano() {
      return this.imagePlaneCamera;
    }

    updateFloorplan = (floorplan) => {
      this.config.floorplan = {...floorplan};
      this.initTarget = new THREE.Vector3(
        floorplan.position[0] * Math.sin((180 - floorplan.angle) * Math.PI / 180) - floorplan.position[1] * Math.cos(floorplan.angle * Math.PI / 180),
        floorplan.position[0] * Math.cos((180 - floorplan.angle) * Math.PI / 180) - floorplan.position[1] * Math.sin(floorplan.angle * Math.PI / 180),
        0
      );

      if (floorplan.init_view.position) {
        this.initPosition = (new THREE.Vector3()).copy(floorplan.init_view.position);
      }
      this.entryArrow.updateArrow(floorplan.arrow);
      this.FloorplanManager.updateFloorplan(floorplan.plane);
      this.enterFloorplan(false);
    }

    saveEntryArrow = () => {
      if (this.entryArrow.arrow) {
        let {position, rotation} = this.entryArrow.getArrowProps();
        this.config.floorplan.arrow = {position, rotation};

        store.dispatch('floors/updateFloorplan', {
          floorplan: {
            arrow: this.config.floorplan.arrow,
            id: this.config.floorplan.id
          }
        });
      }
      store.commit('controls/set_controls_state', CONTROLS_STATE.NULL);
 
    }

    saveFloorplan = (newInitView = false) => {
      const config = this.FloorplanManager.getCurrentConfig()
      if (this.FloorplanManager.getFloorplanTransforms()) {
        let {position, rotation, scale} = this.FloorplanManager.getFloorplanTransforms();
        config.plane = {position, rotation, scale};
      }

      if (this.entryArrow.arrow) {
        let {position, rotation} = this.entryArrow.getArrowProps();
        this.config.floorplan.arrow = {position, rotation};
      }

      if (newInitView) config.init_view.position = this.controlsPosition;
      store.dispatch('floors/updateFloorplan', {floorplan: this.FloorplanManager.getCurrentConfig()});
    }

    moveCamera = (type, amount) => {
      let direction = type.split('-')[1];
      if (direction === 'down' || direction === 'up') {
        direction === 'down' ? this.config.floorplan.position[1] += (amount || 0.1) : this.config.floorplan.position[1] -= (amount || 0.1);
      } else {
        direction === 'left' ? this.config.floorplan.position[0] -= (amount || 0.1) : this.config.floorplan.position[0] += (amount || 0.1);
      }

      this.initTarget = new THREE.Vector3(
        this.config.floorplan.position[0] * Math.sin((180 - this.config.floorplan.angle) * Math.PI / 180) - this.config.floorplan.position[1] * Math.cos(this.config.floorplan.angle * Math.PI / 180),
        this.config.floorplan.position[0] * Math.cos((180 - this.config.floorplan.angle) * Math.PI / 180) - this.config.floorplan.position[1] * Math.sin(this.config.floorplan.angle * Math.PI / 180),
        0
      );
      this.enterFloorplan();
    }

    zoomCamera = (type, amount) => {
      let direction = type.split('-')[1];
      direction === 'in' ? this.config.floorplan.distance += (amount || 0.1) : this.config.floorplan.distance -= (amount || 0.1);
      this.enterFloorplan();
    }

    rotateCamera = (type, amount, enterFloorplan) => {
      let direction = type.split('-')[1];
      direction === 'left' ? this.config.floorplan.angle -= (amount || 1) : this.config.floorplan.angle += (amount || 1);
      if (enterFloorplan) this.enterFloorplan()
    }

    initCameraAction = (type, amount) => {
      switch (type) {
        case 'move-down':
        case 'move-up':
        case 'move-left':
        case 'move-right':
          this.moveCamera(type, amount);
          break;
        case 'zoom-in':
        case 'zoom-out':
          this.zoomCamera(type, amount);
          break;
        case 'rotate-right':
        case 'rotate-left':
          this.rotateCamera(type, amount, true);
          break;
      }
    }

    initFloorplanAction = (type, amount) => {
      switch (type) {
        case 'move-down':
          this.FloorplanManager.shiftDown(amount);
          break;
        case 'move-up':
          this.FloorplanManager.shiftUp(amount);
          break;
        case 'move-left':
          this.FloorplanManager.shiftLeft(amount);
          break;
        case 'move-right':
          this.FloorplanManager.shiftRight(amount);
          break;
        case 'scale-up':
          this.FloorplanManager.scaleUp(amount);
          break;
        case 'scale-down':
          this.FloorplanManager.scaleDown(amount);
          break;
        case 'rotate-left':
          this.FloorplanManager.rotateLeft(amount);
          break;
        case 'rotate-right':
          this.FloorplanManager.rotateRight(amount);
          break;
      }
    }

    /**
     * Display floorplan.
     * @private
     */
    enterFloorplan = (reset = true) => {
      if (this.controls.movingMode != VIEWER_MODES.FLOORPLAN) {
        reset && this.setMovingMode(VIEWER_MODES.FLOORPLAN);
      } else {
        this.resetFloorplan();
      }
      if (this.config.viewParamsCallback) {
        this.config.viewParamsCallback({mode: VIEWER_MODES.FLOORPLAN, floor: this.FloorManager.currentFloor});
      }
    }

    /**
     * Reset current floorplan view to initial view.
     * @private
     */
    resetFloorplan = () => {
      
      // console.log(this.FloorManager.getCurrentMesh().geometry.boundingBox.max.z)
      const floorHeight = this.FloorManager.getCurrentMesh().geometry.boundingBox.max.z
      const floorDatum = this.FloorManager.getCurrentFloor().datumLine
      const floorMid = this.FloorManager.getCurrentFloor().mid
      this.controls.animationPosition.copy(floorMid);
      this.controls.animationTarget.copy(floorMid);
      this.controls.animationTarget.setZ(floorDatum);
      this.controls.animationPosition.copy(floorMid);
      this.controls.animationPosition.setZ(floorHeight + 4);
      this.controls.animationPosition.setX(this.controls.animationTarget.x +
        0.1 * Math.cos(this.config.floorplan.angle * Math.PI / 180));
      this.controls.animationPosition.setY(this.controls.animationTarget.y +
        0.1 * Math.sin(this.config.floorplan.angle * Math.PI / 180));
      this.orthoZoomFactor = 1;
      this.updateOrthoMatrix();
      this.controls.updateOrtho = true;
      this.renderUpdateNeeded = true;
      this.floorplanOffsetX = this.floorplanOffsetY = 0;
    }

    centerRoomLabel = (newPos) => {
      this.controls.animationSpeed = this.defaultAnimationSpeed;
      const floorHeight = this.FloorManager.getCurrentMesh().geometry.boundingBox.max.z
      const floorDatum = this.FloorManager.getCurrentFloor().datumLine
      newPos = new THREE.Vector3(...newPos)
      this.controls.animationPosition.copy(newPos);
      this.controls.animationTarget.copy(newPos);
      this.controls.animationTarget.setZ(floorDatum);
      this.controls.animationPosition.copy(newPos);
      this.controls.animationPosition.setZ(floorHeight + 4);
      this.controls.animationPosition.setX(this.controls.animationTarget.x +
        0.1 * Math.cos(this.config.floorplan.angle * Math.PI / 180));
      this.controls.animationPosition.setY(this.controls.animationTarget.y +
        0.1 * Math.sin(this.config.floorplan.angle * Math.PI / 180));
      this.orthoZoomFactor = 1;
      this.updateOrthoMatrix();
      this.controls.updateOrtho = true;
      this.renderUpdateNeeded = true;
      this.floorplanOffsetX = this.floorplanOffsetY = 0;
    }

    /**
     * Event handler for floorplan mouse clicks.
     * @private
     * @param {MouseEvent} event - Document mouse up event.
     */
    floorplanClickHandler = (event) => {
      this.entryArrow.deselectArrow(this.mouse);
      
      if (!this.mouseDown || event.button === 2) {
        this.mouseDown = undefined;
        return;
      }
      this.floorplanOffsetX += (event.clientX - this.mouseDown.clientX) / this.orthoZoomFactor;
      this.floorplanOffsetY += (event.clientY - this.mouseDown.clientY) / this.orthoZoomFactor;
      this.config.container.style.cursor = "grab";
      this.mouseDown = undefined;
      if (typeof event.changedTouches !== 'undefined') {
        // Handle touch inputs
        event.clientX = event.changedTouches[0].clientX;
        event.clientY = event.changedTouches[0].clientY;
      }
      if ((Date.now() - this.pointerDownTime < 145 || !this.dragging) && !store.state.rooms.overlay) {
        if (this.MeasurementManager.measureMode) {
          this.MeasurementManager.clickHandlerFloorplan(this.FloorplanManager.floorplanPlane, event, this.camera, this.renderer.domElement)
          this.renderUpdateNeeded = true;
          return;
        } else {
          const mousePosition = new THREE.Vector2()
          mousePosition.x = (event.clientX / (this.renderer.domElement.width/window.devicePixelRatio)) * 2 - 1
          mousePosition.y = (event.clientY / (this.renderer.domElement.height/window.devicePixelRatio)) * 2 - 1

          const orthoCamera = new THREE.OrthographicCamera(this.camera.left, this.camera.right, this.camera.bottom, this.camera.top, 0.1, 10000)
          orthoCamera.position.copy(this.camera.position)
          orthoCamera.quaternion.copy(this.camera.quaternion)
          orthoCamera.updateMatrixWorld()

          const raycaster = new THREE.Raycaster()
          raycaster.setFromCamera(mousePosition, orthoCamera)
          const intersect = raycaster.intersectObject(this.FloorManager.getCurrentMesh())
          let point
          if (intersect[0]) {
            point = intersect[0].point
            const id = this.findClosestCameraByFloor(point, this.FloorManager.currentFloor)
            if (id) {
              this.setMovingMode(VIEWER_MODES.PANO, {shot: id});
            }
          }

        }
      }
    }

    /**
     * Show or hide image plane used to display panoramas.
     * @private
     * @param {boolean} value - Whether or not to show image plane.
     */
    setShowImagePlaneBox = (value) => {
    if (!this.renderPano) {
      value = false;
    }
    //this.options.showImagePlane = value;
    this.imagePlaneBox.visible = value;
    this.renderUpdateNeeded = true;
    }

    /**
     * Set control's moving mode.
     * @memberof Viewer
     * @instance
     * @param {string} mode - Moving mode. Should be 'floorplan', 'walk', or
     *  'orbit'.
     */
    setMovingMode = (mode, opts = {}, zoomFromPano) => {
      this.previousMode = this.currentMode
      this.currentMode = mode
      if (!this.skipAnimation) {
        this.controls.animationSpeed = this.defaultAnimationSpeed;
      }
      store.dispatch('controls/setMode', mode);
      this.MeasurementManager.cleanMeasurements();
      this.cleanPanoAdjustments();
      this.panoAdjustHelper.visible = false;

      this.controls.movingMode = mode;
      this.setLookAroundSpeed()
      if(this.panoIndicators._currentindicator !== undefined && this.panoIndicators._currentindicator !== null){
        this.panoIndicators._currentindicator.visible = true
        this.panoIndicators._currentindicator = null
      }

      if (mode === VIEWER_MODES.PANO) {
        if(opts.closest){
          if(this.meshes.length > 1){
            opts.closest = this.findClosestCameraByFloor(this.camera.position, this.FloorManager.currentFloor)
            this.repeatedMode = this.previousMode==VIEWER_MODES.PANO;
          }else{
          opts.closest = this.findClosestCamera(this.camera.position).id
          this.repeatedMode = this.previousMode==VIEWER_MODES.PANO;
          }
        }
        else if(this.repeatedMode){
          this.repeatedMode = false
        }
        if(!this.repeatedMode){
        this.entryArrow.arrow.visible = false;
        this.AnimationController.killAnimations()
        if (this.previousMode !== 'walk' || !this.hasAccess('panos')) {
          this.AnimationController.animateClippingPlaneHeight(1, 0, this.FloorManager.max + 2, this.clippingPlane, this.ipMat)
        }

        this.controls.clamped = false
        let shot_id = opts.closest || opts.shot || this.imagePlaneCamera;
        if (shot_id) {
          if(opts.shot===this.imagePlaneCamera){
            
          }
          this.navigateToShot(shot_id, mode);
          this.controls.noPan = true;
          this.controls.noZoom = true;
          // Adjust rotate speed based on field of view
          let hfov = 2 * Math.atan(Math.tan((this.camera.fov / 180 * Math.PI) * 0.5) * this.camera.aspect);
          this.controls.lookAroundSpeed = (hfov / (2 * Math.PI)) * viewerOptions.lookAroundSpeedCoeff;
        }
        this.FloorplanManager.hideAllFloorplans()
      }
      } else {
        this.renderer.setPixelRatio(window.devicePixelRatio)
        if (!this.isMultiFloor) this.FloorplanManager.showFloorplan(this.FloorManager.currentFloor)
        this.entryArrow.arrow.visible = true;
        this.scene.background = undefined;
        this.anyTextureLoadedAfterOverview = false;
      }

      if (mode === VIEWER_MODES.ORBIT) {
        this.FloorplanManager.showFloorplan(this.FloorManager.currentFloor)
        this.controls.animationFov = this.defaultFov
        this.AnimationController.killAnimations()
        const clippingHeight = this.isMultiFloor ? this.FloorManager.max + 2  : this.FloorManager.getCurrentFloor().datumLine
        this.AnimationController.animateClippingPlaneHeight(1, 0, clippingHeight, this.clippingPlane, this.ipMat)
        if (this.pannellumRenderTarget1) this.pannellumRenderTarget1.dispose()
        if(this.FloorManager.floors.length > 1){
          this.meshes.forEach((mesh) => {
            mesh.material.uniforms.texRatio.value = 0
            mesh.material.uniforms.ratio.value = 1
            mesh.material.uniforms.opacity.value = 0.5
          })
          this.FloorManager.getCurrentMesh().material.uniforms.opacity.value = 1
        }else{
          this.meshes.forEach((mesh) => {
            mesh.material.uniforms.texRatio.value = 0
            mesh.material.uniforms.ratio.value = 1 })
        }
        if(this.options.imagePlaneDir==false)this.options.imagePlaneDir = true
        this.controls.clamped = true
        this.config.viewParamsCallback({mode: mode, floor: this.FloorManager.currentFloor});
        this.previousShot = undefined;
        this.setShowImagePlaneBox(false);
        this.controls.noRotate = false;
        this.controls.noLookAround = false;
        this.controls.noPan = false;
        this.controls.noZoom = false;
        this.controls.noKeys = false;
        if (zoomFromPano) {
          this.AnimationController.moveCameraOutOfPano(0.5)
          return
        }

        if(this.meshes.length > 1 && this.previousMode !== this.currentMode){
          if(this.previousMode === 'floorplan' && this.currentMode === 'orbit'){
            this.AnimationController.moveCameraToInitialPosition(1.5, this.initTarget, this.initPosition)
          }else{
            this.AnimationController.moveCameraOutOfPanoMultiFloor(2,this.FloorManager.getCurrentFloorObject())
          }
        }else if(opts.frameall){
          this.FloorManager.activateAllFloors()
          let center = this.FloorManager.boundingBox.getCenter(new THREE.Vector3())
          this.controls.animationPosition.setZ(center.z)
          this.controls.animationTarget = center
        }
        else{
          this.AnimationController.moveCameraToInitialPosition(1.5, this.initTarget, this.initPosition)
        }
        
        
        this.updateOrthoMatrix();
        this.controls.updateOrtho = true;
      }

      if (mode === VIEWER_MODES.FLOORPLAN) {
        if (this.FloorManager.floors.length > 1){
          this.FloorManager.isolateFloor(this.currentFloor)
        }
        this.controls.animationFov = this.defaultFov
        this.AnimationController.killAnimations()
        this.AnimationController.animateClippingPlaneHeight(0.5, 0, this.FloorManager.getCurrentFloor().datumLine, this.clippingPlane, this.ipMat)
        if (this.pannellumRenderTarget1) this.pannellumRenderTarget1.dispose()
        this.meshes.forEach(mesh => {
          mesh.material.uniforms.texRatio.value = 0
          mesh.material.uniforms.ratio.value = 1
        })
        if(this.options.imagePlaneDir==false)this.options.imagePlaneDir = true
        this.previousShot = undefined;
        this.controls.perspMix = 1;
        this.controls.enabled = false;
        this.setShowImagePlaneBox(false);
        this.enterFloorplan();
        this.FloorplanManager.showFloorplan(this.FloorManager.currentFloor)
        if(!store.state.layout.isMobileScreen)this.panoIndicators.toggleAllIndicators(true,this.FloorManager.currentFloor);
        store.state.controls.editMode? this.config.container.style.cursor = "default" : this.config.container.style.cursor = "grab"
      } else {
        if(!store.state.layout.isMobileScreen)this.panoIndicators.toggleAllIndicators(false,this.FloorManager.currentFloor);
        this.FloorManager.showFloors()
        if (this.isMultiFloor) this.FloorplanManager.hideAllFloorplans()
        this.controls.perspMix = 0;
        this.controls.enabled = true;
        this.floorplanOffsetX = this.floorplanOffsetY = 0;
      }
    };

    /**
     * Converts panorama camera origin into a translation.
     * @private
     * @param {object} shot - Panorama camera shot object
     * @returns {THREE.Vector3} Resulting translation
     */
    originToTranslation = (shot) => {
      let euler = this.getEuler(shot.rotation);
      let rotation = new THREE.Matrix4().makeRotationFromEuler(euler);
      let position = this.panoTemporaryPosition && this.panoTemporaryPosition.length ?
        new THREE.Vector3(this.panoTemporaryPosition[0], this.panoTemporaryPosition[1], this.panoTemporaryPosition[2]) :
        new THREE.Vector3(shot.position[0], shot.position[1], shot.position[2]);
      let r3 = new THREE.Matrix3().setFromMatrix4(rotation);
      return position.applyMatrix3(r3.multiplyScalar(-1));
    }

    /**
     * Determines center position of panorama camera.
     * @private
     * @param {object} shot - Panorama camera shot object
     * @returns {THREE.Vector3} Center position
     */
    opticalCenter = (shot) => {
      let Rt = this.originToTranslation(shot);
      let euler = this.getEuler(shot.rotation);
      let matrix = new THREE.Matrix4().makeRotationFromEuler(euler).transpose();
      Rt.applyMatrix4(matrix);
      Rt.negate();
      return Rt;
    }

    /**
     * Calculates camera matrix.
     * @private
     * @param {object} shot - Panorama camera shot object
     * @returns {THREE.Matrix4} Camera matrix
     */
    projectorCameraMatrix = (shot) => {
      let euler = this.getEuler(shot.rotation);
      let rotation = new THREE.Matrix4().makeRotationFromEuler(euler);
      rotation.setPosition(this.originToTranslation(shot));
      return rotation;
    }

    getMouseIntersection = (x, y) => {
      const mousePosition = new THREE.Vector2()
      mousePosition.x = (x / (this.renderer.domElement.width/window.devicePixelRatio)) * 2 - 1
      mousePosition.y = -((y / (this.renderer.domElement.height/window.devicePixelRatio)) * 2) + 1

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mousePosition, this.camera);
      let intersection = raycaster.intersectObjects(this.meshes)[0];
      return intersection ? {x: intersection.point.x, y: intersection.point.y, z: intersection.point.z} : undefined;
    }

    /**
     * Calculates location of each panorama camera on the floor below it.
     * @private
     */
    calcCameraFootprints = () => {
      this.camera_footprints = [];
      let raycaster = new THREE.Raycaster();
      let direction = new THREE.Vector3(0, 0, -1);    // Looking down
      for (let i = 0; i < this.camera_ids.length; i++) {
        this.calcCameraFootprint(i, raycaster, direction);
      }

      this.panoIndicators = new PanoIndicators();
      this.panoIndicators.initIndicators(this.camera_footprints, this.config, this.isTouchable, this.initialFloor);
      this.panoIndicators._mobile = this.isTouchable;
    }

    updateCameraFootPrints = (panoData, newCameraIds, newPanos, mesh, floor) => {
      const newFootprints = []
      let raycaster = new THREE.Raycaster();
      let direction = new THREE.Vector3(0, 0, -1);    // Looking down

      for (let i = 0; i < newCameraIds.length; i++) {
        let {position, rotation} = panoData[newCameraIds[i]];
        let oc = this.opticalCenter({position, rotation});
        raycaster.set(oc, direction);
        let intersects = raycaster.intersectObject(mesh);
        newFootprints[i] = intersects.length > 0 ? intersects[0].point : undefined;
        newPanos[i].footprint = intersects.length > 0 ? intersects[0].point : undefined;
      }
      this.panos.push(...newPanos)

      this.panoIndicators.updateIndicators(newFootprints, this.config, false, this.scene, floor)
      this.config.panos = {...this.config.panos, ...panoData}
      this.camera_ids.push(...newCameraIds)
      this.camera_footprints.push(...newFootprints)
    }

    calcCameraFootprint = (index, raycaster = new THREE.Raycaster(), direction = new THREE.Vector3(0, 0, -1)) => {
      let {position, rotation} = this.config.panos[this.camera_ids[index]];
      let oc = this.opticalCenter({position, rotation});
      raycaster.set(oc, direction);
      let intersects = raycaster.intersectObjects(this.meshes);
      this.camera_footprints[index] = intersects.length > 0 ? intersects[0].point : undefined;
      this.panos[index].footprint = intersects.length > 0 ? intersects[0].point : undefined;
    }

    applyTransformationMatrix = (tf, mode) => {
      const transformationMatrix = new THREE.Matrix4().set(...tf)
      const rotationVector = new THREE.Vector3(0, 1, 0).applyMatrix4(transformationMatrix) //Y up
      const rads = Math.atan2(rotationVector.x, rotationVector.y)
      const degs = THREE.Math.radToDeg(rads)

      this.rotateCamera('rotate-right', degs, mode === 'floorplan')
    }


    checkLabelOcclusion = (rayStart, position, floor) => {
      this.sphere.position.set(...position)
      this.sphere.updateMatrixWorld()
      const dir = new THREE.Vector3(...position).sub(rayStart).normalize()
      this.raycaster.set(rayStart, dir)
      let intersect
      if (floor) {
        const mesh = this.FloorManager.getFloorMesh(floor)
        intersect = this.raycaster.intersectObjects([mesh, this.sphere], false)
      } else {
        intersect = this.raycaster.intersectObjects([...this.meshes, this.sphere], false)
      }
      if (intersect.length) {
        if (intersect[0].object.name === 'labelIntersectSphere') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
  }


    getPanoDataDebug = () => {
      const panos = Object.values(this.config.panos)
      for (let i = 0; i < panos.length; i++) {
        console.log(`
          Pano: ${i + 1} - ${panos[i].id.slice(60)} \n 
          Position: x: ${panos[i].position[0]} y: ${panos[i].position[1]} z: ${panos[i].position[2]} \n 
          Rotation: ${THREE.Math.radToDeg(panos[i].rotation[2])}
        `)
      }
    }

    gatherReport = () => {
      const vertices = this.meshes.reduce((total, mesh) => {
        return total += mesh.geometry.attributes.position.count
      }, 0)
      const canvas = this.renderer.domElement
      this.renderer.render(this.scene, this.camera)
      const image = canvas.toDataURL('image/jpeg', 0.5)
      const gl = this.renderer.getContext()
      const debug = gl.getExtension("WEBGL_debug_renderer_info")

      const report = {
        stats: {
          panoCount: this.panos.length,
          floorCount: this.config.floorCount,
          currentFloor: this.FloorManager.currentFloor,
          vertices,
        },
        href: window.location.href,
        tourId: this.config.id,
        image: image,
        name: this.config.name,
        mode: this.currentMode,
        panoNumber: Object.keys(this.config.panos).indexOf(this.imagePlaneCamera) + 1,
        panoId: this.imagePlaneCamera,
        camera: {
          position: this.camera.position.toArray(),
          target: this.controls.target.toArray()
        },
        screen: {
          height: this.config.container.clientHeight,
          width: this.config.container.clientWidth,
          pixelRatio: window.devicePixelRatio
        },
        specs: {
          driverRenderer: gl.getParameter(debug.UNMASKED_RENDERER_WEBGL),
          dirverVendor: gl.getParameter(debug.UNMASKED_VENDOR_WEBGL),
          renderer: gl.getParameter(gl.RENDERER),
          vendor: gl.getParameter(gl.VENDOR),
          maxTexture: gl.getParameter(gl.MAX_TEXTURE_SIZE),
          userAgent: window.navigator.userAgent,
          cores: window.navigator.hardwareConcurrency,
          touch: window.navigator.maxTouchPoints,
          network: window.navigator.connection?.downlink,
          memory: window.navigator.deviceMemory,

        }
      }
      console.log(report)
      return report
    }

    setInitPositionAndTarget = (modelCenter, radius, axis, radians) => {
      const position = new THREE.Vector3(0, 1, 0)
      position.multiplyScalar(radius * 1.1).applyAxisAngle(axis, -radians)
      position.add(modelCenter).setZ(this.scene_geometry.boundingBox.max.z + (radius / 2.5))

      const targetPosition = new THREE.Vector3(0, 1, 0)
      targetPosition.multiplyScalar(radius * 0.33).applyAxisAngle(axis, -radians)
      targetPosition.add(modelCenter).setZ(this.scene_geometry.boundingBox.min.z + 1)

      this.initPosition = new THREE.Vector3().copy(position)
      this.initTarget = new THREE.Vector3().copy(targetPosition)
    }

    /**
     * Renders current panorama view using Pannellum.
     * @private
     */
    pannellumRender = () => {
      if (this.imagePlaneCamera) {
        // Compute pointing for Pannellum
        let euler = this.getEuler(this.config.panos[this.imagePlaneCamera].rotation);
        this.pannellum_render_quat.setFromEuler(euler);
        let quat = new THREE.Quaternion();
        this.camera.getWorldQuaternion(quat);
        this.pannellum_render_quat.multiply(quat);
        this.pannellum_render_euler.setFromQuaternion(this.pannellum_render_quat, 'YXZ');
        let pitch = -this.pannellum_render_euler.x;
        let yaw = Math.PI + this.pannellum_render_euler.y;
        let roll = this.pannellum_render_euler.z - Math.PI;
        let hfov = 2 * Math.atan(Math.tan((this.camera.fov / 180 * Math.PI) * 0.5) * this.camera.aspect);
        hfov = 2 * Math.atan(this.pnlmRenderOversizeFactor * Math.tan(hfov / 2));

        // Update view parameters
        if (this.config.viewParamsCallback && this.locationURLToUpdate)
          this.config.viewParamsCallback({
            mode: this.controls.movingMode,
            floor: this.FloorManager.currentFloor,
            pano: this.imagePlaneCamera,
            yaw: yaw / Math.PI * 180,
            pitch: pitch / Math.PI * 180
          });

        // Update Three.js image plane material
        // this.imagePlane.material.uniforms.focal_len.value = 1 / Math.tan((this.camera.fov / 180 * Math.PI) * 0.5) / this.camera.aspect / this.pnlmRenderOversizeFactor;
        // this.imagePlane.material.uniforms.aspect_ratio.value = this.camera.aspect;
        const focalLength = 1 / Math.tan((this.camera.fov / 180 * Math.PI) * 0.5) / this.camera.aspect / this.pnlmRenderOversizeFactor;
        this.meshes.forEach(mesh => {
          mesh.material.uniforms.focal_len.value = focalLength
          mesh.material.uniforms.aspect_ratio.value = this.camera.aspect
        })
        this.imagePlaneBox.material.uniforms.focal_len.value = focalLength
        this.imagePlaneBox.material.uniforms.aspect_ratio.value = this.camera.aspect;
        if (this.textureLoaded) {
          if (this.options.imagePlaneDir) {
            // this.imagePlane.material.uniforms.pitch_offset2.value = pitch;
            // this.imagePlane.material.uniforms.yaw_offset2.value = yaw;
            this.meshes.forEach(mesh => {
              mesh.material.uniforms.pitch_offset2.value = pitch
              mesh.material.uniforms.yaw_offset2.value = yaw
            })
            this.imagePlaneBox.material.uniforms.pitch_offset2.value = pitch;
            this.imagePlaneBox.material.uniforms.yaw_offset2.value = yaw;
          } else {
            // this.imagePlane.material.uniforms.pitch_offset1.value = pitch;
            // this.imagePlane.material.uniforms.yaw_offset1.value = yaw;
            this.meshes.forEach(mesh => {
              mesh.material.uniforms.pitch_offset1.value = pitch
              mesh.material.uniforms.yaw_offset1.value = yaw
            })
            this.imagePlaneBox.material.uniforms.pitch_offset1.value = pitch;
            this.imagePlaneBox.material.uniforms.yaw_offset1.value = yaw;
          }
        }

        let shot = this.config.panos[this.imagePlaneCamera];

        if (this.options.imagePlaneDir) {
          // this.imagePlane.material.uniforms.projectorMat2.value = this.projectorCameraMatrix(shot);
          this.meshes.forEach(mesh => mesh.material.uniforms.projectorMat2.value = this.projectorCameraMatrix(shot))
          this.imagePlaneBox.material.uniforms.projectorMat2.value = this.projectorCameraMatrix(shot);
        } else {
          // this.imagePlane.material.uniforms.projectorMat1.value = this.projectorCameraMatrix(shot);
          this.meshes.forEach(mesh => mesh.material.uniforms.projectorMat1.value = this.projectorCameraMatrix(shot))
          this.imagePlaneBox.material.uniforms.projectorMat1.value = this.projectorCameraMatrix(shot);
        }

        // Activate render target to render to texture
        //console.log('RENDERING: '+ (this.options.imagePlaneDir ? 2 : 1))
        this.renderer.setRenderTarget(this.options.imagePlaneDir ? this.pannellumRenderTarget2 : this.pannellumRenderTarget1);

        // Render with Pannellum
        this.pannellumRenderer.render(pitch, yaw, hfov, {roll: roll});

        // Switch back to canvas rendering
        this.renderer.setRenderTarget(null);
      }
    }

    getEuler(rotation) {
      return new THREE.Euler(Math.PI / 2 - rotation[0], rotation[1], rotation[2] + this.panoAdjustAngle, 'YXZ');
    }

    /**
     * Updates orthographic camera matrix.
     * @private
     */
    updateOrthoMatrix = () => {
      const ratio = this.config.container.clientHeight / this.config.container.clientWidth
      const radius = this.scene_geometry.boundingSphere ? this.scene_geometry.boundingSphere.radius : 10
      const padding = 1.1
      const width = radius * padding / ratio / this.orthoZoomFactor
      const height = radius * padding / this.orthoZoomFactor

      this.controls.ortho = (new THREE.Matrix4()).makeOrthographic(-width, width, height, -height, 0.001, 10000);
      this.camera.left = -width;
      this.camera.right = width;
      this.camera.top = height;
      this.camera.bottom = -height;
    }

    /**
     * Resizes renderer when window size changes.
     * @private
     */
    onWindowResize = () => {
      this.camera.aspect = this.config.container.clientWidth / this.config.container.clientHeight;
      this.defaultFov = this.getFOV();
      this.camera.fov = this.defaultFov;
      this.controls.animationFov = this.defaultFov;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.config.container.clientWidth, this.config.container.clientHeight);
      if (this.pannellumRenderTarget1) {
        this.pannellumRenderTarget1.setSize(this.config.container.clientWidth * this.pnlmRenderOversizeFactor,
            this.config.container.clientHeight * this.pnlmRenderOversizeFactor);
        this.pannellumRenderTarget2.setSize(this.config.container.clientWidth * this.pnlmRenderOversizeFactor,
            this.config.container.clientHeight * this.pnlmRenderOversizeFactor);
      }
      this.updateOrthoMatrix();
      this.controls.persp = this.camera.projectionMatrix.clone();
      if (this.controls.movingMode == VIEWER_MODES.FLOORPLAN) {
        this.controls.updateOrtho = true;
      } else if (this.controls.movingMode == VIEWER_MODES.PANO && !this.hasAccess('panos') && this.minimap) {
        this.minimap.updateCameraResolution();
      }
      this.renderUpdateNeeded = true;
    }

    /**
     * Process floorplan mouse movement events.
     * @private
     * @param {MouseEvent} event - Document mouse move event.
     */
    floorplanMouseMove = (event, isTouch) => {
      if (typeof event.changedTouches !== 'undefined') {
        // Handle touch inputs
        event.clientX = event.changedTouches[0].clientX;
        event.clientY = event.changedTouches[0].clientY;
      }
      if(this.entryArrow.selected){
        let x = (event.clientX || event.targetTouches[0].clientX);
        let y = (event.clientY || event.targetTouches[0].clientY);
        this.entryArrow.moveArrow({x, y}, this.currentMode);
        return
      }
      if(this.entryArrow.enableRotation){
        if(!store.state.layout.isMobileScreen){
          const currentAngle = Math.atan2(event.clientX - this.entryArrow.position2D.x, event.clientY - this.entryArrow.position2D.y);
          this.entryArrow.arrow.rotation.z = currentAngle-this.entryArrow.initialAngle+this.entryArrow.oldAngle
        }
        return
      }
      if (this.mouseDown){
          this.controls.animationSpeed = 1;
          let diffX = event.clientX - this.mouseDown.clientX;
          let diffY = event.clientY - this.mouseDown.clientY;
          
          this.controls.pan(
            diffX - this.floorplanDiffX,
            diffY - this.floorplanDiffY
          );
          this.floorplanDiffX = diffX;
          this.floorplanDiffY = diffY;
        
      }
      if (this.MeasurementManager.measureMode >= 1 && this.currentMode === VIEWER_MODES.FLOORPLAN && !isTouch) {

        this.MeasurementManager.updatePointsFloorplan( event, this.camera, this.renderer.domElement)
      }
    }

    /**
     * Event handler for mouse moves.
     * @private
     * @param {MouseEvent} event - Document mouse move event.
     */
    onDocumentMouseMove = (event) => {


      if (event.preventDefault) {
        event.preventDefault();
      }
      let bounds = this.config.container.getBoundingClientRect();
      this.mouse.x = ((event.clientX - bounds.left) / this.config.container.clientWidth) * 2 - 1;
      this.mouse.y = -((event.clientY - bounds.top) / this.config.container.clientHeight) * 2 + 1;
      this.mousePos.x = event.clientX
      this.mousePos.y = event.clientY
      if (this.mouseDown && !this.dragging) {
        let movement = Math.sqrt(Math.abs(this.mouseDown.clientX + this.floorplanOffsetX - event.clientX)
          * Math.abs(this.mouseDown.clientX + this.floorplanOffsetX - event.clientX)
          + Math.abs(this.mouseDown.clientY + this.floorplanOffsetY - event.clientY)
          * Math.abs(this.mouseDown.clientY + this.floorplanOffsetY - event.clientY));
        if (movement > 2)
          this.dragging = true;
      }
      this.renderUpdateNeeded = true;
      if (!(event.sourceCapabilities && event.sourceCapabilities.firesTouchEvents)) {
        this.mouseIn = true;
      }

      if (this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
        this.floorplanMouseMove(event, false);
      }
    }

    /**
     * Event handler for touch movements. Pans center of view if one touch or
     * adjusts zoom if two touches.
     * @private
     * @param {TouchEvent} event - Document touch move event.
     */
    onDocumentTouchMove = (event) => {
      if (this.mouseDown && !this.dragging) {
        let movement = Math.max(
          Math.abs(this.mouseDown.clientX - event.targetTouches[0].clientX),
          Math.abs(this.mouseDown.clientY - event.targetTouches[0].clientY),
        )
        if (movement > 10) // Without this threshold, it can be difficult to recognize taps
          this.dragging = true;
      }

      event.preventDefault();

      let bounds = this.config.container.getBoundingClientRect();
      this.mouse.x = ((event.targetTouches[0].clientX - bounds.left) / this.config.container.clientWidth) * 2 - 1;
      this.mouse.y = -((event.targetTouches[0].clientY - bounds.top) / this.config.container.clientHeight) * 2 + 1;
      this.renderUpdateNeeded = true;

      if (event.targetTouches.length == 2 && this.onPointerDownPointerDist != -1) {
        let pos0 = event.targetTouches[0],
          pos1 = event.targetTouches[1],
          clientDist = Math.sqrt((pos0.clientX - pos1.clientX) * (pos0.clientX - pos1.clientX) +
            (pos0.clientY - pos1.clientY) * (pos0.clientY - pos1.clientY)),
          newEvent = {amount: (this.onPointerDownPointerDist - clientDist) * 0.1 / 2.5};
        this.onPointerDownPointerDist = clientDist;
        this.onDocumentMouseWheel(newEvent);
      } else if (this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
          let newEvent = {clientX: event.targetTouches[0].clientX, clientY: event.targetTouches[0].clientY};
          this.floorplanMouseMove(newEvent, true);
          if(this.entryArrow.enableRotation){
            const currentAngle = Math.atan2(event.targetTouches[0].clientX - this.entryArrow.position2D.x, event.targetTouches[0].clientY - this.entryArrow.position2D.y);
            this.entryArrow.arrow.rotation.z = currentAngle-this.entryArrow.initialAngle+this.entryArrow.oldAngle
          }
      }
    }

    /**
     * Event handler for mouse wheel. Changes zoom.
     * @private
     * @param {WheelEvent} event - Document mouse wheel event.
     */
    onDocumentMouseWheel = (event) => {
      if (this.AnimationController.animating) return
      if (event.preventDefault) {
        event.preventDefault();
      }
      const isTouch = typeof event.amount !== 'undefined'
      let amount = typeof event.amount !== 'undefined' ? event.amount : (event.deltaY > 0 ? 1 : -1);
      // Only do something while in panorama mode (other handler in OrbitControls.js)
      if (this.controls.movingMode === VIEWER_MODES.ORBIT) {
        if (amount < 0 && !isTouch) {
          // this section pans the camera while zooming a certain amount according to how far away the camera is from the target
          const distance = this.controls.animationPosition.distanceTo(this.controls.animationTarget)
          const deltaX = (this.mouse.x * this.config.container.clientWidth / 2).toFixed(3)
          const deltaY = (this.mouse.y * this.config.container.clientHeight / 2).toFixed(3)
          if (distance > 4) {
            this.controls.pan(-deltaX * 0.1, deltaY * 0.1)
          } else if (distance > 2.6) {
            this.controls.pan(-deltaX * 0.075, deltaY * 0.075)
          } else if (distance > 2.0){
            this.controls.pan(-deltaX * 0.01, deltaY * 0.01)
          }
        }

        return;
      }

      if (this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
        this.orthoZoomFactor *= 1 - amount * 0.075;
        if (this.orthoZoomFactor > 4.5) this.orthoZoomFactor = 4.5
        if (this.orthoZoomFactor < 0.6) this.orthoZoomFactor = 0.6
        this.updateOrthoMatrix();
        this.controls.updateOrtho = true;
        this.renderUpdateNeeded = true;
      } else {
        this.controls.animationFov = Math.max(Math.min(this.controls.animationFov + amount * 3.5, this.config.maxFov), this.config.minFov);
        this.setLookAroundSpeed()
        if (this.controls.animationFov >= this.config.maxFov)
          this.setMovingMode(VIEWER_MODES.ORBIT, {}, true);
      }
    }

    setLookAroundSpeed = () => {
      ///This method fires almost eveytime the viewer is interacted with for some reason
      let hfov = 2 * Math.atan(Math.tan((this.controls.animationFov / 180 * Math.PI) * 0.5) * this.camera.aspect);
      this.controls.lookAroundSpeed = (hfov / (2 * Math.PI)) * viewerOptions.lookAroundSpeedCoeff;

      const fovNorm = normalize(this.controls.animationFov, this.config.maxFov, this.config.minFov)
      //console.log(fovNorm)
      this.controls.minPanoPitch = lerp(fovNorm, this.config.bottomPanoPitchMin, this.config.bottomPanoPitchMax)
      this.controls.maxPanoPitch = lerp(fovNorm, this.config.topPanoPitchMin, this.config.topPanoPitchMax)
    }

    /**
     * Sets the current panorama.
     * @private
     * @param {string} shot_id - Panorama ID
     */
    setImagePlaneCamera = (shot_id) => {
      let shot = this.config.panos[shot_id];
      if (shot) {
        if (this.previousShot !== shot_id) {
          this.previousShot = this.returnShot = shot_id;
          if (this.imagePlaneCamera !== 0) {
            this.textureLoaded = false;
            if (this.controls.movingMode === VIEWER_MODES.ORBIT) {
              // First panorama when coming from overview
              this.options.imagePlaneDir = false;
            }

            this.renderer.resetState();
            let multiRes = shot.config.multiRes;
            let {cubeResolution, extension, maxLevel, tileResolution} = multiRes;
            if (shot.config.multiRes) {
              let panoData = {
                cubeResolution,
                extension,
                maxLevel,
                tileResolution: Number(tileResolution),
                hfov: shot.config.hfov,
                tiles: {...shot.tiles},
                shtHash: shot.config.multiRes.shtHash

              }
              this.pannellumRenderer.init(panoData, shot.config.type, false,
                2 * Math.PI, Math.PI, 0, () => {
                }, {});
            }

            if (this.options.imagePlaneDir) {
              // this.imagePlane.material.uniforms.projectorTex1.value = this.pannellumRenderTarget1.texture;
              // this.imagePlane.material.uniforms.projectorMat1.value = this.projectorCameraMatrix(shot);
              this.meshes.forEach(mesh => {
                mesh.material.uniforms.projectorTex1.value = this.pannellumRenderTarget1.texture
                mesh.material.uniforms.projectorMat1.value = this.projectorCameraMatrix(shot)
              })
              this.imagePlaneBox.material.uniforms.projectorTex1.value = this.pannellumRenderTarget1.texture;
              this.imagePlaneBox.material.uniforms.projectorMat1.value = this.projectorCameraMatrix(shot);
            } else {
              // this.imagePlane.material.uniforms.projectorTex2.value = this.pannellumRenderTarget2.texture;
              // this.imagePlane.material.uniforms.projectorMat2.value = this.projectorCameraMatrix(shot);
              this.meshes.forEach(mesh => {
                mesh.material.uniforms.projectorTex2.value = this.pannellumRenderTarget2.texture
                mesh.material.uniforms.projectorMat2.value = this.projectorCameraMatrix(shot)
              })
              this.imagePlaneBox.material.uniforms.projectorTex2.value = this.pannellumRenderTarget2.texture;
              this.imagePlaneBox.material.uniforms.projectorMat2.value = this.projectorCameraMatrix(shot);
            }

            this.options.imagePlaneDir = !this.options.imagePlaneDir;
          }
          this.options.imagePlaneOpacity = 1;
          this.imagePlaneCamera = shot_id;
          this.imagePlane.material.visible = false;
          this.imagePlaneBox.material.visible = false;
          this.controls.animationSpeed = 0.1;

          this.currentFootprint = this.camera_footprints[this.camera_ids.indexOf(shot_id)];
        }
      }
    }

    /**
     * Event handler for mouse clicks.
     * @private
     * @param {MouseEvent} event - Document mouse down event.
     */
    onDocumentMouseDown = (event) => {
      if (this.AnimationController.animating) return
      if (this.controls.zoomFollow) {
        this.controls.zoomFollow = false;
      }
      // this.config.container.focus();
      this.mouseDown = {clientX: event.clientX, clientY: event.clientY};
      this.dragging = false;
      this.pointerDownTime = Date.now();
      event.preventDefault();
      event.stopPropagation();
      this.config.container.style.cursor = "grabbing";
      if (this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
        this.floorplanDiffX = 0;
        this.floorplanDiffY = 0;
        /*IF EDIT ENTRY ARROW STATE IS ENABLED*/
        if(this.entryArrow && store.state.controls.controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW){
          this.config.container.style.cursor = "move";
          this.entryArrow.selectArrow()
          this.entryArrow.initialAngle = Math.atan2(event.clientX - this.entryArrow.position2D.x, event.clientY - this.entryArrow.position2D.y);
        }
      }
    }

    /**
     * Event handler for touches. Initializes panning if one touch or zooming if
     * two touches.
     * @private
     * @param {TouchEvent} event - Document touch start event.
     */
    onDocumentTouchStart = (event) => {
      this.floorplanDiffX = 0;
      this.floorplanDiffY = 0;
      // this.config.container.focus();
      this.mouseDown = {clientX: event.targetTouches[0].clientX, clientY: event.targetTouches[0].clientY};
      this.dragging = false;
      this.pointerDownTime = Date.now();
      event.preventDefault();
      //event.stopPropagation();

      if (event.targetTouches.length == 2) {
        // Down pointer is the center of the two fingers
        let pos0 = {x: event.targetTouches[0].clientX, y: event.targetTouches[0].clientY},
        pos1 = {x: event.targetTouches[1].clientX, y: event.targetTouches[1].clientY};
        this.mouseDown.clientX += (pos1.x - pos0.x) * 0.5;
        this.mouseDown.clientY += (pos1.y - pos0.y) * 0.5;
        this.onPointerDownPointerDist = Math.sqrt((pos0.x - pos1.x) * (pos0.x - pos1.x) +
          (pos0.y - pos1.y) * (pos0.y - pos1.y));
      }else if(this.entryArrow && store.state.controls.controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW){
        let bounds = this.config.container.getBoundingClientRect();
        this.mouse.x = ((event.targetTouches[0].clientX - bounds.left) / this.config.container.clientWidth) * 2 - 1;
        this.mouse.y = -((event.targetTouches[0].clientY - bounds.top) / this.config.container.clientHeight) * 2 + 1;
        if (this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
        this.entryArrow.selectArrow()
        this.entryArrow.initialAngle = Math.atan2(event.targetTouches[0].clientX - this.entryArrow.position2D.x, event.targetTouches[0].clientY - this.entryArrow.position2D.y);
        }
      }
    }

    /**
     * Navigates to panorama.
     * @private
     * @param {object} camera - Panorama camera object
     * @param {boolean} level_camera - Whether or not to level camera after navigating
     */
     navigateToShot = (shot_id) => {
      
      this.panoIndicators._currentindicator = this.panoIndicators._indicator
      this.panoAdjustAngle = 0
      let shot = this.config.panos[shot_id];
      if (!shot) {
        this.setMovingMode(VIEWER_MODES.ORBIT);
        return;
      }
      const distance = new THREE.Vector3(...shot.position).distanceTo(this.controls.animationPosition)
      let speedMultiplier = 0.5
      let close = 0
      if(distance > 10){
        speedMultiplier = 1
        close = Math.pow(distance*0.05,0.16)
      }
       this.AnimationController.killAnimations();
       this.setImagePlaneCamera(shot_id);
       this.controls.noLookAround = true;
       if(shot.floorNumber!==this.FloorManager.currentFloor){
        store.dispatch('floors/changeFloor', shot.floorNumber);
        this.FloorManager.setCurrentFloor(shot.floorNumber)
        this.renderPano = false
        if(store.state.controls.editMode){ ///Retain Visibility Slider Value between panos in Edit Mode
          let opacity = this.FloorManager.getCurrentMesh().material.uniforms.texRatio.value
          this.AnimationController.animateTexRatio(1, close, opacity, this.meshes)
        }else{
        this.AnimationController.animateTexRatio(1, close, 1, this.meshes)
        }
       }else if(this.viewAll){
        store.dispatch('floors/isolateFloorLabels', shot.floorNumber);
        this.viewAll = false
       }
       if (this.previousMode === VIEWER_MODES.PANO) {
         this.AnimationController.moveCameraToPanoPosition(shot, 0.7, VIEWER_MODES.PANO, this.panoTemporaryPosition)
         this.AnimationController.animateRatio(0.7, 0.0, this.options.imagePlaneDir, this.meshes, this.imagePlaneBox)
       } else if (this.previousMode === VIEWER_MODES.ORBIT) {
        //store.commit('rooms/set_room', shot.floorNumber);
        this.renderPano = false
        this.AnimationController.moveCameraToPanoPosition(shot, 2 * speedMultiplier, VIEWER_MODES.ORBIT, this.panoTemporaryPosition)
        this.AnimationController.animateTexRatio(1, close, 1, this.meshes)
        this.AnimationController.animateRatio(1 * speedMultiplier, 0.5, this.options.imagePlaneDir,this.meshes, this.imagePlaneBox)
        this.AnimationController.animateOpacity(1 * speedMultiplier, 0.7, this.options, this.imagePlane, this.imagePlaneBox, () => {this.renderPano = true})
      }
      ///When previous mode is floorplan
      else {
        this.renderPano = false
        this.AnimationController.moveCameraToPanoPosition(shot, 2 * speedMultiplier, VIEWER_MODES.FLOORPLAN, this.panoTemporaryPosition)
        this.AnimationController.animateTexRatio(1, close, 1, this.meshes)
        this.AnimationController.animateRatio(1 * speedMultiplier, 0.5, this.options.imagePlaneDir,this.meshes, this.imagePlaneBox)
        this.AnimationController.animateOpacity(1 * speedMultiplier, 0.7, this.options, this.imagePlane, this.imagePlaneBox, () => {this.renderPano = true})
      }
      if(store.state.layout.isMobileScreen)this.renderer.setPixelRatio(1);
    }

    /**
     * Calculates angle between two vectors. Used for calculating valid movements.
     * @private
     */
    angleBetweenVector2 = (x1, y1, x2, y2) => {
      let a = Math.atan2(y2, x2) - Math.atan2(y1, x1);
      if (a > Math.PI) return a - 2 * Math.PI;
      else if (a < -Math.PI) return a + 2 * Math.PI;
      else return a;
    }

    /**
     * Computes list of possible movements for use with keyboard controls.
     * @private
     */
    computeValidMoves = () => {
      let currentPosition = this.controls.animationPosition;
      let currentTarget = this.controls.animationTarget;
      let currentDir = currentTarget.clone().sub(currentPosition);

      let wantedMotionDirs = {
        STEP_LEFT: new THREE.Vector3(-currentDir.y, currentDir.x, 0),
        STEP_RIGHT: new THREE.Vector3(currentDir.y, -currentDir.x, 0),
        STEP_FORWARD: new THREE.Vector3(currentDir.x, currentDir.y, 0),
        STEP_BACKWARD: new THREE.Vector3(-currentDir.x, -currentDir.y, 0),
        TURN_LEFT: new THREE.Vector3(0, 0, 0),
        TURN_RIGHT: new THREE.Vector3(0, 0, 0),
        TURN_U: new THREE.Vector3(0, 0, 0)
      };

      let wantedDirs = {
        STEP_LEFT: new THREE.Vector3(currentDir.x, currentDir.y, 0),
        STEP_RIGHT: new THREE.Vector3(currentDir.x, currentDir.y, 0),
        STEP_FORWARD: new THREE.Vector3(currentDir.x, currentDir.y, 0),
        STEP_BACKWARD: new THREE.Vector3(currentDir.x, currentDir.y, 0),
        TURN_LEFT: new THREE.Vector3(-currentDir.y, currentDir.x, 0),
        TURN_RIGHT: new THREE.Vector3(currentDir.y, -currentDir.x, 0),
        TURN_U: new THREE.Vector3(-currentDir.x, -currentDir.y, 0)
      };

      let min_d = {};
      let closest_line = {};
      let turn_threshold;
      let k;
      for (k in wantedMotionDirs) {
        if (Object.prototype.hasOwnProperty.call(wantedMotionDirs, k)) {
          min_d[k] = 999999999999;
          closest_line[k] = undefined;
        }
      }

      for (let i = 0; i < this.camera_ids.length; i++) {
        let shot_id = this.camera_ids[i];
        let shot = this.config.panos[shot_id];
        let oc = this.opticalCenter(shot);
        let motion = oc.clone().sub(currentPosition);
        let d = currentPosition.distanceTo(oc);

        for (k in wantedMotionDirs) {
          if (Object.prototype.hasOwnProperty.call(wantedMotionDirs, k)) {
            let turn = this.angleBetweenVector2(wantedDirs[k].x, wantedDirs[k].y, currentDir.x, currentDir.y);
            let driftAB = this.angleBetweenVector2(wantedMotionDirs[k].x, wantedMotionDirs[k].y,
              motion.x, motion.y);
            let driftBA = driftAB - turn;
            let drift = Math.max(driftAB, driftBA);
            if (k.lastIndexOf('STEP', 0) === 0) {
              turn_threshold = 0.5;
              if (Math.abs(turn) < turn_threshold && Math.abs(drift) < 0.5 && d > 0.01 && d < 20) {
                if (d < min_d[k]) {
                  min_d[k] = d;
                  closest_line[k] = shot_id;
                }
              }
            } else if (k.lastIndexOf('TURN', 0) === 0) {
              if (Math.abs(turn) < 0.7 && d < 15) {
                if (d < min_d[k]) {
                  min_d[k] = d;
                  closest_line[k] = shot_id;
                }
              }
            }
          }
        }
      }
      return closest_line;
    }

    /**
     * Navigates to another panorama. For use with keyboard controls.
     * @private
     */
    walkOneStep = (motion_type) => {
      let line = this.validMoves[motion_type];
      if (line !== undefined)
        this.navigateToShot(line);
    }

    /**
     * Event handler for key down events.
     * @private
     * @param {KeyboardEvent} event - Document key down event.
     */
    onKeyDown = (event) => {
      if (this.controls.movingMode === VIEWER_MODES.PANO && event.target.nodeName === 'BODY' && store.state.controls.editMode && this.hasAccess('panos')) {
        this.validMoves = this.computeValidMoves();

        let validKey = true;

        switch (event.keyCode) {
          case 37: // left arrow
            if (event.shiftKey) {
              this.walkOneStep('TURN_LEFT');
            } else {
              this.walkOneStep('STEP_LEFT');
            }
            break;
          case 38: // up arrow
            this.walkOneStep('STEP_FORWARD');
            break;
          case 39: // right arrow
            if (event.shiftKey) {
              this.walkOneStep('TURN_RIGHT');
            } else {
              this.walkOneStep('STEP_RIGHT');
            }
            break;
          case 40: // down arrow
            if (event.shiftKey) {
              this.walkOneStep('TURN_U');
            } else {
              this.walkOneStep('STEP_BACKWARD');
            }
            break;
          case 27: // ESC
            this.setMovingMode(VIEWER_MODES.ORBIT);
            break;
          case 87: // W
            if (event.shiftKey) {
              this.flyThroughControls.zoom('in', true)
            } else {
              this.flyThroughControls.zoom('in', false)
            }
            break;
          case 82: // R
            this.flyThroughControls.zoomReset(this.defaultFov)
          break;
          case 83: // S
            if (event.shiftKey) {
              this.flyThroughControls.zoom('out', true)
            } else {
              this.flyThroughControls.zoom('out', false)
            }
            break;
          case 65: // A
            if (event.shiftKey) {
              this.flyThroughControls.rotate('left', true)
            } else {
              this.flyThroughControls.rotate('left', false)
            }
            break;
          case 68: // D
            if (event.shiftKey) {
              this.flyThroughControls.rotate('right', true)
            } else {
              this.flyThroughControls.rotate('right', false)
            }
            break;
          default:
            validKey = false;
            break;
        }

        if (validKey) {
          event.preventDefault();
        }
      }
    }

        /**
     * Event handler for key up events.
     * @private
     * @param {KeyboardEvent} event - Document key up event.
     */
      onKeyUp = (event) => {
        event.preventDefault();
        if (this.controls.movingMode === VIEWER_MODES.PANO && event.target.nodeName === 'BODY' && store.state.controls.editMode) {
          switch (event.keyCode) {
            case 87:
            case 83:
              this.flyThroughControls.zoomStop()
              break;
            case 65:
            case 68:
              this.flyThroughControls.rotateStop()
              break;
            default:
              break;
          }

        }
      }

    /**
     * Animates view, using requestAnimationFrame to trigger rendering.
     * @private
     */
    animate = () => {
      if (this.hasAccess('stats')) {
        if (!this.stats ) {
          this.stats = new Stats();
          this.stats.showPanel(0);
          this.config.stats.appendChild(this.stats.dom);
        } else {
          this.stats.begin();
        }
      }

      this.time = Date.now();

      if (this.time > this.prevTime + 1000) {
        this.prevTime = this.time;
        this.locationURLToUpdate = true;
      } else {
        this.locationURLToUpdate = false;
      }

      requestAnimationFrame(this.animate);
      // Check if base level cube faces have loaded
      if (this.pannellumRenderer && this.pannellumRenderer.isBaseLoaded()) {
        this.textureLoaded = true
        this.controls.noLookAround = false;
        if (this.AnimationController.animationRequested) this.AnimationController.dispatchAnimation()
      }

      if (this.minimap && this.minimap.minimapArrow) {
        this.minimap.minimapArrow.track(this.camera.position, this.controls.animationTarget);
      }

      this.imagePlane.material.visible = true;
      this.imagePlaneBox.material.visible = true;
      // this.options.imagePlaneOpacity = 0

      if (
        this.textureLoaded &&
        !this.anyTextureLoadedAfterOverview &&
        this.controls.movingMode == VIEWER_MODES.PANO
      ) {
        this.anyTextureLoadedAfterOverview = true;
      }

      this.controls.update();
      if (this.renderUpdateNeeded || (this.pannellumRenderer && this.pannellumRenderer.isLoading())) {
        this.renderUpdateNeeded = false;
        this.render();
      }

      this.skipAnimation = false;
      if (this.hasAccess('stats')) {
        this.stats.end();
      }
    }

    /**
     * Event handler for mouse up events.
     * @private
     * @param {MouseEvent} event - Document mouse up event.
     */
    onDocumentMouseUp = (event) => {
      if (this.AnimationController.animating) return

      if (this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
        this.floorplanClickHandler(event);
        return
      }
      this.mouseDown = undefined;
      this.onPointerDownPointerDist = -1;
      let time = Date.now() - this.pointerDownTime;

      // Assume click is at last mouse move location
      if ((this.dragging === false && time < 200) && (this.mouseOnModel || event.type == 'touchend' ||
        (event.sourceCapabilities && event.sourceCapabilities.firesTouchEvents))) {
        if (event.type == 'touchend' ||
          (event.sourceCapabilities && event.sourceCapabilities.firesTouchEvents)) {
          if (typeof event.changedTouches !== 'undefined') {
            // Handle touch inputs
            event.clientX = event.changedTouches[0].clientX;
            event.clientY = event.changedTouches[0].clientY;
          }
          let bounds = this.config.container.getBoundingClientRect();
          this.mouse.x = ((event.clientX - bounds.left) / this.config.container.clientWidth) * 2 - 1;
          this.mouse.y = -((event.clientY - bounds.top) / this.config.container.clientHeight) * 2 + 1;
          this.bvhPicking(true)
        }

        if (this.MeasurementManager.measureMode) {
          this.MeasurementManager.clickHandler(this.mouseHelperOffset)
          this.renderUpdateNeeded = true;
          return;
        }

        if (this.controls.movingMode !== VIEWER_MODES.FLOORPLAN) {
          const toggledPano = this.findClosestCamera(this.mouseHelper.position)
          if (toggledPano.id !== undefined) {
            // Navigate to closest camera
            this.setMovingMode(VIEWER_MODES.PANO, {shot: toggledPano.id});
          }
        }
      }
      this.currentMode===VIEWER_MODES.PANO? this.config.container.style.cursor = "pointer" : this.config.container.style.cursor = "default"
      this.dragging = false;
    }

    /**
     * Main picking method. positions the mouse helper
     * @private
     * @param {boolean} touch - Whether or not this was triggered by a touch.
    */
     bvhPicking = touch => {
      if ((!this.mouseIn && !touch) || this.controls.movingMode === VIEWER_MODES.FLOORPLAN) {
        // Hide cursors if mouse is no longer in container
        this.mouseHelper.visible = false;
        this.panoIndicators.toggleClosestIndicatorM(false);//edge case leaves on pano indicators
        return;
      }

      this.raycaster.setFromCamera(this.mouse, this.camera)
      let intersects

      if(this.camera.getWorldDirection(new THREE.Vector3(0,0,-1)).z < -0.55){
        intersects = this.raycaster.intersectObjects([this.FloorManager.getCurrentMesh()], true)
      }else{
        intersects = this.raycaster.intersectObjects(this.meshes, true)
      }

      let result = null


      //if multi-floor
      if (this.isMultiFloor) {
        //use the first result on active floor otherwise use first result
        if (this.currentMode === VIEWER_MODES.ORBIT) {
          for (let i = 0; i < intersects.length; i++) {
            if (intersects[i].object.name === this.FloorManager.getCurrentMesh().name) {
              result = intersects[i]
              break
            }
          }

          if (!result) result = intersects[0]
        }else {
          result = intersects[0]
        }
      } else {
        // if orbit mode - use first result under the adjusted clipping height
        if (this.currentMode === VIEWER_MODES.ORBIT) {
          for (let i = 0; i < intersects.length; i++) {
            if (intersects[i].point.z < this.defaultClippingHeightAdjusted) {
              result = intersects[i]
              break
            }
          }
        } else {
          result = intersects[0]
        }
      }

      if (result) {
        const position = result.point
        const normal = result.face.normal
        normal.transformDirection(result.object.matrixWorld)
        normal.multiplyScalar(10)
        normal.add(position)

        if(result.distance < 1.75){ ///Shrink mousehelper in confined spaces
        let delta = (1.75 - result.distance) / 1.5
        delta = 1 - delta
        this.mouseHelper.scale.set(delta,delta,delta)
        }
        else{
          this.mouseHelper.scale.set(1,1,1)
        }

        this.mouseHelperOffset.position.copy(position)
        this.mouseHelperOffset.lookAt(normal)
        this.mouseHelperOffset.translateZ(0.075)

        this.mouseHelper.position.copy(position)
        this.mouseHelper.lookAt(normal)
        if (!store.state.layout.hiddenUI) this.mouseHelper.visible = true
        this.mouseOnModel = true


        if (this.MeasurementManager.measureMode === 2) {
          this.MeasurementManager.updatePoints(this.mouseHelperOffset)
        }

        const toggledPano = this.findClosestCamera(position)
        const closestIndex = this.camera_footprints.indexOf(this.camera_footprints.find(element => element === toggledPano.footprint))
        
        this.panoIndicators.toggleClosestIndicator(false)
        if (
          (this.camera.position.distanceTo(toggledPano.footprint) < 100 || this.controls.movingMode === VIEWER_MODES.ORBIT)) {
          if (!store.state.layout.isMobileScreen && !this.MeasurementManager.measureMode) {
            this.panoIndicators.setClosestIndicator(closestIndex)
            this.panoIndicators.toggleClosestIndicator(true)
            if(!this.mouseDown && this.currentMode===VIEWER_MODES.ORBIT) this.config.container.style.cursor = "pointer";
          }
        }

      } else {
        if(this.mouseOnModel) this.config.container.style.cursor = "default";
        this.mouseHelper.visible = false
        this.mouseOnModel = false
        this.panoIndicators.toggleClosestIndicator(false)
      }
    }

    /**
     * Finds the closest camera to a position
     * Returns the closest index in this.camera_footprints and the distance to it
     * @private
     * @param {THREE.Vector3} position - The position to compare to
    */
    findClosestCamera = position => {

      const panos = this.panos
      let closestPano
      let minDistance = Infinity

      for (let i = 0; i < panos.length; i++) {
        const footprint = panos[i].footprint
        if (!footprint) continue
        const distance = footprint.distanceTo(position)

        if (distance < minDistance) {
          minDistance = distance
          closestPano = panos[i]
        }
      }
      const initialClosestId = closestPano
      
      if(initialClosestId.id===this.imagePlaneCamera){
        minDistance = Infinity
        let panos = this.panos.filter(pano => pano.floorNumber == this.FloorManager.currentFloor)
        const frustum = new THREE.Frustum()
        const matrix = new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse)
        frustum.setFromProjectionMatrix(matrix)
       
        for (let i = 0; i < panos.length; i++) {
          if(!panos[i].footprint || !frustum.containsPoint(panos[i].footprint)){
            continue
        }
        ///CALC ANGLE HERE
        const camDir = new THREE.Vector3().subVectors(this.camera.position,position)
        const panoDir = new THREE.Vector3().subVectors(this.camera.position,panos[i].footprint)
        
        const distance = camDir.angleTo(panoDir)*(180/Math.PI)
        if (distance < minDistance) {
            minDistance = distance
            closestPano = panos[i]
          }
        }
      }
      return closestPano
    }

    findClosestCameraByFloor = (position, floor = this.FloorManager.currentFloor) => {
      const panos = this.panos.filter(pano => pano.floorNumber === floor)
      let closestId = this.imagePlaneCamera
      let minDistance = Infinity

      for (let i = 0; i < panos.length; i++) {
        const footprint = panos[i].footprint
        if (!footprint) continue
        const distance = footprint.distanceTo(position)

        if (distance < minDistance) {
          minDistance = distance
          closestId = panos[i].id
        }
      }
      return closestId
    }
    
    /**
     * Renders view.
     * @private
     */
    render = () => {
      this.ipMat.needsUpdate = true
      /*if (this.currentMode !== VIEWER_MODES.FLOORPLAN)*/ this.bvhPicking(false);

      // Reset renderer to avoid interactions with Pannellum
      this.renderer.resetState();

      // Render
      if (this.controls.movingMode === VIEWER_MODES.PANO && this.imagePlaneCamera) {
        this.pannellumRender();
        if (this.textureLoaded && this.renderPano) {
          if (this.imagePlane.material.uniforms.ratio.value < 0.5) {
            this.scene.background = this.pannellumRenderTarget2.texture;
          } else {
            this.scene.background = this.pannellumRenderTarget1.texture;
          }
        }
      }
      this.renderer.render(this.scene, this.camera);
      /*if (this.controls.movingMode === VIEWER_MODES.ORBIT) {
        // Render twice to ensure solid color is always above any transparency
        //this.scene_material.uniforms.renderAlpha.value = 0.0;
        //this.renderer.render(this.scene, this.camera);
        //this.scene_material.uniforms.renderAlpha.value = 1.0;
      }*/
      this.renderer.clearDepth();
      this.renderer.render(this.frontScene, this.camera);
      //this.renderer.render(this.backScene, this.camera);

      // Render minimap overview
      if (this.controls.movingMode === VIEWER_MODES.PANO && this.imagePlaneCamera) {
        if (this.meshTextureLoaded) {
          if (this.showMinimap && this.minimap) {
            this.setShowImagePlaneBox(false);
            this.camera.getWorldDirection(this.camera_dir);
            this.camera_dir.z = -800;
            this.camera_dir.normalize();
            this.minimap.updateCamera(this.camera, this.camera_dir, this.config.cursorSize * 20);
            let old_background = this.scene.background
            this.scene.background = undefined
            this.minimap.renderMiniMap(this.config.container,this.backScene,this.renderer,8)
            this.minimap.renderMiniMap(this.config.container,this.scene,this.renderer,0,this.meshes,this.FloorManager.getCurrentMesh())
            let mouseHelperVisibility = this.mouseHelper.visible;
            let closestPanoIndicator = this.panoIndicators.getClosestIndicatorVisibility();
            this.mouseHelper.visible = false;
            if(!this.MeasurementManager.measureMode)this.panoIndicators.toggleClosestIndicator(true);
            this.panoAdjustHelper.visible = false
            if (this.minimap && this.minimap.minimapArrow) this.minimap.minimapArrow.toggle(true);
            this.minimap.renderMiniMap(this.config.container,this.frontScene,this.renderer,8)
            //this.MeasurementManager.showMagnifier(true)
            if (this.minimap && this.minimap.minimapArrow) this.minimap.minimapArrow.toggle(false);
            this.mouseHelper.visible = mouseHelperVisibility;
            if(!this.MeasurementManager.measureMode)this.panoIndicators.toggleClosestIndicator(closestPanoIndicator);
            this.scene.background = old_background;
          }
        }

        if (this.meshTextureLoaded && this.imagePlaneCamera) {
          let shot = this.config.panos[this.imagePlaneCamera];
          let panoAdjustHelperPosition = new THREE.Vector3(
            this.panoAdjustPosition[0] + shot.position[0],
            this.panoAdjustPosition[1] + shot.position[1],
            this.panoAdjustPosition[2] + shot.position[2] - this.panoAdhustHelperZAxisOffset
          );
          this.panoAdjustHelper.position.copy(panoAdjustHelperPosition);
           if (this.hasAccess('panos') && !store.state.layout.hiddenUI) {
             this.panoAdjustHelper.visible = true;
           } else {
            this.panoAdjustHelper.visible = false;
           }
        }
        if (this.anyTextureLoadedAfterOverview && this.renderPano) this.setShowImagePlaneBox(true)
      }

      //render new magnifying glass
      if(!this.isTouchable){
        if (this.MeasurementManager.measureMode){
          if(this.currentMode !== VIEWER_MODES.FLOORPLAN && !this.mouseOnModel || this.dragging && this.currentMode !== VIEWER_MODES.FLOORPLAN){
            this.MeasurementManager.magMode = false
          }
          else{
          this.MeasurementManager.magMode = true
          }
        }
        else if(this.MeasurementManager.magMode){
          this.MeasurementManager.magMode = false
        }
        this.MeasurementManager.moveMagnifier(this.config.container,this.mousePos,this.camera,this.mouseHelper,this.renderer,this.mouseHelperOffset, this.controls.movingMode === VIEWER_MODES.FLOORPLAN)
      }

      //show measurements in measure mode
      if (this.MeasurementManager.measureMode) {
        if(this.currentMode === VIEWER_MODES.PANO && this.FloorManager.getCurrentMesh().material.uniforms.texRatio.value > 0) this.FloorManager.getCurrentMesh().material.uniforms.texRatio.value -= 0.02
        this.MeasurementManager.showMeasures(this.camera, this.config.container.clientWidth, this.config.container.clientHeight)
        this.MeasurementManager.updateTempLabel(this.camera, this.config.container.clientWidth, this.config.container.clientHeight)
      }

      let xCurrent = this.controls.animationTarget.x;
      let xEnd = this.controls.target.x;
      let animationTargeEnd = xCurrent.toFixed(5) === xEnd.toFixed(5);

      // Update positions of room labels
      if (typeof this.mouseDown === undefined || this.mouseDown || !(this.mouseDown && animationTargeEnd)) {
        if ((this.currentMode !== VIEWER_MODES.PANO && store.state.controls.showNames) ||
          (this.currentMode === VIEWER_MODES.PANO && store.state.controls.panoShowNames)) {
          this.updateRoomLabels();
        }
        
        if (this.entryArrow && store.state.controls.controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW) {
          if(this.entryArrow.gizmo.material.visible === false)this.entryArrow.gizmo.material.visible = true;
        }else if(this.entryArrow && this.entryArrow.gizmo.material.visible === true){
          this.entryArrow.gizmo.material.visible = false;
        }
        this.labelO.updateCounter()
        if (this.currentMode !== VIEWER_MODES.FLOORPLAN && store.state.controls.showFeatures) {
          this.updateFeatureLabels();
        }
      }
      this.renderUpdateNeeded = true;
    }

    moveLabel(event) {
      this.plane.setFromNormalAndCoplanarPoint(this.plane.normal, new THREE.Vector3(0, 0, this.FloorManager.getCurrentFloor().datumLine));
      // Moving label is working in floorplan mode
      let camera = new THREE.OrthographicCamera(this.camera.left, this.camera.right, this.camera.bottom, this.camera.top, 0.01, 2000)
      camera.position.copy(this.camera.position)
      camera.quaternion.copy(this.camera.quaternion)
      camera.updateMatrixWorld();

      let mouse = {
        x: (event.x / this.config.container.clientWidth) * 2 - 1,
        y: (event.y / this.config.container.clientHeight) * 2 - 1
      }

      //console.log(mouse)
      let p = new THREE.Vector3();

      this.raycaster.setFromCamera(mouse, camera);
      this.raycaster.ray.intersectPlane(this.plane, p);
      //console.log(mouse)
      return [p.x, p.y, p.z];
    }

    updateFeatureLabels() {
      if (this.controls.movingMode !== VIEWER_MODES.FLOORPLAN) {
        let old_label_markers = store.state.features.featuresLabelMarkers;
        let new_label_markers = [];
        let sameValueCounter = 0;
        let features = store.state.features.features;
        this.labelO.features.length = features.length
        if(this.labelO.features[this.labelO.features.length-1]===undefined)this.labelO.features[this.labelO.features.length-1] = true
        for (let i = 0; i < features.length; i++) {
          let feature = {...features[i]};
          new_label_markers[i] = this.updateHtmlLabel(feature, feature.position,i).label_marker;
          // prevent labels update if position not changed
          if (old_label_markers[i]) {
            let sameX = new_label_markers[i].x === old_label_markers[i].x;
            let sameY = new_label_markers[i].y === old_label_markers[i].y;

            if (sameX && sameY) {
              sameValueCounter++;
            }
          }
        }

        if (sameValueCounter < new_label_markers.length) {
          store.commit('features/set_features_label_markers', new_label_markers);
        }
      }
    }

    updateRoomLabels() {
      if (store.state.controls.showNames || store.state.controls.panoShowNames) {
        let old_label_markers = store.state.rooms.roomsLabelMarkers;
        let new_label_markers = [];
        let sameValueCounter = 0;
        let rooms = store.state.rooms.rooms;
        for (let i = 0; i < rooms.length; i++) {
          new_label_markers[i] = this.updateHtmlLabel({floor: rooms[i].floor}, rooms[i].label_pos).label_marker;

          // prevent labels update if position not changed
          if (old_label_markers[i]) {
            let sameX = new_label_markers[i].x === old_label_markers[i].x;
            let sameY = new_label_markers[i].y === old_label_markers[i].y;

            if (sameX && sameY) {
              sameValueCounter++;
            }
          }
        }
        if (sameValueCounter < new_label_markers.length) {
          store.commit('rooms/set_rooms_label_markers', new_label_markers);
        }
      }
    }

    updateHtmlLabel(labelObj, position, index) {
      let pos = new THREE.Vector3(0, 0, 0);
      pos.set(...position);
      pos.sub(this.camera.position);
      const isInFrustrum = pos.angleTo(this.camera.getWorldDirection(new THREE.Vector3())) < Math.PI / 2
      const isBelowClipping = this.isMultiFloor ? true : (!labelObj.id || this.currentMode === VIEWER_MODES.PANO || position[2] < this.defaultClippingHeightAdjusted)

      if(this.currentMode === VIEWER_MODES.PANO && this.AnimationController.occlusionCompleted && position){
        if (labelObj.id) this.labelO.features[index] = this.checkLabelOcclusion(this.camera.position,position, labelObj.floor)
        if(index == this.labelO.features.length-1){
          this.AnimationController.occlusionCompleted = false
        }
      }
      if(this.currentMode !== VIEWER_MODES.PANO && this.labelO.counter <= 0 && position){
        if(this.camera.getWorldDirection(new THREE.Vector3()).z < -0.78 || this.camera.position.z>this.FloorManager.getCurrentMesh().geometry.boundingBox.max.z){
            this.labelO.setAll(true)
          }else{
            if (labelObj.id) this.labelO.features[index] = this.checkLabelOcclusion(this.camera.position, position, labelObj.floor)
          }
          if(index == this.labelO.features.length-1){
            this.labelO.resetCounter()
          }
      }

      let isNotOccluded = this.labelO.features[index]

      if (!labelObj.label_marker) labelObj.label_marker = {}
      if (!labelObj.id) isNotOccluded = true ///Room labels always visible

      if (isInFrustrum && isBelowClipping && isNotOccluded) {
        pos.set(...position);
        pos.project(this.camera);
        let w_2 = this.config.container.clientWidth / 2;
        let h_2 = this.config.container.clientHeight / 2;
        labelObj.label_marker.x = ((pos.x * w_2) + w_2).toFixed(1);
        labelObj.label_marker.y = (-(pos.y * h_2) + h_2).toFixed(1);
        //Hides room label when magnifyingGlass covers it
        if(this.MeasurementManager.magMode){
          let magPos = this.mousePos.clone();
          //let isTop = magPos.y < 128 * 1.9 / 849 * this.config.container.clientHeight
          magPos = magPos.add(new THREE.Vector2(0,-170));
          magPos = magPos.distanceTo(labelObj.label_marker);

          if(magPos < 0.160*this.config.container.clientHeight){
            labelObj.label_marker.x = labelObj.label_marker.y = -100;
          }
        }
        ///Hide labels from minimap
        if(this.currentMode === VIEWER_MODES.PANO && this.minimap){
          if(labelObj.label_marker.x < 34+this.minimap.minimapWidth && labelObj.label_marker.y > this.config.container.clientHeight-this.minimap.minimapHeight-34){
            labelObj.label_marker.x = labelObj.label_marker.y = -100;
          }
        }
      } else {
        labelObj.label_marker.x = labelObj.label_marker.y = -100;
      }

      return labelObj;
    }

    hasAccess(feature) {
      return hA(feature, store.state.users.userRole, store.state.users.permissions, store.state.controls.editMode);
    }

    setFlythroughRotateSpeed(value) {
      this.flyThroughControls.rotateSpeed = value
    }

    setFlythroughZoomSpeed(value) {
      this.flyThroughControls.zoomSpeed = value
    }

    /**
     * Destroys viewer.
     */
    destroy = () => {
      this.pannellumRenderer.destroy();
      this.config.container.removeEventListener('keydown', this.onKeyDown, false);
      this.config.container.innerHTML = '';
    };

    get showMinimap() {
      return !this.hasAccess('panos') && !store.state.layout.isMobileScreen;
    }

    get controlsPosition() {
      let {x, y, z} = this.controls.animationPosition;
      return {x, y, z}
    }

    set controlsPosition(data) {
      this.controls.animationPosition.copy(data);
    }

    get getViewer() {
      return this;
    }
  }

  return {
    viewer: (config) => {
      return new Viewer({...config, ...viewerOptions});
    }
  };

})(window, document);
