import * as THREE from 'three';
import {viewerOptions} from '../config';
import roundedBg from  '../../../assets/rounded-bg.svg';

export default class Minimap {
  minimapWidth;
  minimapHeight;
  minimapRenderer;
  minimapCamera;
  minimapArrow;
  
  constructor($mainRenderer, $minimap) {
    this.$mainRenderer = $mainRenderer;
    this.$minimap = $minimap;
  }

  renderMiniMap = (container, scene, renderer, padding, meshes, currentFloorMesh) => {
      const width = container.clientWidth
      const height = container.clientHeight
      const x = 26-padding
      const y = 26-padding
      renderer.setScissorTest(true)
      renderer.setViewport(x, y,this.minimapWidth+padding*2, this.minimapHeight+padding*2)
      renderer.setScissor(x, y,this.minimapWidth+padding*2, this.minimapHeight+padding*2)
      if(this.minimapArrow)this.minimapArrow.show_bg(true)
      if(meshes){
       // if(meshes.length == 1) currentFloor = 1
          if(currentFloorMesh){
          meshes.forEach(mesh => {
              mesh.visible = false
          })
          currentFloorMesh.visible = true
          let r = currentFloorMesh.material.uniforms.texRatio.value
          currentFloorMesh.material.uniforms.texRatio.value = 0
          renderer.render(scene, this.minimapCamera)
          currentFloorMesh.material.uniforms.texRatio.value = r
          meshes.forEach(mesh => {
          mesh.visible = true
          })
        }
      }
      else{
        renderer.render(scene, this.minimapCamera)
      }
      if(this.minimapArrow)this.minimapArrow.show_bg(false)
      renderer.clearDepth()
      renderer.setScissorTest(false)
      renderer.setViewport(0, 0, width, height)
  }

  create(defaultFov) {
    if (!this.minimapRenderer) this.minimapRenderer = document.createElement('div');
    this.minimapWidth = 250;
    this.minimapHeight = Math.floor(this.minimapWidth * 2 / 3);
    this.minimapRenderer.style.width = this.minimapWidth + 'px';
    const zoom = viewerOptions.minimapZoomOut;
    this.minimapRenderer.style.height = this.minimapHeight + 'px';
    this.minimapCamera = new THREE.OrthographicCamera(
      -3.12*zoom, 3.12*zoom, 2.47*zoom, -2.46*zoom, 0.001, 2000
    );
    this.minimapCamera.up.set(0, 0, 1);
    this.$minimap.appendChild(this.minimapRenderer);
  }


  updateCamera(camera, cameraDir, minimapDistScale) {
    this.minimapCamera.position.set(camera.position.x - cameraDir.x * minimapDistScale,
      camera.position.y - cameraDir.y * minimapDistScale,
      camera.position.z + minimapDistScale);
    this.minimapCamera.lookAt(camera.position);
  }

  updateCameraResolution() {
    this.minimapCamera.aspect = this.minimapWidth / this.minimapHeight;
    this.minimapCamera.updateProjectionMatrix();
  }

  loadArrow(scene) {
    this.minimapArrow = new MinimapArrow(scene);
    this.minimapArrow.create();
  }

  create_bg(scene) {
    this.minimapArrow.create_bg(scene)
  }
}


class MinimapArrow {
  _arrow;

  constructor(scene) {
    this.scene = scene;
  }

  create() {
    this.arrow = new THREE.Group();
    const mul = 1.3; ///Scale multiplier for minimap arrow
    const shape = new THREE.Shape() //Minimap arrow shape
   
    this.arrowGeo = new THREE.Mesh(new THREE.ShapeGeometry(shape),
    new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide
    }))
    this.arrowGeo.rotation.y = Math.PI / 2;
    this.arrowGeo.rotation.z = Math.PI / 2;
    this.arrow.visible = false;
    this.arrow.add(this.arrowGeo);
    this.scene.add(this.arrow);
  }
  
  create_bg(scene) {
    const roundedCornersImage = new THREE.TextureLoader().load( roundedBg );
    this.mini_bg = new THREE.Sprite(new THREE.SpriteMaterial({ map: roundedCornersImage, color: 0x000000, opacity: .5,
      sizeAttenuation: false
    }));

    this.mini_bg.scale.set(9,7,7);

    scene.add(this.mini_bg);
  }

  show_bg(state){
   this.mini_bg.visible = state
  }

  toggle(state) {
    this.arrow.visible = state;
  }

  track(pos, tar) {
    this.arrow.position.copy(pos);
    this.mini_bg.position.copy(pos);
    const target = new THREE.Vector3().copy(tar);
    target.z = this.arrow.position.z;
    this.arrow.lookAt(target);
  }

  get arrow() {
    return this._arrow;
  }

  set arrow(value) {
    this._arrow = value;
  }
}
