<template>
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M10.5526 1.47696L23.6405 14.5649C24.3565 15.2808 24.3565 16.4416 23.6405 17.1576L17.1587 23.6394C16.4428 24.3554 15.282 24.3554 14.566 23.6394L1.47811 10.5515C1.04466 10.1181 0.856523 9.49667 0.976739 8.89559L2.17689 2.89484C2.24946 2.53196 2.53311 2.24832 2.89598 2.17574L8.89674 0.975594C9.49781 0.855378 10.1192 1.04352 10.5526 1.47696ZM3.85477 3.85363L2.77447 9.25514L15.8622 22.3429L22.3441 15.8611L9.25628 2.77332L3.85477 3.85363ZM6.96484 6.9637C6.60686 7.32168 6.02646 7.32168 5.66848 6.9637C5.3105 6.60572 5.3105 6.02532 5.66848 5.66734C6.02646 5.30935 6.60686 5.30935 6.96484 5.66734C7.32282 6.02532 7.32282 6.60572 6.96484 6.9637Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'Tag',
}
</script>
