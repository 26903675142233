<template>
  <div>
    <div class="btn-group-vertical mobile-aside glass" ref="asideMenu">
      <b-dropdown
          ref="controlsDropdown"
          v-if="!editMode"
          id="labels-dropdown"
          no-caret class="controls-dropdown labels-dropdown"
           dropleft
          toggle-class="btn-control btn-icon btn-icon-rounded"
      >
        <template #button-content>
          <TicketIcon/>
        </template>

        <b-dropdown-item v-if="mode !== VIEWER_MODES.FLOORPLAN">
          <Tag/>
          <p class="small mx-2 mb-0">Feature labels</p>
          <div @click.stop="toggle_features(!showFeatures)" class="checkbox-switcher" :class="{checked: showFeatures}">
            <div class="toggler"></div>
          </div>
        </b-dropdown-item>
        <b-dropdown-divider v-if="mode !== VIEWER_MODES.FLOORPLAN"/>
        <b-dropdown-item>
          <RoomIcon/>
          <p class="small mx-2 mb-0">Room labels</p>
          <div v-if="mode !== VIEWER_MODES.PANO" @click.stop="toggleNames(!showNames)" class="checkbox-switcher"
               :class="{checked: showNames}">
            <div class="toggler"></div>
          </div>
          <div v-else @click.stop="toggleNames(!panoShowNames)" class="checkbox-switcher"
               :class="{checked: panoShowNames}">
            <div class="toggler"></div>
          </div>
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item class="all-features" @click="showFeatureList()">
          <StarIcon/>
          <p class="small mb-2 mt-1">All features</p>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-if="!editMode"
          ref="measurementsDropdown"
          class="controls-dropdown" dropleft no-caret toggle-class="btn-control btn-icon btn-icon-rounded">
        <template #button-content class="mobile">
          <RulerEditIcon/>
        </template>
        <b-dropdown-item
            :active="controlsState === CONTROLS_STATE.MEASURE_DISTANCE"
            @click.native="setControlsState(CONTROLS_STATE.MEASURE_DISTANCE)"
        >
          <DistanceIcon class="stroke-secondary"/>
          <p class="small mb-0 ml-2 text-center">Measure<br/>distance</p>
        </b-dropdown-item>
        <b-dropdown-divider v-if="mode === VIEWER_MODES.FLOORPLAN"/>
        <b-dropdown-item
            v-if="mode === VIEWER_MODES.FLOORPLAN"
            :active="controlsState === CONTROLS_STATE.MEASURE_AREA"
            @click.native="setControlsState(CONTROLS_STATE.MEASURE_AREA)"
        >
          <Area/>
          <p class="small mb-0 ml-2 text-center">Measure<br/>area</p>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
          ref="floorsDropdown"
          v-if="floors.length > 1" :id="TOOLTIPS.MOBILE_FLOORS" class="controls-dropdown" dropleft no-caret toggle-class="btn-control btn-icon btn-icon-rounded">
        <template #button-content>
          <LayersIcon/>
        </template>
        <b-dropdown-item v-b-modal.floorEditModalId v-if="editMode">
          <EditIcon class="mr-1 edit-icon"/>
          <span class="font-weight-semi-bold">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="!editMode">
          <span class="font-weight-semi-bold">Select Floor</span>
        </b-dropdown-item>
        <b-dropdown-divider class="mx-n2"/>
        <template v-for="(fl, index) in floors">
          <b-dropdown-item
              class="text-light"
              @click="changeFloor(fl.floor)"
              :key="fl.floor"
              :active="fl.floor === floor.floor"
          >
            {{ floorName(fl, 'floor') }}
          </b-dropdown-item>
          <b-dropdown-divider v-if="index !== floors.length - 1" :key="fl.floor + '-divider'"/>
        </template>
      </b-dropdown>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex/dist/vuex.esm.browser';
import LayersIcon from '@/components/svg-icons/layers';
import DistanceIcon from '@/components/svg-icons/distance';
import Area from '@/components/svg-icons/area';
import RulerEditIcon from '@/components/svg-icons/ruler-edit';
import TicketIcon from '@/components/svg-icons/ticket';
import Tag from '@/components/svg-icons/tag';
import RoomIcon from '@/components/svg-icons/room';
import EditIcon from '@/components/svg-icons/edit';
import StarIcon from '@/components/svg-icons/star';
import { MenuPages } from '@/constants';
export default {
  name: 'MobileAside',
  components: {StarIcon, EditIcon, RoomIcon, Tag, TicketIcon, RulerEditIcon, Area, DistanceIcon, LayersIcon},
  computed: {
    ...mapState('floors', ['floors', 'floor']),
    ...mapState('layout', ['allFloorsProcessed']),
    ...mapState('controls', ['controlsState', 'mode', 'showNames', 'panoShowNames', 'showFeatures']),
  },
  methods: {
    ...mapActions('controls', ['setControlsState', 'toggleNames']),
    ...mapActions('floors', ['changeFloor']),
    ...mapActions('mobile', ['toggleMobileMenu']),
    ...mapMutations('controls', ['toggle_features']),
    ...mapMutations('mobile', ['set_menu_page']),
    showFeatureList() {
      this.toggleMobileMenu(true);
      this.set_menu_page(MenuPages.FEATURE_LIST);
    },
    closeMenuOnOutsideTouch(event) {
      let isClickInside = this.$refs.asideMenu.contains(event.target);

      if (!isClickInside) {
        if (this.$refs.measurementsDropdown && this.$refs.measurementsDropdown.visible) {
          this.$refs.measurementsDropdown.visible = false;
        }
        if (this.$refs.controlsDropdown && this.$refs.controlsDropdown.visible) {
          this.$refs.controlsDropdown.visible = false;
        }
        if (this.$refs.floorsDropdown && this.$refs.floorsDropdown.visible) {
          this.$refs.floorsDropdown.visible = false;
        }
      }
    }
  },
  created() {
    if (this.isTouchable) {
      document.body.addEventListener('touchstart', this.closeMenuOnOutsideTouch)
    }
  },
  destroyed() {
    if (this.isTouchable) {
      document.body.removeEventListener('touchstart', this.closeMenuOnOutsideTouch)
    }
  }
}
</script>

<style scoped src="./Aside.scss" lang="scss"></style>
