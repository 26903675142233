<template>
  <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M14.7951 26.9998C14.3811 26.9998 13.9851 26.8288 13.7016 26.5273L6.40707 18.7588C5.83857 18.1558 5.87007 17.2063 6.47307 16.6393C7.07757 16.0723 8.02707 16.1023 8.59257 16.7053L14.7801 23.2918L27.3921 9.4888C27.9531 8.8753 28.9011 8.8348 29.5131 9.3928C30.1236 9.9508 30.1656 10.9003 29.6076 11.5108L15.9021 26.5108C15.6216 26.8198 15.2226 26.9968 14.8056 26.9998H14.7951Z"
          />
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
}
</script>
