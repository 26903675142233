<template>
  <svg width="27" height="13" viewBox="0 0 27 13" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.80766 2.95162V6.57262H8.55366V8.44263H2.80766V12.9986H0.597656V1.09862H9.28466V2.95162H2.80766Z"
          />
    <path d="M13.9189 2.96862H9.97488V1.09862H20.0729V2.96862H16.1289V12.9986H13.9189V2.96862Z" />
    <path
        d="M26.6614 6.23262V7.43963H20.8814V6.48762L23.9244 3.83562C24.2758 3.51829 24.5138 3.26329 24.6384 3.07062C24.7744 2.86662 24.8424 2.66262 24.8424 2.45863C24.8424 2.18662 24.7178 1.96562 24.4684 1.79562C24.2304 1.62562 23.8678 1.54063 23.3804 1.54063C22.9951 1.54063 22.6494 1.60296 22.3434 1.72762C22.0374 1.85229 21.7938 2.03929 21.6124 2.28862L20.5414 1.52362C20.8361 1.12696 21.2328 0.820958 21.7314 0.605625C22.2301 0.378958 22.8194 0.265625 23.4994 0.265625C24.3948 0.265625 25.0974 0.458292 25.6074 0.843625C26.1174 1.22896 26.3724 1.73329 26.3724 2.35662C26.3724 2.71929 26.2818 3.05929 26.1004 3.37662C25.9304 3.69396 25.5848 4.07929 25.0634 4.53262L23.1254 6.23262H26.6614Z"
        />
  </svg>

</template>
<script>
export default {
  name: 'SquareFeetIcon',
}
</script>
