<template>
  <svg width="20" height="23" viewBox="0 0 20 23" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8.02216 18.9298C8.02047 18.7351 8.09515 18.5475 8.23012 18.4075L10.6692 15.9734H0.740918C0.544414 15.9734 0.355959 15.8951 0.21701 15.7559C0.0780607 15.6166 0 15.4277 0 15.2308C0 15.0339 0.0780607 14.845 0.21701 14.7057C0.355959 14.5665 0.544414 14.4882 0.740918 14.4882H10.6692L8.23012 12.0319C8.09515 11.8918 8.02047 11.7042 8.02216 11.5095C8.02385 11.3148 8.10177 11.1286 8.23914 10.9909C8.37651 10.8533 8.56235 10.7752 8.75661 10.7735C8.95088 10.7718 9.13804 10.8466 9.27777 10.9819L13.2298 14.9561C13.2643 14.9905 13.2917 15.0315 13.3104 15.0766C13.3291 15.1217 13.3387 15.1701 13.3387 15.2189C13.3387 15.2678 13.3291 15.3161 13.3104 15.3612C13.2917 15.4063 13.2643 15.4473 13.2298 15.4818L9.27777 19.4574C9.13804 19.5927 8.95088 19.6675 8.75661 19.6659C8.56235 19.6642 8.37651 19.5861 8.23914 19.4484C8.10177 19.3107 8.02385 19.1245 8.02216 18.9298Z"
        />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4.97902 1.69972C5.57461 1.10413 6.38241 0.769531 7.2247 0.769531H16.7523C17.5946 0.769531 18.4024 1.10413 18.998 1.69972C19.5936 2.29531 19.9282 3.10311 19.9282 3.9454V19.8248C19.9282 20.6671 19.5936 21.4749 18.998 22.0704C18.4024 22.666 17.5946 23.0006 16.7523 23.0006H7.2247C6.38241 23.0006 5.57461 22.666 4.97902 22.0704C4.38343 21.4749 4.04883 20.6671 4.04883 19.8248V18.5541H5.63676V19.8248C5.63676 20.2459 5.80406 20.6498 6.10186 20.9476C6.39966 21.2454 6.80355 21.4127 7.2247 21.4127H16.7523C17.1735 21.4127 17.5774 21.2454 17.8752 20.9476C18.173 20.6498 18.3403 20.2459 18.3403 19.8248V3.9454C18.3403 3.52426 18.173 3.12036 17.8752 2.82256C17.5774 2.52477 17.1735 2.35747 16.7523 2.35747H7.2247C6.80355 2.35747 6.39966 2.52477 6.10186 2.82256C5.80406 3.12036 5.63676 3.52426 5.63676 3.9454V11.8848H4.04883V3.9454C4.04883 3.10311 4.38343 2.29531 4.97902 1.69972Z"
          />
    <path
        d="M16.0496 12.3729C15.7518 12.6707 15.348 12.838 14.9268 12.838C14.5057 12.838 14.1018 12.6707 13.804 12.3729C13.5062 12.0751 13.3389 11.6712 13.3389 11.25C13.3389 10.8289 13.5062 10.425 13.804 10.1272C14.1018 9.82941 14.5057 9.66211 14.9268 9.66211C15.348 9.66211 15.7518 9.82941 16.0496 10.1272C16.3474 10.425 16.5147 10.8289 16.5147 11.25C16.5147 11.6712 16.3474 12.0751 16.0496 12.3729Z"
        />
  </svg>
</template>
<script>
export default {
  name: 'EntryArrowIcon',
}
</script>
