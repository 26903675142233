<template>
  <div class="tutors" :class="{open: showTutors}">
    <a class="close" @click="toggleTutors({show: false})"><i class="las la-times"></i></a>
    <b-carousel
        v-show="tutorsType === VIEWER_MODES.FLOORPLAN"
        id="carousel-1"
        :interval="0"
        v-model="slide[VIEWER_MODES.FLOORPLAN]"
        controls
        :ref="'carousel' + VIEWER_MODES.FLOORPLAN"
    >
      <template>
        <b-carousel-slide v-for="slide of slides[VIEWER_MODES.FLOORPLAN]" :key="VIEWER_MODES.FLOORPLAN + '-' + slide.name">
          <div class="tutor-animation" :ref="VIEWER_MODES.FLOORPLAN + '-' + slide.name"></div>
          <h5 class="mb-0"><b>{{ slide.title.main }}</b> {{ slide.title.secondary }}</h5>
        </b-carousel-slide>
      </template>
    </b-carousel>
    <b-carousel
        v-show="tutorsType === VIEWER_MODES.ORBIT"
        id="carousel-2"
        :interval="0"
        v-model="slide[VIEWER_MODES.ORBIT]"
        controls
        :ref="'carousel' + VIEWER_MODES.ORBIT"
    >
      <template>
        <b-carousel-slide v-for="slide of slides[VIEWER_MODES.ORBIT]"
                          :key="VIEWER_MODES.ORBIT + '-' + slide.name">
          <div class="tutor-animation" :ref="VIEWER_MODES.ORBIT + '-' + slide.name"></div>
          <h5 class="mb-0"><b>{{ slide.title.main }}</b> {{ slide.title.secondary }}</h5>
        </b-carousel-slide>
      </template>
    </b-carousel>
    <b-carousel
        v-show="tutorsType === VIEWER_MODES.PANO"
        id="carousel-3"
        :interval="0"
        v-model="slide[VIEWER_MODES.PANO]"
        controls
        :ref="'carousel' + VIEWER_MODES.PANO"
    >
      <template>
        <b-carousel-slide v-for="slide of slides[VIEWER_MODES.PANO]"
                          :key="VIEWER_MODES.PANO + '-' + slide.name">
          <div class="tutor-animation" :ref="VIEWER_MODES.PANO + '-' + slide.name"></div>
          <h5 class="mb-0"><b>{{ slide.title.main }}</b> {{ slide.title.secondary }}</h5>
        </b-carousel-slide>
      </template>
    </b-carousel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import lottie from 'lottie-web/build/player/lottie';
import { VIEWER_MODES } from '@/constants';

import slidesConfig from './config';
export default {
  name: 'Tutors',
  data() {
    return {
      slide: {
        [VIEWER_MODES.FLOORPLAN]: 0,
        [VIEWER_MODES.ORBIT]: 0,
        [VIEWER_MODES.PANO]: 0,
      },
      animations: []
    }
  },
  computed: {
    ...mapState('controls', ['showTutors', 'tutorsType']),
    slides() {
      return slidesConfig[this.isTouchable ? 'mobile' : 'desktop']
    }
  },
  mounted() {
    this.initSlides();
  },
  methods: {
    ...mapActions('controls', ['toggleTutors']),
    nextSlide(index) {
      if (this.showTutors && index === this.slide[this.tutorsType]) {
        this.$refs['carousel' + this.tutorsType].next();
      }
    },
    initSlides() {
      this.animations = {};
      Object.keys(this.slides).forEach(type => {
        this.animations[type] = [];
        this.slides[type].forEach((slide, index) => {
          let anim = lottie.loadAnimation({
            animationData: {...slide.data},
            container: this.$refs[type + '-' + slide.name][0],
            renderer: 'svg',
            loop: 1,
            autoplay: false
          })
          anim.onComplete = () => {
            this.nextSlide(index);
          }
          this.animations[type].push(anim);
        })
      })
    }
  },
  watch: {
    showTutors(newValue) {
      if (newValue) {
        this.animations[this.tutorsType][this.slide[this.tutorsType]].play();
      } else {
        this.animations[this.tutorsType][this.slide[this.tutorsType]].stop();
      }
      this.slide[this.tutorsType] = 0;
    },
    slide: {
      handler(newValue, oldValue) {
        if (this.showTutors) {
          this.animations[this.tutorsType][oldValue[this.tutorsType]].stop();
          this.animations[this.tutorsType][newValue[this.tutorsType]].play();
        }
      },
      deep: true
    }
  }
}
</script>
<style src="./Tutors.scss" scoped lang="scss"></style>
