import Vue from 'vue';
import Vuex from 'vuex';
import layout from './layout';
import rooms from './rooms';
import floors from './floors';
import controls from './controls';
import measurements from './measurements';
import viewer from './viewer';
import users from './users';
import panos from './panos';
import features from './features';
import mobile from './mobile';
import rts from './rts';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    rooms,
    floors,
    controls,
    measurements,
    viewer,
    users,
    panos,
    features,
    mobile,
    rts
  }
})
