<template>
  <div class="rts-checklist">
    <div class="row">
      <div class="col-md-12 col-6 d-flex align-items-center">
        <h6 class="text-primary font-weight-semi-bold mb-0">Beginner's Guide</h6>
      </div>
      <div class="col-md-12 col-6">
        <RTSProgress class="my-3" :ratio="rtsReadyRatio"/>
      </div>
    </div>
    <div class="stats mb-3" v-if="!isMobile">
      <p class="mb-0 pr-3 py-2 border-right">Tasks completed: {{ rtsReady }} of {{ rtsLength }}</p>
      <p class="mb-0 mx-auto text-primary font-weight-semi-bold">{{ rtsReadyRatio }}%</p>
    </div>
    <ul class="checklist list-unstyled mb-5 mb-md-0">
      <li v-for="(data, name) in rtsStepsSorted" 
          :key="name" 
          class="checklist-item"
          :class="{completed: checklist[name]}"
          @click="selectRTSStep(name)"
      >
        <div class="d-flex align-items-center">
          <div :class="{active: checklist[name]}" class="checklist-checkbox mr-3">
            <CheckIcon class="check"/>
          </div>
          {{ data.title }}
        </div>
      </li>
    </ul>
    <p v-if="isMobile" class="mt-0 mt-md-2 mb-0 text-right completed-tasks">Tasks completed: {{ rtsReady }} of {{ rtsLength }}</p>
    <!-- <button v-if="isDev" :style="{ marginTop: '1rem' }" @click="resetRTS">Debug: Reset Progress</button> -->
    <div v-if="isMobile" @click="toggle_checklist(false)" class="hook"></div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex/dist/vuex.esm.browser';
import { RTSSteps } from '@/store/rts';
import CheckIcon from '@/components/svg-icons/check';
import RTSProgress from '@/components/rts/progress/RTSProgress';
import { MenuPages } from '@/constants';

export default {
  name: 'RTSChecklist',
  components: {RTSProgress, CheckIcon, MenuPages},
  data() {
    return {
      RTSSteps,
      isDev: process.env.NODE_ENV === 'development'
    }
  },
  computed: {
    ...mapState('rts', ['checklist', 'step', 'rtsMode']),
    ...mapState('viewer', ['viewer']),
    ...mapState('floors', ['floors']),
    ...mapState('layout', ['isMobileScreen']),
    ...mapState('mobile', ['mobileMenuOpen', 'menuPage']),
    rtsStepsSorted() {
      let checked = {};
      let unchecked = {};
      Object.keys(this.RTSSteps).map(name => {
        if (this.checklist[name]) {
          checked[name] = this.RTSSteps[name];
        } else {
          unchecked[name] = this.RTSSteps[name];
        }
      })
      return {...unchecked, ...checked};
    },
    rtsLength() {
      return Object.keys(this.checklist).length;
    },
    rtsReady() {
      return Object.keys(this.checklist).filter(key => this.checklist[key]).length;
    },
    rtsReadyRatio() {
      return Math.floor(this.rtsReady / this.rtsLength * 100);
    }
  },
  watch: {
    step(newStep, prevStep) {

      switch (prevStep) {
        case this.RTSLabels.tourInfo:
          if(this.isMobileScreen) { this.$bvModal.hide(this.tourEditModalId) }
          break;
        case this.RTSLabels.floorNames:
          this.$bvModal.hide(this.floorEditModalId);
          break;
        case this.RTSLabels.entryArrow:
        case this.RTSLabels.roomLabels:
        case this.RTSLabels.features:
          this.setControlsState(this.CONTROLS_STATE.NULL);
          break;
        case this.RTSLabels.panos:
          this.toggle_pano_list(false);
          break;
        case this.RTSLabels.modelReview:
          this.$bvModal.hide(this.problemReportModalId);
        default:
          break;
      }
      
      switch (newStep) {
        case this.RTSLabels.tourInfo:
          if(this.isMobileScreen){
            this.toggleMobileMenu(true);
            this.set_menu_page(MenuPages.TOUR_META);
          }else{
            this.$bvModal.show(this.tourEditModalId);
          }
          break;
        case this.RTSLabels.entryArrow:
          this.viewer.setMovingMode(this.VIEWER_MODES.FLOORPLAN);
          this.toggleArrow();
          this.setControlsState(this.CONTROLS_STATE.EDIT_ENTRY_ARROW);
          break;
        case this.RTSLabels.roomLabels:
          this.viewer.setMovingMode(this.VIEWER_MODES.FLOORPLAN);
          this.setControlsState(this.CONTROLS_STATE.EDIT_ROOM_LABEL);
          this.selectRoom();
          break;
        case this.RTSLabels.floorNames:
          this.$bvModal.show(this.floorEditModalId);
          break;
        case this.RTSLabels.panos:
          this.viewer.setMovingMode(this.VIEWER_MODES.PANO);
          this.toggle_pano_list(true);
          break;
        case this.RTSLabels.features:
          if(this.viewer.currentMode === 'floorplan') this.viewer.setMovingMode(this.VIEWER_MODES.ORBIT);
          if(this.viewer.currentMode === 'walk'){
            this.viewer.controls.minPanoPitch = Math.PI-0.01;
            this.viewer.controls.maxPanoPitch = 0.01;
          }
          this.setControlsState(this.CONTROLS_STATE.MOVE_FEATURE);
          break;
        case this.RTSLabels.modelReview:
          this.viewer.setMovingMode(this.VIEWER_MODES.ORBIT);
          this.$bvModal.show(this.problemReportModalId);
          console.log(newStep,this.RTSLabels);
          break;
        default:
          break;
      }
    }
  },
  methods: {
    ...mapActions('rts', ['selectStep', 'toggleRTSMode', 'resetProgress']),
    ...mapActions('controls', ['setControlsState','toggleArrow']),
    ...mapMutations('rts', ['toggle_checklist']),
    ...mapMutations('panos', ['toggle_pano_list']),
    ...mapMutations('mobile', ['set_menu_page']),
    ...mapActions('rooms', ['selectRoom']),
    ...mapActions('mobile', ['toggleMobileMenu']),

    selectRTSStep(step) {
      if (step === this.RTSLabels.floorNames && this.floors.length === 1) return;
      if (!this.rtsMode) {
        this.toggleRTSMode(true);
      }
      this.selectStep(step);
      this.$emit('hide');
    },
    resetRTS() {
      this.resetProgress()
    }
  }
}
</script>
<style src="./RTSChecklist.scss" scoped lang="scss"></style>
