import firebase from 'firebase/app';
import 'firebase/functions';
import handleError from '@/interceptors/error-handler';
import { CONTROLS_STATE, RTSLabels, SELECTED_ROOM_STATES } from '@/constants';

export default {
  namespaced: true,
  state: () => ({
    roomsLabelMarkers: [],
    rooms: [],
    selectedRoom: {},
    overlay: false,
  }),
  mutations: {
    set_rooms(state, rooms) {
      state.rooms = [...rooms];
    },
    set_rooms_label_markers(state, array) {
      state.roomsLabelMarkers = [...array];
    },
    append_rooms(state, rooms) {
      rooms.forEach(room => {
        if(!state.rooms.includes(room))state.rooms.push(room)
      });
    },
    set_room(state, {isNew, room}) {
      if (!isNew) {
        state.rooms = state.rooms.map((r) => {
          if (r.id === room.id) {
            r = {...r, ...room};
          }
          return r;
        })
      } else {
        state.rooms = [...state.rooms.slice(0, state.rooms.length - 1), room];
      }

    },
    create_room(state, room) {
      state.rooms = [...state.rooms, room];
    },
    update_selected_room(state, room) {
      state.selectedRoom = {...state.selectedRoom, ...room};
    },
    unselect_room(state) {
      state.overlay = false;
      state.selectedRoom = {};
    },
    delete_room(state, id) {
      if (id) {
        state.rooms = state.rooms.filter(r => r.id !== id);
      } else {
        state.rooms = state.rooms.slice(0, state.rooms.length - 1);
      }
    }
  },
  actions: {
    unselectRoom({commit, state}) {
      if (state.selectedRoom.label_pos) {
        if (!state.selectedRoom.id) {
          commit('delete_room');
        }
        commit('unselect_room');
      }
    },
    selectRoom({commit, state, rootState}, id) {
      state.overlay = true;
      let room = id ? state.rooms.find(room => room.id === id) : state.rooms[state.rooms.length - 1];
      /*if(id)*/if(room) rootState.viewer.viewer.centerRoomLabel(room.label_pos)
      ///rootState.viewer.viewer.AnimationController.moveCameraToRoomPosition(...room.label_pos)
      room.state = SELECTED_ROOM_STATES.SHOW;
      commit('update_selected_room', room);
    },
    addRoom({commit, state, rootState}, id) {
      let room = id ? state.rooms.find(room => room.id === id) : state.rooms[state.rooms.length - 1];
      room.state = SELECTED_ROOM_STATES.SHOW;
      commit('update_selected_room', room);
    },
    async updateRoomById({commit, state, dispatch}) {
      let room = {
        name: state.selectedRoom.name,
        sqft: Number(state.selectedRoom.sqft),
        label_pos: state.selectedRoom.label_pos
      }

      let data = {
        ...room,
        room_id: state.selectedRoom.id,
      };
      console.log(data)
      let update_room = firebase.functions().httpsCallable('rooms-updateRoomLabel'); //update function name
      commit('layout/toggle_confirm_button', true, {root: true});
      await update_room(data).then(() => {
        commit('set_room', {room: {id: state.selectedRoom.id, ...room}});
        if (state.selectedRoom.state === SELECTED_ROOM_STATES.NAME) {
          dispatch('rts/updateProgress', {key: RTSLabels.roomLabels, value: true}, {root: true})
        }
      }).finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
    },
    moveRoom({commit, dispatch}, state) {
      //if(rootState.viewer.currentMode !== 'floorplan') rootState.viewer.viewer.setMovingMode('floorplan')// Causes bug with sending payload
      if (state !== SELECTED_ROOM_STATES.MOVE) {
        dispatch('unselectRoom');
        dispatch('createRoom', {});
        dispatch('addRoom');
      }
      commit('update_selected_room', {state});
    },
    createRoom({commit}, {label_pos, id}) {
      let data = {
        label_pos: label_pos || [0,0,0],
        id,
      };
      commit('create_room', data);
    },
    async saveRoom({commit, rootState, state, dispatch}) {
      let data = {
        name: state.selectedRoom.name || 'Name',
        label_pos: state.selectedRoom.label_pos,
        sqft: Number(state.selectedRoom.sqft) || 0,
        floor: rootState.floors.floor.floor,
        tour_id: 'tours/' + rootState.viewer.tour.id,
        type: "other",
        labelDeletable: true,
      }

      const createReq = firebase.functions().httpsCallable('rooms-createRoom'); //update function name
      commit('layout/toggle_confirm_button', true, {root: true});
      //console.log(data)
      await createReq(data).then((res) => {
        let id = `tours/${rootState.viewer.tour.id}/rooms/${res.data.id}`;
        commit('set_room', {room: {...data, id}, isNew: true});
        commit('update_selected_room', {id});
        commit('unselect_room');
        dispatch('rts/updateProgress', {key: RTSLabels.roomLabels, value: true}, {root: true})
      }).finally(() => commit('layout/toggle_confirm_button', false, {root: true}))
    },
    deleteRoomById({commit, state}, id) {
      let room = state.rooms.find(r => r.id === id);
      if (!(room && room.labelDeletable)) {
        return;
      }

      let delete_room = firebase.functions().httpsCallable('rooms-deleteRoom'); //update function name
      commit('layout/toggle_confirm_button', true, {root: true});
      delete_room({room_id: id}).then(() => {
        commit('delete_room', id);
        commit('unselect_room');
      }).catch(e => {
        handleError(e);
      }).finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
    }
  }
}
