import firebase from 'firebase/app';
import 'firebase/auth';
import handleError from '@/interceptors/error-handler';

export const SignInProviders = {
  methods: {
    signInByProvider(providerName, callback) {
      let provider;
      switch (providerName) {
        case 'google':
          provider = new firebase.auth.GoogleAuthProvider();
          break;
        case 'facebook':
          provider = new firebase.auth.FacebookAuthProvider();
          break;
        case 'apple':
          provider = new firebase.auth.OAuthProvider('apple.com');
          break;
      }
      firebase.auth().signInWithPopup(provider).then(() => {
        callback();
      }).catch((e) => {
        handleError(e);
      });
    }
  }
}
