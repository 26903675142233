<template>
  <div class="d-flex align-items-center">
    <CloseIcon @click.native="$bvModal.hide(forgotPassFormId)"/>
    <div class="sign-in-wrapper">
      <img class="logo" :src="logoBigBeta" alt="logo" v-if="isMobile"/>
      <h5 class="header font-weight-semi-bold text-center mb-5 mt-3">Forgot your password?</h5>
      <ValidationObserver v-slot="{ invalid }">
        <form class="sign-in-form" @submit.prevent="send()">
          <div class="form-group">
            <label for="email">Email</label>
            <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
              <input class="form-control" name="email" type="text" v-model="email" required placeholder="name@email.com" id="email">
              <InvalidFeedback :errors="errors"/>
            </ValidationProvider>
          </div>
          <p class="my-4">We will send you an email with instructions on how to reset your password</p>
          <div class="text-center">
            <button :disabled="invalid" type="submit" class="btn rounded-pill btn-primary font-weight-normal px-5 py-2">EMAIL ME</button>
          </div>
        </form>
      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import logoBigBeta from '@/assets/logo_big_beta.svg';

import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'Forgot',
  data() {
    return {
      logoBigBeta,
      email: ''
    }
  },
  methods: {
    send() {
      firebase.auth().sendPasswordResetEmail(this.email)
          .then(() => {
            this.$bvModal.hide(this.forgotPassFormId);
            this.$toasted.success('The email has been sent', {
              icon: {
                name: 'las la-check mr-2 la-lg'
              }
            })
          })
          .catch((error) => {
            this.handleError(error);
          });
    }
  }
}
</script>
<style scoped lang="scss" src="./AuthEmail.scss"></style>
