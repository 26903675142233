<template>
  <svg width="19" height="23" viewBox="0 0 19 23" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.81057 12.569L9.48076 6.10275L12.6823 9.20639L6.03226 15.66L2.49707 15.972L2.81057 12.569ZM13.3845 2.31839L16.5849 5.41972L14.2716 7.66456L11.0713 4.56092L13.3845 2.31839ZM1.18767 18.3937C1.22329 18.3937 1.25892 18.3925 1.29573 18.3891L6.24761 17.9528C6.79148 17.9044 7.30211 17.673 7.68804 17.2977L18.372 6.94039C19.2365 6.10002 19.2009 4.69901 18.2912 3.81604H18.29L15.0387 0.664059C14.1599 -0.186677 12.647 -0.226969 11.8134 0.583475L1.13067 10.9408C0.744731 11.3149 0.504856 11.8111 0.454981 12.3372L0.00491832 17.1389C-0.0271442 17.4785 0.0987309 17.8146 0.348106 18.0564C0.571356 18.2739 0.874169 18.3937 1.18767 18.3937Z"
          />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.8125 20.6973H1.1875C0.534375 20.6973 0 21.2153 0 21.8485C0 22.4816 0.534375 22.9997 1.1875 22.9997H17.8125C18.4656 22.9997 19 22.4816 19 21.8485C19 21.2153 18.4656 20.6973 17.8125 20.6973Z"
          />
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
}
</script>
