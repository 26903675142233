<template>
  <div @click="$emit('onCreate', $event)" class="root">
    <svg class="aim-clipping" width="100%" height="100%">
      <defs>
        <mask id="aim-mask" x="0" y="0">
          <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
          <circle :cx="cx" :cy="cy" r="50px"></circle>
        </mask>
      </defs>
      <rect class="aim-background" ref="aim" @mousemove="trackMouse($event)" mask="url(#aim-mask)" height="100%" width="100%" y="0" x="0"></rect>
    </svg>
    <div class="aim-form" :style="{top: cy + 'px', left: cx + 'px'}">
      <div class="inner-border"></div>
      <div class="aim-center"></div>
    </div>
     <!--h5 v-if="isTouchable" class="text-light text-center mt-5 mx-5">Move the dot to where you want the label</h5--> 
    <div id="aim-bottom-container">
      <div class="aim-actions btn-toolbar">
        <button 
          @click.stop="$emit('onCancel'), setControlsState(CONTROLS_STATE.NULL)" 
          type="button" 
          class="mr-3 text-secondary btn btn-light btn-form aim-cancel">
          Cancel
        </button>
        <button v-if="isTouchable"
                @click.stop="$emit('onCreate', {clientX: win.innerWidth/2, clientY: win.innerHeight/2})"
                type="button" class="text-secondary btn btn-light btn-form aim-save">
          Place
        </button>
      </div>
      <TutorialBar />
    </div>

  </div>
</template>
<script>
import TutorialBar from '@/components/bottom-nav/tutorial-bar/TutorialBar'
import {mapActions} from 'vuex'
 export default {
   name: 'LabelAim',
   components: {TutorialBar},
   data() {
     return {
       cx: '50%',
       cy: '50%'
     }
   },
   computed: {
     win() {
       return window;
     }
   },
   methods: {
     ...mapActions('controls', ['setControlsState']),
     trackMouse(e) {
       let isTarget = e.target === this.$refs.aim;
       let {clientX, clientY} = e;
       if (isTarget) {
         this.cx = clientX;
         this.cy = clientY;
       }
     },
   }
 }
</script>
<style src="./LabelAim.scss" scoped lang="scss"></style>
