import {Vector3} from 'three'
import config from '@/config'

class TileLoader {
  constructor(viewer) {
    this.viewer = viewer
    this.tiles = {}
  }

  addTiles = async (panos, initialPano) => {
    if (!panos || !Object.keys(panos).length) return

    //get cache or create a new one
    const cache = await caches.open('tileCache')

    //get urls and split into level 1 and 2
    const newTiles = {}
    for (let pano in panos) {
      newTiles[pano] = {level1: [], level2: []}
      const tileSet = panos[pano].tiles
      for (let url in tileSet) {
        if (url.slice(0, 1) === '1') newTiles[pano].level1.push(tileSet[url])
        if (url.slice(0, 1) === '2') newTiles[pano].level2.push(tileSet[url])
      }
    }
    const oldTiles = this.tiles
    this.tiles = {...oldTiles, ...newTiles}

    const ids = Object.keys(newTiles)

    //if pano mode, sort by distance
    if (initialPano) {
      const initialPosition = new Vector3(...panos[initialPano].position)
      ids.sort((a, b) => {
        const distanceA = initialPosition.distanceTo(new Vector3(...panos[a].position))
        const distanceB = initialPosition.distanceTo(new Vector3(...panos[b].position))
        return distanceA - distanceB
      })
    }

    //cache level 1
    // for (let i = 0; i < ids.length; i++) {
    //   await Promise.all(this.tiles[ids[i]].level1.map(async url => {
    //     const match = await cache.match(url)
    //     if (!match) await cache.add(url)
    //   }))
    // }

    //cache level 2
    // for (let i = 0; i < ids.length; i++) {
    //   await Promise.all(this.tiles[ids[i]].level2.map(async url => {
    //     const match = await cache.match(url)
    //     if (!match) await cache.add(url)
    //   }))
    // }

    //fetch level 1
    for (let i = 0; i < ids.length; i++) {
      await Promise.all(this.tiles[ids[i]].level1.map(url => fetch(url)))
    }
  }

  deleteCache = async () => {
    await caches.delete('tileCache')
  }

  checkTileCount = async () => {
    const tileCache = await caches.open('tileCache')
    const keys = await tileCache.keys()
    console.log(keys.length)
  }

  checkQuota = async () => {
    const amount = await navigator.storage.estimate()
    console.log(`Quota: ${(amount.quota * 0.000001).toFixed(1)}MB Usage: ${(amount.usage * 0.000001).toFixed(1)}MB`)
  }

  //from generate.py
  generateURLS = (maxLevel = 4, cubeRes = 2232, tileRes = 512) => {
    const {cloudDomain, storageBucket} = config.firebase
    const faceLetters = ['f', 'b', 'u', 'd', 'l', 'r']
    const panoData = {}

    for (const letter of faceLetters) {
      let size = cubeRes
      for (let level = maxLevel; level > 0; level--) {
          const tiles = Math.ceil(size / tileRes)
          for (let i = 0; i < tiles; i++) {
            for (let j = 0; j < tiles; j++) {
              const url = `https://${cloudDomain}/${storageBucket}/tours/TOUR_ID/rooms/ROOM_ID/panos/PANO_ID/${level}/${letter}${i}_${j}.jpg`
              // console.log(url)
              panoData[`${level}_${letter}${i}_${j}`] = url
            }
          }
          size = size / 2
      }
    }
    console.log(panoData)
  }
}

export default TileLoader