import * as THREE from 'three'

class FlyThroughControls {
  constructor(controls, minFov, maxFov, viewer) {
    this.controls = controls
    this.minFov = minFov
    this.maxFov = maxFov
    this.viewer = viewer

    this.rotateSpeed = 0.05
    this.zoomSpeed = 0.05

    this.zooming = false
    this.rotating = false
  }

  zoom = (direction, multiplier) => {
    if (this.zooming) return
    this.zoomStop()
    this.zooming = true

    this.zoomInterval = setInterval(() => {
      if (direction === 'in') {
        this.controls.animationFov = Math.max(Math.min(this.controls.animationFov + -this.zoomSpeed * (multiplier ? 2 : 1), this.maxFov), this.minFov);
      } else {
        this.controls.animationFov = Math.max(Math.min(this.controls.animationFov + this.zoomSpeed * (multiplier ? 2 : 1), this.maxFov), this.minFov);
      }
      this.viewer.setLookAroundSpeed()
    }, 8)
  }

  zoomStop = () => {
    this.zooming = false
    clearInterval(this.zoomInterval)
  }

  zoomReset = fov => {
    this.controls.animationFov = fov
    this.viewer.setLookAroundSpeed()
  }

  rotate = (direction, multiplier) => {
    if (this.rotating) return
    this.rotating = true

    this.rotateInterval = setInterval(() => {
      const camDir = new THREE.Vector3(0, 0, -1).applyQuaternion(this.controls.object.quaternion)
      camDir.z = 0
      camDir.normalize()
      const distance = 2.5
      const axis = new THREE.Vector3(0, 0, 1)
      if (direction === 'left') {
        camDir.applyAxisAngle(axis, multiplier ? this.rotateSpeed * 2 : this.rotateSpeed)
      }else {
        camDir.applyAxisAngle(axis, multiplier ? -(this.rotateSpeed * 2) : -this.rotateSpeed)
      }
      const newTarget = camDir.clone().multiplyScalar(distance)
      newTarget.add(this.controls.object.position)
      newTarget.z = this.controls.animationTarget.z
      this.controls.animationTarget.copy(newTarget)
    }, 8)
  }

  rotateStop = () => {
    this.rotating = false
    clearInterval(this.rotateInterval)
  }

}

export default FlyThroughControls
