import { TOOLTIPS } from '@/constants';

export const emptyFloor = {
  angle: 0,
  distance: 10,
  position: [0,0],
  init_view: {
    position: {
      x: 0,
      y: 0,
      z: 0
    }
  },
  plane: {
    position: {
      x: 0,
      y: 0
    },
    rotation: {
      z: 0
    },
    scale: {
      x: 0,
      y: 0
    }
  }
}

export const emptyRoom = {
  label_pos: [0,0,0]
}

export const emptyPano = {
  rotation: [0,0,0],
  position: [0,0,0]
}

export const TooltipsText = {
  [TOOLTIPS.RTS]: {
    text: 'ROAD TO SUCCESS',
    placement: 'bottomleft',
    customClass: 'rts-tooltip'
  },
  [TOOLTIPS.MOBILE_RTS]: {
    text: 'ROAD TO SUCCESS',
    placement: 'bottom',
    customClass: 'rts-tooltip rts-tooltip-mobile',
    offset: 40
  },
  [TOOLTIPS.ORBIT_MENU]: {
    text: 'Edit Model',
    placement: 'bottom',
  },
  [TOOLTIPS.FLOOR_MENU]: {
    text: 'Room Label & Feature Label',
    placement: 'bottom',
  },
  [TOOLTIPS.PANO_MENU]: {
    text: 'Edit Pano',
    placement: 'bottom',
  },
  [TOOLTIPS.MOBILE_ORBIT_MENU]: {
    text: 'Edit Model',
    placement: 'bottom',
    offset: -20
  },
  [TOOLTIPS.MOBILE_PANO_MENU]: {
    text: 'Edit Pano',
    placement: 'bottom',
    offset: -20
  },
  [TOOLTIPS.MOBILE_FLOOR_MENU]: {
    text: 'Room Label & Feature Label',
    placement: 'bottom',
    customClass: 'mobile-floor-menu-tooltip',
    offset: -30
  },
  [TOOLTIPS.MOBILE_MEASURE]: {
    text: 'Measure and Area Tool',
    placement: 'bottom',
    customClass: 'mobile-measure-tooltip',
  },
  [TOOLTIPS.ORBIT_MODE]: {
    text: '3D Model',
    placement: 'top'
  },
  [TOOLTIPS.FLOOR_MODE]: {
    text: 'Floorplan',
    placement: 'top',
    offset: -27
  },
  [TOOLTIPS.PANO_MODE]: {
    text: '360° View',
    placement: 'top',
    offset: 30
  },
  [TOOLTIPS.MEASURE]: {
    text: 'Measure and Area Tool',
    placement: 'bottom',
    offset: 54
  },
  [TOOLTIPS.EDIT_MODE]: {
    text: 'Edit Mode',
    placement: 'bottom',
    customClass: 'mt-2'
  },
  [TOOLTIPS.MOBILE_EDIT_MODE]: {
    text: 'Edit Mode',
    placement: 'left',
  },
  [TOOLTIPS.FULLSCREEN]: {
    text: 'Window size adjustment',
    placement: 'left'
  },
  [TOOLTIPS.FLOORS]: {
    text: 'Floor selector',
    placement: 'right'
  },
  [TOOLTIPS.MOBILE_ORBIT_MODE]: {
    text: '3D Model',
    placement: 'top'
  },
  [TOOLTIPS.MOBILE_FLOOR_MODE]: {
    text: 'Floorplan',
    placement: 'top'
  },
  [TOOLTIPS.MOBILE_PANO_MODE]: {
    text: '360° View',
    placement: 'top'
  },
  [TOOLTIPS.MOBILE_FLOORS]: {
    text: 'Floor selector',
    placement: 'top'
  },
  [TOOLTIPS.MOBILE_MENU]: {
    text: 'More',
    placement: 'top',
    customClass: 'mt-2'
  },
}
