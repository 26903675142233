<template>
  <div
      ref="entryArrowRef"
      class="root">
        <div id="aim-bottom-container">
      <div class="aim-actions btn-toolbar">
        <button 
          @click.stop="setControlsState(CONTROLS_STATE.NULL),cancelProgress()" 
          type="button" 
          class="mr-3 text-secondary btn btn-light btn-form aim-cancel">
          Cancel
        </button>
        <button 
                @click.stop="saveArrow(),updateProgress({key: RTSLabels.entryArrow, value: true})"
                type="button" class="text-secondary btn btn-light btn-form aim-save">
          Save
        </button>
      </div>
      <TutorialBar />
    </div>
  </div>
</template>
<script>
import TutorialBar from '@/components/bottom-nav/tutorial-bar/TutorialBar'
import { FeatureFormModes, RTSLabels } from '@/constants';
import { mapState , mapActions } from 'vuex';
import { MoveIcon } from '@/components/svg-icons';
  export default {
    name: 'EntryArrowControls',
    components: {MoveIcon, TutorialBar, RTSLabels},
    computed: {
      ...mapState('viewer', ['viewer']),
      ...mapState('controls', ['mode'])
    },
    methods: {
      ...mapActions('controls', ['setControlsState']),
      ...mapActions('rts', ['selectStep','updateProgress','toggleRTSMode']),
      saveArrow() {
          this.viewer.saveEntryArrow()
      },
      cancelProgress() {
      this.toggleRTSMode(false);
      this.selectStep(null)
      },
    }
  }
</script>
<style src="./EntryArrowControls.scss" scoped lang="scss"></style>
