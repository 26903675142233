<template>
  <div class="image-thumb">
    <svg width="55" height="51" viewBox="0 0 55 51" xmlns="http://www.w3.org/2000/svg">
      <rect class="fill" x="45" y="9" width="3" height="17"/>
      <rect class="fill" x="55" y="16" width="3" height="17" transform="rotate(90 55 16)"/>
      <path class="fill"
          d="M22.4355 20.1489L43.4355 35.7289L34.5525 37.1523L39.9355 47.4689L34.437 50.1489L29.026 39.6256L22.4355 45.4889V20.1489Z"
          />
      <rect class="stroke" x="1.5" y="1.5" width="31.2416" height="31.2416" stroke-width="3"/>
      <path class="stroke" d="M0.996094 29.0658L12.3436 21.1026L23.8902 28.0704L32.8487 16.3247" stroke-width="3"/>
      <circle class="stroke" cx="17.1211" cy="11.5464" r="4.07421" stroke-width="3"/>
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'ImageThumb'
  }
</script>
<style scoped lang="scss" src="./ImageThumb.scss"></style>
