<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
          d="M18.7499 40.0005C18.7499 36.0369 19.8419 32.324 21.7391 29.1448V15.6593H8.25355C2.90123 22.6105 0 31.0821 0 40.0005C0 48.9188 2.90123 57.3905 8.25355 64.3416H21.7391V50.8561C19.8419 47.677 18.7499 43.964 18.7499 40.0005Z"
          fill="#F1BE42"/>
      <path
          d="M40 61.2499L30.625 70.6248L40 79.9997C48.9186 79.9997 57.3901 77.0985 64.3414 71.7462V58.2751H50.8701C47.6631 60.1791 43.9345 61.2499 40 61.2499Z"
          fill="#58A55C"/>
      <path
          d="M21.7347 50.8552L8.24902 64.3408C9.30871 65.717 10.4626 67.035 11.7112 68.2837C19.2662 75.8386 29.3111 79.9993 39.9954 79.9993V61.2494C32.2417 61.2494 25.4459 57.0744 21.7347 50.8552Z"
          fill="#58A55C"/>
      <path
          d="M80 39.9993C80 37.5657 79.7797 35.1276 79.345 32.7529L78.9933 30.8312H40V49.581H58.9769C57.1341 53.2468 54.3006 56.2375 50.87 58.2744L64.3412 71.7455C65.7175 70.6858 67.0355 69.5319 68.2842 68.2833C75.8392 60.7282 80 50.6835 80 39.9993Z"
          fill="#5187ED"/>
      <path
          d="M55.0262 24.9738L56.6834 26.631L69.9417 13.3729L68.2845 11.7157C60.7295 4.16077 50.6846 0 40 0L30.625 9.37496L40 18.7499C45.676 18.7499 51.0124 20.9602 55.0262 24.9738Z"
          fill="#D8503F"/>
      <path
          d="M40.0062 18.75V0C29.3219 0 19.2771 4.16078 11.7219 11.7156C10.4734 12.9642 9.31945 14.2822 8.25977 15.6586L21.7453 29.1442C25.4567 22.925 32.2525 18.75 40.0062 18.75Z"
          fill="#D8503F"/>
    </g>
    <g class="hover">
      <path
          d="M18.7499 40.0005C18.7499 36.0369 19.8419 32.324 21.7391 29.1448V15.6593H8.25355C2.90123 22.6105 0 31.0821 0 40.0005C0 48.9188 2.90123 57.3905 8.25355 64.3416H21.7391V50.8561C19.8419 47.677 18.7499 43.964 18.7499 40.0005Z"
          />
      <path
          d="M40 61.2499L30.625 70.6248L40 79.9997C48.9186 79.9997 57.3901 77.0985 64.3414 71.7462V58.2751H50.8701C47.6631 60.1791 43.9345 61.2499 40 61.2499Z"
          />
      <path
          d="M21.7347 50.8552L8.24902 64.3408C9.30871 65.717 10.4626 67.035 11.7112 68.2837C19.2662 75.8386 29.3111 79.9993 39.9954 79.9993V61.2494C32.2417 61.2494 25.4459 57.0744 21.7347 50.8552Z"
          />
      <path
          d="M80 39.9993C80 37.5657 79.7797 35.1276 79.345 32.7529L78.9933 30.8312H40V49.581H58.9769C57.1341 53.2468 54.3006 56.2375 50.87 58.2744L64.3412 71.7455C65.7175 70.6858 67.0355 69.5319 68.2842 68.2833C75.8392 60.7282 80 50.6835 80 39.9993Z"
          />
      <path
          d="M55.0262 24.9738L56.6834 26.631L69.9417 13.3729L68.2845 11.7157C60.7295 4.16077 50.6846 0 40 0L30.625 9.37496L40 18.7499C45.676 18.7499 51.0124 20.9602 55.0262 24.9738Z"
          />
      <path
          d="M40.0062 18.75V0C29.3219 0 19.2771 4.16078 11.7219 11.7156C10.4734 12.9642 9.31945 14.2822 8.25977 15.6586L21.7453 29.1442C25.4567 22.925 32.2525 18.75 40.0062 18.75Z"
          />
    </g>
  </svg>

</template>
<script>
export default {
  name: 'GoogleIcon',
}
</script>
