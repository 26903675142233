<template>
  <svg width="20" height="25" viewBox="0 0 20 25" xmlns="http://www.w3.org/2000/svg">
    <path class="fill"
        d="M16.1902 4.76323C16.1902 4.23223 15.7467 3.80171 15.1997 3.8017C14.6526 3.80175 14.2091 4.23228 14.2091 4.76322C14.2091 5.29423 14.6526 5.72482 15.1997 5.72476C15.7467 5.72477 16.1902 5.29424 16.1902 4.76323Z"
        />
    <path fill-rule="evenodd" clip-rule="evenodd" class="fill"
          d="M9.36222 1.92309C9.36222 0.861074 10.2492 -9.97163e-06 11.3433 3.00363e-05L18.0189 -4.57927e-06C19.1131 -3.13286e-05 20 0.861062 20 1.92308L20 23.0769C20 24.1389 19.1131 25 18.0189 25L11.3433 25C10.2492 25 9.36222 24.1389 9.36222 23.0769L9.36222 1.92309ZM18.1065 23.1817L18.1065 1.96873L11.2385 1.96875L11.2385 3.84617L12.5121 3.84612C13.0591 3.84613 13.5025 4.27671 13.5026 4.80766C13.5026 5.33867 13.0591 5.7692 12.512 5.76925L11.2385 5.76923L11.2385 7.6923L12.512 7.69232C13.0591 7.69233 13.5026 8.12285 13.5026 8.65386C13.5026 9.18487 13.0591 9.6154 12.512 9.61539L11.2385 9.61537L11.2385 11.5384L14.2101 11.5385C14.7572 11.5385 15.2007 11.969 15.2007 12.5C15.2007 13.031 14.7572 13.4615 14.2101 13.4615L11.2385 13.4616L11.2385 15.3846L12.512 15.3846C13.0591 15.3846 13.5026 15.8151 13.5026 16.3461C13.5026 16.8771 13.0591 17.3077 12.512 17.3077L11.2385 17.3077L11.2385 19.2308L12.512 19.2307C13.0591 19.2308 13.5026 19.6613 13.5026 20.1923C13.5025 20.7233 13.0591 21.1539 12.5121 21.1539L11.2385 21.1538L11.2385 23.1817L18.1065 23.1817Z"
          />
    <path class="fill"
        d="M1.86896 1.52947L0.624368 1.52947C0.279539 1.52947 -1.07656e-06 1.09239 -1.09163e-06 0.75766C-1.1067e-06 0.422928 0.279539 5.14866e-05 0.624368 5.14719e-05L6.24316 5.12335e-05C6.58799 5.12189e-05 6.86753 0.422928 6.86753 0.75766C6.86753 1.09239 6.58799 1.52947 6.24316 1.52947L3.74569 1.52947L3.74569 12.7575L1.86896 12.7575L1.86896 1.52947Z"
        />
    <path class="fill"
        d="M3.74967 23.4671L6.24316 23.4671C6.58799 23.4671 6.86753 23.9042 6.86753 24.2389C6.86753 24.5737 6.58799 24.9965 6.24316 24.9965L0.624448 24.9965C0.279619 24.9965 8.03958e-05 24.5737 8.03506e-05 24.2389C8.03054e-05 23.9042 0.279619 23.4671 0.624448 23.4671L1.87293 23.4671L1.87293 12.7575L3.74967 12.7575L3.74967 23.4671Z"
        />
  </svg>
</template>
<script>
export default {
  name: 'RulerEditIcon',
}
</script>
