import * as THREE from 'three';
import store from '../../../store';
import { VIEWER_MODES } from '@/constants';
import gsap from 'gsap'

export default class EntryArrow {
  _entryArrow;
  _plane;
  scene;
  mouse;
  camera;
  el;
  minHeight;
  pixelRatio;
  gizmo;
  arrow;
  selected = false;
  enableRotation = false;
  intersects = [];
  oldAngle;
  position2D = {}
  initialAngle;
  

  constructor({scene, mouse, camera, el, pixelRatio, minHeight}) {
    this.el = el;
    this.scene = scene;
    this.mouse = mouse;
    this.camera = camera;
    this.minHeight = minHeight;
    this.pixelRatio = pixelRatio;
    this.raycaster = new THREE.Raycaster();
  }


  create(props) {
    const extrudeSettings = { depth: 0.05, bevelEnabled: false};
    const shape = new THREE.Shape() ///Arrow shape
    .moveTo( 0, 0.42 )
    .lineTo( -0.35, -0.3 )
    .quadraticCurveTo (0,-0.1,0.35, -0.3 )
    .lineTo( 0, 0.42 );

    let arrow = new THREE.Mesh(new THREE.ExtrudeGeometry( shape, extrudeSettings ),
    new THREE.MeshBasicMaterial({
      color: 0xE544FF,
      side: THREE.DoubleSide
    }))
    arrow.visible = true;
    this.arrow = arrow;
    this.arrow.name = 'entryArrow'

    this.updateArrow(props);
    this.createGizmo()
    this.scene.add(this.arrow);
    if(this.arrow.position.x === 0 && this.arrow.position.y === 0 && !props.hasOwnProperty('rotation')){
      this.arrow.scale.set(0,0,0)
    }

    this.plane = new THREE.Plane();
    this.plane.normal.set(0, 0, 1);
    this.plane.setFromNormalAndCoplanarPoint(this.plane.normal, this.arrow.position);
  }

  createGizmo() {
    const geometry = new THREE.CircleGeometry( 0.8, 64 );
    const material = new THREE.MeshBasicMaterial( { color: 0xffffff, transparent: true, opacity: 0.7 } );
    this.gizmo = new THREE.Mesh( geometry, material );
    this.gizmo.position.set(0,0,0.04)
    this.gizmo.material.visible = false
    this.arrow.add(this.gizmo);
  }

  updateArrow(arrow) {
    if (this.arrow && arrow) {
      let {position, rotation} = arrow;
      let {x, y} = position || {};
      let {z} = rotation || {};
      this.arrow.position.x = x || this.arrow.position.x;
      this.arrow.position.y = y || this.arrow.position.y;
      this.arrow.position.z = this.minHeight;
      this.arrow.rotation.z = z || this.arrow.rotation.z;
    }
  }

  getRightControls({x, y}, mode) {
    let camera = this.camera;
    let mouse = {};
    if (mode === VIEWER_MODES.FLOORPLAN) {
      camera = new THREE.OrthographicCamera(this.camera.left, this.camera.right, this.camera.bottom, this.camera.top, 0.1, 10000)
      camera.position.copy(this.camera.position)
      camera.quaternion.copy(this.camera.quaternion)
      camera.updateMatrixWorld();
    }
    mouse.x = (x / (this.el.width/this.pixelRatio)) * 2 - 1;
    mouse.y = mode === VIEWER_MODES.FLOORPLAN ? (y / (this.el.height/this.pixelRatio)) * 2 - 1 : -(y / (this.el.height/this.pixelRatio)) * 2 + 1;
    return {camera, mouse};
  }

  moveArrow({x, y}, mode) {
    let p = new THREE.Vector3();
    let {mouse, camera} = this.getRightControls({x, y}, mode);
    this.raycaster.setFromCamera(mouse, camera);
    this.raycaster.ray.intersectPlane(this.plane, p);
    this.arrow.position.copy(p);
  }

  updateArrowControls() {
    let controlsX;
    let controlsY;
    let position = new THREE.Vector3(0, 0, 0);
    let {x, y, z} = this.arrow.position;
    position.set(x, y, z);
    position.sub(this.camera.position);
    if (position.angleTo(this.camera.getWorldDirection(new THREE.Vector3())) < Math.PI / 2) {
      position.set(x, y, z);
      position.project(this.camera);
      let w_2 = this.el.clientWidth / 2;
      let h_2 = this.el.clientHeight / 2;
      controlsX = ((position.x * w_2) + w_2).toFixed(1);
      controlsY = (-(position.y * h_2) + h_2).toFixed(1);
    } else {
      controlsX = controlsY = -1000;
    }

    store.commit('floors/update_entry_arrow', {x: controlsX, y: controlsY});
  }

  getArrow2D() {
    let controlsX;
    let controlsY;
    let position = new THREE.Vector3(0, 0, 0);
    let {x, y, z} = this.arrow.position;
    position.set(x, y, z);
    position.sub(this.camera.position);
    if (position.angleTo(this.camera.getWorldDirection(new THREE.Vector3())) < Math.PI / 2) {
      position.set(x, y, z);
      position.project(this.camera);
      let w_2 = this.el.clientWidth / 2;
      let h_2 = this.el.clientHeight / 2;
      controlsX = ((position.x * w_2) + w_2).toFixed(1);
      controlsY = (-(position.y * h_2) + h_2).toFixed(1);
    } else {
      controlsX = controlsY = -1000;
    }

    return {x: controlsX, y: controlsY};
  }

  rotate(direction, amount) {
    if (direction === 'left') {
      this.arrow.rotation.z += amount || 0.01;
    }
    if (direction === 'right') {
      this.arrow.rotation.z -= amount || 0.01;
    }
  }

  selectArrow(){
    let camera = new THREE.OrthographicCamera(this.camera.left, this.camera.right, this.camera.bottom, this.camera.top, 0.1, 10000)
    camera.position.copy(this.camera.position)
    camera.quaternion.copy(this.camera.quaternion)
    camera.updateMatrixWorld();
    this.raycaster.setFromCamera({x:this.mouse.x,y:-1*this.mouse.y}, camera)
    this.raycaster.intersectObjects([this.arrow,this.gizmo], false, this.intersects)
    if(this.intersects.length>1){
        this.selected = true
        gsap.to(this.gizmo.material, {duration:0.05,opacity: 0,onComplete: () => {this.gizmo.visible=false}})
        gsap.to(this.arrow.material.color, {duration: 0.1, r: 0.941, g: 0.584, b: 1})     
    }else if(this.intersects.length>0){
        this.selected = false
        this.enableRotation = true
        this.oldAngle = this.arrow.rotation.z;
        this.position2D = this.getArrow2D()
        gsap.to(this.gizmo.material, {duration:0.05,opacity: 0,onComplete: () => {this.gizmo.visible=false}})
      
    }else{
        this.selected = false
    }
    this.intersects.length = 0;
  }

  deselectArrow(mouse){
    if(this.selected){
      gsap.to(this.arrow.material.color, { r: 0.898, g: 0.266, b: 1})
      this.selected = false
    }
    if(this.enableRotation)this.enableRotation = false
    this.gizmo.visible = true
    gsap.to(this.gizmo.material, {opacity: 0.7})
    this.mouse = mouse;
  }

  getArrowProps() {
    let position = {
      x: this.arrow.position.x,
      y: this.arrow.position.y,
    }

    let rotation = {
      z: this.arrow.rotation.z
    }

    return {
      position,
      rotation
    }
  }

  set arrow(value) {
    this._entryArrow = value;
  }

  get arrow() {
    return this._entryArrow;
  }

  set plane(value) {
    this._plane = value;
  }

  get plane() {
    return this._plane;
  }
}
