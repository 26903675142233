<template>
  <div>
    <ul class="floors list-unstyled">
      <li class="floor-item" v-for="(fl, index) in floors" :key="fl.id">
        <LayerIcon class="floor-layer" :style="{zIndex: floors.length - index}"/>
        <input @change="update($event, fl)" class="floor-name" :value="floorName(fl)">
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LayerIcon } from '@/components/svg-icons';
import { RTSLabels } from '@/constants';

export default {
  name: 'FloorEditModal',
  components: {LayerIcon},
  computed: {
    ...mapState('floors', ['floors']),
  },
  methods: {
    ...mapActions('floors', ['updateFloorplan']),
    update($event, fl) {
      this.updateFloorplan({
        floorplan: {title: $event.target.value, id: fl.floorplan.id},
        RTSLabel: RTSLabels.floorNames
      })
    }
  }
}
</script>
<style src="./FloorEditModal.scss" scoped lang="scss"></style>
