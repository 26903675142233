<template>
  <svg width="30" height="24" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_6083_58813" fill="white">
        <rect x="11.3799" width="10.6207" height="14.1609" rx="1"/>
      </mask>
      <rect class="stroke" x="11.3799" width="10.6207" height="14.1609" rx="1" stroke-width="4"
            mask="url(#path-1-inside-1_6083_58813)"/>
      <mask id="path-2-inside-2_6083_58813" fill="white">
        <rect width="12.9808" height="21.2414" rx="1"/>
      </mask>
      <rect class="stroke" width="12.9808" height="21.2414" rx="1" stroke-width="4"
            mask="url(#path-2-inside-2_6083_58813)"/>
    </svg>

    <path class="fill" fill-rule="evenodd" clip-rule="evenodd"
          d="M30 17C30 20.866 26.866 24 23 24C19.134 24 16 20.866 16 17C16 13.134 19.134 10 23 10C26.866 10 30 13.134 30 17ZM19.833 17.2089L22.7 14.4004L24.0761 15.7484L21.2178 18.5514L19.6982 18.6869L19.833 17.2089ZM24.3781 12.7568L25.7537 14.1038L24.7594 15.0788L23.3838 13.7308L24.3781 12.7568ZM19.1355 19.7389C19.1508 19.7389 19.1661 19.7384 19.1819 19.7369L21.3104 19.5474C21.5441 19.5264 21.7636 19.4259 21.9295 19.2629L26.5217 14.7644C26.8933 14.3994 26.878 13.7909 26.487 13.4074H26.4865L25.089 12.0384C24.7113 11.6689 24.061 11.6514 23.7027 12.0034L19.111 16.5019C18.9451 16.6644 18.842 16.8799 18.8206 17.1084L18.6271 19.1939C18.6133 19.3414 18.6674 19.4874 18.7746 19.5924C18.8706 19.6869 19.0007 19.7389 19.1355 19.7389ZM19.1354 20.7393H26.2812C26.562 20.7393 26.7917 20.9643 26.7917 21.2393C26.7917 21.5143 26.562 21.7393 26.2812 21.7393H19.1354C18.8547 21.7393 18.625 21.5143 18.625 21.2393C18.625 20.9643 18.8547 20.7393 19.1354 20.7393Z"
          />
  </svg>
</template>
<script>
export default {
  name: 'FloorplanEditIcon',
}
</script>
