<template>
  <svg width="27" height="27" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.6922 4.63428C18.6922 4.3413 18.4534 4.10352 18.1615 4.10352H15.5077C12.5757 4.10352 10.2001 6.24248 10.2001 8.88036V11.7465H7.54626C7.25328 11.7465 7.0155 11.9842 7.0155 12.2772V15.0372C7.0155 15.3302 7.25328 15.5679 7.54626 15.5679H10.2001V22.6801C10.2001 22.9731 10.4378 23.2109 10.7308 23.2109H13.9154C14.2073 23.2109 14.4461 22.9731 14.4461 22.6801V15.5679H17.2263C17.4651 15.5679 17.6742 15.4087 17.739 15.1794L18.5043 12.4195C18.5988 12.0819 18.344 11.7465 17.9937 11.7465H14.4461V8.88036C14.4461 8.35278 14.9206 7.92499 15.5077 7.92499H18.1615C18.4534 7.92499 18.6922 7.68721 18.6922 7.39423V4.63428Z"
          />
  </svg>

</template>
<script>
export default {
  name: 'FacebookIcon',
}
</script>
