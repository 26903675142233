<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import firebase from "firebase/app";
import config from "@/config";
import { mapMutations } from 'vuex';

firebase.initializeApp(config.firebase);


export default {
  name: 'App',
  created() {
    if (this.isTouchable) {
      var viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute("content", "width=device-width,initial-scale=1.0, height=" + window.innerHeight);
    }
    window.addEventListener('resize', () => {
      this.set_screen();
    })
  },
  methods: {
    ...mapMutations('layout', ['set_screen']),
  }
}
</script>

<style lang="scss" src="./styles/app.scss"></style>
<style>
  #app {
    height: 100%;
  }
</style>
