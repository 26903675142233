
class labelOccluder {
  constructor(group) {
    this.features = []
    this.counter = 50
    for(let i=0;i<group.length; i++){
    this.features[i] = true
    }
  }
  
  test(){
    return this.features.length
  }

  refreshFeatures(group) {
    this.features = []
    this.counter = 0
    for(let i=0;i<group.length; i++){
    this.features[i] = false
    }
  }

  setAll(bool){
    for(let i=0;i<this.features.length;i++){
      this.features[i] = bool
    }
    this.resetCounter()
  }

  updateCounter(){
    this.counter -= 1
  }

  resetCounter(){
    this.counter = 50
  }

}

export default labelOccluder;
