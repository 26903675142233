<template>
  <div v-if="data" class="d-flex flex-column justify-content-center align-items-center">
    <h5 class="font-weight-semi-bold">{{ data.name }}</h5>
    <p class="mb-4">{{ data.job || data.title }}</p>
    <h5 class="font-weight-semi-bold">
      <a :href="'tel:' + data.phone">{{ data.phone | VMask('(###) ###-####') }}</a>
    </h5>
    <a :href="'mailto:' + data.email" class="h5 font-weight-semi-bold">{{ data.email }}</a>
  </div>
</template>
<script>

export default {
  name: 'OwnerDetails',
  props: ['data']
}
</script>
