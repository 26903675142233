<template>
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.14519 11.5539C1.76558 10.8964 1.97418 10.0652 2.61117 9.69744L15.9053 2.02206C16.5423 1.65426 17.3664 1.88924 17.746 2.54675L25.3073 15.6433C25.6869 16.3008 25.4784 17.132 24.8413 17.4998L11.5472 25.1751C10.9102 25.5429 10.0861 25.3079 9.70645 24.6504L2.14519 11.5539ZM24.154 16.3092L16.5927 3.21264L3.29856 10.888L3.98595 12.0786L6.52557 10.6123C6.84406 10.4285 7.25613 10.546 7.44595 10.8747C7.63576 11.2034 7.53145 11.619 7.21294 11.803L4.67334 13.2692L5.36073 14.4598L7.90033 12.9936C8.21882 12.8097 8.63091 12.9271 8.82072 13.2559C9.01052 13.5847 8.90622 14.0003 8.58773 14.1841L6.04813 15.6504L6.73552 16.8409L10.9854 14.3873C11.3039 14.2034 11.716 14.3209 11.9058 14.6496C12.0956 14.9784 11.9913 15.394 11.6728 15.5779L7.42292 18.0316L8.1103 19.2222L10.6499 17.7559C10.9684 17.572 11.3805 17.6895 11.5703 18.0182C11.7601 18.347 11.6557 18.7626 11.3373 18.9465L8.79768 20.4127L9.48506 21.6033L12.0246 20.1371C12.3432 19.9532 12.7553 20.0707 12.9451 20.3994C13.1348 20.7282 13.0306 21.1438 12.7121 21.3277L10.1724 22.7939L10.8598 23.9845L24.154 16.3092Z"
          />
    <path
        d="M25.4449 15.048C24.5489 14.7978 23.635 15.3254 23.4036 16.2265C23.1724 17.1277 23.7113 18.0611 24.6072 18.3111C25.5032 18.5613 26.4172 18.0338 26.6485 17.1326C26.8798 16.2315 26.3409 15.2982 25.4449 15.048Z"
        />
    <path
        d="M25.4449 15.048C24.5489 14.7978 23.635 15.3254 23.4036 16.2265C23.1724 17.1277 23.7113 18.0611 24.6072 18.3111C25.5032 18.5613 26.4172 18.0338 26.6485 17.1326C26.8798 16.2315 26.3409 15.2982 25.4449 15.048Z"
        />
    <path
        d="M17.147 0.675596C16.251 0.425381 15.3371 0.953055 15.1058 1.85412C14.8745 2.75533 15.4134 3.6887 16.3093 3.93876C17.2053 4.18896 18.1193 3.66144 18.3506 2.76023C18.5819 1.85917 18.043 0.925802 17.147 0.675596Z"
        />
    <path
        d="M17.147 0.675596C16.251 0.425381 15.3371 0.953055 15.1058 1.85412C14.8745 2.75533 15.4134 3.6887 16.3093 3.93876C17.2053 4.18896 18.1193 3.66144 18.3506 2.76023C18.5819 1.85917 18.043 0.925802 17.147 0.675596Z"
        />
    <path
        d="M11.0112 23.3813C10.1152 23.1311 9.20123 23.6588 8.96989 24.5598C8.73864 25.461 9.27756 26.3944 10.1735 26.6445C11.0694 26.8947 11.9835 26.3671 12.2147 25.4659C12.4461 24.5649 11.9071 23.6315 11.0112 23.3813Z"
        />
    <path
        d="M11.0112 23.3813C10.1152 23.1311 9.20123 23.6588 8.96989 24.5598C8.73864 25.461 9.27756 26.3944 10.1735 26.6445C11.0694 26.8947 11.9835 26.3671 12.2147 25.4659C12.4461 24.5649 11.9071 23.6315 11.0112 23.3813Z"
        />
    <path
        d="M2.71326 9.00893C1.81729 8.75872 0.903333 9.28639 0.671998 10.1875C0.440747 11.0887 0.979666 12.022 1.87556 12.2721C2.77155 12.5223 3.68559 11.9948 3.91683 11.0936C4.14816 10.1925 3.60924 9.25914 2.71326 9.00893Z"
        />
    <path
        d="M2.71326 9.00893C1.81729 8.75872 0.903333 9.28639 0.671998 10.1875C0.440747 11.0887 0.979666 12.022 1.87556 12.2721C2.77155 12.5223 3.68559 11.9948 3.91683 11.0936C4.14816 10.1925 3.60924 9.25914 2.71326 9.00893Z"
        />
  </svg>
</template>
<script>
export default {
  name: 'Area',
}
</script>
