<template>
  <div>

    <ul class="floors list-unstyled mb-0">
      <div @click="setAllFloors(floors[0])" v-bind:style="{color: 'white', textAlign: 'center'}">all</div>
      <div>
      </div>
      <li
          v-b-tooltip.hover.right="!isTouchable && floorName(fl)"
          :class="{active: fl.floor === floor.floor }"
          class="floor-item"
          v-for="(fl, index) in floors"
          :key="fl.id"
          :style="{zIndex: floors.length - index}"
      >
        <LayerIcon @click.native="changeFloor(fl.floor)" class="floor-layer"/>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LayerIcon } from '@/components/svg-icons';

export default {
  name: 'FloorSwitcher',
  components: {LayerIcon},
  computed: {
    ...mapState('floors', ['floors', 'floor']),
  },
  methods: {
    ...mapActions('floors', ['changeFloor', 'setAllFloors']),
    clicky() {console.log('yoyoyoyo')}
  }
}
</script>
<style src="./FloorSwitcher.scss" scoped lang="scss"></style>
