<template>
  <ValidationObserver v-slot="{ invalid }">
    <form class="borderless-form">
      <div class="form-group">
        <textarea rows="4" v-model="name" name="description" class="form-control"
                  placeholder="Describe the issue..."/>
      </div>
      <div class="d-flex justify-content-center">
        <b-button class="btn-form mr-2" type="button" variant="light" size="sm" @click="$emit('onCancel')">CANCEL
        </b-button>
        <ConfirmButton 
            text="CONFIRM" 
            :disabled="invalid"
            @click.native="sendProblem()"
        />
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import ConfirmButton from '@/components/confirm-button/ConfirmButton';
import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  name: 'ProblemForm',
  components: {ConfirmButton},
  data() {
    return{
      name:''
    }
  },
  computed: {
    ...mapState('viewer', ['tour']),
  },
  mounted() {
    if (this.tour) {
      this.name = '';
      this.address = this.tour.address;
      this.description = this.tour.description;
    }
  },
  methods: {
    ...mapActions('viewer', ['updateTour']),
    ...mapActions('viewer', ['reportProblem']),
    update() {
      let data = {
        name: 'hi',
        address: this.address || 'not set',
        description: 'hi',
        contactInfo: {
          ...this.contactInfo,
          phone: this.contactInfo.phone.replace(/\D/g, '')
        }
      };
      this.updateTour(data).then(() => {
        this.$emit('onCancel');
      });
    },
    sendProblem() {
      this.reportProblem().then(() => {
        this.$emit('onCancel');
      });;
    },
  }
}
</script>
<style src="./TourMeta.scss" scoped lang="scss"></style>
