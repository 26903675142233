<template>
  <div class="mobile-layout">
    <RTSChecklist v-if="editMode" :class="{open: showChecklist && !mobileMenuOpen}" @hide="toggle_checklist(false)"/>
    <div v-if="mobileMenuOpen || showChecklist" @click="toggleMobileMenu(false),toggle_checklist(false)" class="mobile-menu-backdrop"></div>
    
    <div class="btn-group rounded-pill modes glass">
      <button :id="TOOLTIPS.MOBILE_FLOOR_MODE" v-b-tooltip.hover="tooltipOnHover ? 'Floorplan' : ''" class="btn  btn-control btn-icon" @click="viewer.setMovingMode(VIEWER_MODES.FLOORPLAN)"
                    :class="{active: mode === VIEWER_MODES.FLOORPLAN}">
        <div class="icon-circle"><FloorplanIcon/></div>
      </button>
      <button :id="TOOLTIPS.MOBILE_ORBIT_MODE" v-b-tooltip.hover="tooltipOnHover ? '3D Model' : ''" class="btn  btn-control btn-icon" @click="viewer.setMovingMode(VIEWER_MODES.ORBIT)"
                :class="{active: mode === VIEWER_MODES.ORBIT}">
        <div class="icon-circle"><OrbitIcon/></div>
      </button>
      <button :id="TOOLTIPS.MOBILE_PANO_MODE" v-b-tooltip.hover="tooltipOnHover ? '360° View' : ''" v-if="Object.keys(panos).length !== 0" class="btn btn-control btn-icon" @click="viewer.setMovingMode(VIEWER_MODES.PANO, {closest: true})"
                   :class="{active: mode === VIEWER_MODES.PANO}">
        <div class="icon-circle"><PanoIcon/></div>
      </button>
    </div>
    <MobileAside/> 
    <MobileMenu @signOut="$emit('signOut')"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import MobileMenu from './menu/MobileMenu';
import { FloorplanIcon, OrbitIcon, PanoIcon } from '@/components/svg-icons';
import MobileAside from '@/components/mobile-layout/aside/Aside';
import RTSChecklist from '@/components/rts/checklist/RTSChecklist';

  export default {
    name: 'MobileLayout',
    components: {RTSChecklist, MobileAside, PanoIcon, OrbitIcon, FloorplanIcon, MobileMenu},
    computed: {
      ...mapState('viewer', ['viewer']),
      ...mapState('controls', ['mode', 'showTooltips']),
      ...mapState('mobile', ['mobileMenuOpen']),
      ...mapState('panos', ['panos']),
      ...mapState('rts', ['showChecklist']),
      tooltipOnHover() {
        return !this.isTouchable && !this.showTooltips;
      }
    },
    methods: {
      ...mapActions('mobile', ['toggleMobileMenu']),
      ...mapMutations('rts', ['toggle_checklist']),
    }
  }
</script>

<style scoped src="./MobileLayout.scss" lang="scss"></style>
