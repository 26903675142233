import * as THREE from 'three'

class SceneManager {
  constructor(viewer) {
    this.viewer = viewer
  }

  //dispose of tex, material, and geo to free gpu ram
  destroySceneMesh = (mesh, scene) => {
    scene.remove(mesh)
    mesh.material.uniforms.texture.value.dispose()
    mesh.material.dispose()
    mesh.geometry.dispose()
  }

  destroyImagePlanes = (plane, box, scene) => {
    scene.remove(plane)
    scene.remove(box)
    plane.material.dispose()
    plane.geometry.dispose()
    box.material.dispose()
    box.geometry.dispose()
  }

  destroyPickingMesh = (mesh, scene) => {
    scene.remove(mesh)
  }

  destroyFloorPlanPlane = (plane, scene) => {
    scene.remove(plane)
    plane.material.map.dispose()
    plane.material.dispose()
    plane.geometry.dispose()
  }
  
}

export default SceneManager