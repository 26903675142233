<template>
  <div id="mobile-tutorial-container" class="rounded-top col-md-8 mx-auto" >

    <template v-if="this.controlsState === CONTROLS_STATE.EDIT_ROOM_LABEL">
      <div id="mobile-tutorial-header">
        <RoomIcon class="mobile-tutorial-header-icon"/>
        <div class="font-weight-bold mobile-tutorial-header-text">Add Room Label</div>
      </div>
      <div id="mobile-tutorial-text">
        <div>Move the dot to the room you want the label.</div>
      </div>
    </template>

    <template v-else-if="this.selectedRoom.state === SELECTED_ROOM_STATES.MOVE">
      <div id="mobile-tutorial-header">
        <RoomIcon class="mobile-tutorial-header-icon"/>
        <div class="font-weight-bold mobile-tutorial-header-text">Move Room Label</div>
      </div>
      <div id="mobile-tutorial-text">
        <div>Move the dot to where you want the label.</div>
      </div>
    </template>

    <template v-if="this.controlsState === CONTROLS_STATE.MOVE_FEATURE && this.featureMode === FeatureFormModes.NEW"> 
      <div id="mobile-tutorial-header">
        <Tag class="mobile-tutorial-header-icon"/>
        <div class="font-weight-bold mobile-tutorial-header-text">Add Feature Label</div>
      </div>
      <div id="mobile-tutorial-text">
        <div>Move the dot to where you want the label.</div>
      </div>
    </template>

    <template v-if="this.controlsState === CONTROLS_STATE.MOVE_FEATURE && this.featureMode === FeatureFormModes.MOVE"> 
      <div id="mobile-tutorial-header">
        <Tag class="mobile-tutorial-header-icon"/>
        <div class="font-weight-bold mobile-tutorial-header-text">Move Feature Label</div>
      </div>
      <div id="mobile-tutorial-text">
        <div>Move the dot to where you want the label.</div>
      </div>
    </template>

    <template v-if="this.controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW">
      <div id="mobile-tutorial-header">
        <EntryArrowIcon class="mobile-tutorial-header-icon"/>
        <div class="font-weight-bold mobile-tutorial-header-text">Place Entry Arrow</div>
      </div>
      <div id="mobile-tutorial-text">
        <div>Drag the arrow to the entry way. Drag the white circle to rotate the arrow.</div>
      </div>
    </template>

  </div>
</template>
<script>
import {RoomIcon, Tag, EntryArrowIcon} from '@/components/svg-icons'
import { SELECTED_ROOM_STATES, FeatureFormModes } from '@/constants';
import {mapState} from 'vuex'

export default {
  data: () => {
    return {
      SELECTED_ROOM_STATES,
      FeatureFormModes
    }
  },
  name: 'TutorialBar',
  components: {RoomIcon, Tag, EntryArrowIcon},
  computed: {
    ...mapState('controls', ['controlsState']),
    ...mapState('rooms', ['selectedRoom']),
    ...mapState('features', ['featureMode']),
  }
}
</script>
<style src="./TutorialBar.scss" scoped lang="scss"></style>
