import firebase from 'firebase/app';
import 'firebase/storage';

const storage = firebase.storage();
import mergeWith from 'lodash/mergeWith';
import isNull from 'lodash/isNull';
import {emptyFloor, emptyRoom} from '../helpers/data';
import {getAllPanos} from './panos';

/**
 * Find floor related to the current tour
 */
export const getFloorData = async function (floorData, tourId) {
  let rooms;
  let floor;

  rooms = floorData.rooms.map(r => {
    return mergeWith({floor: floorData.floor}, emptyRoom, r, (o, s) => isNull(s) ? o : s);
  })

  floor = {
    floor: floorData.floor,
    model: floorData.model,
    floorplan: mergeWith({}, emptyFloor, floorData.floorplan, (o, s) => isNull(s) ? o : s),
    rooms
  };

  /**
   * Getting the updated timestamp metadata for invalidating file cache if it has been updated
   */

  const [model_metadata, texture_metadata] = await Promise.all([storage.ref(floor.model.model_key).getMetadata(), storage.ref(floor.model.tex_4k_key).getMetadata()])

  floor.model.model_udpated = model_metadata.updated;
  floor.model.texture_udpated = texture_metadata.updated;

  let textures = ['tex_4k', 'tex_8k'];

  for (let i = 0; i < textures.length; i++) {
    let tex = floor.model[textures[i]];
    if (tex) {
      let texture_metadata = await storage.ref(tex).getMetadata();
      floor.model[textures[i] + '_updated'] = texture_metadata.updated;
    }
  }

  let panosPromise = getAllPanos(floorData.floor, tourId);
  return {floor, panosPromise};
}
