<template>
  <div>
    <div class="btn-control btn-icon btn-icon-rounded shadow position-absolute"></div>
    <b-dropdown ref="dropdown"
                class="meta-dropdown"
                @hide="toggle($refs.dropdown.$el, false)"
                @show="toggle($refs.dropdown.$el, true)"
                :class="{open: open}"
                dropleft
                variant="light"
                toggle-class="btn-control btn-icon btn-icon-rounded"
                no-caret
                menu-class="shadow"
    >
      <template #button-content>
        <button class="btn btn-light btn-control btn-icon btn-icon-rounded">
          <div ref="shareBtn"></div>
        </button>
      </template>
      <div class="dropdown-menu-wrapper">
        <b-dropdown-header id="dropdown-header-label" variant=" px-0">
          <h6 class="font-weight-bold">Share Tour</h6>
        </b-dropdown-header>
        <Share/>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import Share from '@/components/controls/share/Share';
import { DropdownMixin } from '../mixin';
import shareBtn from './shareBtn.json';

export default {
  name: 'ShareDropdown',
  components: {Share},
  mixins: [DropdownMixin],
  mounted() {
    this.initAnim(shareBtn, this.$refs.shareBtn);
  }
}
</script>
<style src="../Dropdowns.scss" scoped lang="scss"></style>
<style src="./ShareDropdown.scss" scoped lang="scss"></style>
