<template>
  <div>
    <div class="h5 font-weight-semi-bold">{{ tour.name }}</div>
    <p class="text-secondary mb-0">{{ tour.address }}</p>
    <p class="text-secondary mt-3 mb-0 tour-description">{{ tour.description }}</p>
    <div class="d-flex mt-5 justify-content-center" v-if="tour.contactInfo && tour.contactInfo.name">
      <button @click="$emit('showOwner')" class="btn btn-form btn-primary">
         CONTACT TOUR CREATOR
      </button>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    name: 'TourMetaDetails',
    computed: {
      ...mapState('viewer', ['tour']),
    }
  }
</script>
<style scoped>
 .tour-description {
   white-space: pre-wrap;
 }
</style>
