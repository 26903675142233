<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
          d="M58.3073 0C58.3073 0 52.3812 0.124843 46.9472 4.85106C41.5132 9.57727 42.4297 15.9443 42.4297 15.9443C42.4297 15.9443 47.8089 16.7998 53.55 11.8066C59.2911 6.81345 58.3073 0 58.3073 0Z"
          fill="#221F20"/>
      <path
          d="M40.3102 20.0127C36.036 19.9838 33.4105 16.332 26.1313 16.332C19.5816 16.332 13.0157 20.5135 10.2239 24.5595C7.44499 28.5866 5.03809 33.0034 5.03809 41.9669C5.03809 50.9305 9.39974 66.346 20.1251 76.7819C21.9619 78.569 24.3933 79.9124 27.1671 79.9956C32.1559 80.1451 35.2192 76.4498 41.521 76.4498C47.8227 76.4498 49.719 79.9956 55.408 79.9956C57.995 79.9956 60.9453 78.8297 63.8103 75.8148C67.4863 71.9466 72.0478 64.5791 74.3714 57.5124C74.3714 57.5124 62.7358 53.066 62.8766 40.2492C62.9933 29.6258 72.1103 24.4006 72.1103 24.4006C72.1103 24.4006 66.7859 16 55.4663 16C47.6476 16.0579 44.1465 20.0387 40.3102 20.0127Z"
          fill="#221F20"/>
    </g>

    <g class="hover">
      <path
          d="M58.3073 0C58.3073 0 52.3812 0.124843 46.9472 4.85106C41.5132 9.57727 42.4297 15.9443 42.4297 15.9443C42.4297 15.9443 47.8089 16.7998 53.55 11.8066C59.2911 6.81345 58.3073 0 58.3073 0Z"
          />
      <path
          d="M40.3102 20.0127C36.036 19.9838 33.4105 16.332 26.1313 16.332C19.5816 16.332 13.0157 20.5135 10.2239 24.5595C7.44499 28.5866 5.03809 33.0034 5.03809 41.9669C5.03809 50.9305 9.39974 66.346 20.1251 76.7819C21.9619 78.569 24.3933 79.9124 27.1671 79.9956C32.1559 80.1451 35.2192 76.4498 41.521 76.4498C47.8227 76.4498 49.719 79.9956 55.408 79.9956C57.995 79.9956 60.9453 78.8297 63.8103 75.8148C67.4863 71.9466 72.0478 64.5791 74.3714 57.5124C74.3714 57.5124 62.7358 53.066 62.8766 40.2492C62.9933 29.6258 72.1103 24.4006 72.1103 24.4006C72.1103 24.4006 66.7859 16 55.4663 16C47.6476 16.0579 44.1465 20.0387 40.3102 20.0127Z"
          />
    </g>
  </svg>

</template>
<script>
export default {
  name: 'AppleIcon',
}
</script>
