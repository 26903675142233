<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="stroke"
        d="M11.9116 2.34355C12.3417 1.41748 13.6583 1.41748 14.0884 2.34356L16.6852 7.93524C16.86 8.31174 17.217 8.57113 17.6292 8.62108L23.7496 9.36287C24.7633 9.48572 25.1701 10.7379 24.4223 11.4331L19.9067 15.6307C19.6027 15.9133 19.4663 16.333 19.5461 16.7404L20.732 22.7905C20.9284 23.7925 19.8633 24.5664 18.971 24.07L13.5834 21.0726C13.2207 20.8708 12.7793 20.8708 12.4166 21.0726L7.02902 24.07C6.13674 24.5664 5.07162 23.7925 5.26801 22.7905L6.45386 16.7404C6.5337 16.333 6.39733 15.9133 6.09329 15.6307L1.57774 11.4331C0.829887 10.7379 1.23673 9.48572 2.25038 9.36287L8.37085 8.62108C8.78296 8.57113 9.13998 8.31174 9.31483 7.93524L11.9116 2.34355Z"
        stroke-width="1.6"/>
  </svg>
</template>
<script>
  export default {
    name: 'StarIcon'
  }
</script>
