import * as THREE from 'three';

//Sprite Shader with extra texture input

const vertexShader = /* glsl */ `
// switch on high precision floats
#ifdef GL_ES
precision highp float;
#endif

out vec2 vUv;

void main() {
    vUv = uv;
    vec3 scale = vec3(1.);
    float rotation = 0.0;

    vec3 alignedPosition = vec3(position.x * scale.x, position.y * scale.y, position.z*scale.z);

    vec2 pos = alignedPosition.xy;

    vec2 rotatedPosition;
    rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
    rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;

    vec4 finalPosition;

    finalPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
    finalPosition.xy += rotatedPosition;
    finalPosition = projectionMatrix * finalPosition;

    //gl_Position =  finalPosition;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
  }`;

const fragmentShader = /* glsl */ `
#ifdef GL_ES
precision highp float;
#endif

uniform float alpha;
uniform sampler2D uTexture;
in vec2 vUv;



mat2 scale(vec2 _scale){
    return mat2(_scale.x,0.0,
                0.0,_scale.y);
}

float bars(in vec2 uv){
  uv-=vec2(0.5);
  uv*=scale(vec2(1.2,1.2));
  uv+=vec2(0.5);
  vec4 rect = vec4(0.496, 0.15, 0.504, 0.85);
  uv.y = fract(uv.y*2.);
  vec2 hv = smoothstep(rect.xy-0.0005,rect.xy+0.0005, uv) * smoothstep(uv-0.0005,uv+0.0005, rect.zw);
  return hv.x*hv.y;
}

float tri(in vec2 p,in float o)  {
    vec2 _p = p;
    p.y +=0.3;
    p -= vec2(0.5);
    p *= scale(vec2(0.72,1.0));
    p += vec2(0.5);
    float cut = smoothstep(((1.0 - p.y) * 1.)-0.002,((1.0 - p.y) * 1.), 1.0 - p.x) * smoothstep(((1.0 - p.y) * 1.)-0.002,((1.0 - p.y) * 1.), p.x);
    cut *= smoothstep(_p.y-0.002,_p.y, 0.45);
    cut -= o;
    return cut;
}

void main()
{
  vec2 _vUv = vec2(vUv.x,vUv.y*2.-0.75);
  vec3 vTexture = texture(uTexture, _vUv).rgb;
  float a = distance(_vUv,vec2(0.5,0.5));
  a = smoothstep(0.015,0.005,a);
  a += smoothstep(0.41,0.42,distance(_vUv,vec2(0.5,0.5)));
  float cross = bars(_vUv) + bars(_vUv.yx) + a;
  vTexture.rgb += vec3(cross);
  float cutout = smoothstep(0.505,0.51,(1.0-(distance(_vUv,vec2(0.5)))));
  cutout -= smoothstep(0.47,0.465,distance(_vUv,vec2(0.5)))-smoothstep(0.43,0.425,distance(_vUv,vec2(0.5)));
  float b = tri(vUv,1.-smoothstep(0.48,0.49,distance(_vUv,vec2(0.5,0.5))));
  cutout = max(cutout,b);
  gl_FragColor = vec4(vTexture,cutout*alpha);
}
`;

/**
 * Create shader material for displaying panoramas.
 * @private
 */
export default function createCustomSpriteMaterial(rendertarget,alpha) {
  return new THREE.ShaderMaterial({
    depthWrite: false,
    depthTest: false,
    transparent: true,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    uniforms: {
      uTexture: { value: rendertarget },
      alpha : { value: alpha}
    },

  });
}
