import * as THREE from 'three';
import gsap from 'gsap'
import store from '../../../store';

class PanoIndicators {
  _indicators = [];
  _indicator = null;
  _currentindicator = null;
  _mobile = false;

  initIndicators(footprints, config, visible = true, floor) {
    footprints.forEach(f => {
      if (!f) {
        f = {x: 10000, y: 10000, z: 10000};
      }
      let indicator = this.buildIndicator(config);
      indicator.userData.floor = floor
      indicator.position.copy(f);
      indicator.visible = visible;
      indicator.position.z += 0.1 // raise slightly to avoid z fighting
      this.indicators = [...this.indicators, indicator];
    })
  }

  updateIndicators(footprints, config, visible = true, scene, floor) {
    footprints.forEach(f => {
      if (!f) {
        f = {x: 10000, y: 10000, z: 10000};
      }
      let indicator = this.buildIndicator(config);
      indicator.userData.floor = floor
      indicator.position.copy(f);
      indicator.visible = visible;
      indicator.position.z += 0.1 // raise slightly to avoid z fighting
      this.indicators = [...this.indicators, indicator];
      // console.log('indicator', indicator, this.indicators)
      scene.add(indicator)
    })
  }

  getClosestIndicatorVisibility() {
    return this._indicator ? this._indicator.visible : false;
  }

  setClosestIndicator(index) {
    this._indicator = this.indicators[index];
  }
  toggleClosestIndicatorM(visible) { ///Turns off Indicators without animations
    if (this._indicator && !this._mobile) {
      this._indicator.visible = visible
    }
  }

  toggleClosestIndicator(visible) {
    
    if (this._indicator && !this._mobile && !store.state.layout.hiddenUI) {
      this._indicator.visible = true
      if(this._currentindicator !== undefined && this._currentindicator !== null) this._currentindicator.visible = false
      this._indicator.children.map((e,i) =>{let _opacity; switch(i) { //Different max opacities for pano indicator children
        case 1:
           _opacity = 0.2*1.5
          break;
        case 2:
           _opacity = 0.33*1.5
          break;
        case 3:
           _opacity = 0.2*1.5
          break
        default:
           _opacity = 0.66*1.5
      } gsap.to(e.material, {opacity: visible ? _opacity : 0, duration: visible ? 0.2 : 0.7})})
      /*if(this._indicator.children[0].material.opacity == 0){
         this._indicator.visible = visible
      }*/
    }
  }

  toggleAllIndicators( visible ) {
    this._indicators.forEach((indicator)=>{
      if (!store.state.layout.hiddenUI) {
        indicator.visible = true
        indicator.children.map((e,i) =>{
          let _opacity; switch(i) { 
          //Different max opacities for pano indicator children
          case 1:
             _opacity = 0.2*1.5
            break;
          case 2:
             _opacity = 0.33*1.5
            break;
          case 3:
             _opacity = 0.2*1.5
            break
          default:
             _opacity = 0.66*1.5
        } gsap.to(e.material, {opacity: visible ? _opacity : 0, duration: visible ? 0.2 : 0.7})})
      }
    })
  }

  addIndicators(scene) {
    this.indicators.forEach(indicator => {
      scene.add(indicator);
    })
  }

  updateIndicator(index, position, meshes) {
    position = new THREE.Vector3(...position)
    const raycaster = new THREE.Raycaster()
    const direction = new THREE.Vector3(0, 0, -1)
    raycaster.set(position, direction)
    const intersect = raycaster.intersectObjects(meshes)
    if (intersect.length) position.z = intersect[0].point.z + 0.1
    this.indicators[index].position.copy(position);
    console.log('updated pano indicator')
  }

  removeIndicators(scene) {
    this.indicators.forEach(indicator => {
      scene.remove(indicator);
    });

    this.indicators = [];
  }

  buildIndicator(config) {
    const indicatorGroup = new THREE.Group()
    const materialWhite = new THREE.MeshBasicMaterial({opacity: 0.66, transparent: true, depthWrite: false})
    const materialTransparent = new THREE.MeshBasicMaterial({opacity: 0.33, transparent: true, depthWrite: false, blending: THREE.AdditiveBlending})
    const materialGray = new THREE.MeshBasicMaterial({color: 0x000000, opacity: 0.2, transparent: true, depthWrite: false})

    const closestCameraHelperCenter = new THREE.Mesh(new THREE.CircleGeometry((config.cursorSize / 6), 48),
    materialWhite);

    const innerRing = new THREE.Mesh(new THREE.RingGeometry((config.cursorSize / 6), (config.cursorSize / 6) * 1.2, 48), materialGray)

    const closestCameraHelper = new THREE.Mesh(new THREE.RingGeometry((config.cursorSize / 6) * 1.2, config.cursorSize, 48),
    materialTransparent);

    const outterRing = new THREE.Mesh(new THREE.RingGeometry(config.cursorSize, config.cursorSize * 1.05, 48), materialGray)

    indicatorGroup.scale.set(0.8, 0.8, 1)

    indicatorGroup.add(closestCameraHelperCenter)
    indicatorGroup.add(innerRing)
    indicatorGroup.add(closestCameraHelper)
    indicatorGroup.add(outterRing)
    indicatorGroup.renderOrder = 3
    return indicatorGroup
  }

  //used in mobile mode
  toggleVisibilityByFloor = floorNumber => {
    const floorIndicators = this._indicators.filter(ind => ind.userData.floor === floorNumber)
    const otherIndicators = this._indicators.filter(ind => ind.userData.floor !== floorNumber)
    floorIndicators.forEach(ind => ind.visible = true)
    otherIndicators.forEach(ind => ind.visible = false)
  }

  set indicators(indicators) {
    this._indicators = indicators;
  }

  get indicators() {
    return this._indicators;
  }
}

export default PanoIndicators;
