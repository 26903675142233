<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.4877 7.83798C18.8531 7.47256 18.8531 6.88002 18.4877 6.51457C18.1221 6.14913 17.5296 6.14914 17.1643 6.51457C16.7988 6.88 16.7987 7.47254 17.1643 7.83798C17.5297 8.20343 18.1222 8.20341 18.4877 7.83798Z"
        />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M15.8809 1.32221C16.6118 0.59136 17.7969 0.591302 18.5277 1.32221L22.9872 5.78166C23.7182 6.51257 23.7181 7.69764 22.9872 8.42849L8.4297 22.986C7.69886 23.7169 6.51379 23.7169 5.78288 22.986L1.32342 18.5265C0.592514 17.7956 0.592573 16.6106 1.32342 15.8797L15.8809 1.32221ZM7.09267 21.7932L21.6909 7.19501L17.1029 2.60701L15.8109 3.89901L16.6617 4.74977C17.0271 5.11521 17.0271 5.70775 16.6617 6.07317C16.2963 6.4386 15.7037 6.43862 15.3382 6.07317L14.4875 5.22242L13.1641 6.54582L14.0148 7.39658C14.3803 7.76203 14.3803 8.35457 14.0148 8.72C13.6494 9.08542 13.0569 9.08544 12.6914 8.72L11.8407 7.86924L10.5173 9.19265L12.5024 11.1778C12.8678 11.5432 12.8678 12.1358 12.5024 12.5012C12.137 12.8666 11.5444 12.8666 11.179 12.5012L9.19386 10.5161L7.87045 11.8395L8.72121 12.6902C9.08666 13.0556 9.08664 13.6482 8.72121 14.0136C8.35578 14.379 7.76324 14.379 7.39779 14.0136L6.54704 13.1629L5.22363 14.4863L6.07439 15.337C6.43983 15.7025 6.43981 16.2951 6.07439 16.6605C5.70896 17.0258 5.11642 17.0259 4.75098 16.6605L3.90022 15.8097L2.50468 17.2052L7.09267 21.7932Z"
          />
  </svg>

</template>
<script>
export default {
  name: 'Ruler',
}
</script>
