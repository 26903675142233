export const VIEWER_MODES = {
  PANO: 'walk',
  ORBIT: 'orbit',
  FLOORPLAN: 'floorplan'
}

Object.freeze(VIEWER_MODES);

export const AppStates = {
  init: 'INIT',
  change: 'CHANGE',
  ready: 'READY'
}

Object.freeze(AppStates);

export const UserRoles = {
  ADMIN: 'admin',
  TOUR_OWNER: 'tour_owner',
  USER: 'user',
  VISITOR: 'visitor'
}

Object.freeze(UserRoles);

export const REFERENCES = {
  OWNER: 'owner',
  LISTING: 'listing'
}

Object.freeze(REFERENCES);

export const FeatureFormModes = {
  NEW: 'new',
  EDIT: 'edit',
  MOVE: 'move'
}

Object.freeze(FeatureFormModes);

export const ItemConditions = {
  NEW: 'new',
  USED: 'used'
}

Object.freeze(ItemConditions);

export const MenuPages = {
  MAIN: 'main',
  STATISTICS: 'statistics',
  FLOORS: 'floors',
  SHARE: 'share',
  AUTH: 'auth',
  FEATURE_FORM: 'feature-form',
  FEATURE_DETAILS: 'feature-details',
  FEATURE_OWNER: 'feature-owner',
  FEATURE_LIST: 'feature-list',
  TOUR_META: 'tour-meta'
}

Object.freeze(MenuPages);

export const CONTROLS_STATE = {
  NULL: 'NULL',
  MEASURE_DISTANCE: 'MEASURE_DISTANCE',
  MEASURE_AREA: 'MEASURE_AREA',
  ADD_FEATURE: 'ADD_FEATURE',
  EDIT_FEATURE: 'EDIT_FEATURE',
  MOVE_FEATURE: 'MOVE_FEATURE',
  ADD_ROOM_LABEL: 'ADD_ROOM_LABEL',
  EDIT_ROOM_LABEL: 'EDIT_ROOM_LABEL',
  MOVE_ROOM_LABEL: 'MOVE_ROOM_LABEL',
  EDIT_ENTRY_ARROW: 'EDIT_ENTRY_ARROW',
  TRIM_MODEL: 'TRIM_MODEL',
  BLUR_MODEL: 'BLUR_MODEL',
  SMOOTH_MODEL: 'SMOOTH_MODEL',
}

Object.freeze(CONTROLS_STATE);

export const SELECTED_ROOM_STATES = {
  SHOW: 'SHOW',
  NAME: 'NAME',
  SQFT: 'SQFT',
  NEW: 'NEW',
  MOVE: 'MOVE',
}

Object.freeze(SELECTED_ROOM_STATES);

export const TOOLTIPS = {
  RTS: 'RTS-tooltip-id',
  MOBILE_RTS: 'MOBILE_RTS-tooltip-id',
  ORBIT_MENU: 'ORBIT_MENU-tooltip-id',
  FLOOR_MENU: 'FLOOR_MENU-tooltip-id',
  PANO_MENU: 'PANO_MENU-tooltip-id',
  MOBILE_ORBIT_MENU: 'MOBILE_ORBIT_MENU-tooltip-id',
  MOBILE_PANO_MENU: 'MOBILE_PANO_MENU-tooltip-id',
  MOBILE_FLOOR_MENU: 'MOBILE_FLOOR_MENU-tooltip-id',
  MOBILE_MEASURE: 'MOBILE_MEASURE-tooltip-id',
  ORBIT_MODE: 'ORBIT_MODE-tooltip-id',
  FLOOR_MODE: 'FLOOR_MODE-tooltip-id',
  PANO_MODE: 'PANO_MODE-tooltip-id',
  MOBILE_ORBIT_MODE: 'MOBILE_ORBIT_MODE-tooltip-id',
  MOBILE_FLOOR_MODE: 'MOBILE_FLOOR_MODE-tooltip-id',
  MOBILE_PANO_MODE: 'MOBILE_PANO_MODE-tooltip-id',
  MEASURE: 'MEASURE-tooltip-id',
  EDIT_MODE: 'EDIT_MODE-tooltip-id',
  MOBILE_EDIT_MODE: 'MOBILE_EDIT_MODE-tooltip-id',
  FULLSCREEN: 'FULLSCREEN-tooltip-id',
  FLOORS: 'FLOORS-tooltip-id',
  MOBILE_FLOORS: 'MOBILE_FLOORS-tooltip-id',
  MOBILE_MENU: 'MOBILE_MENU-tooltip-id',
}

Object.freeze(TOOLTIPS);

export const RTSLabels = {
  tourInfo: 'tourInfo',
  entryArrow: 'entryArrow',
  roomLabels: 'roomLabels',
  floorNames: 'floorNames',
  sqft: 'sqft',
  panos: 'panos',
  features: 'features',
  capture: 'capture',
  modelReview: 'modelReview'
}

Object.freeze(RTSLabels);

export const SUCCESS_PANO_STATUS = 'aligned';
