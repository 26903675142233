<template>
  <div id="viewer"></div>
</template>

<script>
  export default {
    name: 'Viewer',
  }
</script>

<style lang="scss" src="./Viewer.scss" scoped></style>
