<template>
  <div>
    <b-tooltip
        v-for="(tooltip, id) in tooltips"
        :target="id"
        :key="id"
        :title="tooltip.text"
        :placement="tooltip.placement"
        :show="showTooltips"
        :customClass="tooltip.customClass || ''"
        :offset="tooltip.offset || 0"
        boundary-padding="14"
        triggers="manual"
    />
  </div>
</template>
<script>
  import { mapState } from 'vuex/dist/vuex.esm.browser';
  import { TooltipsText } from '@/helpers/data';

  export default {
    data() {
      return {
        tooltips: TooltipsText
      }
    },
    name: 'Tooltips',
    computed: {
      ...mapState('controls', ['showTooltips'])
    }
  }
</script>
<style src="./Tooltips.scss" lang="scss"></style>
