<template>
  <svg width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.9659 7.1H11.6982V2.9C11.6982 1.57438 10.6299 0.5 9.31179 0.5H4.68821C3.37012 0.5 2.30185 1.57438 2.30185 2.9V7.1H1.03409C0.704102 7.1 0.4375 7.36813 0.4375 7.7V14.9C0.4375 15.2319 0.704102 15.5 1.03409 15.5H12.9659C13.2959 15.5 13.5625 15.2319 13.5625 14.9V7.7C13.5625 7.36813 13.2959 7.1 12.9659 7.1ZM3.64418 2.9C3.64418 2.32063 4.11213 1.85 4.68821 1.85H9.31179C9.88787 1.85 10.3558 2.32063 10.3558 2.9V7.1H3.64418V2.9ZM12.2202 14.15H1.77983V8.45H12.2202V14.15ZM6.47798 11.5438V12.5375C6.47798 12.62 6.5451 12.6875 6.62713 12.6875H7.37287C7.4549 12.6875 7.52202 12.62 7.52202 12.5375V11.5438C7.67591 11.4326 7.79078 11.2753 7.85009 11.0943C7.90939 10.9133 7.91008 10.7181 7.85204 10.5368C7.79401 10.3554 7.68024 10.1972 7.52713 10.085C7.37402 9.97278 7.18945 9.91232 7 9.91232C6.81055 9.91232 6.62598 9.97278 6.47287 10.085C6.31976 10.1972 6.20599 10.3554 6.14796 10.5368C6.08992 10.7181 6.09061 10.9133 6.14991 11.0943C6.20922 11.2753 6.32409 11.4326 6.47798 11.5438Z"
        />
  </svg>

</template>
<script>
export default {
  name: 'LockIcon',
}
</script>
