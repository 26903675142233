<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M40 42.3767L0.05 10H79.95L40 42.3767ZM40 50.9567L0 18.54V70H80V18.54L40 50.9567Z" fill="#E544FF"/>
    </g>
    <g class="hover">
      <path d="M40 42.3767L0.05 10H79.95L40 42.3767ZM40 50.9567L0 18.54V70H80V18.54L40 50.9567Z" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'EmailIcon',
}
</script>
