<template>
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M6.85216 14.2608L1.11293 20L0 18.8871L5.73922 13.1478H1.34343V11.5739H8.42608V18.6566H6.85216V14.2608ZM14.2608 13.1478L20 18.8871L18.8871 20L13.1478 14.2608V18.6566H11.5739V11.5739H18.6566V13.1478H14.2608ZM13.1478 5.73922L18.8871 0L20 1.11293L14.2608 6.85216H18.6566V8.42608H11.5739V1.34343H13.1478V5.73922ZM5.73922 6.85216L0 1.11293L1.11293 0L6.85216 5.73922V1.34343H8.42608V8.42608H1.34343V6.85216H5.73922Z"
      />
    </svg>
</template>

<script>
export default {
  name: 'FullscreenOffIcon',
}
</script>
