import firebase from 'firebase/app';
import 'firebase/functions';

import config from '@/config';
const {VUE_APP_DefaultTour} = process.env

export const getAllPanos = async function (floor, tourId) {
  let {cloudDomain, storageBucket} = config.firebase;
  let panoData

  if (tourId === `tours/${VUE_APP_DefaultTour}`) {
    //fetch data from public/JSON
    try {
      const tourPanosJSON = await fetch(`./${VUE_APP_DefaultTour}_${floor}_Panos.json`)
      panoData = await tourPanosJSON.json()
    } catch(err) {console.warn('loading default tour panos from server')}
  }

  if (tourId === 'tours/bkxOfGr5ni2Iek7zT6LK') {
    //sun inn
    try {
      const tourPanosJSON = await fetch(`./bkxOfGr5ni2Iek7zT6LK_${floor}_Panos.json`)
      panoData = await tourPanosJSON.json()
    } catch(err) {console.warn('loading default tour panos from server')}
  }

  if (tourId === 'tours/IAyFfMtzV9jipI5izvyz') {
    //49 prince
    try {
      const tourPanosJSON = await fetch(`./IAyFfMtzV9jipI5izvyz_${floor}_Panos.json`)
      panoData = await tourPanosJSON.json()
    } catch(err) {console.warn('loading default tour panos from server')}
  }

  if (!panoData) {
    // fetch data from server
    let get_panos = firebase.functions().httpsCallable('panos-getFloorPanoTiles');
    panoData = await get_panos({tourId, floor})
  }


  try{
    let panos = {};
    panoData.data.forEach(panosData => {
      // check if there is no pano data
      if (!panosData) return

      panosData.forEach(data => {
        //check if pano config, or pano stitching/tiling errored
        if (!data[0]?.config || !data[1]) return
        let tiles = {};
        for (let layer in data[1]) {
          for (let j in data[1][layer]) {
            let tile = data[1][layer][j];
            let tileKey = `${layer}_${tile.tile_name.replace('.jpg', '')}`;
            //some tours have file url some have full file url
            const url = tile.full_file_url || tile.file_url
            //newer tours have an absolute url, older tours might not
            if (url.startsWith('http')) {
              tiles[tileKey] = url
            } else {
              tiles[tileKey] = `https://${cloudDomain}/${storageBucket}/${url}`
            }
          }
        }
        let panoId = data[0].pano.id;
        let config = data[0].config;
        let pano = data[0].pano;

        //if pos or rot are missing then initialize default
        if (!pano.position) pano.position = [0,0,0]
        if (!pano.rotation) pano.rotation = [0,0,0]

        panos[panoId] = {config, tiles, ...pano};
      })
    })
    return panos;
  }
  catch(err) {console.log(err)}
}

