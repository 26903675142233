<template>
    <div class="d-flex align-items-center" :class="{'no-back': noBack}">
      <div class="flex-1">
        <b-button variant="link" size="sm" class="text-secondary p-0" @click="$emit('onBack')">
          <i class="las la-arrow-left la-lg back-icon"></i>
        </b-button>
      </div>
      <h5 class="font-weight-bold mb-0 text-center title">{{title}}</h5>
      <div class="flex-1"></div>
    </div>
</template>

<script>
  export default {
    name: 'BackMenu',
    props: ['title', 'noBack']
  }
</script>
<style scoped lang="scss" src="./BackMenu.scss"></style>
