import { CONTROLS_STATE, SELECTED_ROOM_STATES, VIEWER_MODES } from '@/constants';
import { postMessagesMixin } from '@/mixins/post-messages';
import { HelpersMixin } from '@/mixins/helpers-mixin';

export default {
  namespaced: true,
  state: () => ({
    mode: null,
    editMode: false,

    showNames: true,
    panoShowNames: false,
    showFeatures: true,
    showTutors: false,
    showTooltips: false,

    fullscreen: false,

    tutorsType: '',
    controlsState: CONTROLS_STATE.NULL,
    measureComplete: false
  }),
  mutations: {
    set_mode(state, mode) {
      state.controlsState = CONTROLS_STATE.NULL;
      state.showNames = mode !== VIEWER_MODES.PANO !== HelpersMixin.computed.inIframe();
      state.mode = mode;
    },
    toggle_edit_mode(state, value) {
      if(value){
         state.showNames = true
         state.showFeatures = true
      }
      state.editMode = value;
    },
    set_controls_state(state, target) {
      state.controlsState = target;
    },
    toggle_names(state, show) {
      if (state.mode === VIEWER_MODES.PANO) {
        state.panoShowNames = show;
      } else {
        state.showNames = show;
      }
    },
    toggle_fullscreen(state, show) {
      state.fullscreen = show;
      postMessagesMixin.methods.fullscreenMessage(show)
    },
    change_tutors_type(state, type) {
      state.tutorsType = type;
    },
    toggle_tutors(state, show) {
      state.showTutors = show;
    },
    toggle_features(state, show) {
      state.showFeatures = show;
    },
    toggle_tooltips(state, value) {
      state.showTooltips = value;
    },
    toggle_measureComplete(state, value) {
      state.measureComplete = value;
    }
  },
  actions: {
    toggleNames({commit, state, rootState}, show) {
      commit('toggle_names', show);
      if (state.mode === VIEWER_MODES.ORBIT && rootState.viewer.viewer.FloorplanManager) {
        //take out for multi-floor, might put back in for single floor
        // rootState.viewer.viewer.FloorplanManager.toggleVisibility(show);
      }
    },
    toggleTutors({commit, state}, {show, tutorsType}) {
      if (tutorsType) {
        commit('change_tutors_type', tutorsType);
      }

      if (state.showTutors && show) {
        commit('toggle_tutors', false);

        setTimeout(() => {
          commit('toggle_tutors', true);
        })
      } else {
        commit('toggle_tutors', show);
      }
    },
    toggleFullscreen({commit}, state) {
      commit('toggle_fullscreen', state);
      if (state) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else {
          document.documentElement.webkitRequestFullScreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else {
          document.webkitCancelFullScreen();
        }
      }
    },
    setControlsState({commit, state, dispatch}, target) {
      dispatch('rooms/unselectRoom', null, {root: true})
      // MAIN
      if (state.controlsState === target) {
        commit('set_controls_state', CONTROLS_STATE.NULL);
      } else {
        commit('set_controls_state', target);
      }

      if (state.showTooltips) {
        commit('toggle_tooltips', false);
      }
    },
    setMode({commit, dispatch, state, rootState}, mode) {
      commit('set_mode', mode);
      ///Removed for now as it was causing pano list modal to close when moving between panos
      /*if (rootState.panos.panoList) {
        commit('panos/toggle_pano_list', false, {root: true});
      }*/
      if (state.showTutors) {
        dispatch('toggleTutors', {show: true, tutorsType: mode});
      }
      dispatch('setControlsState', CONTROLS_STATE.NULL);
    },
    toggleEditMode({commit, dispatch}, value) {
      commit('toggle_edit_mode', value);
      dispatch('setControlsState', CONTROLS_STATE.NULL);
    },
    toggleTooltips({commit, state}, value) {
      if (state.editMode) {
        commit('toggle_tooltips', value);
      }
    },
    toggleArrow({commit, state, rootState}) {
      if(rootState.viewer.viewer.entryArrow.arrow.scale.x===0) rootState.viewer.viewer.entryArrow.arrow.scale.set(1,1,1)
      rootState.viewer.viewer.centerRoomLabel([rootState.viewer.viewer.entryArrow.arrow.position.x,rootState.viewer.viewer.entryArrow.arrow.position.y,rootState.viewer.viewer.entryArrow.arrow.position.z])
    }
  }
}
