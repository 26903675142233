<template>
  <svg width="26" height="24" viewBox="0 0 26 24" xmlns="http://www.w3.org/2000/svg">
    <path class="stroke" d="M14.0928 13.5488L10.6763 9.56255" stroke-width="1.5" stroke-linecap="round"/>
    <path class="stroke"
        d="M3.46289 22.6602L24.7231 4.43916M7.44917 19.2437L5.17155 16.5862M10.7711 16.3967L8.49345 13.7392M17.4149 10.7026L15.1373 8.04511M20.7368 7.8556L18.4592 5.19808"
        stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'DistanceIcon',
}
</script>
