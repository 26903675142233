<template>
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.38119 16.1246H3.38172C2.80548 16.1246 2.33834 15.5349 2.33834 14.8074V3.90264C2.33834 3.17518 2.80548 2.58546 3.38172 2.58546H22.2983C22.8746 2.58546 23.3417 3.17518 23.3417 3.90264V14.8075C23.3417 15.5349 22.8746 16.1246 22.2983 16.1246H15.9024L12.6677 21.2844L9.38119 16.1246ZM16.8223 17.7745H22.2983C24.1364 17.7745 24.9999 16.0586 24.9999 14.8075V3.90264C24.9999 2.6515 24.1364 0.935547 22.2983 0.935547H3.38172C1.54363 0.935547 0.680176 2.6515 0.680176 3.90264V14.8074C0.680176 16.0586 1.54363 17.7745 3.38172 17.7745H8.46897L12.6789 24.3841L16.8223 17.7745Z"
          />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5.65466 7.2602C5.65466 6.80459 6.02585 6.43525 6.48374 6.43525L19.1963 6.43525C19.6542 6.43525 20.0254 6.80459 20.0254 7.2602C20.0254 7.71582 19.6542 8.08516 19.1963 8.08516L6.48374 8.08516C6.02585 8.08516 5.65466 7.71581 5.65466 7.2602Z"
          />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5.65466 11.66C5.65466 11.2044 6.02585 10.835 6.48374 10.835L19.1963 10.835C19.6542 10.835 20.0254 11.2044 20.0254 11.66C20.0254 12.1156 19.6542 12.4849 19.1963 12.4849L6.48374 12.4849C6.02585 12.4849 5.65466 12.1156 5.65466 11.66Z"
          />
  </svg>

</template>
<script>
export default {
  name: 'RoomIcon',
}
</script>
