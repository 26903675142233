import { render, staticRenderFns } from "./MobileLayout.vue?vue&type=template&id=ad8896ca&scoped=true&"
import script from "./MobileLayout.vue?vue&type=script&lang=js&"
export * from "./MobileLayout.vue?vue&type=script&lang=js&"
import style0 from "./MobileLayout.scss?vue&type=style&index=0&id=ad8896ca&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad8896ca",
  null
  
)

export default component.exports