<template>
  <div class="header" :class="{edit: editMode}">
    <div class="left-nav">
      <b-button :id="TOOLTIPS.MOBILE_MENU" v-if="isMobile" variant="link" @click="toggleMobileMenu(!mobileMenuOpen)" class="mobile-menu-btn">
        <img v-if="!editMode" :src="menuIcon" alt="menu icon"/>
        <img v-else :src="editMenuIcon" alt="menu icon"/>
      </b-button>
      <a v-if="!isMobile && !inIframe" href="https://www.the3dapp.com" target=”_blank”
         class="logo-header mr-3">
        <img :src="logo" height="100%" alt="Logo">
      </a>
      <div v-if="editMode" class="d-flex align-items-center">

        <!-- hide RTS until it is done -->

        <ul class="edit-menu">
          <li>
            <b-dropdown ref="RTSChecklistDropdown" v-if="!isMobile" :id="TOOLTIPS.RTS" :toggle-class="'edit-menu-item goblet-btn'" no-caret
                        menu-class="rts-menu">
              <template #button-content>
                <GobletIcon/>
              </template>
              <RTSChecklist @hide="$refs.RTSChecklistDropdown.visible = false"/>
            </b-dropdown>
            <template v-else>
              <button :id="TOOLTIPS.MOBILE_RTS" @click="toggle_checklist(!showChecklist)" class="btn edit-menu-item goblet-btn">
                <GobletIcon/>
              </button>
            </template>
          </li>
        </ul>

        <EditMenu v-if="!isMobile"/>
      </div>
    </div>

    <div class="btn-toolbar auth-buttons">
      <Switcher 
          :id="isMobile ? TOOLTIPS.MOBILE_EDIT_MODE : TOOLTIPS.EDIT_MODE"
          v-if="showEditMode() && editMode || showEditMode() && !isMobile"
          @onSwitch="toggleEditMode(!editMode), cancelProgress()" class="mr-md-3 shadow" :state="editMode"
      >
        <template v-slot:onValue>
          <span v-if="!editMode">EDIT TOUR</span>
          <CheckIcon v-else/>
        </template>
        <template v-slot:offValue>
          <span v-if="editMode">FINISH EDITS</span>
          <EditIcon v-else/>
        </template>
      </Switcher>
      <template v-if="!isMobile && !inIframe">
        <div v-if="name" class="btn-group shadow bg-light">
          <div class="btn btn-light btn-control">
            <small>{{ name }}</small>
          </div>
          <div class="vertical-divider"></div>
          <button @click="$emit('signOut')" class="btn ml-0 btn-light btn-icon btn-control">
            <LogoutIcon/>
          </button>
        </div>
        <button v-else @click="$bvModal.show(signInModal)" class="btn btn-light btn-icon btn-control shadow">
          <UserIcon/>
        </button>
      </template>
    </div>


    <b-modal :id="signInModal" dialog-class="auth-modal" hide-footer hide-header>
      <Auth/>
    </b-modal>
    <b-modal :id="emailSignInFormId" dialog-class="auth-modal" hide-footer hide-header>
      <AuthEmail/>
    </b-modal>
    <b-modal :id="forgotPassFormId" dialog-class="auth-modal" hide-footer hide-header>
      <Forgot/>
    </b-modal>
  </div>
</template>
<script>
  import logo from '../../assets/Monochrome_Logo_Horizontal.svg';
  import { mapState, mapActions } from 'vuex';
  import Auth from '@/components/auth/Auth';
  import AuthEmail from '@/components/auth/AuthEmail';
  import Forgot from '@/components/auth/Forgot';
  import { UserRoles } from '@/constants';
  import EditMenu from './edit-menu/EditMenu';
  import Switcher from '@/components/switcher/Switcher';
  import { EditIcon, CheckIcon } from '@/components/svg-icons';
  import MobileMenuIcon from '../../assets/mobile_menu_icon.svg';
  import MobileEditMenuIcon from '../../assets/mobile_edit_menu_icon.svg';
  import RTSChecklist from '@/components/rts/checklist/RTSChecklist';
  import GobletIcon from '@/components/svg-icons/goblet';
  import { mapMutations } from 'vuex/dist/vuex.esm.browser';
  import UserIcon from '@/components/svg-icons/user';
  import LogoutIcon from '@/components/svg-icons/logout';

  export default {
    name: "Header",
    data() {
      return {
        logo,
        menuIcon: MobileMenuIcon,
        editMenuIcon: MobileEditMenuIcon
      }
    },
    components: {
      LogoutIcon,
      UserIcon,
      RTSChecklist,
      GobletIcon,
      CheckIcon, EditIcon, Forgot, AuthEmail, Auth, Switcher, EditMenu },
    mounted() {
      this.$emit('init');
    },
    computed: {
      ...mapState('users', ['name']),
      ...mapState('rts', ['showChecklist', 'step', 'showFinishProgressModal', 'rtsMode']),
      ...mapState('mobile', ['mobileMenuOpen']),
    },
    methods: {
      ...mapActions('controls', ['toggleEditMode']),
      ...mapActions('mobile', ['toggleMobileMenu']),
      ...mapActions('rts', ['selectStep', 'toggleRTSMode']),
      ...mapMutations('rts', ['toggle_checklist']),
      showEditMode() {
        return this.userRole === UserRoles.ADMIN || this.userRole === UserRoles.TOUR_OWNER
      },
      cancelProgress() {
        this.toggleRTSMode(false);
        this.selectStep(null)
      }
    }
  }
</script>
<style src="./Header.scss" lang="scss" scoped></style>
