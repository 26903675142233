<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M3.01419 5.74229C3.01419 4.53689 3.99249 3.55859 5.19789 3.55859C6.40329 3.55859 7.38159 4.53689 7.38159 5.74229C7.38159 6.94769 6.40329 7.92599 5.19789 7.92599C3.99249 7.92599 3.01419 6.94769 3.01419 5.74229ZM14.8065 8.79986C11.6708 8.79986 9.12796 11.3271 9.12796 14.4425V20.1541C9.12796 20.6365 9.52005 21.0286 10.0024 21.0286H12.0396C12.5219 21.0286 12.914 20.6365 12.914 20.1541V14.4425C12.914 13.34 13.869 12.4588 15.0026 12.5723C15.9867 12.6703 16.6981 13.57 16.6981 14.559V20.1541C16.6981 20.6365 17.0902 21.0286 17.5726 21.0286H19.6097C20.0921 21.0286 20.4842 20.6365 20.4842 20.1541V14.4425C20.4842 11.3271 17.9414 8.79986 14.8065 8.79986ZM6.50771 9.67276H3.88824C3.40589 9.67276 3.01379 10.0639 3.01379 10.5462V20.1545C3.01379 20.6369 3.40589 21.028 3.88824 21.028H6.50771C6.99007 21.028 7.38119 20.6369 7.38119 20.1545V10.5462C7.38119 10.0639 6.99007 9.67276 6.50771 9.67276Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'LinkedinIcon',
}
</script>
