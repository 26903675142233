import firebase from 'firebase/app';
import 'firebase/functions';
import { RTSLabels } from '@/constants';
import Vue from 'vue';

export const RTSSteps = {
  [RTSLabels.tourInfo]: {
    title: 'Edit Tour Info',
  },
  [RTSLabels.entryArrow]: {
    title: 'Place Entry Arrow',
  },
  [RTSLabels.roomLabels]: {
    title: 'Edit Room Details',
  },
  [RTSLabels.floorNames]: {
    title: 'Edit Floor Names',
  },
  [RTSLabels.panos]: {
    title: 'Review Panos',
  },
  [RTSLabels.features]: {
    title: 'Add Feature Labels',
  },
  [RTSLabels.capture]: {
    title: 'Capture and Upload',
  },
  [RTSLabels.modelReview]: {
    title: 'Review 3D Model',
  },
}

export default {
  namespaced: true,
  state: () => ({
    step: null,
    checklist: {
      [RTSLabels.tourInfo]: false,
      [RTSLabels.entryArrow]: false,
      [RTSLabels.roomLabels]: false,
      [RTSLabels.floorNames]: false,
      [RTSLabels.panos]: false,
      [RTSLabels.features]: false,
      [RTSLabels.capture]: false,
      [RTSLabels.modelReview]: false
    },
    showChecklist: false,
    showFinishProgressModal: false,
    rtsMode: false
  }),
  mutations: {
    toggle_rts_mode(state, payload) {
      state.rtsMode = payload;
    },
    toggle_checklist(state, value) {
      state.showChecklist = value;
    },
    update_progress(state, {key, value}) {
      state.checklist[key] = value;
    },
    set_progress(state, checklist) {
      state.checklist = {...checklist};
    },
    select_step(state, step) {
      state.step = step;
    },
    toggle_finish_progress_modal(state, value) {
      state.showFinishProgressModal = value;
    }
  },
  actions: {
    toggleRTSMode({commit}, value) {
      commit('toggle_rts_mode', value);
    },
    selectStep({commit}, step) {
      commit('select_step', step)
    },
    nextStep({dispatch, state}) {
      if (!state.rtsMode) return;
      let next = Object.keys(state.checklist).find(step => {
        return !state.checklist[step]; 
      })
      dispatch('selectStep', next);
      
      if (!next) {
        dispatch('finishProgress');
      }
    },
    finishProgress({commit, dispatch}) {
      dispatch('toggleRTSMode', false);
      commit('toggle_finish_progress_modal', true);
    },
    setProgress({commit}, payload) {
      let checklist = {}; 
      payload.map(label => {
        checklist[label.id] = label.complete;
      })
      commit('set_progress', checklist);
    },
    updateProgress({commit, rootState, state, dispatch}, {key,  value}) {
      if (state.checklist[key]) {
        dispatch('nextStep');
        return;
      }
      let tourId = `tours/${rootState.viewer.tour.id}`;
      let update = firebase.functions().httpsCallable('tours-updateTourProgress');
      update({tourId, changes: [{id: key, complete: value}]}).then(res => {
        commit('update_progress', {key, value});
        if (state.rtsMode) {
          dispatch('selectStep', null);
          notifySuccess(dispatch);
        }
      })
    },
    resetProgress({commit, rootState, state} ) {
      let tourId = `tours/${rootState.viewer.tour.id}`;
      let update = firebase.functions().httpsCallable('tours-updateTourProgress');

      const list = Object.keys(state.checklist).reduce((acc, item) => {
        acc.push({id: item, complete: item === 'capture' ? true : false})
        return acc
      },[])
      update({tourId, changes: list}).then(() => location.reload())
    }
  }
}

function notifySuccess(dispatch) {
  let text = `<div>
  <p class="mb-2 font-weight-semi-bold">Nicely done!</p>
  <p class="mb-0 small">Your progress has been updated.</p>
</div>`
  Vue.toasted.success(text, {
    icon: {
      name: 'las la-chrome mr-2 la-lg'
    },
    position: 'bottom-right',
    duration: 2000,
    className: 'rts-success animate__animated animate__slideInRight',
    containerClass: 'lavamapToasted rts-success-container',
    onComplete() {
      dispatch('nextStep');
    }
  })
}
