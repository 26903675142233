<template>
  <svg width="36" height="29" viewBox="0 0 36 29" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.0976 25.5V12.1364M12.0976 25.5L1.48259 20.174C1.18676 20.0255 1 19.7229 1 19.3919V6.56818M12.0976 25.5L23.2523 20.1697C23.5564 20.0243 23.75 19.7172 23.75 19.3802V6.56818M12.0976 12.1364L1 6.56818M12.0976 12.1364L23.75 6.56818M1 6.56818L11.7145 1.19218C11.9562 1.07094 12.2403 1.0682 12.4842 1.18476L23.75 6.56818"
        class="stroke" stroke-width="1.75" stroke-linecap="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M35.125 22C35.125 25.866 31.991 29 28.125 29C24.259 29 21.125 25.866 21.125 22C21.125 18.134 24.259 15 28.125 15C31.991 15 35.125 18.134 35.125 22ZM24.958 22.2089L27.825 19.4004L29.2011 20.7484L26.3428 23.5514L24.8232 23.6869L24.958 22.2089ZM29.5031 17.7568L30.8787 19.1038L29.8844 20.0788L28.5088 18.7308L29.5031 17.7568ZM24.2605 24.7389C24.2758 24.7389 24.2911 24.7384 24.3069 24.7369L26.4354 24.5474C26.6691 24.5264 26.8886 24.4259 27.0545 24.2629L31.6467 19.7644C32.0183 19.3994 32.003 18.7909 31.612 18.4074H31.6115L30.214 17.0384C29.8363 16.6689 29.186 16.6514 28.8277 17.0034L24.236 21.5019C24.0701 21.6644 23.967 21.8799 23.9456 22.1084L23.7521 24.1939C23.7383 24.3414 23.7924 24.4874 23.8996 24.5924C23.9956 24.6869 24.1257 24.7389 24.2605 24.7389ZM24.2604 25.7393H31.4062C31.687 25.7393 31.9167 25.9643 31.9167 26.2393C31.9167 26.5143 31.687 26.7393 31.4062 26.7393H24.2604C23.9797 26.7393 23.75 26.5143 23.75 26.2393C23.75 25.9643 23.9797 25.7393 24.2604 25.7393Z"
          class="fill"/>
  </svg>

</template>
<script>
export default {
  name: 'OrbitEditIcon',
}
</script>
