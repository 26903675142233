<template>
  <ul class="edit-menu main-part">
      <li>
        <b-dropdown :id="TOOLTIPS.FLOOR_MENU" :toggle-class="isActiveItem(VIEWER_MODES.FLOORPLAN)">
          <template #button-content>
            <!--RoomIcon v-if="controlsState === CONTROLS_STATE.EDIT_ROOM_LABEL"/-->
            <EntryArrowIcon v-if="controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW"/>
            <FloorplanEditIcon v-else/>
          </template>
          <b-dropdown-item
              @click="$bvModal.show(tourEditModalId), cancelProgress()"
          >
            <div class="icon">
              <EditIcon/>
            </div>
            <div class="name">Edit Tour Info</div>
          </b-dropdown-item>
          <b-dropdown-item
              @click.native="enterFloorplan(),setControlsState(CONTROLS_STATE.EDIT_ROOM_LABEL),moveRoom('NEW'), cancelProgress()"
              :active="controlsState === CONTROLS_STATE.EDIT_ROOM_LABEL"
          >
            <div class="icon">
              <RoomIcon/>
            </div>
            <div class="name">Add Room Label</div>
          </b-dropdown-item>
          <b-dropdown-item
              @click.native="newFeature(), cancelProgress()"
              :active="controlsState === CONTROLS_STATE.EDIT_FEATURE"
          >
            <div class="icon">
              <Tag/>
            </div>
            <div class="name">Add Feature Label</div>
          </b-dropdown-item>
          <b-dropdown-item
              @click.native="enterFloorplan(),setControlsState(CONTROLS_STATE.EDIT_ENTRY_ARROW),toggleArrow(), cancelProgress()"
              :active="controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW"
          >
            <div class="icon">
              <EntryArrowIcon/>
            </div>
            <div class="name">Place Entry Arrow</div>
          </b-dropdown-item>
        </b-dropdown>
      </li>
      <li>
        <b-dropdown :id="TOOLTIPS.MEASURE" :toggle-class="isActiveItem('measure')">
          <template #button-content>
            <DistanceIcon v-if="controlsState === CONTROLS_STATE.MEASURE_DISTANCE"/>
            <Area v-else-if="controlsState === CONTROLS_STATE.MEASURE_AREA"/>
            <RulerEditIcon v-else/>
          </template>
          <b-dropdown-item v-if="mode === VIEWER_MODES.FLOORPLAN"
                           @click.native="setControlsState(CONTROLS_STATE.MEASURE_AREA)"
                           :active="controlsState === CONTROLS_STATE.MEASURE_AREA"
          >
            <div class="icon">
              <Area/>
            </div>
            <div class="name">Measure Area</div>
          </b-dropdown-item>
          <b-dropdown-item
              @click.native="setControlsState(CONTROLS_STATE.MEASURE_DISTANCE)"
              :active="controlsState === CONTROLS_STATE.MEASURE_DISTANCE"
          >
            <div class="icon">
              <DistanceIcon/>
            </div>
            <div class="name">Measure Distance</div>
          </b-dropdown-item
          >
        </b-dropdown>
      </li>
    </ul>
</template>
<script>
  import {OrbitEditIcon, PanoEditIcon, FloorplanEditIcon, RulerEditIcon, RoomIcon, EntryArrowIcon, SquareFeetIcon, Area, DistanceIcon, BlurIcon, Tag, TrimIcon, SmoothIcon, EditIcon} from '@/components/svg-icons';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { VIEWER_MODES, FeatureFormModes } from '@/constants';
  
  export default {
    name: 'EditMenu',
    components: {
      SmoothIcon, TrimIcon, Tag, BlurIcon, DistanceIcon, Area, SquareFeetIcon, EntryArrowIcon, RoomIcon, RulerEditIcon, FloorplanEditIcon, PanoEditIcon, OrbitEditIcon, EditIcon},
    data() {
      return {
        rts_checklist: false,
        FeatureFormModes
      }
    },
    computed: {
      ...mapState('viewer', ['viewer']),
      ...mapState('controls', ['mode']),
    },
    methods: {
      ...mapMutations('features', ['set_feature_mode']),
      ...mapActions('controls', ['setControlsState','toggleArrow']),
      ...mapActions('rooms', ['moveRoom']),
      ...mapActions('rts', ['selectStep', 'toggleRTSMode']),
      enterFloorplan() {
        if (this.mode !== VIEWER_MODES.FLOORPLAN) this.viewer.setMovingMode(VIEWER_MODES.FLOORPLAN)
      },
      newFeature() {
      if (this.mode === VIEWER_MODES.FLOORPLAN) this.viewer.setMovingMode(VIEWER_MODES.ORBIT)
      this.viewer.controls.minPanoPitch = Math.PI-0.01;
      this.viewer.controls.maxPanoPitch = 0.01;
      this.setControlsState(this.CONTROLS_STATE.MOVE_FEATURE);
      this.set_feature_mode(FeatureFormModes.NEW)
      },
      isActiveItem(menu) {
        let isActive = menu === 'measure' && (
            this.controlsState === this.CONTROLS_STATE.MEASURE_DISTANCE ||
            this.controlsState === this.CONTROLS_STATE.MEASURE_AREA
        );
        if (isActive) {
          return 'edit-menu-item active';
        }

        if (this.mode === VIEWER_MODES.FLOORPLAN && menu === VIEWER_MODES.FLOORPLAN) {
          isActive = this.controlsState === this.CONTROLS_STATE.EDIT_ROOM_LABEL ||
              this.controlsState === this.CONTROLS_STATE.EDIT_ENTRY_ARROW;
        } else if (this.mode === VIEWER_MODES.PANO && menu === VIEWER_MODES.PANO) {
          isActive = this.controlsState === this.CONTROLS_STATE.BLUR_MODEL ||
              this.controlsState === this.CONTROLS_STATE.EDIT_FEATURE;
        } else if (this.mode === VIEWER_MODES.ORBIT && menu === VIEWER_MODES.ORBIT) {
          isActive = this.controlsState === this.CONTROLS_STATE.BLUR_MODEL ||
              this.controlsState === this.CONTROLS_STATE.TRIM_MODEL ||
              this.controlsState === this.CONTROLS_STATE.SMOOTH_MODEL ||
              this.controlsState === this.CONTROLS_STATE.EDIT_FEATURE;
        }
        return 'edit-menu-item' + (isActive ? ' active' : '');
      },
      cancelProgress() {
        this.toggleRTSMode(false);
        this.selectStep(null)
      }
    }
  }
</script>
