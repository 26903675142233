<template>
  <div class="pano-list-component p-4">
    <h4 class="text-center font-weight-normal">Pano List</h4>
    <ul class="rooms-list list-unstyled">
      <li v-for="room in list.filter(item => item.panos.length > 0)" :key="room.id" class="room">
        <div class="collapse-title" v-b-toggle="room.id">
          {{room.name}}
          <span class="font-weight-semi-bold"
                :class="{'text-secondary': roomIsComplete(room), 'text-primary': !roomIsComplete(room)}">
            ({{approvedPanosLength(room.panos)}}{{room.panos.length}})
          </span>
        </div>
        <b-collapse :id="room.id" accordion="list">
          <ul class="list-unstyled panos-list">
            <li @click="viewer.navigateToShot(pano.id)" v-for="pano in room.panos" :key="pano.id" 
                class="pano"
                :style="{'background-image': panoThumbnail(pano.thumbnailUrl)}"
            >
              <div @click.stop="approvePano({id: pano.id, isTrue: !pano.approved})" :class="{active: pano.approved}" class="approved-checkbox">
                <CheckIcon class="check"/>
              </div>
              <button @click.stop="approvePano({id: pano.id, isTrue: false})" class="btn btn-light rounded-pill mb-2">REPORT</button>
              <button
                  @click.stop="approvePano({id: pano.id, isTrue: !pano.approved})"
                  class="btn rounded-pill"
                  :class="{'btn-primary': pano.approved, 'btn-light': !pano.approved}"
              >
                LOOKS GOOD
              </button>
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
    <!--button @click="approveAll(false)" class="btn btn-light btn-block rounded-pill">CANCEL</button-->
    <button @click="approveAll(true)" class="btn btn-light btn-block rounded-pill">QUICK CONFIRM ALL</button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex/dist/vuex.esm.browser';
import CheckIcon from '@/components/svg-icons/check';

export default {
  name: 'PanoList',
  components: {CheckIcon},
  computed: {
    ...mapState('rooms', ['rooms']),
    ...mapState('panos', ['panos']),
    ...mapState('viewer', ['viewer']),
    list() {
      let rooms = this.rooms.map(room => {
        let panos = [];
        Object.keys(this.panos).map(key => {
          if (this.panos[key].roomId === room.id) {
            panos.push(this.panos[key]);
          }
        })
        return {
          id: room.id,
          panos,
          name: room.name
        }
      })
      return rooms;
    }
  },
  methods: {
    ...mapActions('panos', ['approvePano']),
    approvedPanosLength(panos) {
      let length = panos.filter(p => p.approved).length;
      return length ? length + '/' : '';
    },
    approveAll(state) {
      Object.keys(this.panos).map(key => {
        this.approvePano({id: this.panos[key].id, isTrue: state});
      })
    },
    roomIsComplete(room) {
      return room.panos.length && room.panos.length === room.panos.filter(p => p.approved).length;
    }
  }
}
</script>
<style src="./PanoList.scss" scoped lang="scss"></style>
