<template>
  <button @click="toggle_ui" class="btn btn-secondary btn-block text-light d-flex justify-content-between shadow">
    <span class="mr-3">Hide UI</span>
    <span>"U"</span>
  </button>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  name: 'HideUiBtn',
  methods: {
    ...mapMutations('layout', ['toggle_ui']),
    handleKeyDown(e) {
      if (e.code === 'KeyU' && this.isAdmin() && e.target.nodeName.toLowerCase() !== 'input' && e.target.nodeName.toLowerCase() !== 'textarea') {
        this.toggle_ui();
      }
    }
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>
