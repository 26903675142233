<template>
  <svg width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1.37486 6.31289C2.51509 5.7928 3.64923 5.27271 4.78946 4.75262C6.61261 3.91806 8.44186 3.0835 10.265 2.24894C10.6857 2.05542 11.1004 1.86795 11.5211 1.67443C11.2162 1.67443 10.9053 1.67443 10.6004 1.67443C11.7284 2.19451 12.8565 2.72065 13.9906 3.24074C15.7894 4.0753 17.5881 4.90381 19.393 5.73837C19.8076 5.9319 20.2222 6.11937 20.6308 6.31289C20.6308 5.7928 20.6308 5.26667 20.6308 4.74658C19.4905 5.32109 18.3564 5.90166 17.2162 6.47617C15.4113 7.38935 13.6064 8.30253 11.8016 9.21571C11.3809 9.42738 10.9662 9.63904 10.5455 9.8507C10.8504 9.8507 11.1613 9.8507 11.4662 9.8507C10.326 9.27619 9.19186 8.69562 8.05162 8.12111C6.24676 7.20793 4.4419 6.29475 2.63704 5.38157C2.21631 5.16991 1.80168 4.95824 1.38096 4.74658C0.941936 4.52282 0.387063 4.63167 0.130968 5.07314C-0.106835 5.47833 0.0151152 6.08913 0.460233 6.31289C1.60656 6.89345 2.75289 7.47402 3.89922 8.05458C5.72238 8.97381 7.53943 9.89909 9.36259 10.8183C9.75283 11.0179 10.137 11.2235 10.5333 11.411C10.9174 11.5863 11.2467 11.5259 11.5943 11.3505C11.7955 11.2477 11.9967 11.1449 12.1979 11.0421C13.9235 10.1712 15.6491 9.29433 17.3747 8.42348C18.7405 7.73406 20.1369 7.07488 21.4844 6.34313C21.5088 6.33103 21.5332 6.31894 21.5515 6.30684C22.1308 6.01051 22.1673 5.02476 21.5515 4.74053C20.3991 4.20835 19.2405 3.67011 18.0881 3.13793C16.2833 2.30337 14.4784 1.46881 12.6735 0.634248C12.2955 0.458869 11.9174 0.277443 11.5333 0.108111C11.1979 -0.0370296 10.9296 -0.0370297 10.5882 0.114159C10.5455 0.132302 10.5028 0.150444 10.4662 0.168587C10.2589 0.265348 10.0516 0.356061 9.85039 0.452822C8.1065 1.2511 6.36871 2.04333 4.62483 2.8416C3.26508 3.4645 1.86876 4.04506 0.533403 4.71634C0.509013 4.72843 0.484623 4.74053 0.460233 4.74658C0.00901773 4.95219 -0.100737 5.59323 0.130968 5.98632C0.39316 6.44594 0.923643 6.51851 1.37486 6.31289Z"
        />
    <path
        d="M5.23481 7.16638C3.84458 7.80137 2.44825 8.44241 1.05802 9.0774C0.856804 9.16812 0.655586 9.26488 0.448271 9.35559C-0.167576 9.63983 -0.130991 10.6316 0.448271 10.9219C1.92387 11.6718 3.39946 12.4156 4.88115 13.1655C6.11285 13.7884 7.34454 14.4113 8.57624 15.0342C9.22257 15.3608 9.86281 15.7116 10.5213 16.02C10.9726 16.2316 11.3079 16.1046 11.6982 15.9111C12.0335 15.7418 12.375 15.5664 12.7103 15.3971C14.0579 14.7137 15.4054 14.0303 16.753 13.353C18.3078 12.5668 19.8566 11.7807 21.4115 10.9945C21.4542 10.9703 21.5029 10.9461 21.5456 10.928C22.1249 10.6316 22.1615 9.64587 21.5456 9.36164C20.1615 8.7206 18.7774 8.07956 17.3932 7.43852C17.192 7.34781 16.9908 7.25105 16.7896 7.16033C16.3445 6.95472 15.8018 7.03334 15.5396 7.4869C15.3079 7.87999 15.4176 8.52103 15.8688 8.72665C17.253 9.36769 18.6371 10.0087 20.0212 10.6498C20.2225 10.7405 20.4237 10.8372 20.6249 10.928C20.6249 10.4079 20.6249 9.88173 20.6249 9.36164C19.1615 10.1055 17.692 10.8433 16.2286 11.5871C15.0091 12.204 13.7896 12.8208 12.5701 13.4377C11.8933 13.7824 11.2165 14.1211 10.5396 14.4658C10.8445 14.4658 11.1555 14.4658 11.4604 14.4658C9.98476 13.7219 8.51526 12.972 7.03967 12.2282C5.84456 11.6234 4.65555 11.0187 3.46044 10.42C2.76532 10.0692 2.07021 9.7124 1.36899 9.36164C1.36899 9.88173 1.36899 10.4079 1.36899 10.928C2.75922 10.293 4.15555 9.65192 5.54578 9.01693C5.747 8.92622 5.94822 8.82946 6.15553 8.73874C6.60675 8.53313 6.7165 7.89209 6.4848 7.499C6.21651 7.03938 5.68602 6.96076 5.23481 7.16638Z"
        />
    <path
        d="M15.7203 13.4075C17.1532 14.0727 18.5801 14.7319 20.013 15.3971C20.2142 15.4939 20.4154 15.5846 20.6227 15.6814C20.6227 15.1613 20.6227 14.6352 20.6227 14.1151C19.4825 14.6896 18.3483 15.2701 17.2081 15.8447C15.4033 16.7578 13.5984 17.671 11.7935 18.5842C11.3728 18.7959 10.9582 19.0075 10.5374 19.2192C10.8423 19.2192 11.1533 19.2192 11.4582 19.2192C10.3179 18.6447 9.1838 18.0641 8.04357 17.4896C6.23871 16.5764 4.43385 15.6632 2.62899 14.7501C2.20826 14.5384 1.79363 14.3267 1.3729 14.1151C1.3729 14.6352 1.3729 15.1613 1.3729 15.6814C2.81191 15.0222 4.25092 14.363 5.68993 13.7038C5.89115 13.6131 6.09847 13.5164 6.29968 13.4256C6.7509 13.22 6.86065 12.579 6.62895 12.1859C6.36066 11.7263 5.83017 11.6537 5.37896 11.8593C3.93995 12.5185 2.50094 13.1777 1.06193 13.8369C0.86071 13.9276 0.653395 14.0243 0.452178 14.1151C-0.16367 14.3993 -0.127085 15.3911 0.452178 15.6814C1.59851 16.2619 2.74484 16.8425 3.89117 17.4231C5.71432 18.3423 7.53138 19.2676 9.35453 20.1868C9.74477 20.3864 10.1289 20.592 10.5253 20.7795C10.9094 20.9548 11.2387 20.8944 11.5862 20.719C11.7874 20.6162 11.9887 20.5134 12.1899 20.4106C13.9155 19.5397 15.6411 18.6628 17.3666 17.792C18.7325 17.1025 20.1288 16.4434 21.4764 15.7116C21.5008 15.6995 21.5251 15.6874 21.5434 15.6753C22.1227 15.379 22.1593 14.3932 21.5434 14.109C20.1105 13.4438 18.6837 12.7846 17.2508 12.1194C17.0496 12.0226 16.8484 11.9319 16.641 11.8351C16.1959 11.6295 15.6533 11.7081 15.3911 12.1617C15.1594 12.5548 15.2691 13.1958 15.7203 13.4075Z"
        />
  </svg>
</template>

<script>
export default {
  name: 'LayersIcon',
}
</script>
