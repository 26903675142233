<template>
  <div>
    <div class="feature-labels" v-if="showFeatures && showDependingOnControlsState && mode !== VIEWER_MODES.FLOORPLAN">
      <div class="label"
           :class="{'is-new': feature.isNew}"
           v-for="(feature, index) of features"
           :key="feature.id || index"
           :style="featuresLabelMarkers[index] && isCoordsFitToScreen(featuresLabelMarkers[index]) ? {
             top: featuresLabelMarkers[index].y + 'px',
             left: featuresLabelMarkers[index].x + 'px',
             display: 'block'
           } : {
             display: 'none'
           }"
      >
        <div @click="clickOnFeaturePoint(index)" class="point shadow" :id="'feature-' + index">
          <div class="inner-circle"></div>
        </div>
        <b-popover :show.sync="feature.show" :target="'feature-' + index" triggers="hover click" placement="top">
          <div @click="hideFeaturePopover(index)">
            <p class="mb-2">{{ feature.title }}</p>
            <template v-if="feature.forSale">
              <p class="text-secondary">For sale
                <span v-if="feature.reference === REFERENCES.OWNER">
                by owner <span v-if="feature.owner.price">• <span
                    class="font-weight-semi-bold">${{ feature.owner.price }}</span></span>
              </span>
              </p>
            </template>
            <button
                @click="showFeatureDescription(index)"
                class="small btn btn-link font-weight-semi-bold"
            >
              Learn more <i class="las la-angle-right"></i>
            </button>
            <div v-if="editMode" class="feature-controls my-2">
              <button @click="editFeature(index)" class="btn btn-light btn-control btn-control-sm rounded-0"><EditIcon/></button>
              <button @click="moveFeature(index)" class="btn btn-light btn-control btn-control-sm rounded-0"><MoveIcon/></button>
              <button @click="featureToDelete = feature" class="btn btn-light btn-control btn-control-sm rounded-0"><DeleteIcon/></button>
            </div>
          </div>
        </b-popover>
      </div>
    </div>

    <b-modal
        :id="selectedFeatureModal"
        hide-footer
        hide-header
        dialog-class="feature-details-modal"
        @keypress.esc="$bvModal.hide(selectedFeatureModal)"
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(selectedFeatureModal)"/>
        <FeatureDetails @showOwner="showFeatureOwner()"></FeatureDetails>
      </template>
    </b-modal>

    <b-modal
        :id="selectedOwnerModal"
        hide-footer
        hide-header
        size="sm"
        @hide="onHideSelectedOwner()"
        dialog-class="feature-details-modal"
        @keypress.esc="$bvModal.hide(selectedOwnerModal);"
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(selectedOwnerModal)"/>
        <OwnerDetails :data="selectedOwner"/>
      </template>
    </b-modal>

    <LabelAim @onCancel="setControlsState(CONTROLS_STATE.EDIT_FEATURE),resetPitch(),cancelProgress()" @onCreate="setFeaturePosition($event)" v-if="controlsState === CONTROLS_STATE.MOVE_FEATURE"/>
    <b-modal
        ref="featureFormModal"
        :id="featureFormModalId"
        hide-footer
        hide-header
        @keypress.esc="$bvModal.hide(featureFormModalId);"
        content-class="feature-modal-container"
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(featureFormModalId)"/>
        <p class="modal-title form-title">{{featureMode === FeatureFormModes.NEW ? 'Add' : 'Edit'}} a Feature Label</p>
        <FeatureForm @onCancel="$bvModal.hide(featureFormModalId),hideFeatureModalForm(featureFormModalId)" @onSave="saveFeature({data: $event, modal: $bvModal, modalId: featureFormModalId});"></FeatureForm>
      </template>
    </b-modal>

    <b-modal
        :id="featureListModalId"
        hide-footer
        hide-header
        @keypress.esc="$bvModal.hide(featureListModalId);"
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(featureListModalId)"/>
        <h5 class="text-left font-weight-bold">Tour Features</h5>
        <FeatureList/>
      </template>
    </b-modal>

    <ConfirmDialog
                   :content="featureToDelete ? deleteFeatureText(featureToDelete.title) : ''"
                   :isOpen="!!featureToDelete"
                   @onSubmit="deleteFeature(featureToDelete.id); featureToDelete = null"
                   @onCancel="featureToDelete = null"
    ></ConfirmDialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import LabelAim from '@/components/label-aim/LabelAim';
import FeatureForm from '@/components/features/new-feature/FeatureForm';
import FeatureDetails from '@/components/features/FeatureDetails';
import OwnerDetails from '@/components/owner-details/OwnerDetails';
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog';
import { REFERENCES, FeatureFormModes, MenuPages } from '@/constants';
import FeatureList from './FeatureList';
import { DeleteIcon, MoveIcon, EditIcon } from '@/components/svg-icons';

export default {
  name: 'Features',
  data() {
    return {
      featureFormModalId: 'new-feature-modal',
      selectedFeatureModal: 'selected-feature-modal',
      selectedOwnerModal: 'selected-owner-modal',
      REFERENCES: REFERENCES,
      featureToDelete: null,
      FeatureFormModes: FeatureFormModes
    }
  },
  components: {EditIcon, MoveIcon, DeleteIcon, LabelAim, FeatureForm, FeatureDetails, OwnerDetails, ConfirmDialog, FeatureList},
  computed: {
    ...mapState('controls', ['showFeatures', 'mode']),
    ...mapState('features', ['features', 'featureMode', 'selectedFeature', 'selectedOwner', 'featuresLabelMarkers']),
    ...mapState('viewer', ['viewer'])
  },
  methods: {
    ...mapActions('controls', ['setControlsState']),
    ...mapActions('rts', ['selectStep','updateProgress','toggleRTSMode']),
    ...mapMutations('features', ['delete_feature', 'delete_new_feature', 'set_feature_mode']),
    ...mapMutations('mobile', ['set_menu_page']),
    ...mapActions('features', ['createFeature', 'saveFeature', 'deleteFeature', 'selectFeature']),
    ...mapActions('mobile', ['toggleMobileMenu']),
    setFeaturePosition(e) {
      this.resetPitch()
      let position = this.viewer.getMouseIntersection(e.clientX, e.clientY);
      if (position) {
        let {x, y, z} = position;
        if (this.featureMode === FeatureFormModes.MOVE) {
          this.saveFeature({
            data: {
              position: [x, y, z],
              id: this.selectedFeature.id
            }
          });
        } else {
          this.createFeature({position: [x, y, z]});
          this.set_feature_mode(FeatureFormModes.NEW);
          if (!this.isMobile) {
            this.$bvModal.show(this.featureFormModalId);
          } else {
             this.toggleMobileMenu(true);
             this.set_menu_page(MenuPages.FEATURE_FORM);
          }
        }

        this.setControlsState(this.CONTROLS_STATE.EDIT_FEATURE);
      }
    },
    resetPitch() {
      this.viewer.setLookAroundSpeed()
      this.viewer.controls.minPanoPitch = this.viewer.controls._minPanoPitch
      this.viewer.controls.maxPanoPitch = this.viewer.controls._maxPanoPitch
    },
    newFeature() {
      this.set_feature_mode(FeatureFormModes.NEW);
      this.setControlsState(this.CONTROLS_STATE.MOVE_FEATURE);
    },
    moveFeature(index) {
      this.viewer.controls.minPanoPitch = Math.PI-0.01;
      this.viewer.controls.maxPanoPitch = 0.01;
      this.set_feature_mode(FeatureFormModes.MOVE);
      this.selectFeature({...this.features[index]});
      this.setControlsState(this.CONTROLS_STATE.MOVE_FEATURE);
    },
    editFeature(index) {
      this.set_feature_mode(FeatureFormModes.EDIT);
      this.selectFeature({...this.features[index]});
      if (!this.isMobile) {
        this.$bvModal.show(this.featureFormModalId);
      } else {
        this.toggleMobileMenu(true);
        this.set_menu_page(MenuPages.FEATURE_FORM);
      }
    },
    hideFeatureModalForm() {
      if (this.featureMode === FeatureFormModes.NEW) {
       this.delete_new_feature()
      }
    },
    showFeatureDescription(index) {
      this.selectFeature({...this.features[index]});
      if (!this.isMobile) {
        this.$bvModal.show(this.selectedFeatureModal);
      } else {
        this.toggleMobileMenu(true);
        this.set_menu_page(MenuPages.FEATURE_DETAILS);
      }
    },
    showFeatureOwner() {
      this.$bvModal.hide(this.selectedFeatureModal);
      this.$bvModal.show(this.selectedOwnerModal);
    },
    onHideSelectedOwner() {
      this.$bvModal.hide(this.selectedOwnerModal);
      this.$bvModal.show(this.selectedFeatureModal);
    },
    hideFeaturePopover(index) {
      this.features[index].show = false;
    },
    clickOnFeaturePoint(index) {
      this.features.map((f, i) => {
        if (f.show && i !== index) {
          f.show = false;
        }
      })
    },
    deleteFeatureText() {
      return `Are you sure you want to delete this feature label?`;
    },
    cancelProgress() {
      this.toggleRTSMode(false);
      this.selectStep(null)
    },
  }
}
</script>
<style scoped lang="scss" src="./Features.scss"></style>
