import firebase from 'firebase/app';
import 'firebase/functions';

import config from '../config';
import {UserRoles} from '@/constants';

export const roles = async function (token, tour_id) {
    let name = await getRoles(token, tour_id);
    return {name, permissions: config.permissions[name]};
}

async function getRoles(token, tour_id) {
  try {
    const verifyToken = firebase.functions().httpsCallable('helpers-verifyToken'); //update function name
    let verified = await verifyToken({"token": token});
    let user_id = verified.data.user_id;
    const getRoles = firebase.functions().httpsCallable('helpers-getRoles'); //update function name
    let rolesList = await getRoles({
      "access_id": user_id,
      "object_id": `tours/${tour_id}`
    })
    return rolesList.data[0].name || UserRoles.VISITOR;
  } catch {
    return UserRoles.VISITOR;
  }
}
