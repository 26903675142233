<template>
  <svg width="137" height="72" viewBox="0 0 137 72" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.17404 36.1391L68.1387 1.97727L134.103 36.1391L68.1387 70.301L2.17404 36.1391Z"
          stroke-width="2" stroke-linecap="round"/>
  </svg>

</template>

<script>
export default {
  name: 'LayerIcon',
}
</script>
