<template>
  <div v-if="errors.length" class="invalid-feedback">
    <span class="error-badge">
      <i class="las la-times"></i>
    </span>
    {{ errors[0] }}
  </div>
</template>
<script>
export default {
  name: 'InvalidFeedback',
  props: ['errors']
}
</script>
