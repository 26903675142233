import { VIEWER_MODES } from '@/constants';

import floorplan_move_around from './data/floorplan_move_around.json';
import floorplan_zoom from './data/floorplan_zoom.json';
import floorplan_into_pano from './data/floorplan_into_pano.json';
import pano_rotate from './data/pano_rotate.json';
import pano_zoom from './data/pano_zoom.json';
import pano_into_pano from './data/pano_into_pano.json';
import orbit_pan from './data/orbit_pan.json';
import orbit_zoom from './data/orbit_zoom.json';
import orbit_rotate from './data/orbit_rotate.json';
import orbit_into_pano from './data/orbit_into_pano.json';
import mobile_floorplan_into_pano from './data/mobile_floorplan_into_pano.json';
import mobile_floorplan_zoom from './data/mobile_floorplan_zoom.json';
import mobile_orbit_into_pano from './data/mobile_orbit_into_pano.json';
import mobile_orbit_pan from './data/mobile_orbit_pan.json';
import mobile_orbit_rotate from './data/mobile_orbit_rotate.json';
import mobile_orbit_zoom from './data/mobile_orbit_zoom.json';
import mobile_pano_into_pano from './data/mobile_pano_into_pano.json';
import mobile_pano_rotate from './data/mobile_pano_rotate.json';
import mobile_pano_zoom from './data/mobile_pano_zoom.json';

export default {
  desktop: {
    [VIEWER_MODES.FLOORPLAN]: [
      {
        data: floorplan_move_around,
        name: 'floorplan_move_around',
        title: {
          main: 'Left or right click',
          secondary: 'to move around'
        }
      }, {
        data: floorplan_zoom,
        name: 'floorplan_zoom',
        title: {
          main: 'Scroll',
          secondary: 'wheel to zoom'
        }
      }, {
        data: floorplan_into_pano,
        name: 'floorplan_into_pano',
        title: {
          main: 'Tap',
          secondary: 'to the room to see inside'
        }
      }
    ],
    [VIEWER_MODES.ORBIT]: [
      {
        data: orbit_into_pano,
        name: 'orbit_into_pano',
        title: {
          main: 'Left click',
          secondary: 'in the model to move to pano mode'
        }
      },
      {
        data: orbit_zoom,
        name: 'orbit_zoom',
        title: {
          main: 'Scroll',
          secondary: 'wheel to zoom'
        }
      },
      {
        data: orbit_rotate,
        name: 'orbit_rotate',
        title: {
          main: 'Left click and drag',
          secondary: 'to rotate the view'
        }
      },
      {
        data: orbit_pan,
        name: 'orbit_pan',
        title: {
          main: 'Right click and drag',
          secondary: 'to pan the view'
        }
      }
    ],
    [VIEWER_MODES.PANO]: [
      {
        data: pano_rotate,
        name: 'pano_rotate',
        title: {
          main: 'Left click and drag',
          secondary: 'to rotate the view'
        }
      }, {
        data: pano_zoom,
        name: 'pano_zoom',
        title: {
          main: 'Scroll',
          secondary: 'wheel to zoom'
        }
      }, {
        data: pano_into_pano,
        name: 'pano_into_pano',
        title: {
          main: 'Left click',
          secondary: 'to move to another pano'
        }
      }
    ]
  },
  mobile: {
    [VIEWER_MODES.FLOORPLAN]: [
      {
        data: mobile_floorplan_zoom,
        name: 'mobile_floorplan_zoom',
        title: {
          main: 'Pinch',
          secondary: 'two fingers to zoom'
        }
      }, {
        data: mobile_floorplan_into_pano,
        name: 'mobile_floorplan_into_pano',
        title: {
          main: 'Tap',
          secondary: 'to the room to see inside'
        }
      }
    ],
    [VIEWER_MODES.ORBIT]: [
      {
        data: mobile_orbit_into_pano,
        name: 'mobile_orbit_into_pano',
        title: {
          main: 'Tap',
          secondary: 'to the room to see inside'
        }
      },
      {
        data: mobile_orbit_rotate,
        name: 'mobile_orbit_rotate',
        title: {
          main: 'Rotate',
          secondary: 'with one finger'
        }
      },
      {
        data: mobile_orbit_zoom,
        name: 'mobile_orbit_zoom',
        title: {
          main: 'Pinch',
          secondary: 'two fingers to zoom'
        }
      },
      {
        data: mobile_orbit_pan,
        name: 'mobile_orbit_pan',
        title: {
          main: 'Click and drag',
          secondary: 'to pan the view'
        }
      }
    ],
    [VIEWER_MODES.PANO]: [
      {
        data: mobile_pano_rotate,
        name: 'mobile_pano_rotate',
        title: {
          main: 'Rotate',
          secondary: 'with one finger'
        }
      }, {
        data: mobile_pano_zoom,
        name: 'mobile_pano_zoom',
        title: {
          main: 'Pinch',
          secondary: 'two fingers to zoom'
        }
      }, {
        data: mobile_pano_into_pano,
        name: 'mobile_pano_into_pano',
        title: {
          main: 'Click',
          secondary: 'to move to the next location'
        }
      }
    ]
  }
}
