<template>
  <div class="minimap" ref="minimap">
    <arrow-icon class="arrowIcon"/>
  </div>
</template>

<script>
  import ArrowIcon from '../../components/arrow-icon/ArrowIcon';

  export default {
    name: 'Minimap',
    components: {ArrowIcon}
  }
</script>

<style lang="scss" src="./Minimap.scss" scoped></style>
