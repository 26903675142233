<template>
  <b-button 
      :variant="confirmButtonActive ? 'secondary' : (variant || 'primary')" 
      class="btn-form" 
      :disabled="disabled || confirmButtonActive"
  >
    {{ confirmButtonActive ? 'SAVING...' : (text || 'SAVE') }}
  </b-button>
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ConfirmButton',
    props: ['variant', 'text', 'disabled'],
    computed: {
      ...mapState('layout', ['confirmButtonActive'])
    }
  }
</script>