import { AppStates } from '@/constants';

export default {
  namespaced: true,
  state: () => ({
    appState: AppStates.init,
    allFloorsProcessed: false,
    visited: localStorage.getItem('visited'),
    toursVisited: localStorage.getItem('tours') || "",
    isMobileScreen: window.innerWidth < 768,
    isError: false,
    hiddenUI: false,
    confirmButtonActive: false
  }),
  mutations: {
    finished_proccess_floor(state) {
      state.allFloorsProcessed = true;
    },
    toggle_ui(state) {
      state.hiddenUI = !state.hiddenUI;
    },
    set_error(state, value) {
      state.isError = value;
    },
    set_screen(state) {
      state.isMobileScreen = window.innerWidth < 768;
    },
    toogle_app_state(state, payload) {
      state.appState = payload;
    },
    toggle_confirm_button(state, payload) {
      state.confirmButtonActive = payload;
    }
  },
  actions: {
    visit() {
      localStorage.setItem('visited', true);
    },
  }
}
