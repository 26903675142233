<template>
  <svg width="65" height="53" viewBox="0 0 65 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60.606 23.1611L37.6348 36.0717V47.2253L60.606 34.3148V23.1611Z" class="fill-secondary"/>
    <path
        d="M44.5094 38.7746C44.5094 39.4223 44.001 40.0928 42.9844 40.6625L41.0273 41.7816V38.0335L42.8751 36.9748C43.8206 36.4354 44.306 36.5463 44.306 37.1311C44.2783 37.5505 44.0976 37.9456 43.7977 38.2427C44.2374 38.1368 44.5094 38.3133 44.5094 38.7746ZM41.9093 38.1898V39.0695L42.7811 38.5653C43.2081 38.3133 43.4393 38.036 43.4393 37.7436C43.4393 37.4512 43.2081 37.4361 42.7811 37.6806L41.9093 38.1898ZM43.6274 39.198C43.6274 38.8829 43.3733 38.8729 42.931 39.1325L41.9144 39.7173V40.6423L42.931 40.0575C43.386 39.7853 43.6274 39.508 43.6274 39.1854V39.198Z"
        class="fill-secondary"/>
    <path
        d="M48.7471 36.673V37.3687L45.8242 39.0449V35.2892L48.681 33.6533V34.3515L46.6935 35.4883V36.3025L48.4472 35.2942V35.9698L46.6935 36.978V37.8451L48.7471 36.673Z"
        class="fill-secondary"/>
    <path
        d="M50.8739 33.1095L49.6641 33.8002V33.0944L52.9682 31.209V31.9148L51.7584 32.6079V35.6478L50.8815 36.1519L50.8739 33.1095Z"
        class="fill-secondary"/>
    <path
        d="M56.379 32.1993L54.6253 33.2076L54.2898 34.2032L53.3926 34.7073L55.0777 29.9963L55.9418 29.4922L57.632 32.2648L56.7145 32.7891L56.379 32.1993ZM56.1045 31.6952L55.5047 30.6038L54.9048 32.3682L56.1045 31.6952Z"
        class="fill-secondary"/>
    <path d="M60.8953 15.3706L33.9668 0V17.8811L45.2313 24.3112L60.8953 15.3706Z" class="fill-secondary"/>
    <path d="M19.7676 24.3112L31.0321 17.8811V0L4.10352 15.3706L19.7676 24.3112Z" class="fill-secondary"/>
    <path d="M31.0324 52.3498L0.837891 35.114V17.2354L31.0324 34.4687V52.3498Z" class="fill-secondary"/>
    <path d="M64.1614 17.2354L33.9668 34.4687V52.3498L64.1614 35.114V17.2354Z" class="fill-secondary"/>
    <path
        d="M43.1896 39.6605C43.1896 40.525 42.4983 41.4249 41.1741 42.1811L38.5664 43.6733V38.6724L41.0292 37.2634C42.3001 36.5399 42.938 36.6912 42.938 37.4701C42.9032 38.0285 42.6647 38.5553 42.267 38.9522C42.8287 38.8161 43.1896 39.0328 43.1896 39.6605ZM39.7254 38.8791V40.0587L40.8844 39.3933C41.4537 39.0681 41.7587 38.69 41.7587 38.3119C41.7587 37.9338 41.4486 37.9036 40.8793 38.2288L39.7254 38.8791ZM42.0129 40.2099C42.0129 39.789 41.6901 39.7739 41.0852 40.1217L39.7254 40.9006V42.1357L41.0877 41.3568C41.6926 41.009 42.0129 40.6384 42.0129 40.2099Z"
        class="fill-secondary"/>
    <path
        d="M48.8389 36.8617V37.7893L44.9375 40.025V35.0368L48.7499 32.8564V33.7765L46.1016 35.2888V36.3727L48.4399 35.0342V35.9341L46.1016 37.2725V38.4295L48.8389 36.8617Z"
        class="fill-secondary"/>
    <path
        d="M51.6686 32.1106L50.0547 33.0331V32.0904L54.4466 29.5698V30.5125L52.8352 31.4351V35.4907L51.6686 36.1662V32.1106Z"
        class="fill-secondary"/>
    <path
        d="M59.0114 30.8956L56.6731 32.2341L56.2258 33.5599L55.0312 34.2455L57.278 27.9617L58.4294 27.3013L60.6813 31.004L59.4588 31.7048L59.0114 30.8956ZM58.6454 30.2277L57.8448 28.7708L57.0468 31.1427L58.6454 30.2277Z"
        class="fill-secondary"/>
  </svg>

</template>
<script>
export default {
  name: 'LogoIcon',
}
</script>
