export default {
  namespaced: true,
  state: () => ({
    magnifier: {
      x: 0,
      y: 0,
      classList: {}
    }
  }),
  mutations: {
    update_magnifier(state, data) {
      state.magnifier = {...state.magnifier, ...data}
    },
  },
}
