<template>
  <div>
    <div v-show="step === STEPS.FILL_FORM" class="new-feature-form borderless-form">
      <ValidationObserver v-slot="{ invalid }">
        <form>
          <div class="form-group">
            <label for="label_title">Label title*</label>
            <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
              <input v-model="title" name="title" type="text" class="form-control" placeholder="Title" id="label_title">
              <InvalidFeedback :errors="errors"/>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label for="label_room">Room</label>
            <select v-model="room" class="form-control" id="label_room">
              <option v-for="r in rooms" :key="r.id" :value="r.id">
                {{r.name}}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Is this item for sale?</label>
            <div class="checkbox-radio">
              <input type="radio" :checked="forSale === true" value="true" @change="checkForSale($event)" name="forSale"
                     id="forSale_true">
              <label for="forSale_true">Yes</label>
            </div>
            <b-collapse id="forSale_sub" v-model="forSale_true" class="ml-3">
              <div class="checkbox-radio">
                <input v-model="reference" type="radio" value="owner" name="reference" id="by_owner">
                <label for="by_owner">By owner</label>
              </div>
              <b-collapse id="reference_false_sub" v-model="reference_owner">
                <div class="form-group">
                  <ValidationProvider name="Name" :rules="reference === 'owner' ? 'required|alpha_spaces' : null"
                                      v-slot="{ errors }">
                    <input v-model="owner.name" name="owner_name" type="text" placeholder="Name*"
                           class="form-control" id="owner_name">
                    <InvalidFeedback :errors="errors"/>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <input v-model="owner.job" name="owner_job" type="text" placeholder="Job Title"
                         class="form-control" id="owner_job">
                </div>
                <div class="form-group">
                  <ValidationProvider name="Phone"
                                      :rules="emailOrPhoneRule('phone')"
                                      v-slot="{ errors }">
                  <input v-mask="'(###) ###-####'" v-model="owner.phone" name="owner_phone" type="text"
                         placeholder="Phone number**"
                         class="form-control" id="owner_phone">
                    <InvalidFeedback :errors="errors"/>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider name="E-mail"
                                      :rules="emailOrPhoneRule('email')"
                                      v-slot="{ errors }">
                  <input v-model="owner.email" name="owner_email" type="text"
                         placeholder="Email**"
                         class="form-control" id="owner_email">
                    <InvalidFeedback :errors="errors"/>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider name="Price" :rules="reference === 'owner' ? 'required|numeric' : null"
                                      v-slot="{ errors }">
                    <input v-model="owner.price" name="owner_price" type="text" placeholder="Item price*"
                           class="form-control" id="owner_price">
                    <InvalidFeedback :errors="errors"/>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <input v-model="owner.condition" name="owner_condition" type="text" placeholder="Item condition"
                         class="form-control" id="owner_condition">
                </div>
              </b-collapse>
              <div class="checkbox-radio">
                <input v-model="reference" type="radio" value="listing" name="reference" id="by_listing">
                <label for="by_listing">Link to product listing</label>
              </div>
              <b-collapse id="reference_false_sub" v-model="reference_listing">
                <div class="form-group">
                  <ValidationProvider name="Listing Url" :rules="reference === 'listing' ? 'required|url' : null" v-slot="{ errors }">
                    <input v-model="listing_url" name="listing_url" type="text" placeholder="Paste product link here*"
                           class="form-control" id="listing_url">
                    <InvalidFeedback :errors="errors"/>
                  </ValidationProvider>
                </div>
              </b-collapse>
            </b-collapse>
            <div class="checkbox-radio">
              <input type="radio" :checked="forSale === false" value="false" @change="checkForSale($event)"
                     name="forSale" id="forSale_false">
              <label for="forSale_false">No</label>
            </div>
          </div>
          <div class="form-group">
            <label for="label_description">Item description</label>
            <textarea v-model="description" name="description" rows="5" class="form-control"
                      id="label_description"></textarea>
          </div>
          <div class="form-group">
            <label for="label_url">Link to additional item information (optional)</label>
            <input v-model="url" name="url" type="text" class="form-control" id="label_url" @change="prependHttp($event)">
          </div>
          <div class="form-group">
            <label>Attach media (optional)</label>
            <div class="images">
              <div v-for="index in 4" :key="index" class="image">
                <div v-if="media[index - 1]" class="image-container">
                  <img alt="image" :src="media[index - 1]"/>
                  <div class="btn-toolbar image-actions">
                    <button @click.prevent="selectImage(index - 1)" class="btn btn-sm rounded-circle btn-info mr-1">
                      <EditIcon/>
                    </button>
                    <button @click.prevent="deleteImage(index - 1)" class="btn btn-sm rounded-circle btn-primary">
                      <DeleteIcon/>
                    </button>
                  </div>
                </div>
                <ImageThumb v-else @click.native="selectImage(index - 1)"/>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <b-button class="btn-form mr-2" type="button" variant="light" size="sm" @click="$emit('onCancel')">CANCEL
            </b-button>
            <ConfirmButton 
                :text="featureMode === FeatureFormModes.NEW ? 'ADD FEATURE' : 'SAVE'" 
                :disabled="invalid"
                @click.native="saveFeature()"
            />
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-show="step === STEPS.SELECT_IMAGE" class="feature-select-image">
      <p class="modal-title form-title">Attach Media</p>
      <div class="image-resource form-group">
        <div class="local">
          <MediaIcon class="mb-4"/>
          <p class="h5 mb-4 text-secondary text-center">
            From device or computer
          </p>
          <div class="checkbox-radio">
            <input type="radio" v-model="image_resource" value="local"
                   name="image_resource" id="local_resource">
            <label for="local_resource"></label>
          </div>
        </div>
        <div class="vertical-divider"></div>
        <div class="tour">
          <LogoIcon class="mb-4"/>
          <p class="h5 mb-4 text-secondary text-center">
            From your 3D tour
          </p>
          <div class="checkbox-radio">
            <input type="radio" v-model="image_resource" value="tour"
                   name="image_resource" id="tour_resource">
            <label for="tour_resource"></label>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click="$refs.image.click()" class="btn btn-primary btn-form">
          NEXT
        </button>
      </div>
      <input type="file" accept="image/jpeg,image/png,image/gif" class="input-image" @change="addImage($event)" ref="image">
    </div>
  </div>
</template>
<script>

import { REFERENCES, FeatureFormModes } from '@/constants';
import ImageThumb from '@/components/image-thumb/ImageThumb';
import {LogoIcon, MediaIcon, EditIcon, DeleteIcon} from '@/components/svg-icons';
import { mapMutations, mapState } from 'vuex';
import ConfirmButton from '@/components/confirm-button/ConfirmButton';

const STEPS = {
  FILL_FORM: 'fill-form',
  SELECT_IMAGE: 'select-image'
}

Object.freeze(STEPS);

export default {
  name: 'FeatureForm',
  components: {ConfirmButton, ImageThumb, LogoIcon, MediaIcon, EditIcon, DeleteIcon},
  data() {
    return {
      STEPS: STEPS,
      step: STEPS.FILL_FORM,
      image_resource: 'local',
      editedImageIndex: 0,
      //
      title: '',
      room: '',
      forSale: false,
      description: '',
      url: '',
      reference: '',
      listing_url: '',
      owner: {},
      media: [],
      forSale_true: false,
      reference_owner: false,
      reference_listing: false,
      FeatureFormModes: FeatureFormModes
    }
  },
  mounted() {
    this.checkSelectedFeature();
  },
  watch: {
    selectedFeature() {
      this.checkSelectedFeature();
    },
    forSale(value) {
      this.forSale_true = value;
      if (!this.forSale_true) {
        this.reference_owner = false;
        this.reference_listing = false;
        this.reference = '';
      } else {
        this.reference = this.selectedFeature?.reference || 'owner';
      }
    },
    reference(value) {
      if (value === 'owner') {
        this.reference_owner = true;
        this.reference_listing = false;
      } else if (value === 'listing') {
        this.reference_owner = false;
        this.reference_listing = true;
      }
    }
  },
  computed: {
    ...mapState('features', ['featureMode', 'selectedFeature']),
    ...mapState('viewer', ['viewer']),
    ...mapState('rooms', ['rooms']),
    ...mapState('panos', ['panos']),
  },
  methods: {
    ...mapMutations('features', ['set_prev_media']),

    emailOrPhoneRule(prop) {
      if (this.reference !== 'owner') {
        return null;
      }

      let res = {};

      if (this.emailOrPhoneCheck(prop)) {
        res.emailOrPhone = true;
      }
      if (prop === 'phone') {
        res.regex = this.phone_regex
      }
      if (prop === 'email') {
        res.email = true
      }
      return res;
    },

    emailOrPhoneCheck(prop) {
      if (prop === 'phone') {
        if (!this.owner.email) {
          return true;
        }
      }
      if (prop === 'email') {
        if (!this.owner.phone) {
          return true;
        }
      }
    },

    checkSelectedFeature() {
      if (this.featureMode === FeatureFormModes.EDIT && this.selectedFeature) {
        this.title = this.selectedFeature.title;
        this.forSale = this.selectedFeature.forSale;
        this.media = this.selectedFeature.media || [];
        this.description = this.selectedFeature.description;
        this.url = this.selectedFeature.url;
        this.reference = this.selectedFeature.reference;
        this.listing_url = this.selectedFeature.listing_url;
        this.owner = {...this.selectedFeature.owner};
        this.room = this.selectedFeature.room;

        this.set_prev_media(this.media);
      } else if (this.featureMode === FeatureFormModes.NEW && this.viewer.getPano) {
        if (this.mode === this.VIEWER_MODES.PANO) {
          let pano_id = this.viewer.getPano();
          this.room = this.panos[pano_id].roomId;
        } else {
          this.room = this.rooms[0].id;
        }
      }
    },
    addImage(e) {
      let file = e.target.files[0];
      let reader = new FileReader();

      reader.addEventListener('load', (event) => {
        this.step = STEPS.FILL_FORM;
        let media = [...this.media];
        media[this.editedImageIndex] = event.target.result;
        this.media = [...media];
        this.$refs.image.value = null;
      });

      reader.readAsDataURL(file);
    },
    deleteImage(index) {
      this.media.splice(index, 1, null);
    },
    selectImage(index) {
      this.editedImageIndex = index;
      this.step = STEPS.SELECT_IMAGE
    },
    checkForSale(e) {
      this.forSale = e.target.value === 'true';
    },
    prependHttp(moreInfoLink){
      if (!/^https?:\/\//i.test(moreInfoLink.target.value)) {
        moreInfoLink.target.value = 'http://' + moreInfoLink.target.value;
      }
    },
    saveFeature() {
      let data = {
        title: this.title,
        forSale: this.forSale,
        description: this.description,
        url: this.url,
        media: this.media.filter(img => img),
        room: this.room || this.rooms[0].id
      }

      if (this.featureMode === FeatureFormModes.EDIT) {
        data.id = this.selectedFeature.id;
      }

      if (this.forSale) {
        data.reference = this.reference;

        if (this.reference === REFERENCES.OWNER) {
          data.owner = {...this.owner};
          if (data.owner.phone) {
            data.owner.phone = data.owner.phone.replace(/\D/g, '');
          }
        } else if (this.reference === REFERENCES.LISTING) {
          data.listing_url = this.listing_url;
          if (!/^https?:\/\//.test(data.listing_url)) {
            data.listing_url = 'https://' + data.listing_url;
          }
        }
      }
      console.log('data', data)
      this.$emit('onSave', data);
    }
  }
}
</script>
<style src="./FeatureForm.scss" scoped lang="scss"></style>
