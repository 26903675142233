<template>
  <b-modal
      v-model="isOpenData"
      @keypress.esc="isOpenData = false"
      size="sm"
      hide-header
      hide-footer
      body-class="confirm-modal"
      :id="confirmDialogID"
  >
    <template #default="{}">
      <CloseIcon @click.native="$emit('onCancel')"/>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <h6 class="mb-4 font-weight-semi-bold text-center" v-html="content"></h6>
        <div class="btn-toolbar">
          <b-button class="btn-form shadow text-primary mr-3" type="button" variant="light" @click="$emit('onCancel')">NO</b-button>
          <ConfirmButton
              text="YES"
              @click.native="$emit('onSubmit')"
              class="shadow"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ConfirmButton from '@/components/confirm-button/ConfirmButton';
export default {
  name: 'ConfirmDialog',
  components: {ConfirmButton},
  data() {
    return {
      isOpenData: false,
      confirmDialogID: 'confirmDialogID'
    }
  },
  props: {
    content: {type: String, default: ''},
    isOpen: {type: Boolean, default: false},
  },
  watch: {
    isOpen(state) {
      this.isOpenData = state;
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === this.confirmDialogID === this.isOpenData) {
        this.$emit('onCancel');
      }
    })
  }
}
</script>
<style>
 .modal-body.confirm-modal {
   padding: 3rem;
 }
</style>
