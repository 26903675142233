
export const postMessagesMixin = {
  methods: {
    loadedMessage() {
      window.parent.postMessage({type: 'loadedResponse', loaded: true}, '*')
    },
    fullscreenMessage(status) {
      window.parent.postMessage({type: 'fullscreen', status}, '*')
    }
  },
  created() {
    window.addEventListener("message", (evt) => {
      let allowedOrigin

      if (process.env.NODE_ENV === "development") {
        //use wildcard
        allowedOrigin = "wildcard"
      } else {
        allowedOrigin = "domain"
      }

      // console.log('allowed origin', allowedOrigin)

      const type = evt.data.type
      if (!this.viewer.isLoaded) return

      const switchMode = mode => {
        switch(mode) {
          case 'floorplan':
            this.viewer.setMovingMode('floorplan')
            break;
          case 'orbit':
            this.viewer.setMovingMode('orbit')
            break;
          case 'pano':
            this.viewer.setMovingMode('walk')
            break;
          default:
            console.log('invalid mode')
        }
      }

      const goToRoom = pos => {
        if (this.viewer.currentMode === 'orbit') {
          this.viewer.AnimationController.moveCameraToRoomPosition(pos.x, pos.y, pos.z)
        } else {
          this.viewer.setMovingMode('orbit')
          setTimeout(() => {
            this.viewer.AnimationController.moveCameraToRoomPosition(pos.x, pos.y, pos.z)
          }, 1000)
        }
      }

      const goToPano = id => {
        if (this.viewer.camera_ids.find(_id => _id === id)) this.viewer.setMovingMode('walk', {shot: id})
        else console.log('invalid camera id')
      }

      const getPanoData = () => {
        const data = this.viewer.getPanoDataDebug()
        return data
      }

      switch(type) {
        case 'report' : {
          const report = this.viewer.gatherReport()
          window.parent.postMessage({type: 'reportResponse', report}, '*')
        }
          break;
        case 'switchMode':
          if (evt.data.mode) switchMode(evt.data.mode)
          else console.log('must provide a valid mode')
          break;

        case 'goToRoom':
          if (evt.data.position) goToRoom(evt.data.position)
          else console.log('must provide a valid position')
          break;

        case 'goToPano':
          if (evt.data.id) goToPano(evt.data.id)
          else console.log('no camera id')
          break;

        case 'getPanoData':
          console.log(getPanoData())
          break;
          
        default:
      }
    }, false);
  }
}