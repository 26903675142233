import { FeatureFormModes, MenuPages } from '@/constants';

export default {
  namespaced: true,
  state: () => ({
    mobileMenuOpen: false,
    menuPage: MenuPages.MAIN
  }),
  mutations: {
    toggle_mobile_menu(state, payload) {
      state.mobileMenuOpen = payload;
    },
    set_menu_page(state, page) {
      state.menuPage = page;
    }
  },
  actions: {
    toggleMobileMenu({commit, state, rootState}, payload) {
      if (payload === false && state.menuPage === MenuPages.FEATURE_FORM && rootState.features.featureMode === FeatureFormModes.NEW) {
        commit('features/delete_feature', null, {root: true});
      }
      if(payload === undefined) payload = false
      if (state.menuPage !== MenuPages.MAIN && !payload) {
        setTimeout(() => {
          commit('set_menu_page', MenuPages.MAIN);
        }, payload ? 0 : 200);
      }
      setTimeout(() => {
        commit('toggle_mobile_menu', payload);
      }, payload ? 200 : 0);
    }
  }
}
