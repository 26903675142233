import * as THREE from 'three';

/*
     * Shaders for displaying panorama on scene mesh. Based on Pannellum.
     */

const vertexShader = /* glsl */ `
// switch on high precision floats
#ifdef GL_ES
precision highp float;
#endif

#include <clipping_planes_pars_vertex>

out vec4 vRstq1;
out vec4 vRstq2;
uniform mat4 projectorMat1;
uniform mat4 projectorMat2;

out vec2 vUv;
//out vec3 vNormal;
out vec3 vPosition;
in float alpha;
uniform float renderAlpha;


void main()
{
  #include <begin_vertex>
  vUv = uv;
  //vNormal = normal;
  vPosition = vec4(viewMatrix * vec4( 0.0, 0.0, 0.9, 0.0 )).rgb;
    vRstq1 = projectorMat1 * vec4(position, 1.0);
    vRstq2 = projectorMat2 * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);

    #include <project_vertex>
    #include <clipping_planes_vertex>
}`;

const fragmentShader = /* glsl */ `
#ifdef GL_ES
precision highp float;
#endif

#define tau 6.28318530718

#include <clipping_planes_pars_fragment>

in vec4 vRstq1;
in vec4 vRstq2;
uniform sampler2D projectorTex1;
uniform sampler2D projectorTex2;
uniform float ratio;
uniform float texRatio;
uniform float pitch_offset1;
uniform float yaw_offset1;
uniform float pitch_offset2;
uniform float yaw_offset2;
uniform float focal_len;
uniform float aspect_ratio;
uniform float opacity;

in vec2 vUv;
//in vec3 vNormal;
in vec3 vPosition;
uniform sampler2D uTexture;

void main()
{
  #include <clipping_planes_fragment>
  
  vec3 b1 = normalize(vRstq1.xyz);
  float lat1 = -asin(b1.y);
  float lon1 = atan(b1.x, b1.z);

  lon1 -= yaw_offset1;
  float sin_lat1 = sin(lat1);
  float cos_lat1 = cos(lat1);
  float sin_lon1 = sin(lon1);
  float cos_lon1 = cos(lon1);
  float sin_pitch_offset1 = sin(pitch_offset1);
  float cos_pitch_offset1 = cos(pitch_offset1);
  float x1 = sin_lon1 * cos_lat1 / (sin_lat1 * sin_pitch_offset1
      + cos_lat1 * cos_lon1 * cos_pitch_offset1);
  float y1 = (sin_lat1 * cos_pitch_offset1 - cos_lat1 * cos_lon1
      * sin_pitch_offset1) / (sin_lat1 * sin_pitch_offset1
      + cos_lat1 * cos_lon1 * cos_pitch_offset1);
  x1 = 0.5 * x1 * focal_len + 0.5;
  y1 = 0.5 * y1 * focal_len * aspect_ratio + 0.5;

  vec3 b2 = normalize(vRstq2.xyz);
  float lat2 = -asin(b2.y);
  float lon2 = atan(b2.x, b2.z);

  lon2 -= yaw_offset2;
  float sin_lat2 = sin(lat2);
  float cos_lat2 = cos(lat2);
  float sin_lon2 = sin(lon2);
  float cos_lon2 = cos(lon2);
  float sin_pitch_offset2 = sin(pitch_offset2);
  float cos_pitch_offset2 = cos(pitch_offset2);
  float x2 = sin_lon2 * cos_lat2 / (sin_lat2 * sin_pitch_offset2
      + cos_lat2 * cos_lon2 * cos_pitch_offset2);
  float y2 = (sin_lat2 * cos_pitch_offset2 - cos_lat2 * cos_lon2
      * sin_pitch_offset2) / (sin_lat2 * sin_pitch_offset2
      + cos_lat2 * cos_lon2 * cos_pitch_offset2);
  x2 = 0.5 * x2 * focal_len + 0.5;
  y2 = 0.5 * y2 * focal_len * aspect_ratio + 0.5;

  vec4 baseColor = (texture(projectorTex1, vec2(x1, y1)) * ratio)
      + (texture(projectorTex2, vec2(x2, y2)) * (1.0 - ratio));

      vec4 texColor = texture(uTexture, vUv);
      float tr = 1.0-mix(pow(distance(vec2(0.5),vUv),texRatio),0.0,texRatio);
      tr = pow(tr,1.5);
      //float _opacity = clamp(dot(normalize(vPosition.xyz),normalize(vNormal.xyz)),0.0,1.0);
      float deactive = (0.9-vPosition.z)-0.4;//*_opacity;

      //3 way crossfade
      float _opacity = mix(mix(0.0,deactive,opacity*2.),mix(deactive,1.0,2.*(opacity-0.5)),opacity);
       // gl_FragColor = vec4(vPosition,1.0);
      gl_FragColor = vec4(mix(texColor, baseColor, tr).rgb,_opacity);
}
`;

/*
     * Shaders for displaying panorama box
     */

const vertexBoxShader = /* glsl */ `
// switch on high precision floats
#ifdef GL_ES
precision highp float;
#endif

out vec4 vRstq1;
out vec4 vRstq2;
uniform mat4 projectorMat1;
uniform mat4 projectorMat2;

void main()
{
    vRstq1 = projectorMat1 * vec4(position, 1.0);
    vRstq2 = projectorMat2 * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}`;

const fragmentBoxShader = /* glsl */ `
#ifdef GL_ES
precision highp float;
#endif

#define tau 6.28318530718

in vec4 vRstq1;
in vec4 vRstq2;
uniform sampler2D projectorTex1;
uniform sampler2D projectorTex2;
uniform float ratio;
uniform float pitch_offset1;
uniform float yaw_offset1;
uniform float pitch_offset2;
uniform float yaw_offset2;
uniform float focal_len;
uniform float aspect_ratio;

void main()
{
    vec3 b1 = normalize(vRstq1.xyz);
    float lat1 = -asin(b1.y);
    float lon1 = atan(b1.x, b1.z);

    lon1 -= yaw_offset1;
    float sin_lat1 = sin(lat1);
    float cos_lat1 = cos(lat1);
    float sin_lon1 = sin(lon1);
    float cos_lon1 = cos(lon1);
    float sin_pitch_offset1 = sin(pitch_offset1);
    float cos_pitch_offset1 = cos(pitch_offset1);
    float x1 = sin_lon1 * cos_lat1 / (sin_lat1 * sin_pitch_offset1
        + cos_lat1 * cos_lon1 * cos_pitch_offset1);
    float y1 = (sin_lat1 * cos_pitch_offset1 - cos_lat1 * cos_lon1
        * sin_pitch_offset1) / (sin_lat1 * sin_pitch_offset1
        + cos_lat1 * cos_lon1 * cos_pitch_offset1);
    x1 = 0.5 * x1 * focal_len + 0.5;
    y1 = 0.5 * y1 * focal_len * aspect_ratio + 0.5;

    vec3 b2 = normalize(vRstq2.xyz);
    float lat2 = -asin(b2.y);
    float lon2 = atan(b2.x, b2.z);

    lon2 -= yaw_offset2;
    float sin_lat2 = sin(lat2);
    float cos_lat2 = cos(lat2);
    float sin_lon2 = sin(lon2);
    float cos_lon2 = cos(lon2);
    float sin_pitch_offset2 = sin(pitch_offset2);
    float cos_pitch_offset2 = cos(pitch_offset2);
    float x2 = sin_lon2 * cos_lat2 / (sin_lat2 * sin_pitch_offset2
        + cos_lat2 * cos_lon2 * cos_pitch_offset2);
    float y2 = (sin_lat2 * cos_pitch_offset2 - cos_lat2 * cos_lon2
        * sin_pitch_offset2) / (sin_lat2 * sin_pitch_offset2
        + cos_lat2 * cos_lon2 * cos_pitch_offset2);
    x2 = 0.5 * x2 * focal_len + 0.5;
    y2 = 0.5 * y2 * focal_len * aspect_ratio + 0.5;

    vec4 baseColor = (texture(projectorTex1, vec2(x1, y1)) * ratio)
        + (texture(projectorTex2, vec2(x2, y2)) * (1.0 - ratio));
    gl_FragColor = baseColor;
}`;
/*
const meshVertexShader =  `
#ifdef GL_ES
precision highp float;
#endif

#include <clipping_planes_pars_vertex>

out vec2 vUv;
in float alpha;
out float vAlpha;
uniform float renderAlpha;

void main()
{
  #include <begin_vertex>
    vUv = uv;
    vAlpha = floor(max(renderAlpha, alpha)) * alpha; // If renderAlpha is 0.0, only render where alpha is 1.0.
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);

    #include <project_vertex>
    #include <clipping_planes_vertex>
}`;

const meshFragmentShader = `
#ifdef GL_ES
precision highp float;
#endif

#include <clipping_planes_pars_fragment>

in vec2 vUv;
uniform sampler2D uTexture;
in float vAlpha;

void main()
{
  #include <clipping_planes_fragment>
    vec4 c = texture(uTexture, vUv);
    c.a = vAlpha;
    // Desaturate transparent areas
    float gray = (c.r + c.g + c.b) * 0.333 * (1.0 - vAlpha);
    c.r = c.r * vAlpha + gray;
    c.g = c.g * vAlpha + gray;
    c.b = c.b * vAlpha + gray;
    gl_FragColor = vec4(c);
}
`;

/**
 * Create shader material for displaying panoramas.
 * @private
 */
 export function createImagePlaneMaterial(clippingPlane, tex) {
  return new THREE.ShaderMaterial({
    depthWrite: true,
    transparent: true,
    clippingPlanes: [clippingPlane],
    clipIntersection: true,
    uniforms: {
      uTexture: {type: 't', value: tex},
      renderAlpha: {type: 'f', value: 1.0},
      opacity: {type: 'f', value: 1.0},
      projectorMat1: {
        type: 'm4',
        value: new THREE.Matrix4()
      },
      projectorMat2: {
        type: 'm4',
        value: new THREE.Matrix4()
      },
      projectorTex1: {
        type: 't',
        value: null
      },
      projectorTex2: {
        type: 't',
        value: null
      },
      ratio: {
        type: 'f',
        value: 1.0
      },
      pitch_offset1: {
        type: 'f',
        value: 0.0
      },
      yaw_offset1: {
        type: 'f',
        value: 0.0
      },
      pitch_offset2: {
        type: 'f',
        value: 0.0
      },
      yaw_offset2: {
        type: 'f',
        value: 0.0
      },
      aspect_ratio: {
        type: 'f',
        value: 1.0
      },
      focal_len: {
        type: 'f',
        value: 1.0
      },
      texRatio: {
        type: 'f',
        value: 0
      }
    },
    vertexShader: vertexShader,
    fragmentShader: fragmentShader
  });
}

/**
 * Create shader material for displaying panorama box.
 * @private
 */
 export function createImagePlaneBoxMaterial() {
  return new THREE.ShaderMaterial({
    transparent: false,
    depthWrite: true,
    side: THREE.BackSide,
    uniforms: {
      projectorMat1: {
        type: 'm4',
        value: new THREE.Matrix4()
      },
      projectorMat2: {
        type: 'm4',
        value: new THREE.Matrix4()
      },
      projectorTex1: {
        type: 't',
        value: null
      },
      projectorTex2: {
        type: 't',
        value: null
      },
      ratio: {
        type: 'f',
        value: 1.0
      },
      pitch_offset1: {
        type: 'f',
        value: 0.0
      },
      yaw_offset1: {
        type: 'f',
        value: 0.0
      },
      pitch_offset2: {
        type: 'f',
        value: 0.0
      },
      yaw_offset2: {
        type: 'f',
        value: 0.0
      },
      aspect_ratio: {
        type: 'f',
        value: 1.0
      },
      focal_len: {
        type: 'f',
        value: 1.0
      }
    },
    vertexShader: vertexBoxShader,
    fragmentShader: fragmentBoxShader
  });
}



/*export function createSceneMaterial(tex, clippingPlane) {
  return new THREE.ShaderMaterial({
    uniforms: {
      uTexture: {type: 't', value: tex},
      renderAlpha: {type: 'f', value: 1.0},
    },
    vertexShader: meshVertexShader,
    fragmentShader: meshFragmentShader,
    transparent: true,
    clippingPlanes: [clippingPlane],
    clipIntersection: true,
  });
}*/

export function createPickingMaterial(clippingPlane) {
  return new THREE.MeshBasicMaterial({
    vertexColors: THREE.VertexColors,
    transparent: true,
    clippingPlanes: [clippingPlane],
    clipIntersection: true,
  });
}

export function createColorAttribute(geometry) {
  let colors = new Float32Array(geometry.getAttribute('position').array.length);
  let color = new THREE.Color();
  let i;
  for (i = 0; i < geometry.getAttribute('position').count / 3; i++) {
    color.setHex(i);
    colors[(i * 3) * 3] = colors[(i * 3 + 1) * 3] = colors[(i * 3 + 2) * 3] = color.r;
    colors[(i * 3) * 3 + 1] = colors[(i * 3 + 1) * 3 + 1] = colors[(i * 3 + 2) * 3 + 1] = color.g;
    colors[(i * 3) * 3 + 2] = colors[(i * 3 + 1) * 3 + 2] = colors[(i * 3 + 2) * 3 + 2] = color.b;
  }

  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
}
