<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
          d="M44.444 26.6667V18.7292C44.444 15.1458 45.2357 13.3333 50.7982 13.3333H57.7773V0H46.1315C31.8607 0 27.1523 6.54167 27.1523 17.7708V26.6667H17.7773V40H27.1523V80H44.444V40H56.194L57.7773 26.6667H44.444Z"
          fill="#3579EA"/>
    </g>
    <g class="hover">
      <path
          d="M44.444 26.6667V18.7292C44.444 15.1458 45.2357 13.3333 50.7982 13.3333H57.7773V0H46.1315C31.8607 0 27.1523 6.54167 27.1523 17.7708V26.6667H17.7773V40H27.1523V80H44.444V40H56.194L57.7773 26.6667H44.444Z"
          />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'FacebookIcon',
}
</script>
