const viewerOptions = {
  minModelZoom: 2,
  maxModelZoom: 18,
  minPanoPitch: -20,
  maxPanoPitch: 7,
  bottomPanoPitchMin: 1.8, //-20
  bottomPanoPitchMax: 2.4, //-48
  topPanoPitchMin: 1.8,
  topPanoPitchMax: 1.2,
  minFov: 40,
  maxFov: 110,
  mobileMaxFov: 120,
  modelUnitScaleFactor: 1,
  cursorSize: 0.17,
  imgBasePath: "",
  lookAroundSpeedCoeff: 1.8,
  minimapZoomOut: 1.5,
  minimapBgScale: [9,6.85,1],
  mouseHelperIdle: 2000
}

const controlsOptions = {
  panMultiplier: 0.9,
  zoomMultiplier: 0.5,
  zoomTouchMultiplier: 0.2,
  throttleAmount: 30, //amount in milliseconds
  minPhi: 0.3, //angle of how high the camera can rotate in orbit view
  maxPhi: 1.45
}

export {viewerOptions, controlsOptions}
