<template>
  <div class="controls pb-3 px-3">
    <MeasureDisclaimer :instructions="measureComplete? 'Click on an existing point to move it':'Click anywhere on the model to set a measurement point'" v-if="controlsState === CONTROLS_STATE.MEASURE_DISTANCE||controlsState ===CONTROLS_STATE.MEASURE_AREA"/>
    <template v-if="showDependingOnControlsState">
      <div class="flex-1">
        <template v-if="hasAccess('floors')">
          <FloorplanControls v-if="mode === VIEWER_MODES.FLOORPLAN"/>
          <OrbitControls v-if="mode === VIEWER_MODES.ORBIT"/>
        </template>
      </div>

      <div class="modes">
        <div class="btn-group shadow rounded mr-3 bg-light">
          <button
              :id="TOOLTIPS.FLOOR_MODE"
              v-b-tooltip.hover="tooltipOnHover ? 'Floorplan' : ''"
              class="btn btn-light btn-control btn-icon"
              :class="{'ml-3': Object.keys(panos).length !== 0, active: mode === VIEWER_MODES.FLOORPLAN}"
              @click="viewer.setMovingMode(VIEWER_MODES.FLOORPLAN)"
          >
            <FloorplanIcon/>
          </button>
          <button
              :id="TOOLTIPS.ORBIT_MODE"
              v-b-tooltip.hover="tooltipOnHover ? '3D Model' : ''"
              class="btn btn-light btn-control btn-icon"
              @click="viewer.setMovingMode(VIEWER_MODES.ORBIT)"
              :class="{active: mode === VIEWER_MODES.ORBIT}"
          >
            <OrbitIcon/>
          </button>
          <button
              :id="TOOLTIPS.PANO_MODE"
              v-b-tooltip.hover="tooltipOnHover ? '360° View' : ''"
              v-if="Object.keys(panos).length !== 0" class="btn btn-light btn-control btn-icon mr-3"
              @click="viewer.setMovingMode(VIEWER_MODES.PANO, {closest: true})"
              :class="{active: mode === VIEWER_MODES.PANO}"
          >
            <PanoIcon/>
          </button>
          <div v-if="floor && floors.length > 1 && allFloorsProcessed" class="vertical-divider"></div>
          <b-dropdown :id="TOOLTIPS.FLOORS" class="floors-dropdown"
                      v-b-tooltip.hover="tooltipOnHover ? 'Floor selector' : ''"
                      v-if="floor && floors.length > 1 && allFloorsProcessed" variant="light btn-control mx-3"
                      :text="floorName(floor)">
            <b-dropdown-item
                @click="changeFloor(fl.floor)"
                v-for="fl in floors"
                :key="fl.id"
                :active="fl.floor === floor.floor"
            >
              {{ floorName(fl) }}
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.floorEditModalId v-if="editMode">
              <EditIcon class="mr-3 fill-secondary"/>
              Edit
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div v-bind:style="{ marginBottom: inIframe? '-1rem' : '0' }" class="right-toolbar d-flex flex-column align-items-end">
        <template  v-if="!editMode">

          <b-dropdown 
              ref="measureDrop"
              @mouseover.native="openMeasurementsDropdown($refs.measureDrop)"

              @mouseleave.native="closeMeasurementsDropdown($refs.measureDrop)"

              id="measurements-dropdown"
              no-caret class="controls-dropdown measurements-dropdown mb-3"
              :class="{active:controlsState === CONTROLS_STATE.MEASURE_DISTANCE||controlsState ===CONTROLS_STATE.MEASURE_AREA}"
                      variant="light btn-control btn-icon shadow" dropleft>
            <template #button-content>
              <Ruler  v-if="controlsState !== CONTROLS_STATE.MEASURE_AREA" @click.native="setControlsState(CONTROLS_STATE.MEASURE_DISTANCE)"/>
              <Area @click.native="setControlsState(CONTROLS_STATE.MEASURE_AREA)" v-else/>
            </template>
            <b-dropdown-item
                @mouseover.native="measureItem = 'distance'"
                :active="controlsState === CONTROLS_STATE.MEASURE_DISTANCE"
                @click.native="setControlsState(CONTROLS_STATE.MEASURE_DISTANCE)"
            >
              <Ruler />
              <p class="small mb-2 mt-1 text-center">Measure<br/>distance</p>
            </b-dropdown-item>
            <b-dropdown-divider v-if="mode === VIEWER_MODES.FLOORPLAN"/>
            <b-dropdown-item
                @mouseover.native="measureItem = 'area'"
                v-if="mode === VIEWER_MODES.FLOORPLAN"
                :active="controlsState === CONTROLS_STATE.MEASURE_AREA"
                @click.native="setControlsState(CONTROLS_STATE.MEASURE_AREA)"
            >
              <Area/>
              <p  class="small mb-2 mt-1 text-center">Measure<br/>area</p>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
              ref="labelsDrop"
              @mouseover.native="$refs.labelsDrop.visible = true"
              @mouseleave.native="$refs.labelsDrop.visible = false"
              id="labels-dropdown"
              no-caret class="controls-dropdown labels-dropdown mb-3"
                      variant="light btn-control btn-icon shadow" dropleft
          >
            <template #button-content>
              <TicketIcon/>
            </template>

            <b-dropdown-item v-if="mode !== VIEWER_MODES.FLOORPLAN">
              <Tag/>
              <p class="small mb-2 mt-1">Feature labels</p>
              <div @click.stop="toggle_features(!showFeatures)" class="checkbox-switcher" :class="{checked: showFeatures}">
                <div class="toggler"></div>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider v-if="mode !== VIEWER_MODES.FLOORPLAN"/>
            <b-dropdown-item>
              <RoomIcon/>
              <p class="small mb-2 mt-1">Room labels</p>
              <div v-if="mode !== VIEWER_MODES.PANO" @click.stop="toggleNames(!showNames)" class="checkbox-switcher" :class="{checked: showNames}">
                <div class="toggler"></div>
              </div>
              <div v-else @click.stop="toggleNames(!panoShowNames)" class="checkbox-switcher" :class="{checked: panoShowNames}">
                <div class="toggler"></div>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item class="all-features" @click="$bvModal.show(featureListModalId)">
              <StarIcon/>
              <p class="small mb-2 mt-1">All features</p>
            </b-dropdown-item>
          </b-dropdown>
          <button v-show="!isMobile && !inIframe" class="btn btn-light btn-control btn-icon btn-intercom shadow" id="btn-intercom">
            <IntercomIcon/>
          </button>
        </template>

      </div>
    </template>
    <TutorModal :modal-id="controlsTutorsModalId" :type="controlsState"></TutorModal>
  </div>

</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex';
  import FloorplanControls from './floorplan-controls/FloorplanControls';
  import OrbitControls from './orbit-controls/OrbitControls';
  import TutorModal from '@/components/tutor-modal/TutorModal';
  import { EditIcon, Ruler, TicketIcon, DistanceIcon, Area, Tag, RoomIcon, FloorplanIcon, OrbitIcon, PanoIcon } from '@/components/svg-icons';
  import tutors from '@/components/tutor-modal/tutors';
  import IntercomIcon from '@/components/svg-icons/intercom';
  import StarIcon from '@/components/svg-icons/star';
  import MeasureDisclaimer from '@/components/measure-text/MeasureDisclaimer';

  export default {
    name: 'Controls',
    components: {
      StarIcon,
      MeasureDisclaimer,
      IntercomIcon,
      PanoIcon, OrbitIcon, FloorplanIcon, RoomIcon, Tag, Area, DistanceIcon, TicketIcon,
      Ruler, EditIcon, TutorModal, FloorplanControls, OrbitControls
    },
    data: () => {
      return {
        measureItem: '',
        controlsTutorsModalId: 'controlsTutorsModalId',
      }
    },
    methods: {
      ...mapActions('controls', ['toggleNames', 'setControlsState']),
      ...mapActions('viewer', ['loadTour']),
      ...mapActions('floors', ['changeFloor']),
      ...mapMutations('controls', ['toggle_features']),
      cancelMeasuring(e) {
        if (e.code === 'Escape' &&
            (this.controlsState === this.CONTROLS_STATE.MEASURE_DISTANCE ||
            this.controlsState === this.CONTROLS_STATE.MEASURE_AREA)
        ) {
          this.setControlsState(this.CONTROLS_STATE.NULL);
        }
      },
      openMeasurementsDropdown(dropdown) {
        dropdown.visible = true;
      },
      closeMeasurementsDropdown(dropdown) {
        dropdown.visible = false;
        this.measureItem = '';
      },
      handleKeyDown(e) {
        let target = e.target.nodeName.toLowerCase();
        if (!e.altKey && !e.ctrlKey && !e.shiftKey && target !== 'input' && target !== 'textarea') {
          switch (e.code) {
            case 'KeyF':
              this.viewer.setMovingMode(this.VIEWER_MODES.FLOORPLAN);
              break;
            case 'KeyO':
              this.viewer.setMovingMode(this.VIEWER_MODES.ORBIT);
              break;
            case 'KeyP':
              this.viewer.setMovingMode(this.VIEWER_MODES.PANO);
              break;
            default:
              break;
          }
        }
      },
    },
    created() {
      window.addEventListener('keydown', this.cancelMeasuring);
      window.addEventListener('keydown', this.handleKeyDown);
    },
    destroyed() {
      window.removeEventListener('keydown', this.cancelMeasuring);
      window.removeEventListener('keydown', this.handleKeyDown);
    },
    watch: {
      controlsState(value) {
        if (value !== this.CONTROLS_STATE.NULL && tutors[value]) {
          let relevance = !localStorage.getItem(`${value}-tutor`);
          if (relevance) {
            this.$bvModal.show(this.controlsTutorsModalId);
            localStorage.setItem(`${value}-tutor`, true);
          }
        }
      }
    },
    computed: {
      ...mapState('viewer', ['viewer', 'tour']),
      ...mapState('panos', ['panos']),
      ...mapState('floors', ['floors', 'floor']),
      ...mapState('controls', ['mode', 'showNames', 'panoShowNames', 'showFeatures', 'showTooltips', 'measureComplete']),
      ...mapState('features', ['features']),
      ...mapState('layout', ['allFloorsProcessed']),
      tooltipOnHover() {
        return !this.isTouchable && !this.showTooltips;
      }
    }
  }
</script>

<style lang="scss" src="./Controls.scss" scoped></style>
