<template>
  <div :class="[{open: mobileMenuOpen}, menuClass]" ref="mobileMenuRef">
    <div @click="toggleMobileMenu(false)" class="hook"></div>
    <MobileMain @signOut="$emit('signOut')"/>
    <MobileFeatures/>
    <MobileTourMeta/>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import MobileMain from '@/components/mobile-layout/menu/main/MobileMain';
import MobileFeatures from '@/components/mobile-layout/menu/features/MobileFeatures';
import MobileTourMeta from '@/components/mobile-layout/menu/tour-meta/MobileTourMeta';
import { MenuPages, FeatureFormModes } from '@/constants';

  export default {
    name: 'MobileMenu',
    components: {MobileFeatures, MobileMain, MobileTourMeta},
    data() {
      return {
        menuClass: 'mobile-menu'
      }
    },
    computed: {
      ...mapState('mobile', ['mobileMenuOpen', 'menuPage']),
      ...mapState('features', ['featureMode'])
    },
    methods: {
      ...mapMutations('mobile', ['set_menu_page']),
      ...mapMutations('features', ['delete_feature']),
      ...mapActions('mobile', ['toggleMobileMenu']),
      changeMobileMenuHeight() {
        setTimeout(() => {
          let displayHeight = window.innerHeight;
          let displayWidth = window.innerWidth;
          if (displayWidth > displayHeight) {
            return
          }
          
          let $menuPage = this.$refs.mobileMenuRef.querySelector('.menu-page.active');
          let menuHeight = $menuPage.scrollHeight + 60 + 'px';
          
          let topPosition = (displayHeight - $menuPage.scrollHeight - 60) + 'px';
          let radius = null;
          if ($menuPage.classList.contains('menu-page-height')) {
            menuHeight = '100%';
            topPosition = 0;
            radius = 0;
          }
          this.$refs.mobileMenuRef.style.borderTopLeftRadius = radius;
          this.$refs.mobileMenuRef.style.borderTopRightRadius = radius;
          this.$refs.mobileMenuRef.style.height = menuHeight;
          this.$refs.mobileMenuRef.style.top = topPosition;
        })
      }
    },
    watch: {
      menuPage() {
        if (this.mobileMenuOpen) {
          this.changeMobileMenuHeight();
        }
      },
      mobileMenuOpen(value) {
        if (value) {
          this.changeMobileMenuHeight();
        } else {
          this.$refs.mobileMenuRef.style.height = null;
          this.$refs.mobileMenuRef.style.top = null;
        }
        if (!value && this.menuPage === MenuPages.FEATURE_FORM && this.featureMode === FeatureFormModes.NEW) {
          //this.delete_feature();
        }
      }
    }
  }
</script>

<style scoped src="./MobileMenu.scss" lang="scss"></style>
