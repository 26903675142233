
<template>
  <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 20.1489L51 35.7289L42.117 37.1523L47.5 47.4689L42.0015 50.1489L36.5905 39.6256L30 45.4889V20.1489Z"
          class="fill-secondary"/>
    <rect x="9.06445" y="1.5" width="31.2416" height="31.2416" class="stroke-secondary" stroke-width="3"/>
    <path d="M8.56055 29.0658L19.908 21.1026L31.4546 28.0704L40.4132 16.3247" class="stroke-secondary" stroke-width="3"/>
    <circle cx="24.6855" cy="11.5464" r="4.07421" class="stroke-secondary" stroke-width="3"/>
    <path d="M10.5 11H2V56H57V11H39" class="stroke-secondary" stroke-width="2" stroke-dasharray="6 6"/>
  </svg>
</template>
<script>
export default {
  name: 'MediaIcon',
}
</script>
