<template>
  <div class="auth-wrapper">
    <CloseIcon v-bind:class="{ mobile: isMobile }" @click.native="$bvModal.hide(signInModal)"/>
    <img class="logo" :src="logoBigBeta" alt="logo" v-if="isMobile"/>
    <h5 class="header font-weight-semi-bold text-center mt-5">Sign in<span v-if="!isMobile"> to Your Account with</span></h5>
    <ul class="list-unstyled auth-providers p-5">
      <li class="facebook" @click="signInByProvider('facebook', authCallback)">
        <FacebookIcon/>
        <p class="text-secondary font-weight-semi-bold">Facebook</p>
      </li>
      <li class="google" @click="signInByProvider('google', authCallback)">
        <GoogleIcon/>
        <p class="text-secondary font-weight-semi-bold">Google</p>
      </li>
      <li class="apple" @click="signInByProvider('apple', authCallback)">
        <AppleIcon/>
        <p class="text-secondary font-weight-semi-bold">Apple</p>
      </li>
      <li class="email" @click="openEmailSignIn()">
        <EmailIcon/>
        <p class="text-secondary font-weight-semi-bold">Email</p>
      </li>
    </ul>
  </div>
</template>
<script>
  import FacebookIcon from '@/components/svg-icons/auth/facebook';
  import GoogleIcon from '@/components/svg-icons/auth/google';
  import AppleIcon from '@/components/svg-icons/auth/apple';
  import EmailIcon from '@/components/svg-icons/auth/email';

  import logoBigBeta from '@/assets/logo_big_beta.svg';

  import { SignInProviders } from '@/mixins/sign-in-providers';
  export default {
    name: 'Auth',
    components: {EmailIcon, AppleIcon, GoogleIcon, FacebookIcon},
    mixins: [SignInProviders],
    data() {
      return {
        logoBigBeta: logoBigBeta
      }
    },
    methods: {
      authCallback() {
        this.$bvModal.hide(this.signInModal);
      },
      openEmailSignIn() {
        this.$bvModal.hide(this.signInModal);
        this.$bvModal.show(this.emailSignInFormId);
      }
    }
  }
</script>
<style scoped lang="scss" src="./Auth.scss"></style>
