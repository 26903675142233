<template>
    <div class="statistics">
      <div class="main-table bg-light rounded px-3 py-1 mb-3">
        <header class="d-flex align-items-center justify-content-between statistics-header">
          <h5 v-if="isMobile" class="mb-0 statistics-title font-weight-bold">
            {{ tour.name }}
          </h5>
           <h6 v-else class="mb-0 statistics-title font-weight-semi-bold">
            {{ tour.name }}
          </h6>
          <b-dropdown v-if="floor && floors.length > 1" variant="link" toggle-class="text-decoration-none"
                      class="statistics-floors">
            <template #button-content>
              <span class="text-color">{{ floorName(floor) }}</span>
            </template>
            <p class="helper mb-0">Change floor:</p>
            <div class="floors-list">
              <b-dropdown-item @click="changeFloor(fl.floor)" v-for="(fl, index) of floors"
                               :key="fl.floor + index">
                {{ floorName(fl) }}
              </b-dropdown-item>
              <!--b-dropdown-item>All Floors</b-dropdown-item-->
            </div>
          </b-dropdown>
        </header>
        <div class="table-overflow">
          <table class="table table-hover table-borderless mb-0 statistics-table">
            <thead v-if="floor">
            <tr class="border-bottom border-gray">
              <th>
                <span >{{ checkName() }}</span>
              </th>
              <th v-if="sumArea" class="text-right">{{ sumArea }} sq ft</th>
            </tr>
            </thead>
            <tbody v-if="rooms">
              <tr v-for="room of rooms" :key="room.id" :class="{'not-hover': !hasAccess('rooms')}"
                  @click="hasAccess('rooms') && selectRoom(room.id)">
                <td>{{ room.name }}</td>
                <td v-if="room.sqft" class="text-right">{{ room.sqft }} sq ft</td>
              </tr>
            </tbody>
            <tfoot>
            <tr class="border-top border-gray" v-if="floors.length > 1">
              <td colspan="2" class="text-center">
                <button class="btn btn-link text-color font-weight-semi-bold h5 text-decoration-none mb-0 p-0">Total Floor Area</button>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

  export default {
    name: 'Statistics',
    computed: {
      ...mapState('viewer', ['tour', 'viewer']),
      ...mapState('floors', ['floor', 'floors']),
      ...mapState('rooms', ['rooms']),
    },
    methods: {
      ...mapActions('floors', ['changeFloor']),
      checkName(){
        let name = this.floorName(this.floor)
        ///Check if string is made of numbers
        !isNaN(parseFloat(name)) && isFinite(name) ? name =`Floor ${name}` : name = `${name}`
        return name
      }
    }
  }
</script>

<style lang="scss" src="./Statistics.scss" scoped></style>
