<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21.709 11.3075L18.709 8.29451C18.319 7.90351 17.685 7.90051 17.295 8.29151C16.903 8.68151 16.901 9.31351 17.291 9.70551L18.58 10.9995H13V5.40851L14.291 6.70551C14.486 6.90151 14.743 6.99951 15 6.99951C15.255 6.99951 15.511 6.90251 15.706 6.70851C16.097 6.31851 16.099 5.68551 15.709 5.29451L12.722 2.29451C12.533 2.10551 12.315 2.00851 12.013 1.99951C11.748 1.99951 11.494 2.10451 11.307 2.29151L8.29401 5.29151C7.90301 5.68151 7.90101 6.31451 8.29101 6.70551C8.68001 7.09651 9.31301 7.09851 9.70601 6.70851L11 5.41951V10.9995H5.40801L6.70501 9.70851C7.09701 9.31851 7.09901 8.68551 6.70901 8.29451C6.31901 7.90351 5.68501 7.90051 5.29501 8.29151L2.29501 11.2785C2.10601 11.4655 2.00101 11.7195 2.00001 11.9845C1.99901 12.2495 2.10501 12.5045 2.29101 12.6925L5.29101 15.7055C5.48601 15.9015 5.74301 15.9995 6.00001 15.9995C6.25501 15.9995 6.51101 15.9025 6.70501 15.7085C7.09701 15.3185 7.09901 14.6855 6.70901 14.2945L5.42001 12.9995H11V18.5915L9.70901 17.2945C9.31901 16.9025 8.68601 16.9005 8.29401 17.2915C7.90301 17.6815 7.90101 18.3145 8.29101 18.7055L11.278 21.7055C11.466 21.8935 11.72 21.9995 11.985 21.9995H11.987C12.252 21.9995 12.506 21.8945 12.693 21.7085L15.706 18.7085C16.097 18.3185 16.099 17.6855 15.709 17.2945C15.319 16.9025 14.686 16.9005 14.294 17.2915L13 18.5795V12.9995H18.592L17.295 14.2915C16.903 14.6815 16.901 15.3135 17.291 15.7055C17.486 15.9015 17.743 15.9995 18 15.9995C18.255 15.9995 18.511 15.9025 18.705 15.7085L21.705 12.7215C21.894 12.5345 21.999 12.2805 22 12.0155C22.001 11.7495 21.896 11.4955 21.709 11.3075Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'MoveIcon',
}
</script>
