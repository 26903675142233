<template>
  <ValidationObserver v-slot="{ invalid }">
    <form class="borderless-form">
      <div class="form-group">
        <label for="tour_name">Tour Title*</label>
        <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
          <input v-model="name" name="name" type="text" class="form-control" placeholder="Title" id="tour_name">
          <InvalidFeedback :errors="errors"/>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <label for="tour_address">Tour Address</label>
        <input v-model="address" name="address" type="text" class="form-control" placeholder="Address"
               id="tour_address">
      </div>
      <div class="form-group">
        <label for="tour_address">Tour Description</label>
        <textarea rows="4" v-model="description" name="description" class="form-control"
                  placeholder="Tell us a little bit about your tour..."/>
      </div>
      <h5 class="header font-weight-semi-bold text-center mb-2">Contact (optional)</h5>
      <p class="small text-secondary mb-4">If you want to add contact info then Name and Email or Phone are required</p>
      <div class="form-group">
        <label for="tour_owner_name">Name</label>
        <ValidationProvider name="Name" :rules="{required: anyContactInfo}" v-slot="{ errors }">
          <input v-model="contactInfo.name" name="address" type="text" class="form-control" placeholder="Add name"
                 id="tour_owner_name">
          <InvalidFeedback :errors="errors"/>
        </ValidationProvider>

      </div>
      <div class="form-group">
        <label for="tour_owner_title">Title</label>
        <input v-model="contactInfo.title" name="address" type="text" class="form-control" placeholder="Add job title"
               id="tour_owner_title">
      </div>
      <div class="form-group">
        <label for="tour_owner_email">Email</label>
        <ValidationProvider name="Email" :rules="emailOrPhoneRule('email')" v-slot="{ errors }">
          <input v-model="contactInfo.email" name="address" type="text" class="form-control"
                 placeholder="Add email address" id="tour_owner_email">
          <InvalidFeedback :errors="errors"/>
        </ValidationProvider>

      </div>
      <div class="form-group">
        <label for="tour_owner_phone">Phone</label>
        <ValidationProvider name="Phone" :rules="emailOrPhoneRule('phone')" v-slot="{ errors }">
          <input v-mask="'(###) ###-####'" v-model="contactInfo.phone" name="address" type="text" class="form-control"
                 placeholder="Add phone number" id="tour_owner_phone">
          <InvalidFeedback :errors="errors"/>
        </ValidationProvider>
      </div>
      <div class="d-flex justify-content-center">
        <b-button class="btn-form mr-2" type="button" variant="light" size="sm" @click="$emit('onCancel')">CANCEL
        </b-button>
        <ConfirmButton 
            text="CONFIRM" 
            :disabled="invalid"
            @click.native="update()"
        />
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import ConfirmButton from '@/components/confirm-button/ConfirmButton';

export default {
  name: 'TourMetaForm',
  components: {ConfirmButton},
  data() {
    return {
      name: '',
      address: '',
      description: '',
      contactInfo: {
        name: '',
        title: '',
        email: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapState('viewer', ['tour']),
    anyContactInfo() {
      return !!(this.contactInfo.name ||
             this.contactInfo.title ||
             this.contactInfo.email ||
             this.contactInfo.phone);
    }
  },
  mounted() {
    if (this.tour) {
      this.name = this.tour.name;
      this.address = this.tour.address;
      this.description = this.tour.description;
      if (this.tour.contactInfo) {
        this.contactInfo.name = this.tour.contactInfo.name;
        this.contactInfo.title = this.tour.contactInfo.title;
        this.contactInfo.email = this.tour.contactInfo.email;
        this.contactInfo.phone = this.tour.contactInfo.phone;
      }
    }
  },
  methods: {
    ...mapActions('viewer', ['updateTour']),
    update() {
      let data = {
        name: this.name,
        address: this.address || 'not set',
        description: this.description,
        contactInfo: {
          ...this.contactInfo,
          phone: this.contactInfo.phone.replace(/\D/g, '')
        }
      };
      this.updateTour(data).then(() => {
        this.$emit('onClose');
      });
    },
    emailOrPhoneRule(prop) {
      if (!this.anyContactInfo) {
        return null;
      }

      let res = {};

      if (this.emailOrPhoneCheck(prop)) {
        res.emailOrPhone = true;
      }
      if (prop === 'phone') {
        res.regex = this.phone_regex
      }
      if (prop === 'email') {
        res.email = true
      }
      return res;
    },

    emailOrPhoneCheck(prop) {
      if (prop === 'phone') {
        if (!this.contactInfo.email) {
          return true;
        }
      }
      if (prop === 'email') {
        if (!this.contactInfo.phone) {
          return true;
        }
      }
    },
  }
}
</script>
<style src="./TourMeta.scss" scoped lang="scss"></style>
