<template>
  <div class="wrapper">
    <div class="loader">
      <div ref="loader" class="logo" />
      <div class="tour-title">
        <h3 class="text-center" v-if="tour && tour.name"><span class="tour-name">{{ tourNameComputed }}</span> is
          loading</h3>
        <h4 v-if="tour && tour.address && tour.address !== 'not set'" class="text-center text-secondary mt-3">{{ tour.address }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
  import logo from './logo2.json';
  import lottie from 'lottie-web/build/player/lottie';
  import {mapState} from 'vuex';
  import { AppStates } from '@/constants';
  export default {
    name: 'Loader',
    mounted() {
      this.logoEl = lottie.loadAnimation({
        animationData: {...this.logo},
        container: this.$refs.loader,
        renderer: 'svg',
        loop: true,
      })
      if (this.appState !== AppStates.ready) {
        this.logoEl.play();
      }
    },
    data() {
      return {
        logo,
        logoEl: undefined
      }
    },
    computed: {
      ...mapState('layout', ['appState']),
      ...mapState('viewer', ['tour']),
      tourNameComputed() {
        return this.tour.name[0].toUpperCase() + this.tour.name.substring(1)
      }
    },
    watch: {
      appState(newValue) {
        if (newValue === AppStates.ready) {
          this.logoEl.stop();
        } else {
          this.logoEl.play();
        }
      }
    },
    destroyed() {
      this.logoEl.destroy();
    }
  }
</script>

<style lang="scss" src="./Loader.scss" scoped></style>
