<template>
  <div @click="$emit('onSwitch')" class="switcher" :class="{on: state}">
    <span class="state-value"><slot name="offValue"></slot></span>
    <div class="switcher-state"></div>
    <span class="state-value"><slot name="onValue"></slot></span>
  </div>
</template>

<script>
  export default {
    name: 'Switcher',
    props: ['state'],
  }
</script>

<style scoped src="./Switcher.scss" lang="scss"></style>
