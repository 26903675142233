import Vue from 'vue';

export default function handleError(error) {
  console.log(error);

  if (window.innerWidth < 768) {
    return;
  }
  Vue.toasted.error(error, {
    icon: {
      name: 'las la-chrome mr-2 la-lg'
    }
  })
}
