import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  namespaced: true,
  state: () => ({
    panos: [],
    panoList: false
  }),
  mutations: {
    toggle_pano_list(state, value) {
      console.log(value)
      state.panoList = value;
    },
    set_panos(state, panos) {
      state.panos = {...panos};
    },
    append_panos(state, panos) {
      merge(state.panos,panos)
    },
    set_pano(state, {id, pano}) {
      let panos = {...state.panos};
      panos[id] = {...panos[id], ...pano};
      state.panos = {...panos};
    }
  },
  actions: {
    async updatePano({commit}, {pano, panos}) {
      let data = cloneDeep(pano);
      delete data.tiles;
      delete data.config;
      data.pano_id = data.panoPath;
      let update_pano = firebase.functions().httpsCallable('panos-updatePanoLocation'); //update function name
      await update_pano(data).then(() => {
        commit('set_panos', panos);
      });
    },
    approvePano({commit}, {id, isTrue}) {
      commit('set_pano', {id, pano: {approved: isTrue}});
    }
  }
}
