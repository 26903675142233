import { UserRoles } from '@/constants';
import {roles} from '@/api/users';

export default {
  namespaced: true,
  state: () => ({
    userRole: UserRoles.VISITOR,
    permissions: [],
    name: ''
  }),
  mutations: {
    set_role(state, role) {
      state.userRole = role;
    },
    set_permissions(state, permissions) {
      state.permissions = permissions;
    },
    set_name(state, name) {
      state.name = name;
    }
  },
  actions: {
    async getRoles({commit}, {token, tour_id}) {
      let role = UserRoles.VISITOR;
      let permissions = [];
      if (token) {
        let roleRes = await roles(token, tour_id);
        role = roleRes.name;
        permissions = roleRes.permissions;
      }
      commit('set_role', role);
      commit('set_permissions', permissions);
    }
  }
}
