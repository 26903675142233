<template>
  <svg width="24" height="30" viewBox="0 0 24 30" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M22.9375 8L10.8131 1C9.86048 0.45 8.63105 0.779423 8.08106 1.73205L0.581058 14.7224C0.0310583 15.6751 0.447084 16.9545 1.06311 17.8875L5.12529 24.8516L13.1875 24.8875C14.3035 24.9545 15.6195 24.6751 16.1695 23.7224L23.6695 10.7321C24.2195 9.77943 23.8901 8.55 22.9375 8ZM14.9092 22.9054C14.7305 23.2149 14.4004 23.4055 14.0431 23.4055L6 23.4054L1.92209 16.4066C1.74151 16.0967 1.74075 15.7138 1.9201 15.4032L9.13009 2.91506C9.40624 2.43677 10.0178 2.2729 10.4961 2.54904L21.7544 9.04904C22.2327 9.32518 22.3966 9.93678 22.1205 10.4151L14.9092 22.9054ZM10.7282 6.14712L18.5224 10.6471L17.7724 11.9462L9.97817 7.44615L10.7282 6.14712Z"
        />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.5 21C9.32843 21 10 20.3284 10 19.5C10 18.6716 9.32843 18 8.5 18C7.67158 18 7 18.6716 7 19.5C7 20.3284 7.67158 21 8.5 21ZM8.5 22C9.88071 22 11 20.8807 11 19.5C11 18.1193 9.88071 17 8.5 17C7.11929 17 6 18.1193 6 19.5C6 20.8807 7.11929 22 8.5 22Z"
          />
    <path
        d="M7.14572 18.7957C8.27222 18.8315 9.3569 19.2316 10.2359 19.9359L9.42297 20.9504C8.76386 20.4222 7.95003 20.1219 7.10448 20.0951C6.25894 20.0682 5.42812 20.3163 4.73756 20.8015C4.04703 21.2866 3.5343 21.9824 3.27637 22.7833C3.01845 23.5842 3.02917 24.4472 3.30705 25.2421C3.58495 26.037 4.11507 26.7209 4.81779 27.19C5.52054 27.6592 6.35749 27.8879 7.2021 27.8414C8.04669 27.7949 8.85256 27.4758 9.49803 26.9327C9.77272 26.7016 10.1828 26.7369 10.4139 27.0116C10.645 27.2863 10.6097 27.6963 10.335 27.9275C9.47352 28.6523 8.39888 29.0775 7.27356 29.1395C6.14824 29.2014 5.0329 28.8967 4.09597 28.2712C3.15901 27.6457 2.45117 26.7332 2.07988 25.6711C1.70858 24.609 1.69416 23.4554 2.03896 22.3848C2.38374 21.3142 3.06883 20.3851 3.99023 19.7378C4.91159 19.0905 6.01922 18.76 7.14572 18.7957Z"
        />
  </svg>

</template>
<script>
export default {
  name: 'TicketIcon',
}
</script>
