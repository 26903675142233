import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { createImagePlaneMaterial } from '../shaders';
import gsap from 'gsap'
import { VIEWER_MODES } from '../../../constants';
import config from '@/config';
import store from '@/store';

class FloorManager {
  constructor(viewer, isMobile, floorData) {
    this.viewer = viewer
    this.isMobile = isMobile
    this.floorData = floorData
    this.currentFloor = 0
    this.min
    this.max
    this.boundingBox

    this.floors = []

    this.textureLoader = new THREE.TextureLoader()

    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setWorkerLimit(2)
    this.dracoLoader.defaultAttributeIDs.subobject = 'sub_obj';
    this.dracoLoader.defaultAttributeTypes.subobject = 'Float32Array';
    this.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.0/')
    this.dracoLoader.preload()
  }

  addInitialFloor = (floorNumber, mesh, tex4kURL, tex8kURL, clippingHeight, floorplanParams, mode) => {
    const floor = {floorNumber, mesh, tex4kURL, tex8kURL}
    const mid = new THREE.Vector3().copy(mesh.geometry.boundingBox.getCenter(new THREE.Vector3()))
    const threeFourths = mesh.geometry.boundingBox.max.z * 0.75
    mesh.localToWorld(mid)
    floor.mid = mid
    floor.datumLine = clippingHeight ? clippingHeight : threeFourths
    if (floor.mid.z > floor.datumLine) floor.datumLine = threeFourths
    mesh.name = `Mesh${floorNumber}`

    this.currentFloor = floorNumber
    this.boundingBox = new THREE.Box3().expandByObject(mesh)
    this.max = mesh.geometry.boundingBox.max.z
    this.min = mesh.geometry.boundingBox.min.z
    this.floors.push(floor)
    this.activateFloor(floor.floorNumber)

    this.viewer.FloorplanManager.prepareFloorplan(
      floorplanParams,
      floorNumber,
      mode,
      mesh.geometry,
      floor.datumLine
    )
  }

  prepareAdditionalFloors = (floorData) => {
    if (floorData.floors.length === 1) return
    floorData.floors = floorData.floors.filter(floor => floor.floor !== this.currentFloor)
    let promises = []
    floorData.floors.forEach(floor => {
      if (!floor.model.model_key || !floor.model.tex_4k_key) throw('Error finding urls')
      if (!floor.model.tex_8k_key) floor.model.tex_8k_key = floor.model.tex_4k_key

      this.viewer.TileLoader.addTiles(floor.panos)

      let {storageBucket, cloudDomain} = config.firebase;
      let modelURL = `https://${cloudDomain}/${storageBucket}/${floor.model.model_key}?${floor.model.model_udpated}`;
      let tex4kURL = `https://${cloudDomain}/${storageBucket}/${floor.model.tex_4k_key}?${floor.model.texture_udpated}`;
      let tex8kURL = `https://${cloudDomain}/${storageBucket}/${floor.model.tex_8k_key}?${floor.model.texture_udpated}`;

      promises.push(this.loadFloor(floor.floor, modelURL, tex4kURL, tex8kURL, floor.panos, floor.floorplan));
    })

    Promise.all(promises).then(() => {
      store.commit('layout/finished_proccess_floor');
    }).catch(err => {
      console.warn('Error loading floor:', err)
    })
  }

  loadFloor = (floorNumber, modelURL, tex4kURL, tex8kURL, panoData, floorplanParams) => {
    const floor = {floorNumber, tex4kURL, tex8kURL}

    const fetchGeometry = new Promise((resolve, reject) => {
      this.dracoLoader.load(
        modelURL,
        geometry => {
          geometry.computeBoundsTree()
          geometry.computeBoundingBox()
          resolve(geometry)
        },
        () => {},
        error => reject(error)
      )
    })

    const fetchTexture = new Promise((resolve, reject) => {
      this.textureLoader.load(
        !this.floors.length && !this.isMobile ? tex8kURL : tex4kURL,
        texture => resolve(texture),
        () => {},
        error => reject(error)
      )
    })

    return Promise.all([fetchGeometry, fetchTexture])
    .then(assets => {
      const [geometry, texture] = assets
      const material = createImagePlaneMaterial(this.viewer.clippingPlane, texture)
      material.clipping = true
      if(this.viewer.currentMode === 'walk'){
        material.uniforms.texRatio.value = 1
        material.uniforms.ratio.value = 1
      }
      const mesh = new THREE.Mesh(geometry, material)
      mesh.name = `Mesh${floorNumber}`

      this.viewer.scene.add(mesh)
      this.viewer.meshes.push(mesh)
      const mid = new THREE.Vector3().copy(geometry.boundingBox.getCenter(new THREE.Vector3()))
      mesh.localToWorld(mid)
      floor.mid = mid
      this.boundingBox.expandByObject(mesh)
      this.viewer.controls.maxDistance = this.boundingBox.getBoundingSphere(new THREE.Sphere()).radius*1.5//this.boundingBox.min.distanceTo(this.boundingBox.max)
      if (geometry.boundingBox.max.z > this.max) {
        this.max = geometry.boundingBox.max.z
        if (this.viewer.currentMode !== VIEWER_MODES.FLOORPLAN) {
          this.viewer.AnimationController.animateClippingPlaneHeight(0.5, 0, this.max, this.viewer.clippingPlane, this.viewer.ipMat)
        }
      }
      this.viewer.MeasurementManager.updateMeasurePlane(this.max)
      if (geometry.boundingBox.min.z < this.min) this.min = geometry.boundingBox.min.z

      floor.mesh = mesh
      this.floors.push(floor)
      if (this.viewer.meshes.length) {
        this.deactivateFloorInit(floor.floorNumber)
        floor.mesh.material.uniforms.opacity.value = 0.0
        if(VIEWER_MODES.PANO !== this.viewer.currentMode) this.animateFade(floor.mesh.material.uniforms.opacity,0.5,2.3)
      } else {
        this.activateFloor(floor.floorNumber)
      }

      //process panos
      const newPanos = panoData ? Object.values(panoData) : []
      const threeFourths = mesh.geometry.boundingBox.max.z * 0.75
      floor.datumLine = newPanos.length ? this.calcDatumLine(newPanos) : threeFourths
      if (floor.datumLine < mid.z) floor.datumLine = threeFourths
      newPanos.forEach(pano => pano.floorNumber = floorNumber)
      const newCameras = Object.keys(panoData)
      this.viewer.updateCameraFootPrints(panoData, newCameras, newPanos, mesh, floorNumber)


      //floorplan
      this.viewer.FloorplanManager.prepareFloorplan(
        floorplanParams,
        floorNumber,
        this.viewer.currentMode,
        geometry,
        floor.datumLine
      )
    })
    .catch(err => console.warn('error loading floor:', err))
  }

  calcDatumLine = panos => {
    return panos.reduce((max, current) => {
      const height = current.position[2]
      return max = height > max ? height : max
    }, -Infinity)
  }

  activateFloor = floorNumber => {
    if (this.currentFloor === floorNumber) return
    const floor = this.floors.find(floor => floor.floorNumber === floorNumber)
    this.currentFloor = floorNumber
    floor.mesh.visible = true
    floor.mesh.renderOrder = 0
    this.animateFade(floor.mesh.material.uniforms.opacity,1.0,0.5)

    const otherFloors = this.floors.filter(floor => floor.floorNumber !== this.currentFloor)
    otherFloors.forEach(floor => this.deactivateFloor(floor.floorNumber))
  }

  activateAllFloors = () => {
    this.floors.forEach(floor => {
      this.animateFade(floor.mesh.material.uniforms.opacity,1.0,0.5)
    });
  }

  deactivateFloor = floorNumber => {
    const floor = this.floors.find(floor => floor.floorNumber === floorNumber)
    floor.mesh.renderOrder = 1
    this.animateFade(floor.mesh.material.uniforms.opacity,0.5,0.5)
  }

  deactivateFloorInit = floorNumber => {
      const floor = this.floors.find(floor => floor.floorNumber === floorNumber)
      floor.mesh.renderOrder = 1
     //this.animateFade(floor.mesh.material.uniforms.opacity,0.5,0.5)
  }

  moveToFloor = floorNumber => {
    const floor = this.floors.find(floor => floor.floorNumber === floorNumber)
    const amount2 = this.viewer.controls.animationTarget.z - floor.mid.z
    this.viewer.controls.animationPosition.setZ(this.viewer.controls.animationPosition.z - amount2)
    this.viewer.controls.animationTarget.setZ(this.viewer.controls.animationTarget.z - amount2)
  }

  showFloors = () => {
    this.floors.forEach(floor => floor.mesh.visible = true)
  }

  isolateFloor = (floorNumber = this.currentFloor) => {
    this.currentFloor = floorNumber
    const floor = this.floors.find(floor => floor.floorNumber === floorNumber)
    floor.mesh.visible = true
    floor.mesh.material.uniforms.opacity.value = 1
    floor.mesh.renderOrder = 0
    const topFloors = this.floors.filter(floor => floor.floorNumber > this.currentFloor)
    const bottomFloors = this.floors.filter(floor => floor.floorNumber < this.currentFloor)
    topFloors.forEach(floor => {
      floor.mesh.visible = false
      floor.mesh.renderOrder = 1
    })
    bottomFloors.forEach(floor => {
      floor.mesh.visible = true
      this.deactivateFloor(floor.floorNumber)
    })
  }

  setCurrentFloor = floorNumber => {
    if (typeof floorNumber === 'string') floorNumber = parseInt(floorNumber)
    this.currentFloor = floorNumber
  }

  getCurrentFloor = () => this.floors.find(floor => floor.floorNumber == this.currentFloor)

  getCurrentMesh = () => this.floors.find(floor => floor.floorNumber == this.currentFloor).mesh

  getFloorMesh = floorNumber => this.floors.find(floor => floor.floorNumber == floorNumber).mesh

  getCurrentFloorObject = () => this.floors.find(floor => floor.floorNumber == this.currentFloor)

  animateFade(prop,value,time) {
      gsap.to(prop, {
        duration: time,
        delay: 0,
        value: value
      })
    }

}

export default FloorManager
