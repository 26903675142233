import * as THREE from 'three'
import { VIEWER_MODES } from '@/constants';
import appConfig from '@/config';
import createFloorplanMaterial from '../shaders/customFloorplanMaterial.js'

class FloorplanManager {
  constructor(viewer) {
    this.viewer = viewer
    this.camera = viewer.camera;
    this.scene = viewer.scene
    this.floorplanPlane;
    this.floorplans = []
    this.textureLoader = new THREE.TextureLoader()
  }

  prepareFloorplan = (floorplanParams, floor, mode, geometry, height) => {
    if (floorplanParams.file_key) {
      try {
        let {cloudDomain, storageBucket} = appConfig.firebase;
        const url = `https://${cloudDomain}/${storageBucket}/${floorplanParams.file_key}`;

        this.textureLoader.load(url, (tex) => {
          this.createFloorplanPlane(geometry, floorplanParams, floor, mode, height, tex);
        });
      } catch (error){
        //no image or can't load image. Creating blank floorplan plane instead
        this.createFloorplanPlane(geometry, floorplanParams, floor, mode)
      }
    } else {
      this.createFloorplanPlane(geometry, floorplanParams, floor, mode)
    }
  }

  createFloorplanPlane = (geometry, params, floor, mode, height, tex) => {
    let min = geometry.boundingBox.min
    let max = geometry.boundingBox.max
    const center = new THREE.Vector3()
    geometry.boundingBox.getCenter(center)
    const x = Math.abs(min.x) + Math.abs(max.x)
    const y = Math.abs(min.y) + Math.abs(max.y)
    const size = Math.max(x, y) * 1.03

    const planeGeo = new THREE.PlaneGeometry(size, size, 2, 2);

    const controls = this.viewer.controls;
    const distanceToCam = controls.target.distanceTo(controls.object.position);
    const threshold = distanceToCam/size > 1? size/distanceToCam*0.9 : distanceToCam/size
    const planeMat = createFloorplanMaterial(tex,distanceToCam*threshold*1.1);
    planeMat.needsUpdate = true;

    const floorplanPlane = new THREE.Mesh(planeGeo, planeMat);
    floorplanPlane.renderOrder = 99
    floorplanPlane.name = `floorplanPlane${floor}`
    let position;
      if (params && (params.plane.position.x || params.plane.position.y)) {
        position = params.plane.position
      } else {
        position = {x: center.x, y: center.y}
      }
    let rotation = params && params.plane.rotation || {};
    let scale = params && params.plane.scale || {} ;
    floorplanPlane.position.z = height + 0.001;


    //apply transforms from database here
    //if there are none use the center location below
    this.updateFloorplan(floorplanPlane, {
      position,
      rotation,
      scale
    })


    switch (mode) {
      case VIEWER_MODES.PANO: 
        floorplanPlane.visible = false
        break
      case VIEWER_MODES.FLOORPLAN:
        if (this.viewer.FloorManager.currentFloor == floor) floorplanPlane.visible = true
        else floorplanPlane.visible = false
        break
      case VIEWER_MODES.ORBIT: 
        if (this.viewer.isMultiFloor) floorplanPlane.visible = false
        else floorplanPlane.visible = true
        break
    }

    const floorplanGroup = new THREE.Group()
    floorplanGroup.name = `Floorplan`
    floorplanGroup.add(floorplanPlane)
    this.scene.add(floorplanGroup)
    const floorPlan = {
      floorNumber: floor,
      mesh: floorplanGroup,
      params
    }
    this.floorplans.push(floorPlan)
  }

  toggleVisibility(show) {
    if (this.floorplanPlane) {
      this.floorplanPlane.visible = show;
    }
  }

  getFloorplanTransforms = () => {
    if (!this.getCurrentMesh()) {
      return;
    }

    const plane = this.getCurrentMesh()

    let position = {
      x: plane.position.x,
      y: plane.position.y,
    };
    let scale = {
      x: plane.scale.x,
      y: plane.scale.y,
    };
    let rotation = {
      z: plane.rotation.z,
    };
    return {position, scale, rotation};
  }

  showFloorplan = (floorNumber) => {
    const floor = this.floorplans.find(floorplan => floorplan.floorNumber == floorNumber)
    if (floor) floor.mesh.children[0].visible = true
  }

  hideAllFloorplans = () => {
    this.floorplans.forEach(floorplan => floorplan.mesh.children[0].visible = false)
  }

  isolateFloorplan = (floorNumber) => {
    this.hideAllFloorplans()
    this.showFloorplan(floorNumber)
  }

  updateFloorplan = (floorplanPlane, newValues) => {
    floorplanPlane.position.x = newValues.position.x || floorplanPlane.position.x;
    floorplanPlane.position.y = newValues.position.y || floorplanPlane.position.y;

    floorplanPlane.rotation.z = newValues.rotation.z || floorplanPlane.rotation.z;

    floorplanPlane.scale.x = newValues.scale.x || floorplanPlane.scale.x;
    floorplanPlane.scale.y = newValues.scale.y || floorplanPlane.scale.y;
  }

  getCurrentMesh = () => {
    const floor = this.floorplans.find(floorplan => floorplan.floorNumber == this.viewer.FloorManager.currentFloor)
    return floor.mesh.children[0]
  }

  getCurrentConfig = () => {
    const floor = this.floorplans.find(floorplan => floorplan.floorNumber == this.viewer.FloorManager.currentFloor)
    return floor.params
  }

  shiftUp = (amount) => {
    const plane = this.getCurrentMesh()
    const upVector = new THREE.Vector3(0, 1, 0).applyQuaternion(this.camera.quaternion);
    upVector.multiplyScalar(amount || 0.01);

    plane.position.x += upVector.x
    plane.position.y += upVector.y
  }

  shiftDown = (amount) => {
    const plane = this.getCurrentMesh()
    const upVector = new THREE.Vector3(0, 1, 0).applyQuaternion(this.camera.quaternion);
    upVector.multiplyScalar(-(amount || 0.01));

    plane.position.x += upVector.x
    plane.position.y += upVector.y
  }

  shiftLeft = (amount) => {
    const plane = this.getCurrentMesh()
    const upVector = new THREE.Vector3(1, 0, 0).applyQuaternion(this.camera.quaternion);
    upVector.multiplyScalar(-(amount || 0.01));

    plane.position.x += upVector.x
    plane.position.y += upVector.y
  }

  shiftRight = (amount) => {
    const plane = this.getCurrentMesh()
    const upVector = new THREE.Vector3(1, 0, 0).applyQuaternion(this.camera.quaternion);
    upVector.multiplyScalar(amount || 0.01);

    plane.position.x += upVector.x
    plane.position.y += upVector.y
  }

  rotateLeft = (amount) => {
    const plane = this.getCurrentMesh()
    let rad = (amount || 1) * Math.PI / 180
    plane.rotation.z += rad;
  }

  rotateRight = (amount) => {
    const plane = this.getCurrentMesh()
    let rad = (amount || 1) * Math.PI / 180
    plane.rotation.z -= rad;
  }

  scaleUp = (amount) => {
    const plane = this.getCurrentMesh()
    plane.scale.y += amount || 0.01
    plane.scale.x += amount || 0.01
  }

  scaleDown = (amount) => {
    const plane = this.getCurrentMesh()
    plane.scale.x -= amount || 0.01
    plane.scale.y -= amount || 0.01
  }
}

export default FloorplanManager
