<template>
  <div class="root">

    <div class="rts-progress-bar">
      <div class="pouring" :style="{width: ratio + '%'}"></div>
      <div class="divider first"></div>
      <div class="divider second"></div>
      <div class="divider third"></div>
      <div class="divider fourth"></div>
    </div>

    <div v-if="ratio < 100" class="thumb lock" :style="{left: ratio + '%'}">
      <LockIcon/>
    </div>
    <div v-if="isMobile" class="ratio text-primary font-weight-semi-bold" :style="{left: ratio + '%'}">{{ratio}}%</div>
    <div class="thumb goblet" :class="{completed: ratio === 100}">
      <GobletIcon/>
    </div>
  </div>
</template>
<script>

import GobletIcon from '@/components/svg-icons/goblet';
import LockIcon from '@/components/svg-icons/lock';
export default {
  name: 'RTSProgress',
  components: {LockIcon, GobletIcon},
  props: ['ratio'],
}
</script>
<style src="./RTSProgress.scss" scoped lang="scss"></style>
