<template>
  <b-modal
      :id="modalId"
      hide-footer
      hide-header
      dialog-class="tutor-modal"
      @keypress.esc="$bvModal.hide(modalId)"
  >
    <template #default="{}">
      <CloseIcon @click.native="$bvModal.hide(modalId)"/>
      <div class="d-flex flex-column align-items-center">
        <component class="tutor-icon mb-2" :is="tutor.icon"/>
        <p class="modal-title form-title">{{ tutor.title }}</p>
        <div class="text-center" v-html="tutor.content"/>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { CONTROLS_STATE } from '@/constants';
  import { Tag, DistanceIcon, Info, Area } from '@/components/svg-icons';
  import tutors from './tutors';
  export default {
    name: 'TutorModal',
    components: {Tag, DistanceIcon, Info, Area},
    props: {
      type: {type: String, default: CONTROLS_STATE.MEASURE_DISTANCE},
      modalId: {type: String, default: ''}
    },
    computed: {
      tutor() {
        return tutors[this.type]
      }
    }
  }
</script>

<style lang="scss" src="./TutorModal.scss" scoped></style>
