<template>
  <div class="share">
    <b-button @click="copyToClipboard(url())" variant="outline-primary" class="mb-3 rounded-pill" block
              v-b-tooltip.focus.v-dark title="Copied!">COPY LINK
    </b-button>
    <!-- <a v-if="hasAccess('share')" href="https://therealdeal.com/"
       class="btn btn-primary btn-block mb-3 small-caps rounded-pill font-weight-bold">TheRealDeal</a> -->
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div :data-href="url()" data-layout="button"
           data-size="small">
        <a target="_blank"
           :href="'https://www.facebook.com/sharer/sharer.php?u=' + url(true)"
           class="fb-xfbml-parse-ignore">
          <button class="btn btn-light btn-social"><FacebookIcon/></button>
        </a>
      </div>
      <a target="_blank" :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + url(true)"
      >
        <button class="btn btn-light btn-social"><LinkedinIcon/></button>
      </a>
      <a target="_blank" :href="'https://twitter.com/intent/tweet?url=' + url(true)">
        <button class="btn btn-light btn-social"><TwitterIcon/></button>
      </a>

      <!--<button class="btn btn-light btn-social"><InstagramIcon/></button>-->
    </div>

    <!--
    <b-dropdown-divider v-if="hasAccess('share')"></b-dropdown-divider>
    <div v-if="hasAccess('share')" class="hint menu-item">
      <div class="collapse-title mb-3" v-b-toggle.context>Add your tour to website</div>
      <b-collapse id="context">
        <div class="scroll-wrapper">
          <p class="text-primary d-flex align-items-center justify-content-end">How to
            <a href="#" id="info-tooltip">
              <Info class="ml-2 fill-primary"/>
            </a>

          </p>
          <b-popover custom-class="p-3" target="info-tooltip" triggers="click" placement="right">
            <h5 class="font-weight-bold">Option 1 - iFrame viewer</h5>
            <p>Copy and paste the HTML code into your website or CMS to embed an iframe of your tour.</p>
            <p>Set your desired width, and the responsive iframe automatically adjusts its height to fit on different
              devices.</p>
            <h5 class="font-weight-bold">Option 2 - Pop-up modal</h5>
            <p>Copy and paste pop-up code into your website or CMS to embed The 3D App icon.</p>
            <p class="mb-0">Clicking this icon will open the tour viewer.</p>
          </b-popover>
          <textarea class="form-control mb-3" rows="3" v-model="iframeContent"></textarea>
          <div class="form-group row">
            <label class="col-3 mb-0" for="width">Width</label>
            <input class="col-3 form-control" placeholder="auto" type="text" v-model="iframeWidth" id="width"/>
          </div>
          <div class="form-group row">
            <label class="col-3 mb-0" for="height">Height</label>
            <input class="col-3 form-control" placeholder="auto" type="text" v-model="iframeHeight" id="height"/>
          </div>
          <b-button variant="outline-primary" @click="copyToClipboard(iframeContent)" class="mb-3 rounded-pill" block
                    v-b-tooltip.focus.v-dark title="Copied!">COPY iFRAME VIEWER
          </b-button>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-between my-3">
            <textarea class="form-control" rows="3" v-model="popupContent"></textarea>
            <div class="logo-wrapper ml-2">
              <img :src="logo" alt="Logo"/>
            </div>
          </div>
          <b-button variant="outline-primary" @click="copyToClipboard(popupContent)" class="mb-3 rounded-pill" block
                    v-b-tooltip.focus.v-dark title="Copied!">COPY POP-UP MODAL
          </b-button>
        </div>
      </b-collapse>
    </div>
     -->
  </div>
</template>

<script>
import logo from '../../../assets/logo_header.png';
import { Info, FacebookIcon, LinkedinIcon, TwitterIcon/*, InstagramIcon*/ } from '@/components/svg-icons';
import { mapState } from 'vuex';
import config from '../../../config';

export default {
  name: 'Share',
  components: {/*InstagramIcon,*/ TwitterIcon, LinkedinIcon, FacebookIcon, Info},
  data() {
    return {
      logo,
      iframeWidth: undefined,
      iframeHeight: undefined
    }
  },
  computed: {
    ...mapState('viewer', ['tour']),
    iframeContent() {
      return `<iframe src="${this.url()}" width="${this.iframeWidth ? this.iframeWidth + 'px' : 'auto'}" height="${this.iframeHeight ? this.iframeHeight + 'px' : 'auto'}" title="Tour Viewer"></iframe>`
    },
    popupContent() {
      return `<a target="_blank" href="${this.url()}" style="display: inline-block; height: 50px;"><img src="${config.assetsUrls.logo}" alt="tour" height="50"/></a>`
    }
  },
  methods: {
    url(encoded = false) {
      let u = location.href
      return encoded ? encodeURIComponent(u) : u;
    },
    copyToClipboard(content) {
      navigator.clipboard.writeText(content).then();
    }
  }
}
</script>
<style src="./Share.scss" scoped lang="scss"></style>
