import { UserRoles } from '@/constants';

const {
  VUE_APP_apiKey,
  VUE_APP_authDomain,
  VUE_APP_databaseURL,
  VUE_APP_projectId,
  VUE_APP_storageBucket,
  VUE_APP_messagingSenderId,
  VUE_APP_appId,
  VUE_APP_measurementId,
  VUE_APP_cloudDomain,
  VUE_APP_the3dAppDomain
} = process.env;

export default {
  the3dAppDomain: VUE_APP_the3dAppDomain,
  intercomSettings: {
    app_id: 'lntdc1cb',
    custom_launcher_selector: '#btn-intercom',
    hide_default_launcher: true
  },
  assetsUrls: {
    logo: 'https://storage.googleapis.com/id3d-5f0de.appspot.com/viewer_assets/logo_beta.png'
  },
  permissions: {
    [UserRoles.TOUR_OWNER]: ['rooms', 'arrow', 'share', 'features', 'panoList'],
    [UserRoles.ADMIN]: true,
    [UserRoles.VISITOR]: false,
    [UserRoles.USER]: false,
  },
  firebase: {
    apiKey: VUE_APP_apiKey,
    authDomain: VUE_APP_authDomain,
    databaseURL: VUE_APP_databaseURL,
    projectId: VUE_APP_projectId,
    storageBucket: VUE_APP_storageBucket,
    messagingSenderId: VUE_APP_messagingSenderId,
    appId: VUE_APP_appId,
    measurementId: VUE_APP_measurementId,
    cloudDomain: VUE_APP_cloudDomain
  }
}
