import * as THREE from 'three'
import gsap from 'gsap'
import {VIEWER_MODES} from '../../../constants';

/*
Animation controller
animates the controls via gsap

I had to copy some of the utility functions from the viewer to calculate optical centers etc. These might be able to be moved to a utilities component as well.
*/

class AnimationController {
  constructor(viewer) {
    this.controls = viewer.controls
    this.viewer = viewer
    this.occlusionCompleted = false
    this.animationQueue = []
    this.animating = false
    this.animationRequested = false
    this.clippingAnim;

    this.upNormal = new THREE.Vector3(0, 0, -1)
  }

  moveCameraToPanoPosition(shot, time, mode, tempPosition) {
    if (mode !== VIEWER_MODES.PANO) this.killAnimations()
    // do not allow an animation from walk -> walk to be interrupted
    this.disableAnimations()
    this.controls.limitPitch = false
    const start = new THREE.Vector3().copy(this.opticalCenter(shot, tempPosition))
    const dir = new THREE.Vector3(0,0,-1).applyQuaternion(this.controls.object.quaternion).normalize()
    const distance = 2.5
    const height = 0.25
    const newTarget = new THREE.Vector3().addVectors(start, dir.multiplyScalar(distance))

    if (mode === VIEWER_MODES.FLOORPLAN) {
      const dir2 = new THREE.Vector3(0,1,0).applyQuaternion(this.controls.object.quaternion).normalize()
      newTarget.add(dir2.multiplyScalar(2))
    }

    const currentPosition = new THREE.Vector3().copy(this.controls.object.position)
    const newPosition = new THREE.Vector3().copy(currentPosition.copy(this.opticalCenter(shot, tempPosition)))
    if (mode !== VIEWER_MODES.PANO) newTarget.z = newPosition.z - height

    const targetAnim = gsap.to(this.controls.animationTarget, {
      duration: time,
      ease: 'power2.out',
      x: newTarget.x,
      y: newTarget.y,
      z: newTarget.z,
      paused: true
    })
    const positionAnim = gsap.to(this.controls.animationPosition, {
      duration: time,
      ease: 'power2.out',
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z,
      onComplete: () => {
        this.enableAnimations()
        this.controls.movingMode = VIEWER_MODES.PANO;
        this.controls.limitPitch = true;
        this.viewer.setLookAroundSpeed()
        this.occlusionCompleted = true;
      },
      paused: true
    })

    this.queueAnimation(targetAnim, positionAnim)
  }

  //receives x, y, z coordinates of the label position for a room
  moveCameraToRoomPosition = (x, y, z) => {
    const newPosition = new THREE.Vector3(x, y, z)
    const newTarget = new THREE.Vector3(x, y, z)

    const dir = new THREE.Vector3(0,0,-1).applyQuaternion(this.controls.object.quaternion).normalize()
    console.log(dir)
    const distance = -2
    const height = 1
    newPosition.addVectors(newPosition, dir.multiplyScalar(distance))
    newPosition.z = newTarget.z + height

    gsap.to(this.controls.animationTarget, {
      duration: 1,
      ease: 'power1.inOut',
      x: newTarget.x,
      y: newTarget.y,
      z: newTarget.z
    })
    gsap.to(this.controls.animationPosition, {
      duration: 1,
      ease: 'power1.inOut',
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z
    })

    //no need to queue this animation because there is no pannellum
  }

  queueAnimation = (...anims) => {
    this.animationRequested = true
    this.animationQueue.push(...anims)
  }

  dispatchAnimation = () => {
    this.animationQueue.forEach(anim => {
      if (anim.paused) anim.resume()
    })
    this.animationRequested = false
  }

  disableAnimations = () => {this.animating = true}

  enableAnimations = () => {this.animating = false}

  //stop all animations, clear the queue, and kill for garbage collection
  killAnimations = () => {
    this.animationQueue.forEach(anim => {
      anim.paused = true
      anim.kill()
    })
    this.controls.animationTarget.copy(this.controls.target)
    this.controls.animationPosition.copy(this.controls.object.position)
    this.animationQueue = []
    this.animating = false
    this.animationRequested = false
  }

  moveCameraToInitialPosition(time, initTarget, initPosition) {
    // this.killAnimations()
    const targetAnim = gsap.to(this.controls.animationTarget, {duration: time, ease: 'power2.out', x: initTarget.x, y: initTarget.y, z: initTarget.z})
    const positionAnim = gsap.to(this.controls.animationPosition, {duration: time, ease: 'power2.out', x: initPosition.x, y: initPosition.y, z: initPosition.z})

    this.queueAnimation(targetAnim, positionAnim)
  }

  //this basically creates a target behind and above the camera to back out of a shot
  moveCameraOutOfPano(time) {
    this.disableAnimations()
    const start = new THREE.Vector3().copy(this.controls.animationPosition)
    const dir = new THREE.Vector3(0,0,-1).applyQuaternion(this.controls.object.quaternion).normalize()
    const distance = -3
    const height = 2.5
    const newPosition = new THREE.Vector3().addVectors(start, dir.multiplyScalar(distance))

    gsap.to(this.controls.animationPosition, {
      duration: time,
      ease: 'power3.out',
      x: newPosition.x,
      y: newPosition.y,
      z: start.z + height,
      onComplete: () => this.enableAnimations()
    })
    gsap.to(this.controls.animationTarget, {
      duration: time,
      ease: 'power3.out',
      x: start.x,
      y: start.y,
      z: start.z
    })
  }

  moveCameraOutOfPanoMultiFloor(time,floorObject) {
    const initTarget = floorObject.mid
    this.disableAnimations()
    // const start = new THREE.Vector3().copy(this.controls.animationPosition)
    const dir = new THREE.Vector3(0,0,1).applyQuaternion(this.controls.object.quaternion).normalize()
    const distance = floorObject.mesh.geometry.boundingSphere.radius*1.5
    const height = floorObject.mesh.geometry.boundingBox.max.z
    const newPosition = new THREE.Vector3().addVectors(floorObject.mesh.geometry.boundingSphere.center, dir.multiplyScalar(distance))

    const positionAnim = gsap.to(this.controls.animationPosition, {
      duration: time,
      ease: 'power2.out',
      x: newPosition.x,
      y: newPosition.y,
      z: height,
      onComplete: () => this.enableAnimations()
    })
    const targetAnim = gsap.to(this.controls.animationTarget, {duration: time, ease: 'power2.out', x: initTarget.x, y: initTarget.y, z: initTarget.z})
    
    /*gsap.to(this.controls.animationTarget, {
      duration: time,
      ease: 'power3.out',
      x: start.x,
      y: start.y,
      z: start.z
    })*/

    this.queueAnimation(positionAnim, targetAnim)
  }

  //animates the clipping plane on the mesh shader
  animateClippingPlaneHeight = (time, delay, height, plane, material) => {
    this.clippingAnim?.kill()
    material.needsUpdate = true
    this.clippingAnim = gsap.to(plane, {
      duration: time, 
      delay: delay, 
      constant: height, 
      ease: 'Power4.inOut',
      onUpdate: () => material.needsUpdate = true,
      onComplete: () => {
        plane.set(this.upNormal, height)
        material.needsUpdate = true
      }
    })
  }

  //animates the uniforms of the shader
  animateRatio = (time, delay, dir, meshes, imageplaneBox) => {
    const animations = []
    meshes.forEach(mesh => {
      const planeAnim = gsap.to(mesh.material.uniforms.ratio, {
        duration: time,
        delay: delay,
        ease: 'power1.in',
        value: dir ? 0 : 1,
        paused: true
      })
      animations.push(planeAnim)
    })

    const boxAnim = gsap.to(imageplaneBox.material.uniforms.ratio, {
      duration: time,
      delay: delay,
      ease: 'power1.in',
      value: dir ? 0 : 1,
      paused: true
    })
    animations.push(boxAnim)

    this.queueAnimation(...animations)
  }
  
  animateTexRatio = (time, delay, amount, meshes) => {
    const animations = []
    meshes.forEach(mesh => {
      const planeAnim = gsap.to(mesh.material.uniforms.texRatio, {
        duration: time,
        delay: delay,
        //ease: dir === 'in' ? 'power1.in' : 'power3.out',
        ease: 'power1.in',
        value: amount,
        paused: true
      })
      const opacityAnim = gsap.to(mesh.material.uniforms.opacity, {
        duration: time,
        delay: delay,
        //ease: dir === 'in' ? 'power1.in' : 'power3.out',
        ease: 'power1.in',
        value: 1,
        paused: true
      })
      animations.push(planeAnim,opacityAnim)
    })

    this.queueAnimation(...animations)
  }

  animateOpacity = (time, delay, options, imagePlane, imagePlaneBox, renderPano) => {
    options.imagePlaneOpacity = 1
      const opacityAnim = gsap.to(options, {
        duration: time,
        delay: delay,
        ease: 'power2.inOut',
        imagePlaneOpacity: 0,
        paused: true,
        onComplete: () => {renderPano()}//,this.viewer.renderer.setPixelRatio(1)} //Flashes a white screen for a single frame on Android
      })

      this.queueAnimation(opacityAnim)
  }

  animInitPositionAndTarget = (modelCenter, radius, axis, radians, maxHeight, minHeight) => {
    const positionAnim = new THREE.Vector3(0, 1, 0)
    positionAnim.multiplyScalar(radius * 1.5).applyAxisAngle(axis, -radians)
    positionAnim.add(modelCenter).setZ((maxHeight + (radius / 2.5)) * 1.5)

    const targetPositionAnim = new THREE.Vector3(0, 1, 0)
    targetPositionAnim.multiplyScalar(radius * 0.6).applyAxisAngle(axis, -radians)
    targetPositionAnim.add(modelCenter).setZ(minHeight + 1)

    this.controls.object.position.copy(positionAnim)
    this.controls.target.copy(targetPositionAnim)
  }

  animInitPanoPosition = (shot) => {
    const start = new THREE.Vector3().copy(this.opticalCenter(shot))
    const dir = new THREE.Vector3(0,0,-1).applyQuaternion(this.controls.object.quaternion).normalize()
    const distance = -3
    const height = 2.5
    const newPosition = new THREE.Vector3().addVectors(start, dir.multiplyScalar(distance))
    newPosition.z = start.z + height
    this.controls.object.position.copy(newPosition)
    this.controls.target.copy(start)
  }

  /**
 * Converts panorama camera origin into a translation.
 * @private
 * @param {object} shot - Panorama camera shot object
 * @returns {THREE.Vector3} Resulting translation
 */
    originToTranslation = (shot, tempPosition) => {
    let euler = this.getEuler(shot.rotation);
    let rotation = new THREE.Matrix4().makeRotationFromEuler(euler);
    let position = tempPosition && tempPosition.length ?
      new THREE.Vector3(tempPosition[0], tempPosition[1], tempPosition[2]) :
      new THREE.Vector3(shot.position[0], shot.position[1], shot.position[2]);
    let r3 = new THREE.Matrix3().setFromMatrix4(rotation);
    return position.applyMatrix3(r3.multiplyScalar(-1));
  }

  /**
 * Determines center position of panorama camera.
 * @private
 * @param {object} shot - Panorama camera shot object
 * @returns {THREE.Vector3} Center position
 */
    opticalCenter = (shot, tempPosition) => {
    let Rt = this.originToTranslation(shot, tempPosition);
    let euler = this.getEuler(shot.rotation);
    let matrix = new THREE.Matrix4().makeRotationFromEuler(euler).transpose();
    Rt.applyMatrix4(matrix);
    Rt.negate();
    return Rt;
  }

  getEuler(rotation) {
    return new THREE.Euler(Math.PI / 2 - rotation[0], rotation[1], rotation[2], 'YXZ');
  }
  
}

export default AnimationController
