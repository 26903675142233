<template>
  <div>
    <div v-if="!isMobile" class="btn-control btn-icon btn-icon-rounded shadow position-absolute"></div>
    <b-dropdown ref="dropdown"
                @hide="toggle($refs.dropdown.$el, false)"
                @show="toggle($refs.dropdown.$el, true)"
                href="javascript:void()"
        :class="{'meta-dropdown meta-dropdown-mobile': isMobile, 'meta-dropdown': !isMobile}"
        dropleft
        variant="light"
        toggle-class="btn-control btn-icon btn-icon-rounded"
        no-caret
        menu-class="shadow"
        :toggleClass="{'btn-control btn-icon btn-icon-rounded glass': isMobile, 'btn-control btn-icon btn-icon-rounded': !isMobile}"
    >
      <template #button-content>
        <button class="btn btn-control btn-icon btn-icon-rounded">
          <div ref="infoBtn"></div>
        </button>
      </template>
      <div class="dropdown-menu-wrapper">
        <b-dropdown-header id="dropdown-header-label">
          <h6 class="font-weight-bold">What can I do here?</h6>
        </b-dropdown-header>
        <div class="hint menu-item" :class="{highlighted: !visited}">
          <div class="collapse-title"  v-b-toggle.tutorial>View Tutorials</div>
          <b-collapse :visible="true" id="tutorial" class="tutorials-links">
              <a href="#" class="d-block mt-3"
               @click.prevent="toggleTutors({show: true, tutorsType: VIEWER_MODES.FLOORPLAN})">
                <FloorplanIcon class="mr-2"/> Floorplan
              </a>
              <a href="#" class="d-block mt-3"
                 @click.prevent="toggleTutors({show: true, tutorsType: VIEWER_MODES.ORBIT})">
                <OrbitIcon class="mr-2"/> 3D Model
              </a>
              <a href="#" class="d-block mt-3"
                 @click.prevent="toggleTutors({show: true, tutorsType: VIEWER_MODES.PANO})">
                <PanoIcon class="mr-2"/> 360&deg; Pano
              </a>
          </b-collapse>
        </div>
        <b-dropdown-divider></b-dropdown-divider>
        <a class="hint menu-item d-block" href="https://www.the3dapp.com/faq" target="_blank">Browse FAQs</a>
        
         <button v-show="isMobile && !editMode && !inIframe" class="btn btn-control btn-icon btn-icon-rounded btn-intercom shadow mb-3" id="btn-intercom">
          <IntercomIcon/>
        </button>
      </div>
    </b-dropdown>
  </div>

</template>

<script>
import infoBtn from './infoBtn.json';
import { mapActions, mapState } from 'vuex';
import { DropdownMixin } from '../mixin';
import FloorplanIcon from '@/components/svg-icons/floorplan';
import OrbitIcon from '@/components/svg-icons/orbit';
import PanoIcon from '@/components/svg-icons/pano';
import IntercomIcon from '@/components/svg-icons/intercom';

export default {
  name: 'InfoDropdown',
  components: {PanoIcon, OrbitIcon, FloorplanIcon, IntercomIcon},
  mixins: [DropdownMixin],
  computed: {
    ...mapState('layout', ['visited']),
  },
  mounted() {
    this.initAnim(infoBtn, this.$refs.infoBtn);
    if (!this.visited) {
      this.$refs.dropdown.show();
    }
  },
  methods: {
    ...mapActions('controls', ['toggleTutors'])
  }
}
</script>
<style src="../Dropdowns.scss" scoped lang="scss"></style>
<style src="./InfoDropdown.scss" scoped lang="scss"></style>
