<template>
<a>
  <div class="measure-wrapper">
    <div class="measure-instructions">{{ instructions }}</div>
  </div>
  <div class="measure-label">DISCLAIMER: Measurements are approximate</div>
</a>
</template>

<script>
export default {
  name: "MeasureDisclaimer",
  props: {
    instructions: String
  },
  methods: {},
  data: () =>{
    return{
      measureComplete: false,
    }
  },
};
</script>

<style lang="scss" src="./MeasureDisclaimer.scss" scoped></style>
