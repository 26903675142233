<template>
  <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.7033 20.9974C16.1753 20.9974 20.4981 15.1959 20.4981 9.69038C20.4981 9.53879 21.5602 8.62722 22.015 6.72674C22.1078 6.34107 21.7324 6.03687 21.3787 6.19877C20.4713 6.61537 19.5504 6.28539 19.1503 5.81104C17.6118 4.13329 15.0389 4.05286 13.4024 5.63058C12.3475 6.64836 11.9 8.16422 12.2269 9.61098C8.77136 9.85743 6.39859 8.2199 4.44758 5.975C4.19597 5.68317 3.72884 5.83682 3.67625 6.22248C3.38751 8.36942 3.29677 13.5893 8.46922 16.587C7.57106 17.878 5.81288 18.6401 3.85567 18.967C3.42257 19.0402 3.31945 19.6403 3.71543 19.8342C5.26634 20.5973 6.9709 20.9964 8.7033 20.9943"
    />
  </svg>
</template>
<script>
export default {
  name: 'TwitterIcon',
}
</script>
