<template>
  <Error v-if="isError"/>
  <div v-else id="main"
       :class="[mode + '-mode', {
         measure: controlsState === CONTROLS_STATE.MEASURE_DISTANCE || controlsState === CONTROLS_STATE.MEASURE_AREA,
         'hidden-ui': hiddenUI
       }]"
  >
    <Header v-if="showDependingOnControlsState && !inIframe" @signOut="signOut()"/>
    <Stats v-show="hasAccess('stats') && showDependingOnControlsState" ref="statsRef"></Stats>
    <template v-if="appState !== appStates.init && tour && tour.id">
      <div v-show="showDependingOnControlsState" class="menu-dropdowns" :class="{'menu-dropdowns iframe': inIframe, 'menu-dropdowns mobile': isMobile && !editMode}">
        <TourMeta v-if="!inThe3dAppIframe"/>
         <ProblemMeta/>
        <div v-if="!isMobile && !isAndroid || isMobile && isAndroid || inThe3dAppIframe" class="mb-3" :id="TOOLTIPS.FULLSCREEN">
          <button @click="toggleFullscreen(true)" v-if="!fullscreen" class="btn btn-control btn-icon btn-icon-rounded" :class="{'glass': isMobile, 'btn-light shadow': !isMobile}">
            <FullscreenIcon/>
          </button>
          <button @click="toggleFullscreen(false)" v-else-if="isMobile || inThe3dAppIframe" class="btn btn-control btn-icon btn-icon-rounded glass" >
            <FullscreenOffIcon/>
          </button>
          <button @click="toggleFullscreen(false)" v-else class="btn btn-control btn-icon btn-icon-rounded btn-light shadow" >
            <FullscreenOffIcon/>
          </button>
        </div>
        <div v-show="!editMode" class="mb-3" v-if="!inThe3dAppIframe && !isMobile">
          <ShareDropdown/>
        </div>
        <div class="mb-3" v-show="!editMode">
          <InfoDropdown/>
        </div>
        
        <!--button v-show="isMobile && !editMode && !inIframe" class="btn btn-light btn-control btn-icon btn-icon-rounded btn-intercom shadow mb-3" id="btn-intercom">
          <IntercomIcon/>
        </button-->
        <template v-if="editMode && !isMobile">
          <div @click="toggleTooltips(!showTooltips)"
               class="checkbox-switcher checkbox-switcher-labels tooltips-switcher"
               :class="{checked: showTooltips}">
            <div class="toggler"></div>
            <span class="on">ON</span>
            <span class="off">OFF</span>
          </div>
          <p class="mb-0 mt-1 text-light font-weight-semi-bold">TOOLTIPS</p>
        </template>
      </div>
      <Tutors/>
    </template>

    <Minimap
        :class="{hidden: hideMinimap}"
        ref="minimapContainer"
        @onDrag="viewer.minimap.resizeMinimap($event)"
    />

    <Viewer ref="viewerContainer"/>

    <div class="top-left-menu" v-if="showDependingOnControlsState">
      <Statistics v-if="mode === VIEWER_MODES.FLOORPLAN && !isMobile"/>
      <PanoDebugger
          v-if="hasAccess('panos') && !isMobile"
          :rotation="rotation"
          :panoId="viewer.getPano ? viewer.getPano() : null"
          :panos="panos"
          @onPanoMove="movePano($event)"
          @onPanoToggle="viewer.togglePano($event)"
          @onSetFlythroughRotateSpeed="viewer.setFlythroughRotateSpeed($event)"
          @onSetFlythroughZoomSpeed="viewer.setFlythroughZoomSpeed($event)"
          @onPanoSave="updatePanoRequest()"
          @onPanoApply="applyPanoChanges()"
          @onPanoReset="resetPanoChanges()"
          @onPanoSelect="navigateToShot($event)"
          @onRotationInput="updatePanoRotation($event)"
          @onClippingChange="viewer.changeClippingDebug($event)"
      />
      <HideUiBtn v-if="isAdmin() && !isMobile && editMode"/>
    </div>
    
    <PanoList v-if="panoList && editMode && !isMobile && mode === VIEWER_MODES.PANO && !isAdmin()"/>
    <Rooms
        v-if="appState === appStates.ready && mode && mode !== VIEWER_MODES.PANO ? showNames : panoShowNames"
        @onWheel="initMouseWheel($event)"/>
    <Features />
    <EntryArrowControls
        v-show="editMode && controlsState === CONTROLS_STATE.EDIT_ENTRY_ARROW"
        v-if="mode && mode !== VIEWER_MODES.PANO && hasAccess('arrow')"
        v-bind="entryArrow"/>

    <template v-if="showDependingOnControlsState">
      <template v-if="!isMobile">
        <Controls/>
<!--        <FloorSwitcher v-if="!editMode && floors.length > 1 && allFloorsProcessed"/>-->
      </template>
      <template v-if="isMobile">
        <MobileLayout @signOut="signOut()"/>
      </template>
    </template>

    <Tooltips v-if="editMode"/>

    <b-modal
        :id="floorEditModalId"
        hide-header
        hide-footer
        @keypress.esc="$bvModal.hide(floorEditModalId)"
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(floorEditModalId)"/>
        <h5 class="header font-weight-semi-bold text-center mb-4">Edit floor names</h5>
        <FloorEditModal/>
      </template>
    </b-modal>
    
    <RTSGuide/>

    <Loader :class="{hidden: appState === appStates.ready}"/>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Controls from '../components/controls/Controls';
import Statistics from '../components/statistics/Statistics';
import Loader from '../components/loader/Loader';

import Rooms from '../components/rooms/Rooms';
import Minimap from '../components/minimap/Minimap';
import Viewer from '../components/viewer/Viewer.vue';
import PanoDebugger from '../components/pano-debugger/PanoDebugger';
import MobileLayout from '../components/mobile-layout/MobileLayout';
import EntryArrowControls from '../components/entry-arrow-controls/EntryArrowControls';
import Stats from '../components/stats/Stats';
import ShareDropdown from '@/components/dropdowns/share-dropdown/ShareDropdown';
import InfoDropdown from '@/components/dropdowns/info-dropdown/InfoDropdown';
import Tutors from '@/components/tutors/Tutors';
import Header from '@/components/header/Header'
import { postMessagesMixin } from '@/mixins/post-messages';
import Features from '@/components/features/Features';
import { AuthMixin } from '@/mixins/auth-mixin';
import TourMeta from '@/components/tour-meta/TourMeta';
import ProblemMeta from '@/components/tour-meta/ProblemMeta';
import FloorSwitcher from '@/components/floor-switcher/FloorSwitcher';
import PanoList from '@/components/pano-list/PanoList';
import Tooltips from '@/components/tooltips/Tooltips';
import Error from '@/components/error/Error';
import { FullscreenIcon, FullscreenOffIcon } from '@/components/svg-icons';
import IntercomIcon from '@/components/svg-icons/intercom';
import EditMenu from '@/components/header/edit-menu/EditMenu';
import FloorEditModal from '@/components/floor-edit-modal/FloorEditModal';
import RTSGuide from '@/components/rts/guide/RTSGuide';
import HideUiBtn from '@/components/hide-ui-btn/HideUiBtn';

export default {
  name: 'Main',
  mixins: [postMessagesMixin, AuthMixin],
  components: {
    HideUiBtn,
    RTSGuide,
    FloorEditModal,
    EditMenu,
    IntercomIcon,
    Error,
    PanoList,
    FloorSwitcher, TourMeta, ProblemMeta, Stats, MobileLayout, Controls, Statistics, Loader, Rooms, Minimap, Viewer,
    PanoDebugger, EntryArrowControls, ShareDropdown, InfoDropdown, Tutors, Header, Features, Tooltips, FullscreenIcon, FullscreenOffIcon},
  mounted() {
    this.loadTour();
    if (!this.visited) {
      this.visit();
    }
  },
  watch: {
    floor(newFloor, oldFloor) {
      if (!oldFloor.floor) {
        this.tour.floor = newFloor.floor
        this.initViewer(this.tour);
      }
    }
  },
  methods: {
    ...mapActions('viewer', ['loadTour']),
    ...mapActions('panos', ['updatePano']),
    ...mapMutations('measurements', ['update_measure_text']),
    ...mapMutations('viewer', ['set_viewer']),
    ...mapMutations('users', ['set_role']),
    ...mapActions('layout', ['visit']),
    ...mapActions('users', ['getRoles']),
    ...mapActions('controls', ['toggleTooltips']),
    ...mapActions('controls', ['toggleFullscreen']),
    navigateToShot(shot_id) {
      if (this.mode !== this.VIEWER_MODES.PANO) {
        this.viewer.setMovingMode(this.VIEWER_MODES.PANO);
      }
      this.viewer.navigateToShot(shot_id);
    },
    initMouseWheel(e) {
      let {deltaY, clientX, clientY} = e;
      e.preventDefault();
      let wheel = new WheelEvent('wheel', {deltaY, clientX, clientY});
      this.viewer.renderer.domElement.dispatchEvent(wheel);
    },
    updatePanoRotation(value) {
      this.viewer.updatePanoRotation(value / 180 * Math.PI);
    },
    updatePanoRequest() {
      this.updatePano(this.viewer.savePano());
    },
    applyPanoChanges() {
      this.viewer.applyPanoPosition();
    },
    movePano(direction) {
      this.viewer.movePano(direction);
    },
    resetPanoChanges() {
      this.viewer.cleanPanoAdjustments();
      this.viewer.moveCameraToPanoPositionDebug();
    },
    initViewer(newTour) {
      let search = (new URL(document.location)).searchParams;
      let viewParams = Object.fromEntries(search);
      if (!viewParams.floor) viewParams.floor = newTour.floor
      let config = {
        container: this.$refs.viewerContainer.$el,
        minimap: this.$refs.minimapContainer.$el,
        viewParams,
        stats: this.$refs.statsRef.$el,
        viewParamsCallback: (newParams) => {
          let params = new URLSearchParams();
          params.append('mode', newParams.mode);
          params.append('tour_id', newTour.id);
          params.append('floor', newParams.floor ||this.floor.floor);
          if (newParams.mode === this.VIEWER_MODES.PANO) {
            params.append('yaw', newParams.yaw.toFixed(2));
            params.append('pitch', newParams.pitch.toPrecision(3));
            params.append('pano', newParams.pano);
          }

          let url = window.location.href;
          let oldSearch = url.split('?')[1];
          let newSearch = params.toString();
          if (oldSearch !== newSearch) {
            window.history.replaceState(null, null, '?' + newSearch);
          }
        }
      };

      const data = {...newTour};
      const floorData = {
        panos: this.panos,
        floorplan: this.floor.floorplan,
        model: this.floor.model,
      }

      Object.keys(data).forEach(function (key) {
        config[key] = data[key];
      });

      this.set_viewer(window.viewer3d.viewer(config));
      this.viewer.initViewer(floorData);
    },
  },
  computed: {
    ...mapState('layout', ['appState', 'visited', 'isError', 'allFloorsProcessed', 'hiddenUI']),
    ...mapState('viewer', ['tour', 'viewer']),
    ...mapState('panos', ['panos', 'panoList']),
    ...mapState('rooms', ['rooms']),
    ...mapState('floors', ['entryArrow', 'floor', 'floors']),
    ...mapState('controls', ['showStatistics', 'showNames', 'mode', 'panoShowNames', 'showTooltips']),
    ...mapState('controls', ['fullscreen']),
    rotation() {
      const pano = {...this.panos[this.viewer.imagePlaneCamera]}
      return pano && pano.rotation ? pano.rotation[2] : 0;
    },
    hideMinimap() {
      return this.mode !== this.VIEWER_MODES.PANO
          || this.isMobile
          || !Object.entries(this.panos).length
          || this.hasAccess('panos')
          || this.appState !== this.appStates.ready
          || this.controlsState === this.CONTROLS_STATE.MOVE_FEATURE
    }
  },
}
</script>
<style>
#main {
  height: 100%;
  position: relative;
  overflow: hidden;
}
</style>
