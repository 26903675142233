import { getFloorData } from './floors';
import firebase from 'firebase/app';
import 'firebase/functions';

const {VUE_APP_DefaultTour} = process.env

export const getTourById = async function (id, name, unique) {
  const tourUrl = `tours/${id}`;

  /*
    Increment views count of the tour if the visitor is unique
   */
  if (unique) {
    const incrementView = firebase.functions().httpsCallable('tours-incrementView');
    incrementView({'tour_id': tourUrl}).then();
  }

  /*
    Getting tour details data
   */
  
  let tourData

   if (tourUrl === `tours/${VUE_APP_DefaultTour}`) {
     //fetch data from public/JSON
     try {
       const tourDetailsJSON = await fetch(`./${VUE_APP_DefaultTour}_Details.json`)
       tourData = await tourDetailsJSON.json()
     } catch(err) {console.warn('loading default tour details from server')}
  }

  if (tourUrl === 'tours/IAyFfMtzV9jipI5izvyz') {
    //49 prince
    try {
      const tourDetailsJSON = await fetch(`./IAyFfMtzV9jipI5izvyz_Details.json`)
      tourData = await tourDetailsJSON.json()
    } catch(err) {console.warn('loading tour details from server')}
  }

  if (tourUrl === 'tours/bkxOfGr5ni2Iek7zT6LK') {
    //sun inn
    try {
      const tourDetailsJSON = await fetch(`./bkxOfGr5ni2Iek7zT6LK_Details.json`)
      tourData = await tourDetailsJSON.json()
    } catch(err) {console.warn('loading tour details from server')}
  }


  if (!tourData) {
    // fetch data from server
    const get_tour_details = firebase.functions().httpsCallable('tours-getTourDetails');
    tourData = await get_tour_details({'tour_id': tourUrl})
  }

  let floors = [];
  let panoPromises = [];
  let tourResult = tourData.data;
  let floorsResult = tourResult.details;
  let labels = tourResult.labels

  let floorName = (floorsResult.find(f => name === f.floor) || {}).floor || floorsResult[0].floor;

  let tour = {
    id,
    name: tourResult.name || tourResult.id,
    address: tourResult.address || '',
    description: tourResult.description,
    contactInfo: tourResult.contactInfo,
    floorCount: tourData.data.details.length
  }

    return {
      tourProgress: tourResult.tourProgress,
      tour,
      getFloor: async () => {
        /*
        Getting current floor data
       */
        let currentFloorIndex;
        let currentFloorData = floorsResult.find((fl, index) => {
          if (fl.floor === floorName) {
            currentFloorIndex = index;
            return true;
          }
          return false;
        })

        let currentFloor;
        const currentFloorResult = await getFloorData(currentFloorData, tourUrl);
        currentFloor = currentFloorResult.floor;

        currentFloor.labels = currentFloorData.labels || []
        const rooms = currentFloor.rooms.map(room => room.id)
        if (labels.length) currentFloor.labels = labels.filter(label => rooms.indexOf(label.room)>= 0).map((label) => {
          return {...label, floor: currentFloor.floor}
        })

        return currentFloorResult.panosPromise.then(panos => {
          currentFloor.panos = panos;
          floorsResult.splice(currentFloorIndex, 1);

          return {
            floor: currentFloor,
            getFloors: async () => {
              /*
                Getting another floors data
               */
              for (let i = 0; i < floorsResult.length; i++) {
                let iterateFloor = floorsResult[i];
                const floorData = await getFloorData(iterateFloor, tourUrl).catch(() => null);
                if (!floorData) {
                  continue;
                }
                let floor = floorData.floor;
                floor.labels = iterateFloor.labels || []
                const rooms = floor.rooms.map(room => room.id)
                if (labels.length) floor.labels = labels.filter(label => rooms.indexOf(label.room)>= 0).map((label) => {
                  return {...label, floor: floor.floor}
                })

                panoPromises.push(floorData.panosPromise);
                floors.push(floor);
              }

              /*
                Setting panos data to each floor
               */
              return Promise.all(panoPromises).then(data => {
                floors.map((fl, index) => {
                  fl.panos = data[index];
                  return fl;
                })

                floors.push(currentFloor);

                return floors;
              })
            }
          }
        })
      }
    }
}
