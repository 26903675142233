<template>
  <div class="root">
    <div class="floorplan-camera mr-3">
      <h6 class="mb-0">Camera</h6>
      <p class="small text-secondary mb-1">Zoom</p>
      <div class="zoom btn-group btn-group-sm shadow mb-2">
          <button
              @mouseup="saveFloorplan()"
              @mousedown="cameraAction('zoom-out', 0.5)"
              @touchstart="cameraAction('zoom-out', 0.5)"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              class="btn-control btn-sm btn-icon btn btn-light"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + T`"
          >
            -5
          </button>
          <button
              @mouseup="saveFloorplan()"
              @mousedown="cameraAction('zoom-out')"
              @touchstart="cameraAction('zoom-out')"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              class="btn-control btn-sm btn-icon btn btn-light"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Y`"
          >
            -1
          </button>
          <button
              @mouseup="saveFloorplan()"
              @mousedown="cameraAction('zoom-in')"
              @touchstart="cameraAction('zoom-in')"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              class="btn-control btn-sm btn-icon btn btn-light"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + U`"
          >
            +1
          </button>
          <button
              @mouseup="saveFloorplan()"
              @mousedown="cameraAction('zoom-in', 0.5)"
              @touchstart="cameraAction('zoom-in', 0.5)"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              class="btn-control btn-sm btn-icon btn btn-light"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + I`"
          >
            +5
          </button>
        </div>
      <p class="small text-secondary mb-1">Rotate</p>
      <div class="rotate btn-group btn-group-sm shadow mb-2">
        <button
            @mouseup="saveFloorplan()"
            @mousedown="cameraAction('rotate-left', 90)"
            @touchstart="cameraAction('rotate-left', 90)"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + G`"
        >
          -90&deg;
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="cameraAction('rotate-left')"
            @touchstart="cameraAction('rotate-left')"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + H`"
        >
          -1&deg;
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="cameraAction('rotate-right')"
            @touchstart="cameraAction('rotate-right')"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + J`"
        >
          +1&deg;
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="cameraAction('rotate-right', 90)"
            @touchstart="cameraAction('rotate-right', 90)"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + K`"
        >
          +90&deg;
        </button>
      </div>
      <p class="small text-secondary mb-1">Move</p>
      <div class="joystick d-flex flex-column align-items-center">
          <div class="joystick-shape"></div>
          <div class="btn-group btn-group-sm btn-group-vertical">
            <button
                @mouseup="saveFloorplan()"
                @mousedown="cameraAction('move-up', 0.5)"
                @touchstart="cameraAction('move-up', 0.5)"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + W`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-double-up"></i>
            </button>
            <button
                @mouseup="saveFloorplan()"
                @mousedown="cameraAction('move-up')"
                @touchstart="cameraAction('move-up')"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + W`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-up"></i>
            </button>
          </div>
          <div class="d-flex">
            <div class="btn-group btn-group-sm mr-btn-control-sm">
              <button
                  @mouseup="saveFloorplan()"
                  @mousedown="cameraAction('move-left', 0.5)"
                  @touchstart="cameraAction('move-left', 0.5)"
                  @touchend="saveFloorplan()"
                  @touchmove="saveFloorplan()"
                  v-b-tooltip.hover.topright.html.v-dark="!isTouchable && `Alt + Shift + A`"
                  class="btn-control btn-light btn-sm btn-icon">
                <i class="las la-angle-double-left"></i>
              </button>
              <button
                  @mouseup="saveFloorplan()"
                  @mousedown="cameraAction('move-left')"
                  @touchstart="cameraAction('move-left')"
                  @touchend="saveFloorplan()"
                  @touchmove="saveFloorplan()"
                  v-b-tooltip.hover.topright.html.v-dark="!isTouchable && `Alt + A`"
                  class="btn-control btn-light btn-sm btn-icon">
                <i class="las la-angle-left"></i>
              </button>
            </div>
            <div class="btn-group btn-group-sm">
              <button
                  @mouseup="saveFloorplan()"
                  @mousedown="cameraAction('move-right')"
                  @touchstart="cameraAction('move-right')"
                  @touchend="saveFloorplan()"
                  @touchmove="saveFloorplan()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + D`"
                  class="btn-control btn-light btn-sm btn-icon">
                <i class="las la-angle-right"></i>
              </button>
              <button
                  @mouseup="saveFloorplan()"
                  @mousedown="cameraAction('move-right', 0.5)"
                  @touchstart="cameraAction('move-right', 0.5)"
                  @touchend="saveFloorplan()"
                  @touchmove="saveFloorplan()"
                  v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + D`"
                  class="btn-control btn-light btn-sm btn-icon">
                <i class="las la-angle-double-right"></i>
              </button>
            </div>
          </div>
          <div class="btn-group btn-group-sm btn-group-vertical">
            <button
                @mouseup="saveFloorplan()"
                @mousedown="cameraAction('move-down')"
                @touchstart="cameraAction('move-down')"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + S`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-down"></i>
            </button>
            <button
                @mouseup="saveFloorplan()"
                @mousedown="cameraAction('move-down', 0.5)"
                @touchstart="cameraAction('move-down', 0.5)"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + S`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-double-down"></i>
            </button>
          </div>
        </div>
    </div>
    <div class="floorplan-image">
      <h6 class="mb-0">Floorplan</h6>
      <p class="small text-secondary mb-1">Scale</p>
      <div class="scale btn-group btn-group-sm shadow mb-2">
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('scale-down', 0.05)"
            @touchstart="floorplanAction('scale-down', 0.05)"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + T`"
        >
          -5
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('scale-down')"
            @touchstart="floorplanAction('scale-down')"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + Y`"
        >
          -1
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('scale-up')"
            @touchstart="floorplanAction('scale-up')"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + U`"
        >
          +1
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('scale-up', 0.05)"
            @touchstart="floorplanAction('scale-up', 0.05)"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + I`"
        >
          +5
        </button>
      </div>
      <p class="small text-secondary mb-1">Rotate</p>
      <div class="scale btn-group btn-group-sm shadow mb-2">
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('rotate-left', 90)"
            @touchstart="floorplanAction('rotate-left', 90)"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + G`"
        >
          -90&deg;
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('rotate-left')"
            @touchstart="floorplanAction('rotate-left')"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + H`"
        >
          -1&deg;
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('rotate-right')"
            @touchstart="floorplanAction('rotate-right')"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + J`"
        >
          +1&deg;
        </button>
        <button
            @mouseup="saveFloorplan()"
            @mousedown="floorplanAction('rotate-right', 90)"
            @touchstart="floorplanAction('rotate-right', 90)"
            @touchend="saveFloorplan()"
            @touchmove="saveFloorplan()"
            class="btn-control btn-sm btn-icon btn btn-light"
            v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + K`"
        >
          +90&deg;
        </button>
      </div>
      <p class="small text-secondary mb-1">Move</p>
      <div class="joystick d-flex flex-column align-items-center">
        <div class="joystick-shape"></div>
        <div class="btn-group btn-group-sm btn-group-vertical">
          <button
              @mouseup="saveFloorplan()"
              @mousedown="floorplanAction('move-up', 0.1)"
              @touchstart="floorplanAction('move-up', 0.1)"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + &uarr;`"
              class="btn-control btn-light btn-sm btn-icon">
            <i class="las la-angle-double-up"></i>
          </button>
          <button
              @mouseup="saveFloorplan()"
              @mousedown="floorplanAction('move-up')"
              @touchstart="floorplanAction('move-up')"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + &uarr;`"
              class="btn-control btn-light btn-sm btn-icon">
            <i class="las la-angle-up"></i>
          </button>
        </div>
        <div class="d-flex">
          <div class="btn-group btn-group-sm mr-btn-control-sm">
            <button
                @mouseup="saveFloorplan()"
                @mousedown="floorplanAction('move-left', 0.1)"
                @touchstart="floorplanAction('move-left', 0.1)"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.topright.html.v-dark="!isTouchable && `Alt + Shift + &larr;`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-double-left"></i>
            </button>
            <button
                @mouseup="saveFloorplan()"
                @mousedown="floorplanAction('move-left')"
                @touchstart="floorplanAction('move-left')"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.topright.html.v-dark="!isTouchable && `Alt + &larr;`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-left"></i>
            </button>
          </div>
          <div class="btn-group btn-group-sm">
            <button
                @mouseup="saveFloorplan()"
                @mousedown="floorplanAction('move-right')"
                @touchstart="floorplanAction('move-right')"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + &rarr;`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-right"></i>
            </button>
            <button
                @mouseup="saveFloorplan()"
                @mousedown="floorplanAction('move-right', 0.1)"
                @touchstart="floorplanAction('move-right', 0.1)"
                @touchend="saveFloorplan()"
                @touchmove="saveFloorplan()"
                v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + &rarr;`"
                class="btn-control btn-light btn-sm btn-icon">
              <i class="las la-angle-double-right"></i>
            </button>
          </div>
        </div>
        <div class="btn-group btn-group-sm btn-group-vertical">
          <button
              @mouseup="saveFloorplan()"
              @mousedown="floorplanAction('move-down')"
              @touchstart="floorplanAction('move-down')"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + &darr;`"
              class="btn-control btn-light btn-sm btn-icon">
            <i class="las la-angle-down"></i>
          </button>
          <button
              @mouseup="saveFloorplan()"
              @mousedown="floorplanAction('move-down', 0.1)"
              @touchstart="floorplanAction('move-down', 0.1)"
              @touchend="saveFloorplan()"
              @touchmove="saveFloorplan()"
              v-b-tooltip.hover.top.html.v-dark="!isTouchable && `Alt + Shift + &darr;`"
              class="btn-control btn-light btn-sm btn-icon">
            <i class="las la-angle-double-down"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    name: 'floorplan-controls',
    methods: {
      cameraAction(type, amount) {
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.viewer.initCameraAction(type, amount);
          }, 100);
        }
      },
      floorplanAction(type, amount) {
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.viewer.initFloorplanAction(type, amount);
          }, 100);
        }
      },
      saveFloorplan() {
        if (this.interval) {
          this.viewer.saveFloorplan();
          clearInterval(this.interval);
          this.interval = null;
        }
      },
      handleKeyUp(e) {
        switch (e.code) {
          case 'KeyI':
          case 'KeyU':
          case 'KeyY':
          case 'KeyT':
          case 'KeyW':
          case 'KeyA':
          case 'KeyS':
          case 'KeyD':
          case 'KeyG':
          case 'KeyH':
          case 'KeyJ':
          case 'KeyK':
          case 'ArrowRight':
          case 'ArrowUp':
          case 'ArrowDown':
          case 'ArrowLeft':
            this.saveFloorplan();
            break;
          default:
            break;
        }
      },

      handleKeyDown(e) {
        if (e.altKey) {
          if (e.shiftKey) {
            switch (e.code) {
              case 'KeyI':
                this.floorplanAction('scale-up', 0.05);
                break;
              case 'KeyU':
                this.floorplanAction('scale-up');
                break;
              case 'KeyY':
                this.floorplanAction('scale-down');
                break;
              case 'KeyT':
                this.floorplanAction('scale-down', 0.05);
                break;
              case 'KeyG':
                this.floorplanAction('rotate-left', 90);
                break;
              case 'KeyH':
                this.floorplanAction('rotate-left');
                break;
              case 'KeyJ':
                this.floorplanAction('rotate-right');
                break;
              case 'KeyK':
                this.floorplanAction('rotate-right', 90);
                break;
              case 'KeyW':
                this.cameraAction('move-up', 0.5);
                break;
              case 'KeyS':
                this.cameraAction('move-down', 0.5);
                break;
              case 'KeyD':
                this.cameraAction('move-right', 0.5);
                break;
              case 'KeyA':
                this.cameraAction('move-left', 0.5);
                break;
              case 'ArrowRight':
                this.floorplanAction('move-right', 0.1);
                break;
              case 'ArrowUp':
                this.floorplanAction('move-up', 0.1);
                break;
              case 'ArrowDown':
                this.floorplanAction('move-down', 0.1);
                break;
              case 'ArrowLeft':
                this.floorplanAction('move-left', 0.1);
                break;
              default:
                break;
            }
            return;
          }

          switch (e.code) {
            case 'KeyI':
              this.cameraAction('zoom-in', 0.5);
              break;
            case 'KeyU':
              this.cameraAction('zoom-in');
              break;
            case 'KeyY':
              this.cameraAction('zoom-out');
              break;
            case 'KeyT':
              this.cameraAction('zoom-out', 0.5);
              break;
            case 'KeyW':
              this.cameraAction('move-up');
              break;
            case 'KeyS':
              this.cameraAction('move-down');
              break;
            case 'KeyD':
              this.cameraAction('move-right');
              break;
            case 'KeyA':
              this.cameraAction('move-left');
              break;
            case 'KeyG':
              this.cameraAction('rotate-left', 90);
              break;
            case 'KeyH':
              this.cameraAction('rotate-left');
              break;
            case 'KeyJ':
              this.cameraAction('rotate-right');
              break;
            case 'KeyK':
              this.cameraAction('rotate-right', 90);
              break;
            case 'ArrowRight':
              this.floorplanAction('move-right');
              break;
            case 'ArrowUp':
              this.floorplanAction('move-up');
              break;
            case 'ArrowDown':
              this.floorplanAction('move-down');
              break;
            case 'ArrowLeft':
              this.floorplanAction('move-left');
              break;
            default:
              break;
          }
        }
      }
    },
    computed: {
      ...mapState('viewer', ['viewer'])
    },
    mounted() {
      window.addEventListener('keydown', this.handleKeyDown);
      window.addEventListener('keyup', this.handleKeyUp);
    },
    destroyed() {
      window.removeEventListener('keydown', this.handleKeyDown);
      window.removeEventListener('keyup', this.handleKeyUp);
    }
  }
</script>
<style lang="scss" scoped src="./FloorplanControls.scss"></style>
