<template>
  <div>
    <div class="menu-page menu-page-height" :class="{active: menuPage === MenuPages.TOUR_META}">
      <BackMenu :title="' Tour Information'" :no-back="true" @onBack="set_menu_page(MenuPages.MAIN)"
                class="mb-3"/>
      <div class="menu-page-padding overflow-auto">
        <TourMetaForm v-if="menuPage === MenuPages.TOUR_META" @onCancel="toggleMobileMenu(false),cancelProgress()" @onClose="closeForm()"/>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import TourMetaForm from '@/components/tour-meta/TourMetaForm';
import BackMenu from '../back-menu/BackMenu';
import { MenuPages } from '@/constants';


export default {
  name: 'MobileTourMeta',
  data() {
    return {
      MenuPages: MenuPages
    }
  },
  components: {BackMenu, TourMetaForm},
  computed: {
    ...mapState('mobile', ['menuPage']),
  },
  methods: {
    ...mapMutations('mobile', ['set_menu_page']),
    ...mapActions('rts', ['selectStep', 'toggleRTSMode']),
    ...mapActions('mobile', ['toggleMobileMenu']),
    cancelProgress() {
       this.toggleRTSMode(false)
       this.selectStep(null)
    },
    closeForm() {
        this.toggleMobileMenu(false)
    }
  }
}
</script>

<style scoped src="../MobilePage.scss" lang="scss"></style>
