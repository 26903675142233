<template>
  <div>
    <div class="d-flex flex-wrap mb-3 feature-list">
      <div class="badge badge-gray-light mb-1 mr-1 py-1 px-2" v-for="feat of features" :key="feat.id">
        {{feat.title}}
      </div>
    </div>
    <h6 class="font-weight-semi-bold mb-4">
      <span v-if="featuresForSale.length">Items For Sale</span>
      <span v-else>There are no items for sale in this tour</span>
    </h6>
    <ul class="list-unstyled feature-list-for-sale">
      <li v-for="feat of featuresForSale" :key="feat.id">
        <a class="my-2" v-if="feat.reference === REFERENCES.LISTING" :href="feat.listing_url" target="_blank" rel="noopener noreferrer">{{feat.title}}</a>
        <a v-else>{{feat.title}}{{' - $'+feat.owner.price}}</a>
      </li>
    </ul>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { REFERENCES } from '@/constants';

  export default {
    name: 'FeatureList',
    data() {
      return {
        REFERENCES: REFERENCES
      }
    },
    computed: {
      ...mapState('features', ['features']),
      featuresForSale() {
        return this.features.filter(feat => feat.forSale)
      }
    }
  }
</script>
<style scoped lang="scss" src="./FeatureList.scss"></style>
