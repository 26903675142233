import {getTourById} from '@/api/tours';
import firebase from 'firebase/app';
import 'firebase/functions';
import { AppStates, RTSLabels } from '@/constants';
import { update } from 'lodash';

const {VUE_APP_DefaultTour} = process.env

export default {
  namespaced: true,
  state: () => ({
    tour: {},
    viewer: {},
  }),
  mutations: {
    set_tour(state, tour) {
      state.tour = {...state.tour, ...tour};

    },
    set_viewer(state, viewer) {
      state.viewer = viewer.getViewer;
    },
  },
  actions: {
    async loadTour({commit, rootState, state, dispatch}) {
      commit('layout/toogle_app_state', AppStates.init, {root: true});
      commit('layout/set_error', false, {root: true});

      try {
        const search = (new URL(document.location)).searchParams;
        const id = search.get('tour_id') || VUE_APP_DefaultTour;
        const floor = search.get('floor') || '';

        let isTourVisitUnique = false;

        if (id) {
          const toursVisitedList = rootState.layout.toursVisited.split(',').filter(t => t !== '');
          isTourVisitUnique = !toursVisitedList.find(t => t === id);
          if (isTourVisitUnique) {
            toursVisitedList.push(id);
            localStorage.setItem('tours', toursVisitedList.join(','));
          }
        }
        getTourById(id, floor, isTourVisitUnique).then(tourRes => {
            commit('set_tour', tourRes.tour);
            if (tourRes.tourProgress) {
              dispatch('rts/setProgress', tourRes.tourProgress, {root: true});
            }

          tourRes.getFloor().then(floorRes => {
            commit('floors/set_floor', floorRes.floor, {root: true});
            commit('rooms/set_rooms', floorRes.floor.rooms, {root: true});
            commit('panos/set_panos', floorRes.floor.panos, {root: true});
            commit('features/set_features', floorRes.floor.labels, {root: true});

            floorRes.getFloors().then(floorsRes => {
              dispatch('floors/setFloors', floorsRes, {root: true});
              state.viewer.updateConfig({floors: floorsRes});
              if (floorsRes.length === 1) {
                commit('rts/update_progress', {key: RTSLabels.floorNames, value: true}, {root: true});
              }
            }).catch((e) => {
              commit('layout/set_error', true, {root: true});
              throw new Error(e);
            });
          }).catch((e) => {
            commit('layout/set_error', true, {root: true});
            throw new Error(e);
          });
        }).catch((e) => {
          commit('layout/set_error', true, {root: true});
          throw new Error(e);
        });

      } catch (e) {
        commit('layout/set_error', true, {root: true});
        throw new Error(e);
      }
    },
    updateTour({commit, state, dispatch}, data) {
      let tourId = `tours/${state.tour.id}`;
      let update = firebase.functions().httpsCallable('tours-updateTour'); //update function name
      commit('layout/toggle_confirm_button', true, {root: true});
      return update({tourId, ...data}).then(() => {
        commit('set_tour', data);
        dispatch('rts/updateProgress', {key: RTSLabels.tourInfo, value: true} , {root: true})
      }).finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
    },
    reportProblem({tour, type, floor, message,state}) {
      let tourId = `tours/${state.tour.id}`;
      let currentFloor = state.viewer.FloorManager.currentFloor
      let report = firebase.functions().httpsCallable('problemReports-createProblemReport');
      //console.log('problem REPORTED!')
      return report({tourId,debug:{"prop":"value"},feedback:'testing321321'});
    }
  }
}
