import { CONTROLS_STATE } from '@/constants';

export default {
  [CONTROLS_STATE.MEASURE_DISTANCE]: {
    icon: 'DistanceIcon',
    title: 'Measure Tool',
    content: `<p>Click or tap once, then drag to another point to measure the distance.</p>
        <p><span class="font-weight-bold">RIGHT CLICK</span> to clear all lines you have created.</p>
        <p>Press <span class="font-weight-bold">ESCAPE</span> to exit the Measure Tool.</p>`
  },
  [CONTROLS_STATE.MOVE_FEATURE]: {
    icon: 'Tag',
    title: 'Add Feature Label',
    content: `<p>Click or tap once on the item you want to a feature.</p>
        <p><span class="font-weight-bold">RIGHT CLICK</span> to move view tag.</p>
        <p>Press <span class="font-weight-bold">ESCAPE</span> to exit the Feature Label Tool.</p>`
  },
  [CONTROLS_STATE.MEASURE_AREA]: {
    icon: 'Area',
    title: 'Area Tool',
    content: `<p>Click or tap inside a space to being creating a shape to calculate area.</p>
        <p><span class="font-weight-bold">RIGHT CLICK</span> to clear all shapes you have created.</p>
        <p>Press <span class="font-weight-bold">ESCAPE</span> to exit the Area Tool.</p>`
  }
}
