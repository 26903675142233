<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0.25 12C0.25 5.52474 5.52474 0.25 12 0.25C18.4753 0.25 23.75 5.52474 23.75 12C23.75 18.4753 18.4753 23.75 12 23.75C5.52474 23.75 0.25 18.4753 0.25 12ZM2.41667 12C2.41667 17.2847 6.71526 21.5833 12 21.5833C17.2847 21.5833 21.5833 17.2847 21.5833 12C21.5833 6.71526 17.2847 2.41667 12 2.41667C6.71526 2.41667 2.41667 6.71526 2.41667 12Z"/>
    <path
        d="M10.8994 8.18746L10.8992 8.1872L10.8899 8.19651C10.6377 8.44874 10.4846 8.74666 10.4347 9.08328H9.33333H8.25797C8.31747 8.15424 8.70839 7.29917 9.37522 6.63161C10.0992 5.93345 11.0733 5.55819 12.1004 5.58321C14.0471 5.6332 15.6998 7.28581 15.7501 9.23247C15.7749 10.4345 15.2233 11.6095 14.2508 12.3327L14.2508 12.3327L14.2487 12.3342C13.5573 12.8597 13.1158 13.6119 13.0851 14.4166H10.9179C10.9302 13.8521 11.0325 13.3334 11.2447 12.8146L11.2447 12.8146L11.2459 12.8116C11.5747 11.977 12.1581 11.2141 12.949 10.6274L12.949 10.6274L12.9524 10.6248C13.3669 10.3059 13.5833 9.8026 13.5833 9.30662H13.5835L13.5831 9.29701C13.5515 8.47451 12.8624 7.78411 12.0404 7.7503C11.5797 7.71875 11.1972 7.91949 10.8994 8.18746Z"/>
    <path d="M10.916 18.4167V16.25H13.0827V18.4167H10.916Z"/>
  </svg>
</template>
<script>
export default {
  name: 'HintIcon'
}
</script>
