<template>
  <div class="root">
    <div class="orbit btn-group shadow">
      <button v-if="this.floor.floorplan.init_view.position"
          @mousedown="goToView()"
          @touchstart="goToView()"
          class="btn-control btn-icon btn btn-light"
          v-b-tooltip.hover.top.html="!isTouchable && `Go to View`"
      >
        <i class="las la-crosshairs"></i>
      </button>
      <button
          @mousedown="saveView()"
          @touchstart="saveView()"
          class="btn-control btn-icon btn btn-light"
          v-b-tooltip.hover.top.html="!isTouchable && `Save View`"
      >
        <i class="las la-save"></i>
      </button>
      <button
              @mousedown="resetView()"
              @touchstart="resetView()"
              class="btn-control btn-icon btn btn-light"
              v-b-tooltip.hover.top.html="!isTouchable && `Reset View`"
      >
        <i class="las la-redo-alt"></i>
      </button>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    name: 'orbit-controls',
    methods: {
      goToView() {
        if (this.floor.floorplan.init_view.position) {
          this.viewer.controlsPosition = {...this.floor.floorplan.init_view.position};
        }
      },
      saveView() {
        console.log('save init view')
        this.viewer.saveFloorplan(true);
      },
      resetView() {
        this.viewer.controlsPosition = {
          x: 0,
          y: 0,
          z: 0
        }
        this.saveView();
      }
    },
    computed: {
      ...mapState('viewer', ['viewer', 'tour']),
      ...mapState('floors', ['floor'])
    }
  }
</script>
<style lang="scss" scoped src="./OrbitControls.scss"></style>
