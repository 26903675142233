<template>
  <div class="tour-meta" :class="{'tour-meta iframe': inIframe}">
    <template v-if="!isMobile || !editMode">
      <h4 class="font-weight-semi-bold mb-0">
        <EditIcon @click.native="$bvModal.show(tourEditModalId)" v-if="editMode && !isMobile" class="mr-3 edit-icon"/>
        <span @click="$bvModal.show(tourDetailsModalId)" class="meta-title">{{ tour.name }}</span>
      </h4>
      <p class="font-weight-light mb-3" v-if="tour && tour.address !== 'not set'">{{ tour.address }}</p>
      <p class="font-weight-light mb-3 tour-description" v-if="tour && tour.description && !isMobile">{{ tour.description }}</p>
    </template>
    
    <b-modal 
        :id="tourEditModalId"
        hide-header
        hide-footer
        @keypress.esc="$bvModal.hide(tourEditModalId)"
        dialog-class="modal-mobile"
    >
      <template #default="{}" v-if="!isMobile">
        <CloseIcon @click.native="$bvModal.hide(tourEditModalId)"/>
        <h5 class="header font-weight-semi-bold text-center mb-4">Tour Information</h5>
        <TourMetaForm @onCancel="cancelProgress()" @onClose="closeForm()"/>
      </template>
    </b-modal>
    <b-modal
        :id="tourDetailsModalId"
        hide-header
        hide-footer
        size="sm"
        @keypress.esc="$bvModal.hide(tourDetailsModalId)"
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(tourDetailsModalId)"/>
        <TourMetaDetails @showOwner="showOwner()"/>
      </template>
    </b-modal>
    <b-modal
        v-if="tour.contactInfo"
        :id="tourOwnerModalId"
        hide-header
        hide-footer
        size="sm"
        @keypress.esc="hideOwner()"
    >
      <template #default="{}">
        <CloseIcon @click.native="hideOwner()"/>
        <OwnerDetails :data="tour.contactInfo"/>
      </template>
    </b-modal>
  </div>
</template>
<script>

 import { mapState, mapActions } from 'vuex';
 import { EditIcon } from '@/components/svg-icons';
 import TourMetaForm from '@/components/tour-meta/TourMetaForm';
 import TourMetaDetails from '@/components/tour-meta/TourMetaDetails';
 import OwnerDetails from '@/components/owner-details/OwnerDetails';

 export default {
   name: 'TourMeta',
   components: {OwnerDetails, TourMetaDetails, TourMetaForm, EditIcon},
   computed: {
     ...mapState('viewer', ['tour'])
   },
   methods: {
    ...mapActions('rts', ['selectStep', 'toggleRTSMode']),
     showOwner() {
       this.$bvModal.hide(this.tourDetailsModalId);
       this.$bvModal.show(this.tourOwnerModalId);
     },
     hideOwner() {
       this.$bvModal.hide(this.tourOwnerModalId);
       this.$bvModal.show(this.tourDetailsModalId);
     },
     cancelProgress() {
       this.toggleRTSMode(false)
       this.selectStep(null)
       this.$bvModal.hide(this.tourEditModalId)
     },
      closeForm() {
        this.$bvModal.hide(this.tourEditModalId)
      }
   }
 }
</script>
<style src="./TourMeta.scss" scoped lang="scss"></style>
