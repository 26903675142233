<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 35 29"
    
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.0777 12.6494L20.6396 19.64V25.6793L33.0777 18.6887V12.6494Z"
      
    />
    <path
      d="M24.361 21.1041C24.361 21.4548 24.0857 21.8179 23.5353 22.1263L22.4756 22.7323V20.7028L23.4761 20.1296C23.988 19.8375 24.2509 19.8976 24.2509 20.2142C24.2358 20.4413 24.138 20.6552 23.9756 20.8161C24.2137 20.7588 24.361 20.8543 24.361 21.1041ZM22.9531 20.7874V21.2638L23.4252 20.9908C23.6564 20.8543 23.7816 20.7042 23.7816 20.5459C23.7816 20.3875 23.6564 20.3794 23.4252 20.5117L22.9531 20.7874ZM23.8834 21.3334C23.8834 21.1628 23.7458 21.1573 23.5064 21.2979L22.9559 21.6145V22.1154L23.5064 21.7988C23.7527 21.6514 23.8834 21.5012 23.8834 21.3265V21.3334Z"
     
    />
    <path
      d="M26.6549 19.9656V20.3423L25.0723 21.2499V19.2163L26.6191 18.3306V18.7086L25.5429 19.3241V19.765L26.4925 19.2191V19.5848L25.5429 20.1308V20.6003L26.6549 19.9656Z"
     
    />
    <path
      d="M27.8074 18.0359L27.1523 18.4099V18.0277L28.9414 17.0068V17.389L28.2863 17.7643V19.4103L27.8115 19.6832L27.8074 18.0359Z"
     
    />
    <path
      d="M30.786 17.543L29.8364 18.0889L29.6547 18.628L29.1689 18.9009L30.0814 16.3501L30.5493 16.0771L31.4644 17.5784L30.9676 17.8623L30.786 17.543ZM30.6373 17.27L30.3126 16.679L29.9878 17.6344L30.6373 17.27Z"
     
    />
    <path
      d="M33.2322 8.43103L18.6514 0.108398V9.79038L24.7507 13.272L33.2322 8.43103Z"
      
    />
    <path
      d="M10.9639 13.272L17.0632 9.79038V0.108398L2.48242 8.43103L10.9639 13.272Z"
      
    />
    <path
      d="M17.0621 28.4536L0.712891 19.121V9.44043L17.0621 18.7717V28.4536Z"
     
    />
    <path
      d="M35.0006 9.44043L18.6514 18.7717V28.4536L35.0006 19.121V9.44043Z"
      
    />
  </svg>
</template>
<script>
export default {
  name: "OrbitIcon",
};
</script>
