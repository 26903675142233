<template>
  <div class="d-flex align-items-center">
    <CloseIcon :class="{mobile: isMobile}" @click.native="$bvModal.hide(emailSignInFormId)"/>
    <div class="sign-in-wrapper">
      <img class="logo" :src="logoBigBeta" alt="logo" v-if="isMobile"/>
      <h5 class="header font-weight-semi-bold text-center mb-5 mt-3">Sign in with Email</h5>
      <ValidationObserver v-slot="{ invalid }">
        <form class="sign-in-form" @submit.prevent="signIn()">
          <div class="form-group">
            <label for="email">Email*</label>
            <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
              <input class="form-control" type="text" v-model="email" required placeholder="name@name.com" id="email">
              <InvalidFeedback :errors="errors"/>
            </ValidationProvider>
          </div>
          <div class="text-right small mb-n3">
            <a href="#" @click.prevent="showForgot()">Forgot Password</a>
          </div>
          <div class="form-group">
            <label for="password">Password*</label>
            <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
              <input class="form-control" type="password" v-model="password" required placeholder="Enter password"
                     id="password">
              <InvalidFeedback :errors="errors"/>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <div class="checkbox-radio">
              <input type="checkbox" id="keep" :checked="keep" @change="keep = !keep">
              <label class="text-color font-size-sm mb-0" for="keep">Keep me signed in</label>
            </div>
          </div>
          <div class="text-center">
            <button :disabled="invalid" type="submit" class="btn rounded-pill btn-primary font-weight-normal px-5 py-2">SIGN IN</button>
          </div>
        </form>
      </ValidationObserver>

      <div class="form-divider">
        <div class="line"></div>
        <div class="mx-3">Or</div>
        <div class="line"></div>
      </div>
      <div class="btn-toolbar btn-providers justify-content-center">
        <button @click="signInByProvider('facebook', signInCallback)"
                class="btn font-weight-semi-bold btn-outline-primary btn-lg rounded-pill">
          <FacebookIcon/>
        </button>
        <button @click="signInByProvider('google', signInCallback)"
                class="btn font-weight-semi-bold btn-outline-primary btn-lg rounded-pill">
          <GoogleIcon/>
        </button>
        <button @click="signInByProvider('apple', signInCallback)"
                class="btn font-weight-semi-bold btn-outline-primary btn-lg rounded-pill">
          <AppleIcon/>
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import FacebookIcon from '@/components/svg-icons/auth/facebook';
import { SignInProviders } from '@/mixins/sign-in-providers';

import logoBigBeta from '@/assets/logo_big_beta.svg';

import firebase from 'firebase/app';
import 'firebase/auth';
import GoogleIcon from '@/components/svg-icons/auth/google';
import AppleIcon from '@/components/svg-icons/auth/apple';

export default {
  name: 'AuthEmail',
  components: {AppleIcon, GoogleIcon, FacebookIcon},
  mixins: [SignInProviders],
  data() {
    return {
      email: '',
      password: '',
      keep: true,
      logoBigBeta
    }
  },
  methods: {
    signInCallback() {
      this.$bvModal.hide(this.emailSignInFormId);
    },
    showForgot() {
      this.$bvModal.hide(this.emailSignInFormId)
      this.$bvModal.show(this.forgotPassFormId)
    },
    async signIn() {
      const auth = firebase.auth();

      await auth.setPersistence(
          this.keep
              ? firebase.auth.Auth.Persistence.LOCAL
              : firebase.auth.Auth.Persistence.NONE,
      );

      auth.signInWithEmailAndPassword(this.email, this.password).then(() => {
        this.signInCallback();
      }).catch((error) => {
        this.handleError(error);
      });

    }
  },
}
</script>
<style scoped lang="scss" src="./AuthEmail.scss"></style>
