import firebase from 'firebase/app';
import 'firebase/functions';
import { CONTROLS_STATE } from '@/constants';

export default {
  namespaced: true,
  state: () => ({
    entryArrow: {
      x: 0,
      y: 0
    },
    floors: [],
    floor: {},
  }),
  mutations: {
    update_entry_arrow(state, data) {
      state.entryArrow = {...state.entryArrow, ...data};
    },
    set_floors(state, floors) {
      state.floors = floors ? [...floors] : [];
    },
    set_floor(state, floor) {
      state.floor = floor ? {...floor} : {};
    }
  },
  actions: {
    setFloors({commit}, floors) {
      let floorsDesc = [...floors];
      floorsDesc.sort((fl1, fl2) => Number(fl2.floor) - Number(fl1.floor));
      commit('set_floors', floorsDesc);
    },
    setAllFloors({commit,state,rootState}) {
    
        rootState.viewer.viewer.FloorManager.activateAllFloors()
        state.floors.forEach(newFloor => {
          if(newFloor.floor === state.floor.floor){
            return;
          }else{
            commit('panos/append_panos', newFloor.panos, {root: true});
            commit('rooms/append_rooms', newFloor.rooms, {root: true});
            commit('features/append_features', newFloor.labels, {root: true});
          }
        });
        
        rootState.viewer.viewer.refreshFeatures()
        rootState.viewer.viewer.setMovingMode('orbit', {frameall: true})
        rootState.viewer.viewer.viewAll = true
      
    },
    changeFloor({commit, state, rootState, dispatch}, floorName) {
      if (floorName === state.floor.floor) {
        return;
      }
      if (rootState.controls.editMode) {
        dispatch('controls/setControlsState', CONTROLS_STATE.NULL, {root: true})
      }

      let newFloor = state.floors.find(fl => fl.floor === floorName);

      if (newFloor) {
        let gate = !(newFloor.panos === undefined && rootState.viewer.viewer.currentMode === 'walk')
        if(gate) commit('panos/set_panos', newFloor.panos, {root: true});
        commit('rooms/set_rooms', newFloor.rooms, {root: true});
        commit('features/set_features', newFloor.labels, {root: true});
        if(gate) commit('set_floor', newFloor);
        rootState.viewer.viewer.labelO.refreshFeatures([...state.floor.labels])
        if(gate) rootState.viewer.viewer.changeFloor(floorName);
      }
    },
    isolateFloorLabels({commit, state, rootState, dispatch}, floorName) {
      let newFloor = state.floors.find(fl => fl.floor === floorName);
      if (newFloor) {
        commit('panos/set_panos', newFloor.panos, {root: true});
        commit('rooms/set_rooms', newFloor.rooms, {root: true});
        commit('features/set_features', newFloor.labels, {root: true});
      }
    },
    async updateFloorplan(context, {floorplan, RTSLabel}) {
      let update_floorplan = firebase.functions().httpsCallable('helpers-updateFloorplan');
      await update_floorplan({...floorplan, floor_id: floorplan.id});
      let {floor, floors} = prepareFloorplanDataForUpdate(context, floorplan, floorplan.id);
      if (floor) {
        context.commit('set_floor', floor);
      }
      context.commit('set_floors', floors);
      if (RTSLabel) {
        context.dispatch('rts/updateProgress', {key: RTSLabel, value: true}, {root: true})
      }
    },
  }
}

function prepareFloorplanDataForUpdate(context, floorplan, id) {
  let floors = context.state.floors; 
  let currentFloorId = context.state.floor.floorplan.id;
  
  let floorIndexFromList;
  let isCurrentFloorUpdated = currentFloorId === id;
  let floor = floors.find((fl, index) => {
    if (fl.floorplan.id === id) {
      floorIndexFromList = index;
      return true;
    }
    return false;
  });

  floor.floorplan = {...floor.floorplan, ...floorplan};
  
  return {
    floor: isCurrentFloorUpdated ? floor: null,
    floors: floors.map((fl, index) => {
      if (index !== floorIndexFromList) {
        return fl;
      }
      
      return {...floor}
    })
  }
} 
