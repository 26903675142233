<template>
  <div>
    <b-modal
        :id="problemReportModalId"
        hide-header
        hide-footer
        @keypress.esc="$bvModal.hide(problemReportModalId)"
        no-close-on-backdrop
        hide-backdrop
        static
    >
      <template #default="{}">
        <CloseIcon @click.native="$bvModal.hide(problemReportModalId)"/>
        <h5 class="header font-weight-semi-bold text-center mb-4">Report a Problem</h5>
        <ProblemForm @onCancel="$bvModal.hide(problemReportModalId),cancelProgress()"/>
      </template>
    </b-modal>
  </div>
</template>
<script>

 import { mapState, mapActions } from 'vuex';
 import { EditIcon } from '@/components/svg-icons';
 import ProblemForm from '@/components/tour-meta/ProblemForm';

 export default {
   name: 'ProblemMeta',
   components: {ProblemForm, EditIcon},
   computed: {
     ...mapState('viewer', ['tour'])
     
   },
   methods: {
     ...mapActions('rts', ['selectStep','updateProgress','toggleRTSMode']),
      cancelProgress() {
      this.toggleRTSMode(false);
      this.selectStep(null)
    },
   }
 }
</script>
<style src="./ProblemMeta.scss" scoped lang="scss"></style>
