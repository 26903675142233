import * as THREE from 'three';

const vertexShader = `
  varying vec2 vUv;
  varying vec3 vPosition;

  void main() {
    vUv = uv;
    vPosition = vec4(viewMatrix * vec4( 0.0, 0.0, 2.0, 0.0 )).rgb;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
  }
`;

const fragmentShader = `
  varying vec2 vUv;
  varying vec3 vPosition;
  uniform sampler2D u_texture;
  uniform float threshold;

  void main() {
    vec4 texture = texture2D(u_texture, vUv);
    float deactive = pow(vPosition.z,3.0)-1.5;
    texture.rgb = floor(1.0 - texture.rgb);
    gl_FragColor = vec4(vec3(1.0),texture.a);//texture.a*deactive); FOR CAMERA ANGLE BASED FADING
    gl_FragColor.a *= max((1.0 / gl_FragCoord.w)-threshold,(1.0 / gl_FragCoord.z)-threshold);
  }
`;

/**
 * Create shader material for displaying panoramas.
 * @private
 */
export default function createFloorplanMaterial(texture,distanceToCam) {
  var uniforms = {
    u_texture: {type: 't', value: texture},
    threshold: {type: 'float', value: distanceToCam}
  };

  return new THREE.ShaderMaterial({
    transparent: true,
    uniforms: uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader
  });
}