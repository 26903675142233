<template>
  <svg width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M4.83107 0.441915C4.68295 0.221786 4.45345 0.0695135 4.19305 0.0185974C3.93266 -0.0323188 3.6627 0.0222917 3.44257 0.170415C3.22244 0.318539 3.07017 0.548041 3.01925 0.808436C2.96834 1.06883 3.02295 1.33879 3.17107 1.55892L7.96407 8.67291L5.63807 12.3509C4.75041 11.9525 3.74795 11.8912 2.81838 12.1786C1.88881 12.4661 1.09588 13.0825 0.588048 13.9124C0.0802178 14.7424 -0.107689 15.729 0.0595111 16.6875C0.226711 17.646 0.737551 18.5107 1.49639 19.1197C2.25522 19.7287 3.21002 20.0403 4.182 19.996C5.15398 19.9517 6.07651 19.5547 6.77684 18.8792C7.47718 18.2038 7.9073 17.2962 7.98668 16.3265C8.06605 15.3567 7.78924 14.3913 7.20807 13.6109L9.18507 10.4849L11.0691 13.2809C10.4071 13.9949 10.0283 14.9257 10.0036 15.899C9.97887 16.8722 10.31 17.8211 10.9348 18.5677C11.5597 19.3143 12.4354 19.8074 13.3978 19.9546C14.3602 20.1017 15.3432 19.8928 16.1626 19.367C16.982 18.8412 17.5815 18.0346 17.8487 17.0984C18.1159 16.1622 18.0325 15.1607 17.6141 14.2816C17.1957 13.4025 16.4711 12.7061 15.576 12.3231C14.6809 11.9401 13.6769 11.8966 12.7521 12.2009L9.13207 6.82592V6.82691L4.83107 0.441915ZM2.00107 16.0019C2.00107 15.4715 2.21179 14.9628 2.58686 14.5877C2.96193 14.2126 3.47064 14.0019 4.00107 14.0019C4.53151 14.0019 5.04021 14.2126 5.41529 14.5877C5.79036 14.9628 6.00107 15.4715 6.00107 16.0019C6.00107 16.5323 5.79036 17.0411 5.41529 17.4161C5.04021 17.7912 4.53151 18.0019 4.00107 18.0019C3.47064 18.0019 2.96193 17.7912 2.58686 17.4161C2.21179 17.0411 2.00107 16.5323 2.00107 16.0019ZM12.0011 16.0019C12.0011 15.4715 12.2118 14.9628 12.5869 14.5877C12.9619 14.2126 13.4706 14.0019 14.0011 14.0019C14.5315 14.0019 15.0402 14.2126 15.4153 14.5877C15.7904 14.9628 16.0011 15.4715 16.0011 16.0019C16.0011 16.5323 15.7904 17.0411 15.4153 17.4161C15.0402 17.7912 14.5315 18.0019 14.0011 18.0019C13.4706 18.0019 12.9619 17.7912 12.5869 17.4161C12.2118 17.0411 12.0011 16.5323 12.0011 16.0019Z"
        />
    <path
        d="M11.5208 6.79113L14.8458 1.53513C14.9877 1.31102 15.0347 1.03973 14.9766 0.780925C14.9185 0.522125 14.7599 0.297019 14.5358 0.155129C14.3117 0.0132378 14.0404 -0.033815 13.7816 0.0243212C13.5228 0.0824575 13.2977 0.241021 13.1558 0.465129L10.3008 4.98013L11.5208 6.79213V6.79113Z"
        />
  </svg>
</template>
<script>
export default {
  name: 'TrimIcon',
}
</script>
