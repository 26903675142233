<template>
  <div @wheel="$emit('onWheel', $event)" class="room-labels">
    <LabelAim @onCancel="cancelMove();" @onCreate="setRoomPosition($event)"
              v-if="isMove || isNew"/>
    <template v-if="showDependingOnControlsState">
       <div v-if="overlay" class="mobile-menu-backdrop"></div>
      <div class="label"
           :class="hasAccess('rooms')?
           {edited: room.id === selectedRoom.id} : 'locked'"
           v-for="(room, index) of rooms"
           :key="room.id"
           :style="roomsLabelMarkers[index] && isCoordsFitToScreen(roomsLabelMarkers[index]) ? {
             top: roomsLabelMarkers[index].y + 'px',
             left: roomsLabelMarkers[index].x + 'px',
             display: 'block'
           } : {
             display: 'none'
           }"
      >
        <div v-if="room.id !== selectedRoom.id" class="label-view"
             @click.stop="hasAccess('rooms') &&
           selectRoom(room.id)"
        >
          <span v-if="room.name">{{ room.name }}<span v-if="mode === VIEWER_MODES.FLOORPLAN && room.sqft">:</span></span>
          <span v-if="mode === VIEWER_MODES.FLOORPLAN && room.sqft" class="ml-3 font-weight-bold">{{ room.sqft }} Sq Ft</span>
           <span v-if="editMode"> ✏️ </span>
        </div>
        <div v-else class="label-edit">
          <CloseIcon @click.native="unselectRoom()"/>
          <div class="input-group input-group-name border-bottom">
            <input placeholder="Name"
                @keydown.enter="updateRoomById()"
                class="form-control form-control-name"
                @input="update_selected_room({name: $event.target.value})"
                :value="selectedRoom.name"
                
            >
            <!--:disabled="!isEdit"<EditIcon v-if="!isSQFT"
            <EditIcon 
                @click.native="update_selected_room({state: SELECTED_ROOM_STATES.NAME})"
                class="edit-icon"
                :class="{active: isEdit}"
            />-->
          </div>
          
          <!--<div class="input-group input-group-name" v-if="isSQFT">-->
          <div class="input-group input-group-name">
            <input placeholder="0"
                @keydown.enter="updateRoomById()"
                class="form-control form-control-sqft"
                type="number"
                @input="update_selected_room({sqft: Number($event.target.value)})"
                :value="selectedRoom.sqft"
            >
            <span class="sqft-label">Sq Ft</span>
          </div>

          <div class="pt-3">
            <div class="btn-toolbar justify-content-center mb-3 flex-nowrap">
              <button
                  @click="cancelRoomEdit(),unselectRoom()"
                  class="btn btn-outline-dark btn-form mr-2 font-weight-normal"
              >
                CANCEL
              </button>
              <ConfirmButton text="APPLY" variant="outline-dark" class="font-weight-normal" @click.native="save()"/>
            </div>
            <div class="btn-toolbar controls">
              <button class="btn btn-light btn-control"
                      :class="{disabled: !isShow}"
              >
                <MoveIcon @mousedown.native="moveRoom(SELECTED_ROOM_STATES.MOVE)"/>
              </button>
              <button v-if="selectedRoom.labelDeletable"
                  class="btn btn-light btn-control"
                  :class="{disabled: !isShow}"
              >
                <DeleteIcon @click.native="roomToDelete = selectedRoom"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <ConfirmDialog v-if="hasAccess('rooms')"
        :content="roomToDelete ? deleteRoomText(roomToDelete.name) : ''"
        :isOpen="!!roomToDelete"
        @onSubmit="deleteRoom()"
        @onCancel="roomToDelete = null"
    ></ConfirmDialog>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapActions } from 'vuex';
  import ConfirmDialog from '../confirm-dialog/ConfirmDialog';
  import { SELECTED_ROOM_STATES } from '@/constants';
  import EditIcon from '@/components/svg-icons/edit';
  import MoveIcon from '@/components/svg-icons/move';
  import DeleteIcon from '@/components/svg-icons/delete';
  import LabelAim from '@/components/label-aim/LabelAim';
  import ConfirmButton from '@/components/confirm-button/ConfirmButton';

  export default {
    name: 'Rooms',
    data: () => {
      return {
        disabled: false,
        roomToDelete: null,
        SELECTED_ROOM_STATES,
      }
    },
    components: {ConfirmButton, LabelAim, DeleteIcon, MoveIcon, EditIcon, ConfirmDialog},
    methods: {
      ...mapMutations('rooms', ['update_selected_room', 'set_room']),
      ...mapActions('rooms', ['updateRoomById', 'deleteRoomById' ,'selectRoom', 'createRoom', 'saveRoom', 'unselectRoom', 'moveRoom']),
      ...mapActions('controls', ['setControlsState']),
      deleteRoomText(name) {
        return `Delete room - <span class="font-weight-semi-bold">"${name || 'unnamed'}"</span>`;
      },
      deleteRoom() {
        this.deleteRoomById(this.roomToDelete.id);
        this.roomToDelete = null;
      },
      cancelRoomEdit() {
        if (!this.selectedRoom.id) {
          this.unselectRoom();
        } else {
          this.update_selected_room({state: this.SELECTED_ROOM_STATES.SHOW});
        }
      },
      escapeKeydown(e) {
        if (e.code === 'Escape'
        ) {
          this.unselectRoom();
        }
      },
      cancelMove() {
        if (this.isMove) {
          this.update_selected_room({state: this.SELECTED_ROOM_STATES.SHOW})
        }

        if (this.isNew) {
          this.unselectRoom();
        }
      },
      setRoomPosition({clientX, clientY}) {
        let label_pos = this.viewer.moveLabel({x: clientX, y: clientY});

        if (this.isNew) {
          this.update_selected_room({state: this.SELECTED_ROOM_STATES.NAME, label_pos});
          this.set_room({room: {label_pos}});
        }
        if (this.isMove) {
          this.update_selected_room({state: this.SELECTED_ROOM_STATES.SHOW, label_pos});
          this.set_room({room: {id: this.selectedRoom.id, label_pos}});
          this.updateRoomById().then(() => { //saving bug
            this.unselectRoom();
          })
        }
      },
      save() {
        if (this.selectedRoom.id) {
          this.updateRoomById().then(() => {
            this.update_selected_room({state: SELECTED_ROOM_STATES.SHOW})
            this.unselectRoom();
          });
        } else { ///Executed when a new label is created
          this.saveRoom();
        }
      }
    },
    computed: {
      ...mapState('rooms', ['rooms', 'selectedRoom', 'roomsLabelMarkers', 'overlay']),
      ...mapState('viewer', ['viewer', 'tour']),
      ...mapState('floors', ['floor']),
      ...mapState('controls', ['mode']),
      isMove() {
        return this.selectedRoom.state === SELECTED_ROOM_STATES.MOVE;
      },
      isNew() {
        return this.selectedRoom.state === SELECTED_ROOM_STATES.NEW;
      },
      isShow() {
        return this.selectedRoom.state === SELECTED_ROOM_STATES.SHOW;
      },
      isEdit() {
        return this.selectedRoom.state === SELECTED_ROOM_STATES.NAME;
      },
    },
    created() {
      window.addEventListener('keydown', this.escapeKeydown);
    },
    destroyed() {
      window.addEventListener('keydown', this.escapeKeydown);
    },
  }
</script>
<style src="./Rooms.scss" scoped lang="scss"></style>
