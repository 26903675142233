<template>
  <svg width="36" height="26" viewBox="0 0 36 26" xmlns="http://www.w3.org/2000/svg">
    <path class="stroke"
        d="M1 4.58646C1 6.5444 5.39182 7.61122 7.58774 7.8999V13.5374M1 4.58646C1 -0.233064 24.7794 -0.157758 24.7794 4.58646M1 4.58646V16.786C1 18.7439 5.52666 20.0115 7.58774 20.25V13.5374M24.7794 4.58646C24.7794 6.54439 20.561 7.56102 18.2206 7.8999V13.5413M24.7794 4.58646V16.786C24.7794 18.8945 20.2913 19.999 18.2206 20.25V13.5413M7.58774 13.5374C10.9028 13.0856 14.9095 13.0869 18.2206 13.5413"
        stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="fill" fill-rule="evenodd" clip-rule="evenodd"
          d="M36 18.5C36 22.366 32.866 25.5 29 25.5C25.134 25.5 22 22.366 22 18.5C22 14.634 25.134 11.5 29 11.5C32.866 11.5 36 14.634 36 18.5ZM25.833 18.7089L28.7 15.9004L30.0761 17.2484L27.2178 20.0514L25.6982 20.1869L25.833 18.7089ZM30.3781 14.2568L31.7537 15.6038L30.7594 16.5788L29.3838 15.2308L30.3781 14.2568ZM25.1355 21.2389C25.1508 21.2389 25.1661 21.2384 25.1819 21.2369L27.3104 21.0474C27.5441 21.0264 27.7636 20.9259 27.9295 20.7629L32.5217 16.2644C32.8933 15.8994 32.878 15.2909 32.487 14.9074H32.4865L31.089 13.5384C30.7113 13.1689 30.061 13.1514 29.7027 13.5034L25.111 18.0019C24.9451 18.1644 24.842 18.3799 24.8206 18.6084L24.6271 20.6939C24.6133 20.8414 24.6674 20.9874 24.7746 21.0924C24.8706 21.1869 25.0007 21.2389 25.1355 21.2389ZM25.1354 22.2393H32.2812C32.562 22.2393 32.7917 22.4643 32.7917 22.7393C32.7917 23.0143 32.562 23.2393 32.2812 23.2393H25.1354C24.8547 23.2393 24.625 23.0143 24.625 22.7393C24.625 22.4643 24.8547 22.2393 25.1354 22.2393Z"
          />
  </svg>

</template>
<script>
export default {
  name: 'PanoEditIcon',
}
</script>
