import { FeatureFormModes, RTSLabels } from '@/constants';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/storage';
import config from '@/config';

export default {
  namespaced: true,
  state: () => ({
    features: [],
    featuresLabelMarkers: [],
    featureMode: FeatureFormModes.NEW,
    selectedFeature: null,
    selectedOwner: {},
    prevMedia: []
  }),
  mutations: {
    set_features(state, features) {
      state.features = [...features];
    },
    set_features_label_markers(state, array) {
      state.featuresLabelMarkers = [...array];
    },
    append_features(state, features) {
      features.forEach(feature => {
        if(!state.features.includes(feature))state.features.push(feature)
      });
    },
    set_feature(state, feature) {
      state.selectedFeature = {...feature};
    },
    set_feature_owner(state, owner) {
      state.selectedOwner = {...owner};
    },
    set_feature_mode(state, mode) {
      state.featureMode = mode;
    },
    set_prev_media(state, media) {
      state.prevMedia = [...media];
    },
    create_feature(state, payload) {
      let feat = {...payload};
      feat.isNew = true;
      state.features = [...state.features, feat];
    },
    update_feature(state, {feature, index}) {
      state.features = [...state.features.map((feat, i) => {
        if (i === index) {
          return {...feat, ...feature};
        }
        return feat;
      })]
    },
    delete_feature(state, id) {
      state.features = [...state.features.filter((f) => {
        return id ? f.id !== id : f.id;
      })]
    },
    delete_new_feature(state) {
      state.features = [...state.features.filter(f => !f.isNew)]
    }
  },
  actions: {
    selectFeature({commit}, feature) {
      if (feature.forSale && feature.owner && feature.owner.price) {
        commit('set_feature_owner', feature.owner);
      }
      commit('set_feature', feature);
    },
    createFeature({commit}, payload) {
      commit('create_feature', payload)
    },
    updateFeature({commit}, payload) {
      commit('update_feature', payload)
    },
    async deleteFeature({commit, rootState}, id) {
      commit('delete_feature', id);
      let deleteFeat = firebase.functions().httpsCallable('labels-deleteLabel'); //update function name
      commit('layout/toggle_confirm_button', true, {root: true});
      await deleteFeat({"tourId": rootState.viewer.tour.id, "labelId": id})
        .finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
    },
    async saveFeature({commit, state, rootState, dispatch}, {data, modal, modalId}) {
      let storageRef = firebase.storage().ref();
      let index
      let tourId = rootState.viewer.tour.id;
      let { cloudDomain, storageBucket } = config.firebase;
      let createFeat = firebase.functions().httpsCallable('labels-createLabel'); //update function name
      let updateFeat = firebase.functions().httpsCallable('labels-updateLabel'); //update function name
      commit('layout/toggle_confirm_button', true, {root: true});
      
      if (state.featureMode === FeatureFormModes.NEW) {
        let media = [...data.media];
        data.media = [];

        index = state.features.length - 1;
        let req = {"label": data, "tourId": tourId};
        req.label.position = state.features[index].position;

        let createFeatRes = await createFeat(req)
          .finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
        data.id = createFeatRes.data.id;


        if (media.length) {
          for (let i = 0; i < media.length; i++) {
            let imagePath = `tourLabels/tours/${tourId}/labels/${data.id}/${Math.random().toString(36).substr(2, 9)}.jpg`
            let ref = storageRef.child(imagePath);
            await ref.putString(media[i], 'data_url');

            data.media[i] = `https://${cloudDomain}/${storageBucket}/${imagePath}`;
          }
          await updateFeat({"label": {media: data.media}, "tourId": tourId, "labelId": data.id});
        }

        dispatch('rts/updateProgress', {key: RTSLabels.features, value: true}, {root: true})

      } else if (state.featureMode === FeatureFormModes.EDIT) {
        index = state.features.findIndex(feat => feat.id === data.id);
        let mediaToDelete = state.prevMedia.filter(img => data.media.indexOf(img) < 0);
        let mediaToAdd = data.media.filter(img => state.prevMedia.indexOf(img) < 0);

        for (let i in mediaToDelete) {
          let refUrl = mediaToDelete[i].replace(`https://${cloudDomain}/${storageBucket}/`, '');
          await storageRef.child(refUrl).delete().catch((e) => {
            console.log(e)
          });
        }

        for (let i in mediaToAdd) {
          let refUrl = `tourLabels/tours/${tourId}/labels/${data.id}/${Math.random().toString(36).substr(2, 9)}.jpg`
          let imgRef = storageRef.child(refUrl);
          await imgRef.putString(mediaToAdd[i], 'data_url');
          let mediaIndex = data.media.indexOf(mediaToAdd[i]);
          data.media[mediaIndex] = `https://${cloudDomain}/${storageBucket}/${refUrl}`;
        }

        await updateFeat({"label": data, "tourId": tourId, "labelId": data.id})
          .finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
      } else if (state.featureMode === FeatureFormModes.MOVE) {
        index = state.features.findIndex(feat => feat.id === data.id);

        await updateFeat({"label": data, "tourId": tourId, "labelId": data.id})
        .finally(() => commit('layout/toggle_confirm_button', false, {root: true}));
      } else {
        return;
      }
      commit('update_feature', {feature: data, index});
      if (modalId) {
        modal.hide(modalId);
      }
    }
  }
}
