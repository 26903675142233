<template>
  <div class="error-page">
    <div class="refresh">
      <a href="#" @click.prevent="loadTour()">
        <RefreshIcon/>
      </a>
    </div>
    <h1 class="text-primary header">404</h1>
    <p class="description text-center mb-5">
      Congratulations, <br/> you broke it.
    </p>
    <p class="text-muted text-center comment">
      Just kidding. <br/><br/>
      We’ll have something awesome for you soon,<br/> so try back again in a little bit.
    </p>
  </div>
</template>
<script>
 import { RefreshIcon } from '@/components/svg-icons';
 import { mapActions } from 'vuex/dist/vuex.esm.browser';

 export default {
   name: 'Error',
   components: {RefreshIcon},
   methods: {
     ...mapActions('viewer', ['loadTour'])
   }
 }
</script>
<style scoped lang="scss" src="./Error.scss"></style>
