<template>
  <svg class="close-icon" width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
    <line y1="-1" x2="24.3263" y2="-1" transform="matrix(0.672673 0.73994 -0.672673 0.73994 0 2)" class="stroke-secondary"
          stroke-width="2"/>
    <line y1="-1" x2="24.3263" y2="-1" transform="matrix(-0.672673 0.73994 0.672673 0.73994 18 2)" class="stroke-secondary"
          stroke-width="2"/>
  </svg>
</template>
<script>
export default {
  name: 'CloseIcon'
}
</script>
