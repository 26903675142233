import lottie from 'lottie-web/build/player/lottie';

export const DropdownMixin = {
  data() {
    return {
      open: false,
      direction: 1,
      anim: null
    }
  },
  methods: {
    toggle(dropdown, open) {
      this.toggleAnim();
      this.open = open;
      let dropMenu = dropdown.querySelector('.dropdown-menu');
      let height = dropdown.querySelector('.dropdown-menu-wrapper').clientHeight;
      dropMenu.style.height = height + 'px';
      if (!this.open) {
        setTimeout(() => {
          dropMenu.style.height = '0px';
        })
      }
      setTimeout(() => {
        dropMenu.style.height = "";
      }, 1000)
    },
    initAnim(data, ref) {
      this.anim = lottie.loadAnimation({
        animationData: {...data},
        container: ref,
        renderer: 'svg',
        loop: false,
        autoplay: false
      })
    },
    toggleAnim() {
      this.anim.setDirection(this.direction);
      this.anim.play();
      this.direction = -this.direction;
    }
  }
}
