<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 22"
   
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect    fill-rule="evenodd"
      clip-rule="evenodd" y="9" width="12" height="13"  />
    <rect    fill-rule="evenodd"
      clip-rule="evenodd" width="12" height="7"  />
    <rect    fill-rule="evenodd"
      clip-rule="evenodd" x="14" width="8" height="13"  />
  </svg>
</template>
<script>
export default {
  name: "FloorplanIcon",
};
</script>
