<template>
  <svg width="31" height="31" viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.2891 0.472595C17.825 0.163159 18.5104 0.346793 18.8198 0.882752L22.1815 6.70538C22.4909 7.24134 22.3073 7.92667 21.7713 8.23611L15.9487 11.5978C15.4127 11.9072 14.7274 11.7236 14.418 11.1877C14.1085 10.6517 14.2922 9.96636 14.8281 9.65693L19.6803 6.85551L16.8789 2.00332C16.5695 1.46736 16.7531 0.782031 17.2891 0.472595Z"
          class="fill-light"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.13209 24.0339C0.822657 23.498 1.00629 22.8127 1.54225 22.5032L7.36488 19.1415C7.90084 18.8321 8.58617 19.0157 8.8956 19.5517C9.20504 20.0876 9.02141 20.773 8.48545 21.0824L3.63325 23.8838L6.43467 28.736C6.74411 29.272 6.56047 29.9573 6.02451 30.2667C5.48855 30.5762 4.80323 30.3925 4.49379 29.8566L1.13209 24.0339Z"
          class="fill-light"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.50282 6.66953C8.03264 7.03248 6.67837 7.76193 5.56636 8.78983C4.45435 9.81774 3.62086 11.1106 3.14364 12.5477C2.66643 13.9849 2.56105 15.5195 2.83735 17.0084C2.95026 17.6169 2.54853 18.2017 1.94004 18.3146C1.33156 18.4275 0.746751 18.0258 0.633835 17.4173C0.288467 15.5562 0.420187 13.6379 1.0167 11.8415C1.61322 10.045 2.65509 8.42898 4.0451 7.1441C5.43511 5.85922 7.12795 4.9474 8.96567 4.49372C10.7975 4.04148 12.7139 4.05917 14.5369 4.545L21.466 6.17249C22.0684 6.314 22.4421 6.91712 22.3006 7.5196C22.1591 8.12207 21.556 8.49576 20.9535 8.35425L14.0078 6.72285C13.9964 6.72017 13.9851 6.71732 13.9738 6.71428C12.5112 6.32198 10.973 6.30659 9.50282 6.66953ZM1.01033 23.2151C1.15184 22.6127 1.75496 22.239 2.35743 22.3805L9.3031 24.0119C9.3145 24.0146 9.32586 24.0174 9.33717 24.0204C10.7998 24.4127 12.3379 24.4281 13.8081 24.0652C15.2783 23.7023 16.6326 22.9728 17.7446 21.9449C18.8566 20.917 19.6901 19.6241 20.1673 18.187C20.6445 16.7498 20.7499 15.2152 20.4736 13.7263C20.3607 13.1178 20.7624 12.533 21.3709 12.4201C21.9794 12.3072 22.5642 12.7089 22.6771 13.3174C23.0225 15.1785 22.8907 17.0968 22.2942 18.8933C21.6977 20.6897 20.6558 22.3058 19.2658 23.5906C17.8758 24.8755 16.183 25.7873 14.3453 26.241C12.5134 26.6932 10.5971 26.6756 8.77401 26.1897L1.84498 24.5622C1.2425 24.4207 0.868817 23.8176 1.01033 23.2151Z"
          class="fill-light"/>
  </svg>

</template>
<script>
export default {
  name: 'RefreshIcon',
}
</script>
