import { mapState } from 'vuex';
import {
  VIEWER_MODES,
  AppStates,
  UserRoles,
  CONTROLS_STATE,
  SELECTED_ROOM_STATES,
  TOOLTIPS,
  RTSLabels
} from '@/constants';
import { hasAccess as hA } from '@/constants/functions';
import handleError from '@/interceptors/error-handler';
import config from '@/config';

export const HelpersMixin =  {
  data() {
    return {
      isProduction: process.env.NODE_ENV === 'production',
      phone_regex: /^\(?([0-9]{3})\)?[\s]([0-9]{3})[-]([0-9]{4})$/,
      signInModal: 'sign-in',
      emailSignInFormId: 'email-sign-in-form',
      forgotPassFormId: 'forgot-pass-form',
      signInformId: 'firebase-form',
      floorEditModalId: 'floorEditModalId',
      tourEditModalId: 'tour-edit-modal-id',
      tourDetailsModalId: 'tour-details-modal-id',
      tourOwnerModalId: 'tour-owner-modal-id',
      featureListModalId: 'feature-list-modal',
      problemReportModalId: 'problem-report-modal',
      appStates: AppStates,
      VIEWER_MODES,
      RTSLabels,
      handleError,
      CONTROLS_STATE,
      TOOLTIPS
    }
  },
  computed: {
    ...mapState('rooms', ['rooms', 'selectedRoom']),
    ...mapState('layout', ['isMobileScreen']),
    ...mapState('controls', ['editMode', 'controlsState']),
    ...mapState('users', ['userRole', 'permissions']),
    showDependingOnControlsState() {
      return this.selectedRoom.state !== SELECTED_ROOM_STATES.NEW &&
        this.selectedRoom.state !== SELECTED_ROOM_STATES.MOVE &&
        this.controlsState !== this.CONTROLS_STATE.MOVE_FEATURE &&
        this.controlsState !== this.CONTROLS_STATE.EDIT_ENTRY_ARROW;
    },
    sumArea() {
      if (this.rooms && this.rooms.length) {
        return this.rooms.reduce((sum, room) => {
          return sum + room.sqft;
        }, 0) || 0;
      }
      return 0;
    },
    isMobile() {
      return this.isMobileScreen;
    },
    inThe3dAppIframe() {
      if (this.inIframe) {
        try {
          let ancestorDomain = document.location.ancestorOrigins[0];
          return  ancestorDomain.includes(config.the3dAppDomain);
        } catch {
          return false
        }
      } else {
        return false;
      }
    },
    inIframe: () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    isTouchable() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    isAndroid() {
      const toMatch = /Android/i
      const result = navigator.userAgent.match(toMatch)!==null? navigator.userAgent.match(toMatch)[0] == 'Android': false
      return result;
    }
  },
  methods: {
    isCoordsFitToScreen({x, y}) {
      let screenW = window.innerWidth;
      let screenH = window.innerHeight;
      let isXAxisFits = x > 0 && x < screenW;
      let isYAxisFits = y > 0 && y < screenH;
      return isXAxisFits && isYAxisFits;
    },
    isAdmin() {
      return this.userRole === UserRoles.ADMIN
    },
    hasAccess(feature) {
      return hA(feature, this.userRole, this.permissions, this.editMode);
    },
    floorName(floor, prefixWord) {
      let title = floor.floorplan.title;
      return title && title !== 'Untitled Floorplan' ? title : `${prefixWord ? `${prefixWord} ` : ''}${floor.floor}`;
    },
    panoThumbnail(url) {
      let {cloudDomain, storageBucket} = config.firebase;
      return `url(https://${cloudDomain}/${storageBucket}/${url})`;
    },
  }
}
