<template>
  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.9991 14.926C13.1684 14.926 14.9269 13.1674 14.9269 10.9981C14.9269 8.82886 13.1684 7.07031 10.9991 7.07031C8.82984 7.07031 7.07129 8.82886 7.07129 10.9981C7.07129 13.1674 8.82984 14.926 10.9991 14.926Z"
        />
    <path
        d="M4.47876 2.12109C3.57774 2.81256 2.78363 3.63314 2.12207 4.55634L3.37897 5.49902C3.95595 4.69889 4.64364 3.98475 5.42144 3.378L4.47876 2.12109Z"
        />
    <path
        d="M2.04338 8.09243L0.550808 7.62109C0.171367 8.70624 -0.014753 9.84955 0.000912652 10.999H1.57204C1.5688 10.0109 1.72803 9.02892 2.04338 8.09243Z"
        />
    <path
        d="M0.550781 14.4557C0.915131 15.5511 1.44497 16.5843 2.12191 17.5194L3.37881 16.5767C2.81089 15.7816 2.36107 14.9084 2.04335 13.9844L0.550781 14.4557Z"
        />
    <path
        d="M4.55664 19.876C5.49174 20.553 6.52494 21.0828 7.62034 21.4472L8.09168 19.9546C7.16764 19.6369 6.29446 19.1871 5.49932 18.6191L4.55664 19.876Z"
        />
    <path
        d="M7.62109 0.550808L8.09243 2.04338C9.02892 1.72803 10.0109 1.5688 10.999 1.57204V0.000912652C9.84955 -0.014753 8.70624 0.171367 7.62109 0.550808Z"
        />
    <path
        d="M17.4407 19.876C18.366 19.1863 19.1863 18.366 19.876 17.4407L18.6191 16.498C18.0349 17.3181 17.3181 18.0349 16.498 18.6191L17.4407 19.876Z"
        />
    <path
        d="M19.9541 13.9066L21.4467 14.3779C21.7883 13.2835 21.9735 12.1463 21.9966 11H20.4254C20.4287 11.9881 20.2694 12.9701 19.9541 13.9066Z"
        />
    <path
        d="M21.3681 7.54222C21.0037 6.44681 20.4739 5.41362 19.7969 4.47852L18.54 5.42119C19.108 6.21634 19.5578 7.08951 19.8755 8.01356L21.3681 7.54222Z"
        />
    <path
        d="M17.3612 2.04379C16.4261 1.36685 15.3929 0.837006 14.2975 0.472656L13.8262 1.96523C14.7502 2.28294 15.6234 2.73276 16.4185 3.30069L17.3612 2.04379Z"
        />
    <path
        d="M14.377 21.4477L13.9056 19.9551C12.9691 20.2704 11.9872 20.4297 10.999 20.4264V21.9975C12.1408 21.9295 13.2726 21.7453 14.377 21.4477Z"
        />
  </svg>
</template>
<script>
export default {
  name: 'SmoothIcon',
}
</script>
