import * as THREE from 'three'
import { MeshLine, MeshLineMaterial } from 'three.meshline'
import store from '../../../store';
import {VIEWER_MODES} from '../../../constants';
import customSpriteMat from '../shaders/customSpriteMat';
import gsap from 'gsap'

class MeasurementManager {
  constructor (scene) {
    this.measures = []
    this.areaMeasurePoints = []
    this.areaMeasureLines = []
    this.isIntersecting = false
    this.measuring = false
    this.measuringArea = false
    this.measureMode = 0

    this.modelUnitScaleFactor = 1
    this.scene = scene;
    this.measuresGroup = new THREE.Group()
    this.pointsGroup = new THREE.Group()
    this.scene.add(this.measuresGroup)
    this.scene.add(this.pointsGroup)
    this.orthoCam = new THREE.OrthographicCamera( -1, 1, -1, 1, 0.1, 10000 )

    this.tempPoint1;
    this.tempPoint2;
    this.tempLine;
    this.tempLength;
    this.polygon;

    this.circleGeometry = new THREE.SphereGeometry(0.045, 10, 10)
    this.circleMaterial = new THREE.MeshBasicMaterial({color: 0xE544FF})// customBasicAttenuation()
    this.lineMaterial = new MeshLineMaterial({
      color: 0xffffff,
      lineWidth: 0.03,
      sizeAttenuation: 1,
    });

    this.dashMaterial = new MeshLineMaterial({
      color: new THREE.Color(0xE544FF),
      lineWidth: 0.03,
      dashArray: 0.033,
      dashRatio: 0.5,
      depthTest: true,
      alphaTest: 0.5,
      transparent: true,
      sizeAttenuation: 1
    })

    this.magMode;
    this.renderTarget = new THREE.WebGLRenderTarget(200, 200);
    this.magplane;
    this.magvec = new THREE.Vector3();
    this.magmouse = new THREE.Vector2();
    this.planespawned = false;
    this.played = false;

    this.measureComplete = false
    this.magScene = new THREE.Scene();
    this.magCam = new THREE.PerspectiveCamera(75,window.innerWidth/window.innerHeight);
    this.dragging = 0
    this.draggingPointOne
    this.lineDrawn = false
    this.pickUp = false
    this.selectedIndex
    this.prevIndex
    this.nextIndex
    this.tempCamera
    this.floorCam = new THREE.OrthographicCamera( window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000 );

    this.createLabel()
  }

  createLabel = () => {
    this.label = document.createElement('div')
    this.label.classList.add('measure-label2')

    this.measureTextSpan = document.createElement('span')
    this.measureTextSpan.classList.add('measure-test')
    this.measureValueSpan = document.createElement('span')
    this.measureValueSpan.classList.add('measure-value')

    this.label.appendChild(this.measureTextSpan)
    this.label.appendChild(this.measureValueSpan)

    this.main = document.querySelector('#main')
    this.main.appendChild(this.label)
  }

  createMeasurePlane = (height) => {
    this.measurePlane = new THREE.Plane(new THREE.Vector3(0,0,1), -(height * 1.1))
    return this.measurePlane
  }

  updateMeasurePlane = (height) => {
    this.measurePlane.constant = -(height * 1.1)
  }

  createMagnifier = () => {
          this.magCam.up.set(0, 0, 1);
          const mat = customSpriteMat(this.renderTarget.texture,1)
          this.magplane = new THREE.Sprite(mat)
          this.magScene.add(this.magplane);
          this.magScene.position.z = -3;
          this.magplane.scale.set( 1, 2, 1 );
          this.magplane.visible = false;
  }

  moveMagnifier = (container , mousePos, camera, mouseHelper, renderer , mouseHelperOffset , isFloorplan ) => {
    if (this.magplane) {
      if (this.magMode) {
        const width = container.clientWidth
        const height = container.clientHeight
        const size = 128 //size of magnifier
        const multiplier = 1.75 // scale of magnifier
        const offsetX = mousePos.x - size / 2 / multiplier
        const offsetY = mousePos.y - size / 2 / multiplier
        if (isFloorplan === true) {
          this.tempCamera = camera.clone()
          this.floorCam.copy(camera)
          this.floorCam.setViewOffset(
            width,
            height,
            offsetX,
            offsetY,
            size / multiplier,
            size / multiplier
          )
        } else {
          camera.setViewOffset(
            width,
            height,
            offsetX,
            offsetY,
            size / multiplier,
            size / multiplier
          )
        }
        mouseHelper.material.opacity = 0
        renderer.setScissorTest(true)
        this.scene.background = new THREE.Color(0xdadada)
        renderer.setRenderTarget(this.renderTarget)
        isFloorplan === true ? renderer.render(this.scene, this.floorCam) : renderer.render(this.scene, camera)
        renderer.setRenderTarget(null)
        this.scene.background = undefined
        renderer.clearDepth()
        renderer.setScissorTest(false)
        renderer.setViewport(0, 0, width, height)
        isFloorplan === true ? camera.copy(this.tempCamera) : camera.clearViewOffset()
        this.magplane.visible = true;
        this.magCam.setViewOffset(
          width,
          height,
          0.5 * width - mousePos.x,
          0.5 * height - mousePos.y + (0.2 * height),
          width,
          height
        )
        if (!isFloorplan) {
          if (this.lineDrawn && this.measureMode < 4) {
            this.hoverPoints(mouseHelperOffset.position, [this.tempPoint1, this.tempPoint2])
          }
          if (this.dragging === 1) this.dragPoint(mouseHelperOffset.position)
        }
      }
      else if (this.magplane.visible == true && mouseHelper.material.opacity != 0.25) {
        mouseHelper.material.opacity = 0.25
      }
      ////Always Render when magMode is true. when it is false, stop rendering after magplane is invisible
      if(this.magMode===true){
        if(this.magplane.material.uniforms.alpha.value<1)gsap.to(this.magplane.material.uniforms.alpha, {value: 1.5})
        renderer.render(this.magScene, this.magCam);
      }else if(this.magMode===false){
        if(this.magplane.material.uniforms.alpha.value>0){
          gsap.to(this.magplane.material.uniforms.alpha, {value: -0.5})
          renderer.render(this.magScene, this.magCam);
        }
      }
    }
  }

  showMagnifier(state){
    if(this.magplane)this.magplane.visible = state
  }

  createTempLine = () => {
    const points = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)]
    const lineGeometry = new MeshLine()
    lineGeometry.setPoints(points);
    this.tempLine = new THREE.Mesh(lineGeometry, this.dashMaterial)
    this.tempLine.renderOrder = 3
    this.tempPoint1 = new THREE.Mesh(this.circleGeometry,this.circleMaterial)//Mesh(this.circleGeometry, this.circleMaterial)
    this.tempPoint2 = new THREE.Mesh(this.circleGeometry,this.circleMaterial)//Mesh(this.circleGeometry, this.circleMaterial)

    this.scene.add(this.tempLine)
    this.scene.add(this.tempPoint1)
    this.scene.add(this.tempPoint2)

    this.tempLine.visible = false
    this.tempPoint1.visible = this.tempPoint2.visible = false
  }

  createShape = () => {
    const shape = new THREE.Shape()
    for (let i = 0; i < this.areaMeasurePoints.length; i++) {
      const point = this.areaMeasurePoints[i]
      if (i === 0) shape.moveTo(point.position.x, point.position.y)
      else shape.lineTo(point.position.x, point.position.y)
    }
    const geometry = new THREE.ShapeGeometry( shape )
    const material = new THREE.MeshBasicMaterial( { color: 0xffffff, opacity: 0.5, transparent: true } )
    this.polygon = new THREE.Mesh( geometry, material )
    this.polygon.position.z = this.areaMeasurePoints[0].position.z
    this.polygon.renderOrder = 3
    this.measuresGroup.add(this.polygon)
  }

  updateShape = () => {
    const shape = new THREE.Shape()
    for (let i = 0; i < this.areaMeasurePoints.length; i++) {
      const point = this.areaMeasurePoints[i]
      if (i === 0) shape.moveTo(point.position.x, point.position.y)
      else shape.lineTo(point.position.x, point.position.y)
    }
    const geometry = new THREE.ShapeGeometry( shape )
    this.polygon.geometry = geometry
  }


  getIntersectionPoint(event, domElement) {
    const mousePosition = new THREE.Vector2()
    mousePosition.x = (event.clientX / (domElement.width / window.devicePixelRatio)) * 2 - 1
    mousePosition.y = (event.clientY / (domElement.height / window.devicePixelRatio)) * 2 - 1
    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mousePosition, this.orthoCam)
    const point = new THREE.Vector3()
    raycaster.ray.intersectPlane(this.measurePlane, point);
    return point;
  }

  dragStartArea = (camera) => {
    this.alignOrthoCam(camera);
  }

  dragArea = (e, camera, domElement) => {

    let p1 = this.getIntersectionPoint(e, domElement);

    const processed = this.areaMeasurePoints.reduce((accumulator, point) => {
      accumulator.push([point.position.x, point.position.y])
      return accumulator
    }, [])
    const mean = this.calcMean(processed);
    let dx = p1.x - mean[0];
    let dy = p1.y - mean[1];

    this.areaMeasureLines.forEach((line,index) => {
      let nextindex = index+1
      if(index === this.areaMeasureLines.length-1) nextindex = 0
      line.geometry = this.createLineGeo([this.areaMeasurePoints[index].position,this.areaMeasurePoints[nextindex].position])
    });

    this.updateShape()
    this.areaMeasurePoints.map(p => {
      let dx = p.position.x - mean[0];
      let dy = p.position.y - mean[1];
      p.position.x = p1.x + dx;
      p.position.y = p1.y + dy;
    })
  }

  setMeasurementPoints = (point1, point2) => {
    if (point1 && point1.length) {
      this.tempLine.geometry.points[0].set(...point1)
      this.tempPoint1.position.set(...point1)
      this.tempPoint1.visible = true
    }
    if (point2 && point2.length) {
      this.tempLine.geometry.points[1].set(...point2)
      this.tempPoint2.position.set(...point2)
      this.tempPoint2.visible = true
    }
    this.tempLine.geometry.setPoints(this.tempLine.geometry.points)
  }

  cleanMeasurements = () => {
    this.cleanMeasureText()
    this.tempLine.visible = false;
    this.tempPoint1.visible = this.tempPoint2.visible = false;
  }

  clickHandler = (mouseOffset) => {
    if(this.lineDrawn){
      if(mouseOffset.position.distanceTo(this.tempPoint1.position) < 0.2){
        this.draggingPointOne = true
        this.measureMode === 2

        //if(this.mobile){
           this.tempPoint1.scale = new THREE.Vector3(1.5,1.5,1.5)
           if(this.dragging==1)this.dragPoint(mouseOffset.position)
        //}

        this.dragging += 1
        if(this.dragging > 2) this.dragging = 1
      }
      else if(mouseOffset.position.distanceTo(this.tempPoint2.position) < 0.2){
        this.draggingPointOne = false
        this.measureMode === 2

        //if(this.mobile){
          this.tempPoint2.scale = new THREE.Vector3(1.5,1.5,1.5)
          if(this.dragging==1) this.dragPoint(mouseOffset.position)
        //}

        this.dragging += 1
        if(this.dragging > 2) this.dragging = 1
      }
      else /*if(this.mobile)*/{
        if(this.dragging==1){
        this.measureMode === 2
        this.dragPoint(mouseOffset.position)
        this.dragging += 1
        if(this.dragging > 2) this.dragging = 1
        }
        else{
          this.dragging = 0
        }
      }
      /*else {
        this.dragging = 0
      }*/
    }
  if(this.dragging==0){
    if (this.measureMode === 1) {
      this.tempLine.material = this.dashMaterial
      this.setMeasurementPoints(
        [mouseOffset.position.x, mouseOffset.position.y, mouseOffset.position.z],
        [mouseOffset.position.x, mouseOffset.position.y, mouseOffset.position.z]
      )
      this.lineDrawn = false
      store.commit('controls/toggle_measureComplete',false)
      this.measureMode = 2
    } else if (this.measureMode === 2) {
      this.setMeasurementPoints(
        null,
        [mouseOffset.position.x, mouseOffset.position.y, mouseOffset.position.z]
      )
      this.tempLine.material = this.lineMaterial
      this.lineDrawn = true
      store.commit('controls/toggle_measureComplete',true)
      this.measureMode = 1
    }
  }
    //if(this.lineDrawn) this.dragPoint(mouseOffset)
    this.tempLine.geometry.pointsNeedUpdate = true;
    if (this.measureMode > 1)
      this.tempLine.visible = true;
  }


  dragPoint = (position) => {
      if(this.draggingPointOne /*&& position.distanceTo(this.tempPoint1.position) < 0.2*/)
      {
        this.setMeasurementPoints(
          [position.x, position.y, position.z],
          null
        )

      }
      else if(!this.draggingPointOne /*&& position.distanceTo(this.tempPoint2.position) < 0.2*/)
      {
        this.setMeasurementPoints(
          null,
          [position.x, position.y, position.z]
        )
       // this.tempLine.geometry.pointsNeedUpdate = true;
      }
      /*if(this.mobile)*/ this.tempPoint1.scale = this.tempPoint2.scale = new THREE.Vector3(1,1,1)
      this.tempLine.geometry.pointsNeedUpdate = true
  }

  clickHandlerFloorplan = (mesh, event, camera, domElement) => {
    this.alignOrthoCam(camera)
    const point = this.getIntersectionPoint(event, domElement);
    //Measure Distance Tool
    if (point && this.measureMode < 4) {
      if(this.lineDrawn){
        if(point.distanceTo(this.tempPoint1.position) < 0.2){
          this.draggingPointOne = true
          this.measureMode === 2

          //if(this.mobile){
             this.tempPoint1.scale = new THREE.Vector3(1.5,1.5,1.5)
             if(this.dragging==1)this.dragPoint(point)
          //}

          this.dragging += 1
          if(this.dragging > 2) this.dragging = 1
        }
        else if(point.distanceTo(this.tempPoint2.position) < 0.2){
          this.draggingPointOne = false
          this.measureMode === 2

          //if(this.mobile){
            this.tempPoint2.scale = new THREE.Vector3(1.5,1.5,1.5)
            if(this.dragging==1) this.dragPoint(point)
          //}

          this.dragging += 1
          if(this.dragging > 2) this.dragging = 1
        }
        else /*if(this.mobile)*/{
          if(this.dragging==1){
          this.measureMode === 2
          this.dragPoint(point)
          this.dragging += 1
          if(this.dragging > 2) this.dragging = 1
          }
          else{
            this.dragging = 0
          }
        }
        /*else {
          this.dragging = 0
        }*/
      }
      if(this.dragging == 0){
        if (this.measureMode === 1) {
          this.tempLine.material = this.dashMaterial
          this.setMeasurementPoints(
            [point.x, point.y, point.z + 0.1],
            [point.x, point.y, point.z + 0.1]
          )
          this.lineDrawn = false
          store.commit('controls/toggle_measureComplete',false)
          this.measureMode = 2
        } else if (this.measureMode === 2) {
          this.setMeasurementPoints(
            null,
            [point.x, point.y, point.z + 0.1]
          )
          this.tempLine.material = this.lineMaterial
          this.lineDrawn = true
          store.commit('controls/toggle_measureComplete',true)
          this.measureMode = 1
        }
        this.tempLine.geometry.pointsNeedUpdate = true;
        if (this.measureMode > 1)
          this.tempLine.visible = true;

        return
      }
    }

    //Calculate Area Tool
    if (point && this.measureMode >= 4) {
      this.tempLine.material = this.dashMaterial
      if (this.isIntersecting) return
      if (this.areaMeasurePoints.length >= 3 && !this.measureComplete) {
        if (this.areaMeasurePoints[0].position.distanceTo(point) < 0.2) this.snapPoint = true
      }
      if (this.snapPoint) {
        this.measureComplete = true
        store.commit('controls/toggle_measureComplete',true)
        this.setMeasurementPoints(
          [this.areaMeasurePoints[0].position.x,
          this.areaMeasurePoints[0].position.y,
          this.areaMeasurePoints[0].position.z],
          [this.areaMeasurePoints[this.areaMeasurePoints.length - 1].position.x,
          this.areaMeasurePoints[this.areaMeasurePoints.length - 1].position.y,
          this.areaMeasurePoints[this.areaMeasurePoints.length - 1].position.z]
        )

        this.tempLine.geometry.points.map(vector => new THREE.Vector3().copy(vector))
        const newLine = this.createLine(this.tempLine.geometry.points)
        this.tempLine.geometry.pointsNeedUpdate = true
        this.areaMeasureLines.push(newLine)
        this.measuresGroup.add(newLine)

        this.snapPoint = false
        this.tempLine.visible = false;
        this.tempPoint1.visible = this.tempPoint2.visible = false;
        this.measureMode = 4

        this.createShape()
        return
      }

      if (this.measureMode === 4) {
        if(this.measureComplete){
          this.hitCounter = 0;
          for(let i = 0; i < this.areaMeasurePoints.length; i++)
          {
            if(point.distanceTo(this.areaMeasurePoints[i].position) < 0.2){
              this.tempLine.material = this.lineMaterial
              this.selectedIndex = i
              //assign prev and next point indicies that are connected via line segments
              if(this.selectedIndex==0){
                this.prevIndex = this.areaMeasureLines.length-1
                this.nextIndex = this.selectedIndex + 1
              }
              else if(this.selectedIndex==this.areaMeasureLines.length-1){
                this.prevIndex = this.selectedIndex-1
                this.nextIndex = 0
              }
              else{
                this.prevIndex = this.selectedIndex-1
                this.nextIndex = this.selectedIndex+1
              }
              if(!this.pickUp /*&& this.mobile*/){
                this.areaMeasurePoints[this.selectedIndex].scale = new THREE.Vector3(1.5,1.5,1.5)
              }
              if(this.pickUp /*&& this.mobile*/){
                this.areaMeasurePoints[this.selectedIndex].position = point
                this.areaMeasureLines[this.prevIndex].geometry = this.createLineGeo([this.areaMeasurePoints[this.prevIndex].position,this.areaMeasurePoints[this.selectedIndex].position])
                this.areaMeasureLines[this.selectedIndex].geometry = this.createLineGeo([this.areaMeasurePoints[this.selectedIndex].position,this.areaMeasurePoints[this.nextIndex].position])
                this.updateShape()
                this.areaMeasurePoints[this.selectedIndex].scale = new THREE.Vector3(1,1,1)
              }
              this.pickUp = !this.pickUp
            }
            else if(this.pickUp /*&& this.mobile*/){
              this.hitCounter += 1;
              if(this.hitCounter >= this.areaMeasurePoints.length){
                this.areaMeasurePoints[this.selectedIndex].position = point
                this.areaMeasureLines[this.prevIndex].geometry = this.createLineGeo([this.areaMeasurePoints[this.prevIndex].position,this.areaMeasurePoints[this.selectedIndex].position])
                this.areaMeasureLines[this.selectedIndex].geometry = this.createLineGeo([this.areaMeasurePoints[this.selectedIndex].position,this.areaMeasurePoints[this.nextIndex].position])
                this.updateShape()
                this.areaMeasurePoints[this.selectedIndex].scale = new THREE.Vector3(1,1,1)
                this.pickUp = !this.pickUp
              }
            }
            else{
              this.hitCounter += 1;
              if(this.hitCounter >= this.areaMeasurePoints.length){
                this.deleteAreaMeasure()
                this.measureComplete = false
                store.commit('controls/toggle_measureComplete',false)
                //first point
                this.areaMeasurePoints = []
                this.areaMeasureLines = []
                this.setMeasurementPoints(
                  [point.x, point.y, point.z + 0.1],
                  [point.x, point.y, point.z + 0.1]
                )
                this.tempLine.geometry.pointsNeedUpdate = true
                this.tempLine.visible = true

                this.measureMode = 5
              }
            }
          }
        }
        else{
        this.deleteAreaMeasure()
        this.measureComplete = false
        store.commit('controls/toggle_measureComplete',false)
        //first point
        this.areaMeasurePoints = []
        this.areaMeasureLines = []
        this.setMeasurementPoints(
          [point.x, point.y, point.z + 0.1],
          [point.x, point.y, point.z + 0.1]
        )
        this.tempLine.geometry.pointsNeedUpdate = true
        this.tempLine.visible = true

        this.measureMode = 5
        }
      } else if (this.measureMode === 5) {
        //second point
        this.setMeasurementPoints(
          null,
          [point.x, point.y, point.z + 0.1]
        )
        this.tempLine.geometry.pointsNeedUpdate = true
        this.tempLine.visible = true

        const newPoint1 = this.tempPoint1.clone()
        const newPoint2 = this.tempPoint2.clone()
        this.tempLine.geometry.points.map(vector => new THREE.Vector3().copy(vector))
        const newLine = this.createLine(this.tempLine.geometry.points)
        this.areaMeasurePoints.push(newPoint1, newPoint2)
        this.areaMeasureLines.push(newLine)
        this.pointsGroup.add(newPoint1)
        this.pointsGroup.add(newPoint2)
        this.measuresGroup.add(newLine)

        this.setMeasurementPoints(
          [point.x, point.y, point.z + 0.1],
          [point.x, point.y, point.z + 0.1]
        )

        this.measureMode = 6
      } else if (this.measureMode === 6) {

        //n point
        this.setMeasurementPoints(
          null,
          [point.x, point.y, point.z + 0.1]
        )

        const newPoint2 = this.tempPoint2.clone()
        this.tempLine.geometry.points.map(vector => new THREE.Vector3().copy(vector))
        const newLine = this.createLine(this.tempLine.geometry.points)
        this.areaMeasurePoints.push(newPoint2)
        this.areaMeasureLines.push(newLine)
        this.pointsGroup.add(newPoint2)
        this.measuresGroup.add(newLine)

        this.setMeasurementPoints(
          [point.x, point.y, point.z + 0.1],
          [point.x, point.y, point.z + 0.1]
        )
        this.tempLine.geometry.pointsNeedUpdate = true
      }
    }
  }

  updatePointsFloorplan = (event, camera, domElement) => {
    this.alignOrthoCam(camera)
    const point = this.getIntersectionPoint(event, domElement);
    if(this.lineDrawn && this.measureMode < 4){
      this.hoverPoints(point,[this.tempPoint1,this.tempPoint2])
    }else if(this.measureComplete){
      this.hoverPoints(point,this.areaMeasurePoints)
    }
    if(this.dragging==1){
      this.dragPoint(point)
    }

    if(this.measureMode == 4 && this.pickUp){
      this.areaMeasurePoints[this.selectedIndex].position = point
      this.areaMeasureLines[this.prevIndex].geometry = this.createLineGeo([this.areaMeasurePoints[this.prevIndex].position,this.areaMeasurePoints[this.selectedIndex].position])
      this.areaMeasureLines[this.selectedIndex].geometry = this.createLineGeo([this.areaMeasurePoints[this.selectedIndex].position,this.areaMeasurePoints[this.nextIndex].position])
      this.updateShape()
    }

    //raycast hit and we are placing length point 2 or area point n
    if (point && (this.measureMode === 2 || this.measureMode > 4)) {
      this.setMeasurementPoints(
        null,
        [point.x, point.y, point.z]
      )
      this.tempLine.geometry.pointsNeedUpdate = true;

      //if we are close to the first point, snap it to complete
      if (this.measureMode === 6) {
        if (this.areaMeasurePoints[0].position.distanceTo(point) < 0.2 && this.areaMeasurePoints.length >= 3) {
          this.setMeasurementPoints(
            null,
            [this.areaMeasurePoints[0].position.x,
            this.areaMeasurePoints[0].position.y,
            this.areaMeasurePoints[0].position.z]
          )
          this.snapPoint = true
        } else {
          this.snapPoint = false
        }
      }
    }
    if (this.areaMeasurePoints.length >= 2 && this.measureMode !== 4) {
      this.isIntersecting = false
      const tempLine = [
        [this.tempLine.geometry.points[1].x, this.tempLine.geometry.points[1].y],
        [this.tempLine.geometry.points[0].x, this.tempLine.geometry.points[0].y]
      ]

      for (let i = 0; i < this.areaMeasurePoints.length - 1; i++) {
        const line = ([
          [this.areaMeasurePoints[i].position.x, this.areaMeasurePoints[i].position.y],
          [this.areaMeasurePoints[i + 1].position.x, this.areaMeasurePoints[i + 1].position.y]
        ])
        if (this.calcIntersect(tempLine, line)) this.isIntersecting = true
      }
      if (this.isIntersecting) this.showTempLine(false)
      else this.showTempLine(true)
    }
  }

  hoverPoints = (mouse_position,points) => {
    points.forEach(point => {
      if(mouse_position.distanceTo(point.position)<=0.2){
        point.scale = new THREE.Vector3(1.5,1.5,1.5)
      }
      else if(point.scale.x !== 1){
        point.scale = new THREE.Vector3(1,1,1)
      }
    });
  }

  updatePoints = (mouseOffset) => {
    this.setMeasurementPoints(
      null,
      [mouseOffset.position.x, mouseOffset.position.y, mouseOffset.position.z]
    )
    this.tempLine.geometry.pointsNeedUpdate = true;
  }

  alignOrthoCam = camera => {
    this.orthoCam.left = camera.left
    this.orthoCam.right = camera.right
    this.orthoCam.bottom = camera.top
    this.orthoCam.top = camera.bottom
    this.orthoCam.updateProjectionMatrix()
    this.orthoCam.position.copy(camera.position)
    this.orthoCam.quaternion.copy(camera.quaternion)
    this.orthoCam.updateMatrixWorld()
  }

  createLine = (lineGeometryPoints) => {
    let lineGeometry = new MeshLine();
    lineGeometry.setPoints(lineGeometryPoints);
    const line = new THREE.Mesh(lineGeometry, this.lineMaterial);
    line.visible = true;
    return line
  }

  createLineGeo = (lineGeometryPoints) => {
    let lineGeometry = new MeshLine();
    lineGeometry.setPoints(lineGeometryPoints);
    return lineGeometry
  }

  storeLengthMeasure = (point1, point2, lineGeometryPoints) => {
    //clone all the THREE vectors and meshes
    const newPoint1 = point1.clone()
    const newPoint2 = point2.clone()
    lineGeometryPoints.map(vector => new THREE.Vector3().copy(vector))
    const newLine = this.createLine(lineGeometryPoints)

    const newMeasure = {
      id: Math.floor(Math.random() * 1000000),
      floor: null,
      mode: VIEWER_MODES.ORBIT,
      type: 'length',
      points: [newPoint1, newPoint2],
      line: newLine,
      length: this.calcLength(lineGeometryPoints)
    }

    this.measures.push(newMeasure)
    this.pointsGroup.add(newPoint1)
    this.pointsGroup.add(newPoint2)
    this.measuresGroup.add(newLine)
  }

  calcLength = (lineGeometryPoints) => {
    return (lineGeometryPoints[0].distanceTo(
      lineGeometryPoints[1]) * this.modelUnitScaleFactor)//.toFixed(1)
  }

  calcArea = (vertices, signed = false) => {
    //https://github.com/HarryStevens/geometric
    let a = 0;

    for (let i = 0, l = vertices.length; i < l; i++) {
      const v0 = vertices[i],
            v1 = vertices[i === l - 1 ? 0 : i + 1];

      a += v0[0] * v1[1];
      a -= v1[0] * v0[1];
    }

    return signed ? a / 2 : Math.abs(a / 2);
  }

  calcMean = (vertices) => {
    //https://github.com/HarryStevens/geometric
    let x = 0, y = 0, l = vertices.length;

    for (let i = 0; i < l; i++) {
      const v = vertices[i];

      x += v[0];
      y += v[1];
    }

    return [x / l, y / l];
  }

  calcIntersect = (lineA, lineB) => {
    //https://github.com/HarryStevens/geometric
    let a = lineA[0][0],
        b = lineA[0][1],
        c = lineA[1][0],
        d = lineA[1][1],
        p = lineB[0][0],
        q = lineB[0][1],
        r = lineB[1][0],
        s = lineB[1][1],
        det, gamma, lambda;

    det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) {
      return false;
    } else {
      lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
      gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
      return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
    }
  }

  calcFacingCamera = (pos, camera) => {
    return pos.angleTo(camera.getWorldDirection(new THREE.Vector3())) < Math.PI / 2
  }

  updateTempLabel = (camera, width, height) => {
    if (this.tempLine.visible || this.areaMeasurePoints.length >= 2) {
      let pos = this.tempLine.geometry.points[1].clone()
      pos.sub(camera.position)

      if (this.calcFacingCamera(pos, camera)) {
        let dir = this.tempLine.geometry.points[1].clone().sub(this.tempLine.geometry.points[0])
        const length = dir.length()
        dir = dir.normalize().multiplyScalar(length * 0.5)
        pos = this.tempLine.geometry.points[0].clone().add(dir)
        let value = this.calcLength(this.tempLine.geometry.points)

        if (this.areaMeasurePoints.length >= 3) {
          const processed = this.areaMeasurePoints.reduce((accumulator, point) => {
            accumulator.push([point.position.x, point.position.y])
            return accumulator
          }, [])
          const mean = this.calcMean(processed)
          pos = new THREE.Vector3(mean[0], mean[1], pos.z)
          value = this.calcArea(processed) * 10.764 / 3.281
        }

        pos.project(camera);
        let w_2 = width / 2;
        let h_2 = height / 2;
        let x = (pos.x * w_2) + w_2,
          y = -(pos.y * h_2) + h_2;
        
        if (this.areaMeasurePoints.length < 3) {
          let ft = Math.floor(+value * 3.28084)
          let inch = Math.round(((+value * 3.28084) - ft) * 12)
          let inch_suffix = 'In'
          if(inch === 12){
            ft += 1
            inch = ''
            inch_suffix = ''
          }else if(inch === 0){
            inch = ''
            inch_suffix = ''
          }
          value = `${ft} Ft ${inch} ${inch_suffix}`;
        } else {
          if (this.isIntersecting) {
            value = `INVALID`
          }else {
            value = `${Math.round(+value * 3.28084)} Sq Ft`
          }
        }
        
        this.label.style.display = 'block'

        this.measureTextSpan.innerHTML = `${this.areaMeasurePoints.length >= 3 ? 'Area' : 'Length'}: `
        this.measureValueSpan.innerHTML = `${value}`

        this.label.style.cssText += `transform: translate(calc(${x}px - 50%), calc(${y}px - 50%));`
      } else {
        this.cleanMeasureText()
      }
    }
  }

  cleanMeasureText = () => {
    this.label.style.display = 'none'
  }

  showMeasures = (camera, width, height) => {
    //loop through all measures and show them
    //TODO - check a floor property to support showing measures for multiple floors
    this.measures.forEach(measure => {
      measure.points.forEach(point => {point.visible = true})
      measure.line.visible = true
      this.updateLabel(camera, width, height, measure)
    })
  }

  showTempLine = (state) => {
    this.tempPoint1.visible = state
    this.tempPoint2.visible = state
    this.tempLine.visible = state
  }

  hideMeasures = () => { ///This is called each frame
    //loop through all measures and hide them
    this.measures.forEach(measure => {
      measure.points.forEach(point => {point.visible = false})
      measure.line.visible = false
    })

      if(this.dragging!=0)this.dragging = 0
      if(this.lineDrawn){
        this.lineDrawn = false
        store.commit('controls/toggle_measureComplete',false)
      }
      if(this.measureMode!=0)this.measureMode=0

    this.deleteAreaMeasure()
  }

  deleteAreaMeasure = () => { ///This is called each frame
    this.measuresGroup.position.x = 0;
    this.measuresGroup.position.y = 0;
    this.areaMeasurePoints.forEach(point => {
      point.geometry.dispose()
      point.material.dispose()
      this.pointsGroup.remove(point)
    })
    this.areaMeasureLines.forEach(line => {
      line.geometry.dispose()
      line.material.dispose()
      this.measuresGroup.remove(line)
    })
    this.areaMeasurePoints = []
    this.areaMeasureLines = []

    if (this.polygon !== undefined) {
      this.polygon.geometry.dispose()
      this.polygon.material.dispose()
      this.measuresGroup.remove(this.polygon)
      this.polygon = undefined
    }

    if(this.measureComplete){ 
      this.measureComplete = false
      store.commit('controls/toggle_measureComplete',false)
    }
  }
}

export default MeasurementManager
