<template>
  <div class="root" v-if="selectedFeature">
      <div class="gallery" v-if="selectedFeature.media && selectedFeature.media.length">
        <b-carousel
            :indicators="selectedFeature.media.length > 1"
            :interval="0"
            :controls="selectedFeature.media.length > 1"
        >
          <b-carousel-slide
              v-for="(img, index) in selectedFeature.media" :key="index"
              :img-src="img"
          >
            <template #img>
              <div class="d-flex justify-content-center h-100 w-100">
                <img :src="img" alt="image" class="d-block gallery-image">
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="content">
        <div class="feature-title mb-3 d-flex justify-content-between">
          <div class="h5 font-weight-semi-bold">{{ selectedFeature.title }}</div>
          <div v-if="selectedFeature.forSale" class="text-primary font-weight-semi-bold for-sale-label mt-1">
            • For sale
            <span v-if="selectedFeature.reference === REFERENCES.OWNER && selectedFeature.owner.name">by owner</span>
            <!-- <span v-else>{{ selectedFeature.listing_url }}</span> -->
          </div>
        </div>
        <p v-if="selectedFeature.description" class="text-secondary feature-description">{{ selectedFeature.description }}</p>
        <div class="price-container">
          <span v-if="selectedFeature.owner && selectedFeature.owner.condition" class="condition-value">
            {{`${selectedFeature.owner.condition.slice(0, 1).toUpperCase()}${selectedFeature.owner.condition.slice(1).toLowerCase()}`}}
            </span>
          <span v-if="selectedFeature.owner && selectedFeature.owner.price" class="text-primary font-weight-semi-bold price-value">
            ${{ selectedFeature.owner.price }}
          </span>
        </div>
        <div class="d-flex justify-content-center">
          <button @click="$emit('showOwner')" v-if="selectedFeature.reference === REFERENCES.OWNER && selectedFeature.owner.price"
                  class="btn btn-primary rounded-pill btn-form">
            INQUIRE
          </button>
          <a v-if="selectedFeature.reference === REFERENCES.LISTING" class="btn btn-primary rounded-pill btn-form" :href="selectedFeature.listing_url" target="_blank" rel="noopener noreferrer">
            PURCHASE
          </a>
        </div>
      </div>
  </div>
</template>
<script>
 import { REFERENCES } from '@/constants';
 import { mapState } from 'vuex';

 export default {
   name: 'FeatureDetails',
   data() {
     return {
       REFERENCES: REFERENCES,
     }
   },
   computed: {
     ...mapState('features', ['selectedFeature'])
   }
 }
</script>
<style scoped lang="scss" src="./FeatureDetails.scss"></style>
