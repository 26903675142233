var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"borderless-form"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_name"}},[_vm._v("Tour Title*")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",attrs:{"name":"name","type":"text","placeholder":"Title","id":"tour_name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('InvalidFeedback',{attrs:{"errors":errors}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_address"}},[_vm._v("Tour Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"form-control",attrs:{"name":"address","type":"text","placeholder":"Address","id":"tour_address"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_address"}},[_vm._v("Tour Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"form-control",attrs:{"rows":"4","name":"description","placeholder":"Tell us a little bit about your tour..."},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})]),_c('h5',{staticClass:"header font-weight-semi-bold text-center mb-2"},[_vm._v("Contact (optional)")]),_c('p',{staticClass:"small text-secondary mb-4"},[_vm._v("If you want to add contact info then Name and Email or Phone are required")]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_owner_name"}},[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":{required: _vm.anyContactInfo}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactInfo.name),expression:"contactInfo.name"}],staticClass:"form-control",attrs:{"name":"address","type":"text","placeholder":"Add name","id":"tour_owner_name"},domProps:{"value":(_vm.contactInfo.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactInfo, "name", $event.target.value)}}}),_c('InvalidFeedback',{attrs:{"errors":errors}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_owner_title"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactInfo.title),expression:"contactInfo.title"}],staticClass:"form-control",attrs:{"name":"address","type":"text","placeholder":"Add job title","id":"tour_owner_title"},domProps:{"value":(_vm.contactInfo.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactInfo, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_owner_email"}},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":_vm.emailOrPhoneRule('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactInfo.email),expression:"contactInfo.email"}],staticClass:"form-control",attrs:{"name":"address","type":"text","placeholder":"Add email address","id":"tour_owner_email"},domProps:{"value":(_vm.contactInfo.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactInfo, "email", $event.target.value)}}}),_c('InvalidFeedback',{attrs:{"errors":errors}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tour_owner_phone"}},[_vm._v("Phone")]),_c('ValidationProvider',{attrs:{"name":"Phone","rules":_vm.emailOrPhoneRule('phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"},{name:"model",rawName:"v-model",value:(_vm.contactInfo.phone),expression:"contactInfo.phone"}],staticClass:"form-control",attrs:{"name":"address","type":"text","placeholder":"Add phone number","id":"tour_owner_phone"},domProps:{"value":(_vm.contactInfo.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactInfo, "phone", $event.target.value)}}}),_c('InvalidFeedback',{attrs:{"errors":errors}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"btn-form mr-2",attrs:{"type":"button","variant":"light","size":"sm"},on:{"click":function($event){return _vm.$emit('onCancel')}}},[_vm._v("CANCEL ")]),_c('ConfirmButton',{attrs:{"text":"CONFIRM","disabled":invalid},nativeOn:{"click":function($event){return _vm.update()}}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }