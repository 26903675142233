<template>
  <div id="rts-guide-container">
    <div v-if="step" class="rts-controller" :class="{mobile: isMobile}">
      <div id="mobile-rts-header">
        <div class="progress-text">Task In Progress</div>
        <div class="step-text">{{RTSSteps[step].title}}</div>
      </div>
      <div id="mobile-rts-buttons">
        <button @click="cancelProgress(),setControlsState(CONTROLS_STATE.NULL)" class="btn btn-sm btn-light shadow">Cancel</button>
        <button @click="updateProgress({key: step, value: true}), setControlsState(CONTROLS_STATE.NULL)" class="btn btn-sm btn-light shadow">Next</button>
      </div>
    </div>
    <b-modal
        v-model="showFinishProgressModal"
        @keypress.esc="toggle_finish_progress_modal(false)"
        hide-header
        hide-footer
    >
      <template #default="{}">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <img width="100" class="mb-3" src="../../../assets/firework.svg" alt="firework"/>
          <h5 class="font-weight-semi-bold">Look at you go!</h5>
          <p class="mb-5 text-center">Your <span class="font-weight-semi-bold text-primary">BEGINNER'S GUIDE</span>
            checklist is complete. Time to start sharing and promoting your tour.
          </p>
          <div class="btn-toolbar">
            <button @click="toggle_finish_progress_modal(false)" type="button" class="btn btn-light shadow btn-form text-primary width-100px mr-4">Later</button>
            <button type="button" @click="openShareMenu()" class="btn btn-light shadow btn-form text-primary width-100px">Share Tour</button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>


import { mapActions, mapMutations, mapState } from 'vuex';
import { RTSSteps } from '@/store/rts';
import { MenuPages } from '@/constants';

export default {
  name: 'RTSGuide',
  data() {
    return {
      RTSSteps
    }
  },
  computed: {
    ...mapState('rts', ['step', 'showFinishProgressModal', 'rtsMode'])
  },
  methods: {
    ...mapActions('rts', ['selectStep', 'updateProgress', 'toggleRTSMode']),
    ...mapActions('mobile', ['toggleMobileMenu']),
    ...mapActions('controls', ['setControlsState']),
    ...mapMutations('rts', ['toggle_finish_progress_modal']),
    ...mapMutations('mobile', ['set_menu_page']),
    cancelProgress() {
      this.toggleRTSMode(false);
      this.selectStep(null)
    },
    openShareMenu() {
      this.toggle_finish_progress_modal(false)
      this.set_menu_page(MenuPages.SHARE)
      this.toggleMobileMenu(true)
    }
  }
}
</script>
<style src="./RTSGuide.scss" scoped lang="scss"></style>
